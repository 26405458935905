<script setup>
  import { ref, inject, computed } from 'vue'
  import { processDefinition } from '../../utils'
  import BcsModal from '../BcsModal.vue'
  import BcsDynamicText from '../BcsDynamicText.vue'
  import { isEmpty, get } from 'lodash'
  import { RequestService } from '../../services/RequestService'
  import { useTemplateVariablesStore } from '../../stores/TemplateVariablesStore'
  import bookIcon from '@/assets/images/book_icon.svg'

  const templateStore = useTemplateVariablesStore()

  const { getDefinitionById } = templateStore
  const { handleErrors } = RequestService()

  const currentDefinition = ref({})
  const emitter = inject('emitter')
  const centerModalRef = ref(null)

  // history of definition
  const definitionStack = ref([])

  const goBack = async () => {
    if (definitionStack.value.length > 0) {
      centerModalRef.value.closeModal()
      await delay(200)

      currentDefinition.value = definitionStack.value.pop()

      centerModalRef.value.openModal()
    }
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  function closeAndClearModal() {
    centerModalRef.value.closeModal()
    definitionStack.value = []
  }

  emitter.on(
    'toggleCenterDefinitionModal',
    async ({ id: currentId, previousModalData = {}, definitionExpected, template }) => {
      // if a definition is already open, make sure to close it before opening a new one
      if (!isEmpty(currentDefinition.value)) {
        centerModalRef.value.closeModal()
        await delay(200)
      }
      const id = currentId || 'Missing id, check your configuration!!'

      definitionStack.value.push(currentDefinition.value)
      const processedDefinition =
        /\s/.test(id) ?
          { contentTitle: '', id, title: definitionExpected, processedText: id }
        : await getProcessedDefinition(id, definitionExpected)

      if (processedDefinition) {
        currentDefinition.value = {
          ...processedDefinition,
          titleTemplate: template,
          previousModalData
        }
      }

      centerModalRef.value.openModal()
    }
  )

  const getProcessedDefinition = async (id, definitionExpected) => {
    try {
      const result = await getDefinitionById(id, definitionExpected)
      return processDefinition(result, id)
    } catch (error) {
      handleErrors('Error getting definition:', error)
      return null
    }
  }

  const backToParams = computed(() => {
    const previousDefinitionIndex = definitionStack.value.length - 1

    if (!isEmpty(currentDefinition.value.previousModalData)) {
      return {
        label: get(currentDefinition.value, 'previousModalData.backToLabel', ''),
        handleClick: () => {
          closeAndClearModal()
          const handleFunction = get(
            currentDefinition.value,
            'previousModalData.handleModal',
            () => null
          )
          handleFunction(true)
        }
      }
    } else if (
      definitionStack.value.length > 0 &&
      !isEmpty(definitionStack.value[previousDefinitionIndex])
    ) {
      return {
        label: get(definitionStack.value, `${previousDefinitionIndex}.title`),
        handleClick: goBack
      }
    }
    return {
      label: '',
      handleClick: () => null
    }
  })
</script>

<template>
  <BcsModal
    @modalButtonClick="() => null"
    @closeButtonClicked="() => null"
    hideCloseButton
    ref="centerModalRef">
    <!-- Header -->
    <template #header>
      <div class="d-flex w-100 justify-content-between align-items-center">
        <img :src="bookIcon" />
        <span class="modal-title header-22 ms-2">
          <strong>Definition</strong>
        </span>
        <button
          type="button"
          class="btn-close"
          @click="closeAndClearModal"
          aria-label="Close"></button>
      </div>
    </template>
    <span
      v-if="!isEmpty(backToParams.label)"
      class="text-primary d-flex align-items-start mb-2 text-14"
      @click="backToParams.handleClick"
      role="button">
      <i class="bi bi-arrow-left me-2 mb-3"></i>
      <strong>Back to {{ backToParams.label }}</strong>
    </span>
    <div class="text-start">
      <div class="header-20 mb-2">
        <strong v-if="isEmpty(currentDefinition?.titleTemplate)">
          {{ get(currentDefinition, 'title', '') }}
        </strong>
        <strong v-else class="offcanvas-title">
          {{ get(currentDefinition, 'titleTemplate', '') }}
        </strong>
      </div>
      <BcsDynamicText
        v-if="currentDefinition && !get(currentDefinition, 'id', '').includes(' ')"
        :text="get(currentDefinition, 'id', '')"
        :isCenterModal="true" />
      <div v-else v-html="get(currentDefinition, 'processedText', '')"></div>
    </div>
  </BcsModal>
</template>

<style scoped lang="scss" />
