import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import router from './router'

import { initializeApp } from 'firebase/app'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './scss/custom.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import mitt from 'mitt' // Import mitt

const emitter = mitt() // Initialize mitt

const CustomTooltip = {
  mounted(el, binding) {
    const tooltip = document.createElement('div')
    tooltip.className = 'custom-tooltip'
    tooltip.textContent = binding.value
    tooltip.style.position = 'absolute'
    tooltip.style.opacity = '0.8'
    tooltip.style.backgroundColor = 'black'
    tooltip.style.color = 'white'
    tooltip.style.padding = '5px'
    tooltip.style.borderRadius = '3px'
    tooltip.style.zIndex = '9999'
    tooltip.style.display = 'none'
    el.addEventListener('mouseenter', () => {
      document.body.appendChild(tooltip)
      const rect = el.getBoundingClientRect()
      tooltip.style.top = rect.top + window.pageYOffset - tooltip.offsetHeight - 5 + 'px'
      tooltip.style.left = rect.left + window.pageXOffset + 'px'
      tooltip.style.display = 'block'
    })
    el.addEventListener('mouseleave', () => {
      document.body.removeChild(tooltip)
    })
  }
}

const getFirebaseConfig = () => {
  const projectType = import.meta.env.VITE_PROJECT_TYPE_FIREBASE || 'dev'
  let config

  switch (projectType) {
    case 'dev':
      config = JSON.parse(import.meta.env.VITE_FIREBASE_DEV_CONFIG)
      break
    case 'qa':
      config = JSON.parse(import.meta.env.VITE_FIREBASE_QA_CONFIG)
      break
    case 'uat':
      config = JSON.parse(import.meta.env.VITE_FIREBASE_UAT_CONFIG)
      break
    default:
      throw new Error('FIREBASE_PROJECT_TYPE should be "dev" or "qa" or "uat')
  }

  return config
}

const firebaseConfig = getFirebaseConfig()
const region = 'us-central1'

// Generate dynamic URLs based on project configuration
const appUrl = `https://${firebaseConfig.projectId}.web.app`

console.log('appUrl---', appUrl)
const functionsBaseUrl = `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net`

console.log('functionsBaseUrl---', functionsBaseUrl)
const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://9fc039e43b038cfec77d98f3f6d83c37@o4507102272618496.ingest.us.sentry.io/4507102272880640',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

initializeApp(firebaseConfig)

app.directive('custom-tooltip', CustomTooltip)

app.provide('wsroute', '/bcs/assets/components/bcs/rest/index.php')
// put your Realtime Database url
app.provide('realdatabase', firebaseConfig.databaseURL)
app.provide('assetsRoute', '/src/assets/')
app.provide('emitter', emitter)
app.provide('appUrl', appUrl)
app.provide('functionsBaseUrl', functionsBaseUrl)

app.use(createPinia())
app.use(router)
app.mount('#q-app')

import 'bootstrap/dist/js/bootstrap.js'
