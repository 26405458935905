<script setup>
  import { ref, defineProps, computed, watch, defineEmits, onMounted } from 'vue'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import { storeToRefs } from 'pinia'
  import { useEmployerStore } from '../stores/EmployerStore'
  import { RequestService } from '../services/RequestService'
  const employerStore = useEmployerStore()
  const { actualPlanYear } = storeToRefs(employerStore)

  const props = defineProps({
    dueDate: {
      type: String,
      required: true
    }
  })

  const date = ref(null)
  const emit = defineEmits(['update:selectedDate'])
  const { handleErrors } = RequestService()

  const formatDate = (date) => {
    if (!date) return ''
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
    const formattedDate = date.toLocaleDateString('en-US', options)
    return formattedDate.replace(/^(\w{3}),\s/, '$1 ')
  }

  onMounted(() => {
    if (!actualPlanYear.value?.plan_start_date) {
      handleErrors('actualPlanYear or plan_start_date is undefined')
      startDate.value = new Date()
      return
    }
    const [dueMonth, dueDay] = props.dueDate.split('/').map(Number)
    const [planMonth, planDay, planYear] = actualPlanYear.value.plan_start_date
      .split('/')
      .map(Number)
    const dueDate = new Date(planYear, dueMonth - 1, dueDay)
    const planStartDate = new Date(planYear, planMonth - 1, planDay)
    if (dueDate < planStartDate) dueDate.setFullYear(planYear + 1)
    startDate.value = dueDate
  })

  watch(date, (newDate) => {
    if (newDate) {
      const formattedDate = formatDate(newDate)
      emit('update:selectedDate', formattedDate)
    }
  })

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const convertDate = (dateString) => {
    if (!actualPlanYear.value?.plan_start_date) {
      handleErrors('actualPlanYear or plan_start_date is undefined')
      return new Date()
    }
    const [month, day] = dateString.split('/').map(Number)
    const [planMonth, planDay, planYear] = actualPlanYear.value.plan_start_date
      .split('/')
      .map(Number)

    const date = new Date(planYear, month - 1, day)
    const planStartDate = new Date(planYear, planMonth - 1, planDay)

    if (date < planStartDate) date.setFullYear(planYear + 1)

    return date
  }

  const dueDateObject = computed(() => convertDate(props.dueDate))
  const startDate = ref()
  const highlight = computed(() => ({
    dates: [dueDateObject.value],
    options: {
      highlightDisabled: true
    }
  }))

  const currentMonthYear = ref({ month: today.getMonth(), year: today.getFullYear() })

  watch(dueDateObject, () => {
    currentMonthYear.value = { month: today.getMonth(), year: today.getFullYear() }
  })
</script>

<template>
  <div class="custom-calendar-container">
    <VueDatePicker
      v-model="date"
      :highlight="highlight"
      highlight-disabled-days
      :enable-time-picker="false"
      auto-apply
      :max-date="dueDateObject"
      :teleport="false"
      :inline="true"
      :preview-format="(date) => ''"
      :month-picker="false"
      :min-date="new Date()"
      :start-date="startDate"
      prevent-min-max-navigation
      :disabled-week-days="[6, 0]" />
  </div>
</template>

<style scoped lang="scss">
  .custom-calendar-container :deep(.dp__instance_calendar .dp__month_year_select) {
    width: auto;
    color: var(--bs-primary);
  }

  .custom-calendar-container :deep(.dp__instance_calendar .dp__month_year_wrap) {
    justify-content: center;
  }

  .custom-calendar-container :deep(.dp__instance_calendar .dp__month_year_wrap .dp__inner_nav) {
    color: var(--bs-primary);
  }

  .custom-calendar-container :deep(.dp__instance_calendar .dp__calendar_header_separator) {
    display: none;
  }

  .custom-calendar-container :deep(.dp__instance_calendar .dp__menu_inner) {
    padding: 0;
  }

  .custom-calendar-container :deep(.dp--header-wrap) {
    border-bottom: 1px solid var(--bs-gray-400);
  }

  .custom-calendar-container :deep(.dp__instance_calendar .dp__calendar_header) {
    margin-top: 10px;
    font-weight: normal;
  }

  .custom-calendar-container :deep(.dp__today) {
    border: none !important;
    background-color: transparent;
  }

  .custom-calendar-container :deep(.dp__active_date) {
    background-color: #5c0cb8 !important;
    border-radius: 50%;
  }

  .custom-calendar-container :deep(.dp__date_hover) {
    border-radius: 50%;
  }

  .custom-calendar-container :deep(.dp__cell_highlight) {
    background-color: #fff6da !important;
    border-radius: 50%;
    color: #5c0cb8 !important;
    border: none !important;
    opacity: initial !important;
  }

  .custom-calendar-container :deep(.dp__cell_disabled) {
    opacity: 0.5;
    pointer-events: none;
  }

  .custom-calendar-container :deep(.dp__outer_menu_wrap) {
    width: 319px;
  }
</style>
