<script setup>
  import ItemNotes from './ItemNotes.vue'
  import { ref, computed, inject, onMounted, watch } from 'vue'
  import { useQuestionsStore } from '../../stores/QuestionsStore.js'
  import { storeToRefs } from 'pinia'
  import { useNotesStore } from '../../stores/NotesStore.js'
  import addIcon from '@/assets/images/add1.svg'

  const customNavigation = inject('customNavigation')

  const questionStore = useQuestionsStore()
  const { assessmentQuestions, planningQuestions, isLoading } = storeToRefs(questionStore)
  const notesStore = useNotesStore()
  const { employerNotes, userData, selectTab } = storeToRefs(notesStore)
  const { getEmployerNotes, startCreatingNote, cancelCreatingNote, convertDate } = notesStore

  const assessmentNotes = ref([])
  const planningNotes = ref([])
  const selectedNote = ref([])
  const tab = ref('')

  watch(
    () => selectTab.value,
    (newValue) => {
      tab.value = newValue.name
    },
    { deep: true, immediate: true }
  )

  watch(
    () => isLoading.value,
    (newIsLoading) => {
      if (!newIsLoading) {
        getNotes()
      }
    }
  )

  onMounted(async () => {
    getNotes()
    await getEmployerNotes()
  })

  const getNotes = () => {
    assessmentNotes.value = extractNotes(assessmentQuestions.value)
    assessmentNotes.value = assessmentNotes.value.map((note) => ({
      ...note,
      category: 'Assessment',
      user: userData.value?.fullname,
      dateFormatted: convertDate(note.note_updated_at)
    }))

    planningNotes.value = extractNotes(planningQuestions.value)
    planningNotes.value = planningNotes.value.map((note) => ({
      ...note,
      category: 'Planning',
      user: userData.value?.fullname,
      dateFormatted: convertDate(note.note_updated_at)
    }))
  }

  function extractNotes(data) {
    const result = []
    Object.values(data).forEach((category) => {
      category.forEach((obj) => {
        if (obj && typeof obj === 'object' && obj.notes !== undefined) {
          if (obj.notes && obj.notes.trim() !== '') {
            const regularObj = Object.assign({}, obj)
            result.push(regularObj)
          }
        }
      })
    })
    return result
  }

  const selectNote = (note) => {
    selectedNote.value = note
    cancelCreatingNote()
    notesStore.setSelectedNote(note)
  }

  const createNote = () => {
    startCreatingNote()
  }

  const handleClickHeaderButton = () => {
    if (tab.value === 'Assessment') {
      customNavigation.value.navigate({
        newPage: customNavigation.value.currentPage,
        subPage: customNavigation.value.routes.DetailEmployer.children[2].name
      })
    } else if (tab.value === 'Planning') {
      customNavigation.value.navigate({
        newPage: customNavigation.value.currentPage,
        subPage: customNavigation.value.routes.DetailEmployer.children[4].name
      })
    } else if (tab.value === 'General' || tab.value === 'All') {
      createNote()
    }
  }

  const currentNotes = computed(() => {
    switch (tab.value) {
      case 'Assessment':
        return assessmentNotes.value
      case 'Planning':
        return planningNotes.value
      case 'General':
        return employerNotes.value
      case 'All':
        return [...assessmentNotes.value, ...planningNotes.value, ...employerNotes.value]
      default:
        return []
    }
  })
</script>

<template>
  <div class="list-notes">
    <div
      style="padding-bottom: 33px"
      :class="{ 'border-style': currentNotes.length > 0 }"
      class="header-list d-flex justify-content-between">
      <div class="d-flex header-list__text align-items-center">
        <h4 class="title">{{ tab }} Notes</h4>
        <div class="numbers d-flex justify-content-center align-items-center text-14">
          <strong>{{ currentNotes.length }}</strong>
        </div>
      </div>

      <button @click="handleClickHeaderButton" class="btn btn-primary">
        <i v-if="tab !== 'Assessment' && tab !== 'Planning'" class="bi bi-plus-circle-fill"></i>
        {{ tab !== 'Assessment' && tab !== 'Planning' ? 'Add General Note' : `${tab} Page` }}
        <i v-if="tab === 'Assessment' || tab === 'Planning'" class="bi bi-chevron-right"></i>
      </button>
    </div>

    <div class="items-container" v-if="currentNotes.length > 0">
      <div @click="selectNote(note)" v-for="note in currentNotes" :key="note.id + Date.now()">
        <ItemNotes :noteData="note" :tab="tab" />
      </div>
    </div>

    <div class="empty-notes-message text-center text-14" v-else>
      <div class="empty-notes-message__description">
        <img :src="addIcon" alt="add-1" />
        <div class="empty-notes-message__txt">
          All of your notes from across the BCS interface will be found here. You'll be able to
          edit, delete, or add notes. Give it a try!
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .header-list {
    margin-top: 33px;
    padding-left: 60px;
    padding-right: 10px;

    &__text {
      gap: 10px;
    }
  }

  .numbers {
    background-color: #f8f9fa;
    width: 36.5px;
    height: 28.5px;
    border-radius: 50%;
  }

  .list-notes {
    overflow-y: scroll;
    height: 450px;
    padding-right: 16px;
  }

  .empty-notes-message {
    padding-left: 60px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 46px;
      height: 46px;
    }

    &__txt {
      color: var(--bs-gray-700);
      margin-top: 10px;
    }

    &__description {
      width: 356px;
    }
  }
</style>
