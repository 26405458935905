import axios from 'axios'
import { inject } from 'vue'
import { isEmpty } from 'lodash'
import { useAuthStore } from '../stores/AuthStore'
import { handleErrors } from '../utils/errorHandler'
import { getDatabase, ref as dbRef, get as dbGet } from 'firebase/database'
import { getApp } from 'firebase/app'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}

// Create axios instance with base configuration
const axiosInstance = axios.create(axiosConfig)

export const RequestService = () => {
  const toggleLoadingWithMessage = inject('toggleLoadingWithMessage', () => {})
  

  // Request interceptor to include token in every request
  axiosInstance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('userToken')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // Response interceptor to handle token refresh
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config

      // If error is 401 and we haven't retried the request yet
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const authStore = useAuthStore()
        try {
          // Try to refresh the token          
          const newToken = await authStore.refreshToken()
          
          // Update token in the original request
          originalRequest.headers.Authorization = `Bearer ${newToken}`
          window.history.replaceState({}, document.title, window.location.pathname)

          // Retry the original request with new token
          return axiosInstance(originalRequest)
        } catch (refreshError) {
          // If refresh fails, logout user
          authStore.logout()
          return Promise.reject(refreshError)
        }
      }

      return Promise.reject(error)
    }
  )

  const simpleGet = async (key, url) => {
    try {
      let response = await axiosInstance({
        method: 'get',
        url
      })

      if (response.status !== 200) {
        handleErrors(key, response)
      } else if (response.data.success === false) {
        handleErrors(key, response)
      } else {
        return response
      }
    } catch (error) {
      handleErrors(key, error)
    }
  }

  const put = async (key, url, data) => {
    try {
      let response = await axiosInstance({
        method: 'put',
        url,
        data
      })

      if (response.status !== 200) {
        handleErrors(key, response)
      } else if (response.data.success === false) {
        handleErrors(key, response)
      } else {
        return response
      }
    } catch (error) {
      handleErrors(key, error)
    }
  }

  const post = async (key, url, data) => {
    try {
      let response = await axiosInstance({
        method: 'post',
        url,
        data
      })

      if (response.status !== 200) {
        handleErrors(key, response)
      } else if (response.data.success === false) {
        handleErrors(key, response)
      } else {
        return response
      }
    } catch (error) {
      handleErrors(key, error)
      throw error
    }
  }

  const createLog = async (entry = {}) => {
    const url = `${import.meta.env.VITE_API_BASE_URL}?_rest=Logging`
    return post('createLog', url, entry)
  }

  const downloadPdf = async ({ eventData, fileName, templateID, data }) => {
    if (isEmpty(data) || isEmpty(templateID)) {
      handleErrors('Downloading PDF', { message: 'Parameters are missing for downloading PDF' })
      return
    }
    const body = {
      convertTo: 'pdf',
      data: data
    }

    const YOU_API_TOKEN =
      'eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI4ODExNzE5MDI0MDIzODY0ODQiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzc2NzU5MTczLCJkYXRhIjp7InR5cGUiOiJwcm9kIn19.AH63adOvfUWFgVaGv8x_7iEpVkI8wPI72GUMUpiCY5c_JpBD4VfnJ3En9sHvpyrT8etBeGCroZA9nQCxlw6YPG7CAC5gHgXIsvp6w1xqYWqkiosBMY6Y43Ha0ye8nrCOgWgXl_UObE7nPSW-Jo6Wbp0jsGkIni8PjttM7gFP6M2ubVmN'

    const headers = {
      Authorization: YOU_API_TOKEN,
      'Content-Type': 'application/json',
      'carbone-version': '5'
    }

    try {
      const renderResponse = await fetch(`https://render.carbone.io/render/${templateID}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })

      const renderData = await renderResponse.json()
      const renderId = renderData.data.renderId

      const pdfResponse = await fetch(`https://render.carbone.io/render/${renderId}`, {
        method: 'GET',
        headers: headers
      })

      const blob = await pdfResponse.blob()
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      toggleLoadingWithMessage(false, null)

      if (!isEmpty(eventData)) {
        await createLog(eventData)
      }
    } catch (error) {
      handleErrors('Downloading PDF', error)
    }
  }

  const del = async (key, url, data) => {
    try {
      let response = await axiosInstance({
        method: 'delete',
        url,
        data
      })

      if (response.status !== 200) {
        handleErrors(key, response)
      } else if (response.data.success === false) {
        handleErrors(key, response)
      } else {
        return response
      }
    } catch (error) {
      handleErrors(key, error)
    }
  }

  const getRealtimeDatabaseValue = async (path) => {
    try {
      const db = getDatabase(getApp())
      const dbReference = dbRef(db, path)
      const snapshot = await dbGet(dbReference)
      if (snapshot.exists()) {
        return snapshot.val()
      }
    } catch (error) {
      handleErrors(`Realtime Database - Loading value from path: ${path}`, error)
    }
    return null
  }

  return {
    simpleGet,
    post,
    put,
    del,
    createLog,
    handleErrors,
    downloadPdf,
    getRealtimeDatabaseValue
  }
}
