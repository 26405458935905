<script setup>
  import { storeToRefs } from 'pinia'
  import { useEmployerStore } from '../../stores/EmployerStore'
  import TabsNotes from './TabsNotes.vue'
  import ListNotes from './ListNotes.vue'
  import EditNotes from './EditNotes.vue'
  import CreateNotes from './CreateNotes.vue'
  import Footer from '../FooterSection.vue'
  import { ref, watch, onMounted } from 'vue'
  import { useNotesStore } from '../../stores/NotesStore.js'
  import BcsModal from '../BcsModal.vue'
  import BcsDynamicText from '../BcsDynamicText.vue'
  import { RequestService } from '../../services/RequestService.js'
  const employerStore = useEmployerStore()
  const { actualEmployer } = storeToRefs(employerStore)
  const notesStore = useNotesStore()
  const { handleErrors } = RequestService()
  const { deleteEmployerNote, crudNote, getEmployerNotes, clearSelectedNote, clearDeleteNote } =
    notesStore
  const { isCreatingNote, newNoteCreated, deleteNote, selectedNote } = storeToRefs(notesStore)

  const tabs = ['All', 'General', 'Assessment', 'Planning']
  const createNote = ref(false)
  const modalRef = ref(null)

  watch(isCreatingNote, () => {
    createNote.value = isCreatingNote.value
  })

  watch(deleteNote, () => {
    if (deleteNote.value) {
      openModal()
    }
  })

  const handleDeleteNote = async () => {
    crudNote()
    modalRef.value.closeModal()
    switch (selectedNote.value.category) {
      case 'Assessment':
        clearInformation()
        break
      case 'Planning':
        clearInformation()
        break
      case 'General':
        await deleteEmployerNote(selectedNote.value.id)
        await getEmployerNotes()
        clearInformation()
        break
      default:
        handleErrors('Unknown category')
    }
  }

  const openModal = () => {
    modalRef.value.openModal()
  }

  const clearInformation = () => {
    clearDeleteNote()
    clearSelectedNote()
  }

  onMounted(() => {
    createNote.value = isCreatingNote.value
  })

  const handleCloseBtnModal = () => {
    clearInformation()
  }
</script>

<template>
  <div class="col-10 px-0 mt-2 scroll-style notes" id="mainContainer">
    <div style="padding-right: 27px" class="d-flex justify-content-between align-items-center">
      <h3 style="margin-top: 5px; padding-left: 26px">{{ actualEmployer.name }} Employer Notes</h3>
    </div>

    <TabsNotes style="margin-top: 20px" :tabNames="tabs" />

    <div class="container-notes">
      <div><ListNotes :key="newNoteCreated" /></div>
      <div>
        <EditNotes v-if="!createNote" />
        <CreateNotes v-if="createNote" />
      </div>
    </div>

    <div class="p-0">
      <Footer class="px-4"></Footer>
    </div>
  </div>

  <BcsModal
    @modalButtonClick="handleDeleteNote()"
    @closeButtonClicked="handleCloseBtnModal"
    ref="modalRef"
    :modalType="'confirmation'"
    :modalButton="'Yep, let’s do this'"
    :modalTitle="'Are you sure?'">
    <BcsDynamicText text="notesModalContentConfirmation" />
  </BcsModal>
</template>

<style scoped lang="scss">
  .container-notes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-height: 520px;
  }
</style>
