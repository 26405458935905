<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useEmployerStore } from '../../stores/EmployerStore'
import { useAuthStore } from '../../stores/AuthStore'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import { storeToRefs } from 'pinia'
import emailIcon from '@/assets/images/email.svg'
import zoomIcon from '@/assets/images/zoom.svg'
import glossaryIcon from '@/assets/images/dictionary.svg'
import faqsIcon from '@/assets/images/help-1.svg'
import emailSendedImage from '@/assets/images/email-sended.svg'
import { RequestService } from '../../services/RequestService'
import { EmailUtilsService } from '../../services/EmailUtilsService'
import BcsDynamicText from '../BcsDynamicText.vue'
import BcsDropdown from '../BcsDropdown.vue'
import AlertNotification from '../AlertNotification.vue'
const { post } = RequestService()

const { getEmailTemplate, getEmailRecipient } = EmailUtilsService()
const functionsBaseUrl = inject('functionsBaseUrl')
const customNavigation = inject('customNavigation')
const emit = defineEmits(['CAP', 'showAlertDefinition'])


const employerStore = useEmployerStore()
const authStore = useAuthStore()
const { userData } = storeToRefs(employerStore)
const db = getDatabase(getApp())
const subjectOptions = ref([])
const emitter = inject('emitter')
const loading = ref(false)
const CAP = ref(null)
const showAlertDefinition = ref(false)

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
const emailSended = ref(false)
const emailSendedError = ref(false)
const isValidEmail = computed(() => {
    return emailRegex.test(formData.value.email)
})

const disabledButtons = computed(() => {
    return !formData.value.fullName ||
        !formData.value.email ||
        !isValidEmail.value ||
        !formData.value.subject ||
        !formData.value.message
})

const formData = ref({
    fullName: '',
    agencyName: '',
    email: '',
    subject: '',
    message: '',
})

const resetForm = () => {
    emailSendedError.value = false
    formData.value = {
        fullName: '',
        agencyName: '',
        email: '',
        subject: '',
        message: '',
    }
}

emitter.on('toggleLoading', (value) => {
    loading.value = value.value
})

onMounted(async () => {
    await initializeUserData()
    await getSubjectOptions()
    emitter.on('contactModalOpen', handleModalOpen)
    
})

const initializeUserData = async () => {
    const user = authStore.user
    if (user && !userData.value?.fullname) {
        await employerStore.getEmployer(user.employerId)
    }
}

const goTo = (route) => {
    customNavigation.value.navigate({
        newPage: customNavigation.value.routes[route].name
    })
}

const handleModalOpen = () => {
    emailSended.value = false
    emailSendedError.value = false    
}

const getSubjectOptions = async () => {
    try {
        const dropdownRef = dbRef(db, 'BCS/Contact/dropdownContent')
        const snapshot = await dbGet(dropdownRef)
        if (snapshot.exists()) {
            subjectOptions.value = snapshot.val()
        }
    } catch (err) {
        handleErrors('Error fetching dropdown content:', err)
    }
}

watch(userData, (newUserData) => {
    if (newUserData) {
        formData.value.fullName = newUserData.fullname || ''
        formData.value.agencyName = newUserData.companyName || ''
        formData.value.email = newUserData.email || ''
        CAP.value = newUserData?.CAPCompany
        emit('CAP', CAP.value)
    }
}, { immediate: true })



const modalToTop = () => {
    const modalBody = document.querySelector('.contact-modal .modal-body')
    if (modalBody) {
        modalBody.scrollTop = 60
    }
}

const modalToBottom = () => {
    const modalBody = document.querySelector('.contact-modal .modal-body')
    if (modalBody) {
        modalBody.scrollTop = (modalBody.scrollHeight / 2) - 20
    }
}


const handleSubmit = async () => {
    try {
        loading.value = true
        modalToTop()
        await post('sendEmail', `${functionsBaseUrl}/email`, {
            type: 'CONTACT',
            email: formData.value.email,
            data: {
                fullName: formData.value.fullName,
                agencyName: formData.value.agencyName,
                subject: formData.value.subject,
                message: formData.value.message
            },
            emailTemplate: await getEmailTemplate('CONTACT_EMAIL'),
            emailRecipient: await getEmailRecipient('CONTACT_EMAIL')
        })
        formData.value = {
            fullName: userData.value?.fullname || '',
            agencyName: userData.value?.companyName || '',
            email: userData.value?.email || '',
            subject: '',
            message: ''
        }
        emailSended.value = true
    } catch (error) {
        handleErrors('Error sending email:', error)
        modalToBottom()
        emailSendedError.value = true
        emailSended.value = false
    } finally {
        loading.value = false
    }
}

const clearInput = (field) => {
    formData.value[field] = '';
}

const handleDynamicClick = (event) => {
  if (event.target.id === 'alertContactDefinition') {
    showAlertDefinition.value = true
    emit('showAlertDefinition', showAlertDefinition.value)
  }
}

</script>

<template>
    <div class="contact-us-container mx-5 mt-4">
        <div class="row">
            <div class="text-start text-14">
                <BcsDynamicText v-if="CAP" text="contactPageCAPIntro" />
                <AlertNotification class="alert-notification-info" @click="handleDynamicClick" v-else :typeAlert="'info'" :showCloseButton="false">
                    <BcsDynamicText text="alertContactNotification" />
                </AlertNotification>
            </div>

            <div class="col-12 main-content">
                <div class="row">
                    <div v-if="loading" class="d-flex justify-content-center spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div v-if="!emailSended && !loading " class="form-container"
                        style="width: 54%;">
                        <div class="contact-form">
                            <p v-if="CAP" class="title text-start">Contact Form (for non-compliance questions)</p>
                            <p v-else class="title text-start">Contact Form</p>

                            <div class="mb-3 pt-2 input-group">
                                <span class="question-title text-start text-14">
                                    <strong>Full Name<span>*</span></strong>
                                </span>
                                <div class="input-wrapper">
                                    <input type="text" class="form-control text-16 form-input"
                                        v-model="formData.fullName">
                                    <button v-if="formData.fullName" class="clear-button"
                                        @click="clearInput('fullName')">
                                        <i class="bi bi-x-circle-fill"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="mb-3 pt-2 input-group">
                                <span class="question-title text-start text-14">
                                    <strong>Agency Name*</strong>
                                </span>
                                <div class="input-wrapper">
                                    <input type="text" class="form-control text-16 form-input"
                                        v-model="formData.agencyName">
                                    <button v-if="formData.agencyName" class="clear-button"
                                        @click="clearInput('agencyName')">
                                        <i class="bi bi-x-circle-fill"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="mb-3 pt-2 input-group">
                                <span class="question-title text-start text-14">
                                    <strong>Email<span>*</span></strong>
                                </span>
                                <div class="input-wrapper">
                                    <input type="email" class="form-control text-16 form-input"
                                        v-model="formData.email">
                                    <button v-if="formData.email" class="clear-button" @click="clearInput('email')">
                                        <i class="bi bi-x-circle-fill"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="mb-3 pt-2 input-group">
                                <span class="question-title text-start text-14">
                                    <strong>Subject<span>*</span></strong>
                                </span>
                                <div style="margin-top: 9px;">
                                    <BcsDropdown :dropdown-options="subjectOptions" v-model="formData.subject" />
                                </div>
                            </div>

                            <div style="padding-bottom: 30px;" class="pt-2 d-flex flex-column">
                                <span class="question-title text-start text-14">
                                    <strong>Message<span>*</span></strong>
                                </span>
                                <textarea class="form-control" rows="5" v-model="formData.message"
                                    placeholder="e.g., I have a question about how to use your site."></textarea>
                            </div>

                            <div style="padding-bottom: 30px;" v-if="emailSendedError">
                                <AlertNotification class="email-sended-error fw-bold" :typeAlert="'error'" :showCloseButton="false" >
                                   <BcsDynamicText text="emailSendedErrorCopy" />
                                </AlertNotification>
                            </div>

                            <div class="actions-container">
                                <button @click="resetForm" type="button" :class="{ 'disabled': disabledButtons }"
                                    class="btn btn-outline-secondary d-flex align-items-center btn-reset btn-info">
                                    <i
                                        class="bi bi-x-circle rounded-circle d-flex justify-content-center align-items-center"></i>
                                    &nbsp; Reset
                                </button>
                                <button @click="handleSubmit" :class="{ 'disabled': disabledButtons }" type="button"
                                    class="btn btn-secondary btn-submit btn-primary">
                                    Submit
                                    <i
                                        class="bi bi-chevron-right rounded-circle d-flex justify-content-center align-items-center"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="emailSended && !loading" class="text-center email-sended"
                        style="width: 54%;">
                        <img v-if="emailSended" class="email-sended-img" :src="emailSendedImage"
                            alt="Email sent successfully" />

                        <div class="email-sended-content">
                            <BcsDynamicText v-if="emailSended" text="emailSendedSucessCopy" />

                            <div>
                                <button @click="emailSended = false, emailSendedError = false"
                                    class="btn btn-primary">Send Another Message</button>
                            </div>
                        </div>
                    </div>

                    <!-- Options section -->
                    <div class="options" style="width: 46%;">
                        <!-- Email Us -->
                        <div class="option-box d-flex email-us">
                            <div
                                class="option-box__img-container icon-background d-flex justify-content-center align-items-center">
                                <img :src="emailIcon" class="icon-email">
                            </div>
                            <div class="option-box__content text-start">
                                <p class="option-box__content-title text-s">Email Us</p>
                                <BcsDynamicText v-if="CAP" text="emailCardContentCAP" />
                                <BcsDynamicText v-else text="emailCardContent" />
                            </div>
                        </div>

                        <!-- FAQs -->
                        <div class="option-box d-flex faqs">
                            <div
                                class="option-box__img-container icon-background d-flex justify-content-center align-items-center">
                                <img :src="faqsIcon" class="icon-faqs">
                            </div>
                            <div class="option-box__content text-start">
                                <p class="option-box__content-title">Frequently Asked Questions</p>
                                <div class="option-box__content-text text-14">
                                    <BcsDynamicText text="faqsCardContent" />
                                    <div @click="goTo('frequentlyQuestions')"
                                        class="redirect d-flex align-items-center text-primary fw-bold">
                                        Go to FAQs <i class="bi bi-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Glossary -->
                        <div class="option-box d-flex glossary">
                            <div
                                class="option-box__img-container icon-background d-flex justify-content-center align-items-center">
                                <img :src="glossaryIcon" class="icon-glossary">
                            </div>
                            <div class="option-box__content text-start">
                                <p class="option-box__content-title">Glossary of Terms</p>
                                <div class="option-box__content-text text-14">
                                    <BcsDynamicText text="glossaryCardContent" />
                                    <div @click="goTo('glossaryTerms')"
                                        class="redirect d-flex align-items-center text-primary fw-bold">
                                        Go to Glossary <i class="bi bi-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Zoom -->
                        <div class="option-box d-flex zoom">
                            <div
                                class="option-box__img-container icon-background d-flex justify-content-center align-items-center">
                                <img :src="zoomIcon" class="icon-zoom">
                            </div>
                            <div class="option-box__content text-start">
                                <p style="padding-right: 50px;" class="option-box__content-title">Upgrade for Compliance Phone or Video Call</p>
                                <div class="text-14 option-box__content-text">
                                    <BcsDynamicText v-if="CAP" text="scheduleComplianceCAP" />
                                    <BcsDynamicText v-else text="zoomCardContent" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">

:deep{
    #alertContactDefinition{
    color: var(--bs-primary);
    cursor: pointer;
    text-decoration: none;
    border-bottom: 3px dotted var(--bs-primary);
    font-weight: bold;
}
}

.email-us {
    :deep .white-space-pre-wrap {
        white-space: normal !important;
    }

}

:deep .dropdown {
    button {
        background: var(--Gray-White, #FFF);
        width: 69% !important;
        border: 1px solid var(--bs-gray-600) !important;
    }

    .placeholder-dropdown {
        &:not(.selected) {
            color: var(--bs-gray-600) !important;
        }

        &.selected {
            color: var(--bs-primary) !important;
        }
    }


    .dropdown-menu {
        width: 69% !important;
    }

    i {
        font-size: 12px !important;
    }
}

:deep .alert-notification-info {
    box-shadow: none !important;
}

.spinner-container {
    width: 54%;
    margin-top: 200px;
}


.contact-us-container {

    .email-sended-content {
        color: var(--bs--gray-700);

        h1 {
            margin-top: 13px;
        }

        button {
            margin-top: 11px;
        }
    }

    .title {
        font-size: 22px;
    }

    .email-sended-img {
        margin-top: 35px;
    }

    .options {
        margin-left: auto;

        .option-box {
            border-radius: 4px;
            border: 1px solid var(--Gray-300, #DEE2E6);
            background: var(--White, #FFF);
            margin-bottom: 20px;
            padding: 44px 0 43px 32px;

            .email-us {
                height: 230px;
            }

            .faqs,
            .glossary {
                height: 181px;
            }

            .zoom {
                height: 283px;
            }

            &__img-container {
                aspect-ratio: 1;
            }


            .redirect {
                margin-top: 10px;
                font-size: 16px;
                cursor: pointer;

                i {
                    font-size: 20px;
                    padding-left: 6px;
                }
            }


            p {
                margin-bottom: 0 !important;
            }

            &__content {
                padding-left: 12px;
                overflow: auto;



                &-title {
                    font-size: 20px;
                    font-weight: 700;
                    letter-spacing: -0.4px;
                }

                &-text {
                    margin-top: 10px;
                    margin-right: 76px;
                }

            }
        }

        .icon-background {
            background-color: var(--bs-gray-200);
            width: 59px;
            height: 59px;
            border-radius: 50%;
        }
    }

    .form-container {
        padding-right: 31px !important;
    }

    .title {
        margin-top: 10px;
    }

    .main-content {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .contact-form {
        height: auto;
        background-color: var(--bs-gray-100);
        padding: 42px 48px 0 45px;
        border-radius: 4px;

        .input-group {
            display: flex;
            flex-direction: column;

            .input-wrapper {
                position: relative;
                width: 69%;
            }

            .clear-button {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                margin-top: 4.5px;
                background: transparent;
                cursor: pointer;
                color: var(--bs-gray-600);

                &:hover {
                    color: var(--bs-gray-800);
                }
            }
        }

        .form-input {
            width: 100%;
        }



        .form-control {
            border: 1px solid var(--bs-gray-600) !important;
            margin-top: 9px !important;
        }

        textarea {
            height: 147px;
            resize: none;

            &::placeholder {
                color: var(--bs-gray-600) !important;
            }
        }

        .actions-container {
            display: flex;
            width: 100%;
            padding: 12px 0px 52px 12px;
            justify-content: flex-end;
            align-items: center;
            background-color: var(--bs-gray-100);
            gap: 8px;
            border-top: 1px solid var(--Gray-400, #C5CDD4);

            .btn-submit {
                display: flex;
                padding: 6px 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
            }
        }
    }
}
</style>
