<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useAuthStore } from '../../stores/AuthStore'
import { useEmployerStore } from '../../stores/EmployerStore'
import { useProfileStore } from '../../stores/ProfileStore'
import { storeToRefs } from 'pinia'
import { handleErrors } from '../../utils/errorHandler'
import AlertNotification from '../AlertNotification.vue'
import BcsDynamicText from '../BcsDynamicText.vue'

const editSection = ref(false)
const employerStore = useEmployerStore()
const profileStore = useProfileStore()
const { updateRealData, updateUserData, showAlert, hideAlert } = profileStore
const { showSuccessAlert } = storeToRefs(profileStore)
const authStore = useAuthStore()
const { userData } = storeToRefs(employerStore)
const emitter = inject('emitter')
const loadingState = ref(false)

const formData = ref({
    fullname: '',
    title: '',
})

const originalData = ref({
    fullname: '',
    title: '',
})

onMounted(async () => {
    await initializeUserData()
    const data = {
        fullname: userData.value?.fullname || '',
        title: userData.value?.role || ''
    }
    Object.assign(formData.value, data)
    originalData.value = JSON.parse(JSON.stringify(data))
})

const initializeUserData = async () => {
    const user = authStore.user
    if (user && !userData.value?.fullname) {
        await employerStore.getEmployer(user.employerId)
    }
}

const handleEdit = () => {
    originalData.value = JSON.parse(JSON.stringify(formData.value))
    editSection.value = true
}

const handleCancel = () => {
    formData.value = JSON.parse(JSON.stringify(originalData.value))
    editSection.value = false
}


const handleSave = async () => {
    try {
        loadingState.value = true
        const { fullname, title } = formData.value
        await updateRealData({ role: title })
        await updateUserData({ fullname })
        editSection.value = false
        showAlert()
        setTimeout(() => {
            hideAlert()
        }, 0)
    } catch (error) {
        handleErrors('Error saving contact information:', error)
    } finally {
        loadingState.value = false
    }
}


const openEditPhoto = () => {
    emitter.emit('togglePhotoModal')
}

const disabledSave = computed(() => {
    return !formData.value.fullname ||
        !formData.value.title ||
        formData.value.title.trim().length < 1 ||
        formData.value.fullname.trim().length < 1
})

const profilePhotoUrl = computed(() =>
    userData.value?.temporaryPhotoURL ? userData.value.temporaryPhotoURL : userData.value?.profilePhotoUrl)


watch(userData, (newUserData) => {
    if (newUserData) {
        const data = {
            fullname: newUserData.fullname || '',
            title: newUserData.role || '',
        }
        Object.assign(formData.value, data)
        originalData.value = JSON.parse(JSON.stringify(data))
    }
}, { immediate: true })

const clearInput = (field) => {
    formData.value[field] = ''
}
</script>

<template>
    <div class="header-profile pl-135 pr-40">
        <AlertNotification v-model:showAlert="showSuccessAlert" typeAlert="success" :showCloseButton="true">
            <strong>
                <BcsDynamicText text="profileAlertNotification" />
            </strong>
        </AlertNotification>
        <div class="header-profile__content d-flex justify-content-between align-items-center">
            <div class="profile-icon">
                <img :src="profilePhotoUrl ? profilePhotoUrl : '/user_silhouette.png'"
                    :alt="profilePhotoUrl ? 'Profile Photo' : 'User Silhouette'" class="profile-photo">
                <div v-if="editSection" class="profile-icon-overlay" @click="openEditPhoto">
                    <i class="bi bi-camera-fill"></i>
                </div>
            </div>

            <div class="header-profile__content-text ">
                <div class="header-22">
                    <span>Member Profile</span>
                </div>
                <div class="header-32 mt-2">
                    <span v-if="!editSection">{{ formData.fullname }}</span>
                    <div v-if="editSection" class="form-row">
                        <span class="fw-bold">Full Name</span>
                        <div class="input-wrapper">
                            <input :class="{ 'is-invalid': formData.fullname && formData.fullname.trim().length < 1 }" type="text"
                                class="form-control text-16 form-input" v-model="formData.fullname">
                            <button v-if="formData.fullname" class="clear-button" @click="clearInput('fullname')">
                                <i class="bi bi-x-circle-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <span v-if="!editSection">{{ formData.title }}</span>
                    <div v-if="editSection" class="form-row">
                        <span class="fw-bold">Title</span>
                        <div class="input-wrapper">
                            <input :class="{ 'is-invalid': formData.title && formData.title.trim().length < 1 }" type="text" class="form-control text-16 form-input" v-model="formData.title">
                            <button v-if="formData.title" class="clear-button" @click="clearInput('title')">
                                <i class="bi bi-x-circle-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>



                <div v-if="editSection" class="form-row">
                    <span></span>
                    <div class="input-wrapper d-flex buttons-container">
                        <button @click="handleSave" :class="{ 'disabled': disabledSave || loadingState }"
                            :disabled="disabledSave || loadingState"
                            class="btn btn-primary btn-action-profile d-flex justify-content-center align-items-center">
                            <span v-if="loadingState" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                            <i v-if="!loadingState"
                                class="bi bi-check-circle-fill d-flex justify-content-center align-items-center"></i>
                            {{ loadingState ? 'Saving...' : 'Save Changes' }}
                        </button>
                        <button @click="handleCancel"
                            class="btn btn-info btn-action-profile d-flex justify-content-center align-items-center">
                            <i
                                class="bi bi-x-circle rounded-circle d-flex justify-content-center align-items-center"></i>
                            Cancel
                        </button>
                    </div>
                </div>


            </div>

            <div v-if="!editSection" class="edit" @click="handleEdit">
                Edit
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.header-profile {
    margin-top: 25px;
    margin-bottom: 45px;
    width: 100%;

    .profile-photo {
        width: 103px;
        height: 103px;
    }

    &__content {
        gap: 22px;
        margin-top: 26px;

        &-text {
            width: 100%;
            margin-right: auto;
        }

        .profile-icon {

            &-overlay {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: white;
                    font-size: 24px;
                }
            }
        }
    }
}
</style>