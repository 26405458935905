<script setup>
import { ref, inject } from 'vue'
import BcsModal from '../BcsModal.vue'
import BcsDynamicText from '../BcsDynamicText.vue'
import BcsContactUs from './BcsContactUs.vue'

const contactModalRef = ref(null)
const emitter = inject('emitter')
const CAP = ref(null)
const showAlertDefinition = ref(false)

emitter.on('toggleContactModal', async () => {
    contactModalRef.value.openModal()
    emitter.emit('contactModalOpen')
})

const closeAndClearModal = () => {
    showAlertDefinition.value = false
    contactModalRef.value.closeModal()
}

const handleCAP = (cap) => {
    CAP.value = cap
}
</script>

<template>
    <BcsModal modalSize="medium" :customClass="showAlertDefinition ? 'definition' : 'contact-modal'" hideCloseButton :showFooter="!showAlertDefinition" @modalButtonClick="() => null"
        @closeButtonClicked="closeAndClearModal" ref="contactModalRef">
        <template #header>
            <div class="d-flex w-100 justify-content-between align-items-center">
                <span class="modal-title header-22 ms-2">
                    <strong v-if="!showAlertDefinition">Connect With Us</strong>
                    <strong v-else>Compliance Question Example</strong>
                    <p v-if="!showAlertDefinition" style="width: 85%; margin-top: 5px;" class="text-14 text-gray-700">
                        <BcsDynamicText v-if="CAP" text="modalContactHeaderCopy" />
                        <BcsDynamicText v-else text="modalContactNoCapHeaderCopy" />
                    </p>
                </span>
                <button type="button" class="btn-close" @click="closeAndClearModal" aria-label="Close"></button>
            </div>
        </template>

        <template v-if="!showAlertDefinition" #body-header>
            <BcsContactUs @CAP="handleCAP" @showAlertDefinition="showAlertDefinition = $event" />
        </template>

        <template v-else #body-header>
            <div class="definition-text">
                <div
              style="margin-top: 28px"
              class="go-back d-flex"
              @click="showAlertDefinition = false">
              <i class="bi bi-chevron-left"></i>
              Back to Main Contact Page
            </div>

            <div style="text-align: left!important; margin-top: 34px;" class="text-14">
                <BcsDynamicText text="alertContactDefinition" />
            </div>
            </div>
            
        </template>

        <template v-if="!showAlertDefinition" #footer>
            <button type="button" class="btn btn-primary" @click="closeAndClearModal">Close</button>
        </template>
        
    </BcsModal>
</template>

<style scoped lang="scss">
:deep {
    .modal-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
        height: calc(100vh - 200px) !important;
        overflow-y: auto;
    }

    .medium {
        max-width: 85% !important;
    }

    .alert-content {
        margin-bottom: 0 !important;
    }

    .modal-header {
        border-bottom: none !important;
    }

    .modal-content {
        border-radius: 4px !important;
        height: calc(100vh - 130px) !important;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .modal-footer {
        padding: 1rem;
        border-top: 1px solid #dee2e6;
        margin-top: auto;
    }
}

:deep .definition{
    height: 374px!important;
    width: 748px!important;

    .modal-body{
        height: auto!important;
    }
}

.btn-close {
    i {
        font-size: 12px;
    }
}

:deep .white-space-pre-wrap {
    white-space: normal !important;
}

.content {
    padding: 33px 35px 29px 25px;
    gap: 20px;
}

.definition-text{
    padding: 0px 41px 0px 24px;
}
</style>
