import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSessionStorageStore = defineStore('sessionStorage', () => {
  const events = ref({})

  const loadEventFromStorage = (eventName) => {
    const storedEvent = sessionStorage.getItem(eventName)
    if (storedEvent !== 'undefined') {
      events.value[eventName] = JSON.parse(storedEvent)
    } else {
      return null
    }
  }

  const saveEventToStorage = (eventName) => {
    sessionStorage.setItem(eventName, JSON.stringify(events.value[eventName]))
  }

  const setEvent = (eventName, eventData) => {
    events.value[eventName] = eventData
    saveEventToStorage(eventName)
  }

  const getRefresh = () => {
    const refresh = sessionStorage.getItem('pageReloaded')
    return JSON.parse(refresh)
  }

  const getEvent = (eventName) => {
    if (!events.value[eventName]) {
      loadEventFromStorage(eventName)
      return events.value[eventName]
    } else {
      return null
    }
  }

  const cleanEvent = (eventName) => {
    delete events.value[eventName]
    sessionStorage.removeItem(eventName)
  }

  const cleanAllEvents = () => {
    Object.keys(events.value).forEach((eventName) => {
      sessionStorage.removeItem(eventName)
    })
    events.value = {}
  }

  return {
    events,
    setEvent,
    getEvent,
    cleanEvent,
    cleanAllEvents,
    getRefresh
  }
})
