<script setup>
  import { defineProps, ref, watch, inject } from 'vue'
  import { isEmpty } from 'lodash'

  const props = defineProps([
    'text',
    'preText',
    'preIdOffCanvas',
    'isCenterModal',
    'previousModalData'
  ])

  const emitter = inject('emitter')

  const toggleModal = (event, id, definitionExpected, title) => {
    if (props.isCenterModal) {
      if (!isEmpty(props.previousModalData)) {
        props.previousModalData.handleModal(false)
      }
      emitter.emit('toggleCenterDefinitionModal', {
        id,
        definitionExpected,
        template: title,
        previousModalData: props.previousModalData || {}
      })
    } else {
      let fromCanvas =
        event.target.parentElement.parentElement.parentElement.classList.contains('offcanvas-body')
      emitter.emit('toggleOffCanvas', { id, definitionExpected, fromCanvas, template: title })
    }
  }

  const functionSplitText = (textToCheck) => {
    const customTagForDefinitionRegex =
      /<definition\s+id\s*=\s*['"](\w+)['"]\s*(?:title\s*=\s*['"]([^'"]*)['"]\s*)?>([\s\S]*?)<\/definition>/g
    const splitText = []
    let match
    let lastIndex = 0

    while ((match = customTagForDefinitionRegex.exec(textToCheck)) !== null) {
      if (match.index > lastIndex) {
        const normalText = textToCheck.substring(lastIndex, match.index)
        splitText.push({ text: normalText, isDefinition: false })
      }

      splitText.push({
        text: match[3],
        isDefinition: true,
        id: match[1],
        title: match[2] || null
      })
      lastIndex = customTagForDefinitionRegex.lastIndex
    }

    if (lastIndex < textToCheck.length) {
      const remainingText = textToCheck.substring(lastIndex)
      splitText.push({ text: remainingText, isDefinition: false })
    }

    return splitText
  }

  const finalSplitText = ref(functionSplitText(props.text))
  watch(
    () => props.text,
    (newValue) => {
      finalSplitText.value = functionSplitText(newValue)
    }
  )

  const partDefinitions = finalSplitText.value.filter((part) => part.isDefinition)
</script>

<template>
  <span :key="props.text">
    <template v-for="(part, i) in finalSplitText" :key="i">
      <template v-if="part.isDefinition">
        <span
          :key="part.id"
          class="words-for-definition no-close"
          :id="part.id"
          @click="toggleModal($event, part.id, part.text, part.title)">
          {{ part.text }}
        </span>
      </template>
      <template v-else>
        <span class="white-space-pre-wrap" v-html="part.text"></span>
      </template>
    </template>
  </span>

  <template v-for="(part, i) in partDefinitions" :key="i"></template>
</template>

<style scoped>
  .words-for-definition {
    color: var(--bs-primary);
    cursor: pointer;
    text-decoration: none;
    border-bottom: 3px dotted var(--bs-primary);
    font-weight: bold;
  }
</style>
