import { inject, ref } from 'vue'
import { RequestService } from '../services/RequestService'
import { defineStore } from 'pinia'

const { post, put, simpleGet } = RequestService()

export const useEmployerStore = defineStore(
  'employer',
  () => {
    const actualEmployer = ref(null)
    const planYearsOfActualEmployer = ref(null)
    const lastPlanYear = ref(null)
    const actualPlanYear = ref(null)
    const lastUserActivity = ref(null)
    const actualPlanYearId = ref(null)
    const actualAgencyId = ref(null)
    const functionsBaseUrl = inject('functionsBaseUrl')
    const userData = ref({})
    async function createEmployer(employerData) {
      let response = await post(
        'createEmployer',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers`,
        employerData
      )
      return response.data.object
    }

    function updateTemporaryPhoto(photoURL) {
      userData.value.temporaryPhotoURL = photoURL
    }

    async function getPlanYearsOfEmployer(employerId, agencyId, forceUpdate) {
      if (!actualPlanYearId.value || forceUpdate) {
        let response = await simpleGet(
          'getPlanYearsOfEmployer',
          `${import.meta.env.VITE_API_BASE_URL}?_rest=PlanYear&employerId=${employerId}&agencyId=${agencyId}`
        )
        planYearsOfActualEmployer.value = response.data.results
        actualPlanYear.value = response.data.results[0]

        if (lastPlanYear.value === null) {
          lastPlanYear.value = JSON.parse(JSON.stringify(response.data.results[0]))
        }

        actualPlanYearId.value = response.data.results[0]?.id
      }
    }

    async function getEmployer(employerId, forceUpdate = false) {
      if (employerId !== actualEmployer.value?.id || forceUpdate) {
        let response = await simpleGet(
          'getEmployer',
          `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers&id=${employerId}`
        )
        actualEmployer.value = response.data.object
      }

      if (actualEmployer.value) {
        actualAgencyId.value = actualEmployer.value.agency_id
        await getPlanYearsOfEmployer(employerId, actualAgencyId.value, forceUpdate)
      }

      return actualEmployer.value
    }

    async function updateEmployer(employerId, dataEmployer) {
      if (employerId === null) {
        return null
      } else if (employerId === actualEmployer.value?.id) {
        let response = await put(
          'updateEmployer',
          `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers&id=${employerId}`,
          dataEmployer
        )
        actualEmployer.value = response.data.object
        return getEmployer(employerId, true)
      }
    }

    function changeLastPlanYear(planYear) {
      lastPlanYear.value = planYear
    }


    //use your local functionsBaseUrl for testing local 
    async function getLastUserActivity() {
      let response = await simpleGet(
        'getLastUserActivity',
        `${functionsBaseUrl}/getLastUserActivity`)
      lastUserActivity.value = response.data
      return lastUserActivity.value
    }

    function resetAll() {
      actualEmployer.value = null
      planYearsOfActualEmployer.value = null
      lastPlanYear.value = null
      actualPlanYear.value = null
      actualPlanYearId.value = null
      actualAgencyId.value = null
    }

    return {
      actualEmployer,
      planYearsOfActualEmployer,
      lastPlanYear,
      actualPlanYear,
      actualPlanYearId,
      actualAgencyId,
      userData,
      getPlanYearsOfEmployer,
      createEmployer,
      updateEmployer,
      getEmployer,
      changeLastPlanYear,
      resetAll,
      getLastUserActivity,
      updateTemporaryPhoto
    }
  },
  {
    persist: true
  }
)
