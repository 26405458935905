<script setup>
  import { ref, watch } from 'vue'
  import { useNotesStore } from '../../stores/NotesStore.js'
  import { storeToRefs } from 'pinia'
  import TextWithDefinitions from './../TextWithDefinitions.vue'
  import { getTodayFormatted } from '../../utils'

  const notesStore = useNotesStore()
  const { editEmployerNote, clearSelectedNote, setDeleteNote, convertDate } =
    notesStore

  const { selectedNote, selectTab } = storeToRefs(notesStore)

  const edit = ref(false)
  const note = ref('')
  const tab = ref('')

  watch(
    () => selectTab.value,
    (newValue) => {
      tab.value = newValue.name
    },
    { deep: true, immediate: true }
  )

  watch(selectedNote, () => {
    edit.value = false
    note.value =
      selectedNote.value?.category !== 'General' ?
        selectedNote.value?.notes
      : selectedNote.value?.note
  })

  async function saveAssessment() {
    const selectedCopy = { ...selectedNote.value }
    delete selectedCopy.category
    selectedCopy.note = note.value
    selectedCopy.date = getTodayFormatted()
    selectedCopy.dateFormatted = convertDate(selectedCopy.date)
    editEmployerNote(selectedCopy.id, selectedCopy)
    clearSelectedNote()
    edit.value = false
  }
</script>

<template>
  <div v-if="selectedNote">
    <div class="edit-notes-buttons pr-26 d-flex justify-content-end">
      <button
        v-if="selectedNote?.category === 'General'"
        @click="setDeleteNote()"
        type="button"
        class="btn btn-outline-danger text-14">
        <i class="bi bi-trash-fill"></i>
        Delete Note
      </button>
      <button
        data-bs-toggle="modal"
        data-bs-target="#pendingFunctionModal"
        v-if="selectedNote?.category !== 'General'"
        type="button"
        class="btn btn-outline-danger text-14">
        <i class="bi bi-trash-fill"></i>
        Delete Note
      </button>

      <button
        v-if="tab === 'General' && !edit"
        @click="edit = true"
        type="button"
        class="btn btn-primary text-14">
        <i class="bi bi-pencil-square"></i>
        Edit
      </button>
    </div>

    <div class="edit-notes-info">
      <div class="d-flex pr-26 justify-content-between edit-notes-info__header">
        <h5>{{ selectedNote.user }}</h5>
        <span class="last-edit text-14">
          {{ selectedNote?.category !== 'General' ? 'Aug 17, 2023' : selectedNote?.dateFormatted }}
        </span>
      </div>

      <div class="edit-notes-info__body pr-26 text-14" v-if="!edit">
        <div v-if="selectedNote?.category !== 'General'" class="assessment-item section-assessment">
          <strong>Section:</strong>
          <span>{{ selectedNote?.subtitle }}</span>
        </div>
        <div
          v-if="selectedNote?.category !== 'General'"
          class="assessment-item question-assessment">
          <strong>Question:</strong>
          <span>
            <TextWithDefinitions :key="selectedNote?.question" :text="selectedNote?.question" />
          </span>
        </div>

        <div class="assessment-item notes-assessment">
          {{ selectedNote?.category !== 'General' ? selectedNote?.notes : selectedNote?.note }}
        </div>
      </div>

      <div v-else class="pr-26">
        <textarea
          class="form-control text-14"
          rows="10"
          v-model="note"
          placeholder="Note content"></textarea>
        <div></div>
        <span
          class="remaining-characters d-flex justify-content-end fw-bold text-12"
          style="margin-top: 4px"
          :class="{
            'error-hint': note.length > 1300
          }">
          {{ note ? 1300 - note.length : 1300 }} characters remaining
        </span>
        <div class="action-buttons d-flex justify-content-end">
          <button class="btn btn-info text-14" @click="edit = false">Cancel Edit</button>
          <button
            @click="saveAssessment()"
            class="btn btn-primary save-btn text-14"
            :disabled="note == undefined || note == '' || 1300 - note.length < 0">
            Update Note
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="empty-selected-note d-flex justify-content-center align-items-center text-14" v-else>
    Focused note details will be here once added.
  </div>
</template>

<style scoped lang="scss">
  .pr-26 {
    padding-right: 26px;
  }

  .edit-notes-buttons {
    gap: 15px;
    margin-top: 31px;
  }

  .edit-notes-info {
    padding-left: 29px;
    &__header {
      margin-top: 49px;
      margin-bottom: 30px;
    }

    &__body {
      color: var(--bs-gray-700);
    }
  }

  .last-edit {
    color: var(--bs-gray-600);
  }

  .action-buttons {
    margin-top: 20px;
    gap: 15px;
  }

  .assessment-item {
    margin-top: 8px;

    span {
      font-weight: 300;
      color: #495057;
      padding-left: 8px;
    }
  }

  .notes-assessment {
    margin-top: 40px;
  }

  .empty-selected-note {
    color: var(--bs-gray-700);
    height: 100%;
  }
</style>
