import { handleErrors } from '../utils/errorHandler'
import {
  getDatabase,
  ref as dbRef,
  get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'


export const EmailUtilsService = () => {


  const getEmailTemplate = async (templateName) => {
    try {
      const db = getDatabase(getApp())
      const dropdownRef = dbRef(db, `EMAIL_TEMPLATES/${templateName}`)
      const snapshot = await dbGet(dropdownRef)
      if (snapshot.exists()) {
        return snapshot.val()
      }
    } catch (err) {
      handleErrors('Error fetching getEmailTemplate:', err)
      return []
    }
  }

  const getEmailRecipient = async (emailType) => {
    try {
      const db = getDatabase(getApp())
      const dropdownRef = dbRef(db, `SYSTEM_PARAMS/${emailType}`)
      const snapshot = await dbGet(dropdownRef)
      if (snapshot.exists()) {
        return snapshot.val()
      }
    } catch (error) {
      handleErrors('Error getting  email recipient:', error)
      return null
    }
  }

  return {
    getEmailTemplate,
    getEmailRecipient
  }
}