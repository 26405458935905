import { getDataConnect, queryRef, executeQuery, mutationRef, executeMutation, validateArgs } from 'firebase/data-connect';

export const connectorConfig = {
  connector: 'default',
  service: 'bcs-pro-data-connect',
  location: 'us-south1'
};

export function agencyInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'agencyInsert', inputVars);
}
export function agencyInsert(dcOrVars, vars) {
  return executeMutation(agencyInsertRef(dcOrVars, vars));
}
export function questionSectionInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'questionSectionInsert', inputVars);
}
export function questionSectionInsert(dcOrVars, vars) {
  return executeMutation(questionSectionInsertRef(dcOrVars, vars));
}
export function agencyEmployeeInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'agencyEmployeeInsert', inputVars);
}
export function agencyEmployeeInsert(dcOrVars, vars) {
  return executeMutation(agencyEmployeeInsertRef(dcOrVars, vars));
}
export function userInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'userInsert', inputVars);
}
export function userInsert(dcOrVars, vars) {
  return executeMutation(userInsertRef(dcOrVars, vars));
}
export function complianceEventInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'complianceEventInsert', inputVars);
}
export function complianceEventInsert(dcOrVars, vars) {
  return executeMutation(complianceEventInsertRef(dcOrVars, vars));
}
export function questionInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'questionInsert', inputVars);
}
export function questionInsert(dcOrVars, vars) {
  return executeMutation(questionInsertRef(dcOrVars, vars));
}
export function eventInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'eventInsert', inputVars);
}
export function eventInsert(dcOrVars, vars) {
  return executeMutation(eventInsertRef(dcOrVars, vars));
}
export function employerInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'employerInsert', inputVars);
}
export function employerInsert(dcOrVars, vars) {
  return executeMutation(employerInsertRef(dcOrVars, vars));
}
export function employerPlanYearInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'employerPlanYearInsert', inputVars);
}
export function employerPlanYearInsert(dcOrVars, vars) {
  return executeMutation(employerPlanYearInsertRef(dcOrVars, vars));
}
export function answerInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'answerInsert', inputVars);
}
export function answerInsert(dcOrVars, vars) {
  return executeMutation(answerInsertRef(dcOrVars, vars));
}
export function reminderSentInsertRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'reminderSentInsert', inputVars);
}
export function reminderSentInsert(dcOrVars, vars) {
  return executeMutation(reminderSentInsertRef(dcOrVars, vars));
}
export function userDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'userDeleteMany');
}
export function userDeleteMany(dc) {
  return executeMutation(userDeleteManyRef(dc));
}
export function agencyDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'agencyDeleteMany');
}
export function agencyDeleteMany(dc) {
  return executeMutation(agencyDeleteManyRef(dc));
}
export function questionSectionDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'questionSectionDeleteMany');
}
export function questionSectionDeleteMany(dc) {
  return executeMutation(questionSectionDeleteManyRef(dc));
}
export function agencyEmployeeDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'agencyEmployeeDeleteMany');
}
export function agencyEmployeeDeleteMany(dc) {
  return executeMutation(agencyEmployeeDeleteManyRef(dc));
}
export function questionEmployeeDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'questionEmployeeDeleteMany');
}
export function questionEmployeeDeleteMany(dc) {
  return executeMutation(questionEmployeeDeleteManyRef(dc));
}
export function eventDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'eventDeleteMany');
}
export function eventDeleteMany(dc) {
  return executeMutation(eventDeleteManyRef(dc));
}
export function complianceEventDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'complianceEventDeleteMany');
}
export function complianceEventDeleteMany(dc) {
  return executeMutation(complianceEventDeleteManyRef(dc));
}
export function answerDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'answerDeleteMany');
}
export function answerDeleteMany(dc) {
  return executeMutation(answerDeleteManyRef(dc));
}
export function employerDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'employerDeleteMany');
}
export function employerDeleteMany(dc) {
  return executeMutation(employerDeleteManyRef(dc));
}
export function employerPlanYearDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'employerPlanYearDeleteMany');
}
export function employerPlanYearDeleteMany(dc) {
  return executeMutation(employerPlanYearDeleteManyRef(dc));
}
export function reminderSentDeleteManyRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'reminderSentDeleteMany');
}
export function reminderSentDeleteMany(dc) {
  return executeMutation(reminderSentDeleteManyRef(dc));
}
export function listAgenciesRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'ListAgencies');
}
export function listAgencies(dc) {
  return executeQuery(listAgenciesRef(dc));
}
export function userByOriginalIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'userByOriginalId', inputVars);
}
export function userByOriginalId(dcOrVars, vars) {
  return executeQuery(userByOriginalIdRef(dcOrVars, vars));
}
export function agencyByOriginalIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'agencyByOriginalId', inputVars);
}
export function agencyByOriginalId(dcOrVars, vars) {
  return executeQuery(agencyByOriginalIdRef(dcOrVars, vars));
}
export function questionSectionsByOriginalIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'questionSectionsByOriginalId', inputVars);
}
export function questionSectionsByOriginalId(dcOrVars, vars) {
  return executeQuery(questionSectionsByOriginalIdRef(dcOrVars, vars));
}
export function employerByOriginalIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'employerByOriginalId', inputVars);
}
export function employerByOriginalId(dcOrVars, vars) {
  return executeQuery(employerByOriginalIdRef(dcOrVars, vars));
}
export function employerPlanYearByOriginalIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'employerPlanYearByOriginalId', inputVars);
}
export function employerPlanYearByOriginalId(dcOrVars, vars) {
  return executeQuery(employerPlanYearByOriginalIdRef(dcOrVars, vars));
}
export function questionByOriginalIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'questionByOriginalId', inputVars);
}
export function questionByOriginalId(dcOrVars, vars) {
  return executeQuery(questionByOriginalIdRef(dcOrVars, vars));
}
