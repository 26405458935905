<script setup>
  import { onMounted, inject, computed } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useEmployerStore } from '../stores/EmployerStore'

  const customNavigation = inject('customNavigation')
  const employerStore = useEmployerStore()
  const { actualEmployer } = storeToRefs(employerStore)

  onMounted(() => {
    // console.log('bread')
    console.log(customNavigation.value, actualEmployer.value)
  })

  const breadCrumbs = computed(() => {
    return customNavigation.value.breadCrumbs
  })
</script>

<template>
  <div class="text-14 breadcrumbs fw-bold">
    <p @click="customNavigation.navigate({ newPage: customNavigation.routes.Home.name })">Home</p>
    <span>></span>
    <template v-for="step in breadCrumbs" :key="step.location">
      <p
        :class="{ disabled: step.isDisabled }"
        @click="
          customNavigation.navigate({
            newPage: step.page,
            subPage: step.subPage,
            params: { employerId: customNavigation.navigationParams.employerId }
          })
        ">
        {{ step.title }}
      </p>
      <span>></span>
    </template>
  </div>
</template>

<style scoped>
  .disabled {
    text-decoration: none;
    color: var(--bs-gray-600);
    cursor: text;
  }
</style>
