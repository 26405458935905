<script setup>
  import Breadcrumbs from './BreadCrumbs.vue'
  import { ref, onMounted } from 'vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'

  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore
  // const customNavigation = inject('customNavigation')
  const termsAndConditions = ref('')

  onMounted(async () => {
    termsAndConditions.value = await getDefinitionById('termsAndConditions')
  })
</script>

<template>
  <div class="container-fluid mx-5 px-5 mt-4 help-resources-container">
    <div class="row mb-3">
      <div class="col-8">
        <Breadcrumbs class="mb-4"></Breadcrumbs>
        <h1 class="text-28 mb-3">Terms & Conditions</h1>
        <h2 class="text-16">
          <strong>
            In using this site you are deemed to have read and agreed to the following terms and
            condiions:
          </strong>
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-7 text-14">
        <div v-html="termsAndConditions.body"></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <Footer class="px-5 mx-n3"></Footer>
    </div>
  </div>
</template>

<style scoped>
  .view-full-profile-link {
    position: relative;
    float: right;
    margin-top: 5px;
  }

  .add-teamates-link {
    display: contents;
  }
</style>
