import { RequestService } from './RequestService'
const { simpleGet, post } = RequestService()

export const RemindersCalendarService = () => {
  const nameMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const { handleErrors } = RequestService()

  function getNumberOfWeek(month, day) {
    const dateObj = new Date(new Date().getFullYear(), month - 1, day)
    const firstDayOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1)
    const weekNumber = Math.ceil((dateObj.getDate() + firstDayOfMonth.getDay()) / 7)
    return weekNumber
  }

  async function getEvents(startMonth, type = 0) {
    try {
      const response = await simpleGet(
        'getEvents',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=ComplianceEvent&id=${type}&plan_start_month=${startMonth}`
      )
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  async function getEmails() {
    try {
      const response = await simpleGet(
        'getEmails',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=AgencyMails`
      )
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  async function getGoogleReminders(id) {
    try {
      const response = await simpleGet(
        'getGoogleReminders',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Reminders&id=${id}`
      )
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  async function postReminders(params2) {
    try {
      const response = await post(
        'postReminders',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Reminders`,
        params2
      )
      return response
    } catch (error) {
      handleErrors('Error posting reminders:', error)
    }
  }

  async function getReminders(startMonth, type = 0) {
    try {
      const response = await simpleGet(
        'getReminders',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=ComplianceEvent&id=${type}&plan_start_month=${startMonth}`
      )
      
      const currentYear = new Date().getFullYear()
      const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
      }
      const currentYearIsLeap = isLeapYear(currentYear)   
      let monthsWithEvents = {}
      let reminders = response.data.results
      reminders = reminders.filter(reminder => {
        if (reminder.date === '2/29' && !currentYearIsLeap) {
          return false 
        }
        return true
      })
      
      for (let i = 0; i < 12; i++) {
        let monthNumber = (startMonth + i) % 12
        let month = nameMonths[monthNumber]
        monthsWithEvents[month] = {
          'Week 1': [],
          'Week 2': [],
          'Week 3': [],
          'Week 4': [],
          'Week 5': []
        }
        reminders = reminders.filter((reminder) => {
          if (reminder.date) {
            let [reminderMonth, day] = reminder.date.split('/').map(Number)
            if (reminderMonth === monthNumber + 1) {
              let weekNumber = getNumberOfWeek(reminderMonth, day)
              weekNumber = weekNumber > 5 ? 5 : weekNumber
              monthsWithEvents[month][`Week ${weekNumber}`].push(reminder)
              return false
            } else {
              return true
            }
          } else {
            monthsWithEvents['January'][`Week ${1}`].push({
              ...reminder,
              date: 'Error: Date is missing'
            })
            return false
          }
        })
      }
      return monthsWithEvents
    } catch {
      handleErrors(`Error get definition from server for id: ${type}`)
      return []
    }
  }

  return {
    nameMonths,
    getReminders,
    getEvents,
    getEmails,
    postReminders,
    getGoogleReminders
  }
}
