<script setup>
  import { ref, onMounted, defineProps, inject, watch } from 'vue'
  // import { storeToRefs } from 'pinia'
  import { useFaqsStore } from '../stores/FaqsStore'
  import TextWithDefinitions from './TextWithDefinitions.vue'

  const faqStore = useFaqsStore()
  const customNavigation = inject('customNavigation')
  // const { getFaqs } = storeToRefs(faqStore)
  const { getFaqs } = faqStore
  const localFaqs = ref([])
  const faqTitle = ref('')
  const isLoaded = ref(false)

  const props = defineProps({
    section: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  })

  watch(
    () => props.loading,
    (newValue) => {
      if (!newValue) {
        isLoaded.value = true
      }
    }
  )

  onMounted(async () => {
    const remoteFaqs = await getFaqs()
    let faqsArray = Object.values(remoteFaqs.value)
    let faqsObject = faqsArray.filter((val) => val.sectionId === props.section)
    if (faqsObject && faqsObject.length > 0) {
      let localFaq = JSON.parse(faqsObject[0].FaqList)
      if (localFaq.length < 4) {
        for (let i = 0; i < 5 - localFaq.length; i++) {
          localFaq.push({ faqTitle: 'Missing faq title', faqBody: 'Missing faq body' })
        }
      } else if (localFaq.length > 4) {
        localFaq = localFaq.slice(0, 4)
      }
      localFaqs.value = localFaq
      faqTitle.value = faqsObject[0].sectionName
    }
  })
</script>

<template>
  <div class="row d-flex justify-content-center mt-20 px-1">
    <div class="col-md-12 pe-2">
      <div class="card">
        <div class="card-header bg-light px-4 pt-2.5">
          <h5 class="card-title add-teamates-link header-20 text-secondary">
            <strong>Frequently Asked Questions:</strong>
            {{ faqTitle }}
          </h5>
          <a
            class="view-full-profile-link hand-cursor text-decoration-none text-14 text-primary"
            @click.prevent="
              customNavigation.navigate({
                newPage: customNavigation.routes.frequentlyQuestions.name
              })
            "
            href="#">
            <strong>Go to All Frequently Asked Questions</strong>
            <i class="bi bi-chevron-right ms-2"></i>
          </a>
        </div>
        <div class="card-body ps-4">
          <div v-if="localFaqs.length > 0" class="row">
            <!-- Left column  -->
            <div class="col-md-6 pe-4.5">
              <div v-for="(faq, index) in localFaqs" :key="index">
                <div v-if="index % 2 === 0">
                  <h5 class="text-14 text-secondary mb-2">
                    <strong>{{ faq.faqTitle }}</strong>
                  </h5>
                  <p class="text-14 text-secondary mb-2.5">
                    <TextWithDefinitions :key="faq.faqBody" :text="faq.faqBody" />
                  </p>
                </div>
              </div>
            </div>
            <!-- Right column  -->
            <div class="col-md-6 ps-1.5 pe-5">
              <div v-for="(faq, index) in localFaqs" :key="index">
                <div v-if="index % 2 === 1">
                  <h5 class="text-14 text-secondary mb-2">
                    <strong>{{ faq.faqTitle }}</strong>
                  </h5>
                  <p class="text-14 text-secondary mb-2.5">
                    <TextWithDefinitions :key="faq.faqBody" :text="faq.faqBody" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="isLoaded && localFaqs.length === 0">
            <h2>Loading configuration for {{ section ? section : 'missing' }} faq section</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .view-full-profile-link {
    position: relative;
    float: right;
    margin-top: 5px;
  }

  .add-teamates-link {
    display: contents;
  }
</style>
