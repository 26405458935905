import { createWebHistory, createRouter } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import LoginPage from '@/views/LoginPage.vue'
import DataLoadPage from '../views/DataLoadPage.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import CreateUsers from '../views/CreateUsers.vue'
import CreatePassword from '../views/CreatePassword.vue'
import ReportsPage from '../views/ReportsPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/dataload',
    name: 'dataload',
    component: DataLoadPage,
    meta: {
      requiresAuth: true,
      requiredEmail: ['tyler@benefitscompliancesolutions.com', 'test@test.com']
    }
  },
  {
    path: '/createUsers',
    name: 'createUsers',
    component: CreateUsers,
    meta: { 
      requiresAuth: true,
      requiredEmail: ['tyler@benefitscompliancesolutions.com', 'test@test.com']
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsPage,
    meta: { 
      requiresAuth: true,
      requiredEmail: ['tyler@benefitscompliancesolutions.com', 'test@test.com']
    }
  },
  {
    path: '/createPassword',
    name: 'createPassword',
    component: CreatePassword
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: CreatePassword
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

// Navigation guard
router.beforeEach(async (to, from, next) => {
  const auth = getAuth()
  const user = await new Promise((resolve) => onAuthStateChanged(auth, resolve))
  const isAuthenticated = !!user
  const resetPass = to.query.resetPass === 'true'
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'login' })
  } else if ((to.name === 'login' || to.name === 'resetPassword') && isAuthenticated && !resetPass) {
    next({ name: 'home' })
  } else if (to.meta.requiredEmail && !to.meta.requiredEmail.includes(user.email)) {
    next({ name: 'home' })
  } else {
    next()
  }
})


export default router
