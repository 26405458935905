<script setup>
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useEmployerStore } from '../../stores/EmployerStore';
import { useAuthStore } from '../../stores/AuthStore';
import { FormatDateService } from '../../services/FormatDateService';

const activityData = ref({
    lastLogin: '',
    userSince: '',
    latestActivity: ''
})

const employerStore = useEmployerStore()
const { getLastUserActivity } = employerStore
const authStore = useAuthStore()
const { userData } = storeToRefs(employerStore)


const initializeUserData = async () => {
    const user = authStore.user
    if (user && !userData.value?.fullname) {
        await employerStore.getEmployer(user.employerId)
    }
}

onMounted(async () => {
    await initializeUserData()
    const lastActivity = await getLastUserActivity()
    activityData.value.latestActivity =  `${lastActivity.eventDescription}, ${FormatDateService.formatToReadableDate(lastActivity.timestamp)}`
})

watch(userData, (newUserData) => {
    if (newUserData?.lastLogin && newUserData?.creationTime) {
        const data = {
            lastLogin: FormatDateService.formatToReadableDate(newUserData.lastLogin),
            userSince: FormatDateService.formatToMonthYear(newUserData.creationTime),
        }
        Object.assign(activityData.value, data)
    }
}, { immediate: true })
</script>

<template>
    <div class="section pl-135 pr-40 d-flex align-items-center">
        <div class="header-22 fw-bold">
            Activity
        </div>

    </div>

    <div class="section-info pl-135 pr-40">
        <div class="section-row">
            <label>User Since</label>
            <div class="section-value">
                {{ activityData.userSince }}
            </div>
        </div>

        <div class="section-row">
            <label>Last Login</label>
            <div class="section-value">
                {{ activityData.lastLogin }}
            </div>

        </div>

        <div class="section-row">
            <label>Latest Activity</label>
            <div class="section-value">
                {{ activityData.latestActivity }}
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss"></style>