import { ref } from 'vue'
import { defineStore } from 'pinia'

export const usePositionStateStore = defineStore('positionStateStore', () => {
  const positionState = ref({})

  function setPositionState(employerId, key, value, subKey = null) {
    if (!(employerId in positionState.value)) {
      positionState.value[employerId] = {}
    }
    if (subKey) {
      if (!(key in positionState.value[employerId])) {
        positionState.value[employerId][key] = {}
      }
      positionState.value[employerId][key][subKey] = value
      return
    }
    positionState.value[employerId][key] = value
  }

  function getPositionState(employerId, key, subKey = null) {
    if (employerId in positionState.value) {
      if (subKey) {
        if (key in positionState.value[employerId]) {
          return positionState.value[employerId][key][subKey]
        }
        return null
      }
      return positionState.value[employerId][key]
    }
    return null
  }

  return {
    positionState,
    setPositionState,
    getPositionState
  }
})
