<script setup>
  import { ref, onMounted, watch, defineProps, inject } from 'vue'
  import { storeToRefs } from 'pinia'
  import * as bootstrap from 'bootstrap'
  import { useNavigationStore } from '../stores/NavigationStore'
  import BcsDynamicText from './BcsDynamicText.vue'
  import { useEmployerStore } from '../stores/EmployerStore'
  import { processDefinition } from '../utils'
  import { get } from 'lodash'
  import { RequestService } from '../services/RequestService'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'

  const employerStore = useEmployerStore()
  const navigationStore = useNavigationStore()
  const templateStore = useTemplateVariablesStore()
  const { refNavigate } = storeToRefs(navigationStore)
  const offcanvasRef = ref(null)
  const offcanvasInstance = ref(null)
  const { actualPlanYear } = storeToRefs(employerStore)

  const { getDefinitionById } = templateStore
  const { handleErrors } = RequestService()

  const yearOfActualPlan = ref('')

  const currentDefinition = ref({})
  const fullDate = ref('')
  const dueDate = ref('')
  const isDashboard = ref(false)
  // name of variable
  const titleTemplate = ref(null)

  // history of definition
  const definitionStack = ref([])

  const emitter = inject('emitter')
  const dateNotAvailable = ref(Boolean)

  const props = defineProps({
    definitionExpected: {
      type: String,
      default: 'definition'
    },
    canvasId: {
      type: String,
      default: ''
    },
    // end / start / top / bottom
    canvasPosition: {
      type: String,
      default: 'end'
    }
  })

  onMounted(() => {
    // needed use of var to avoid build errors
    console.log(props)
    offcanvasInstance.value = new bootstrap.Offcanvas(offcanvasRef.value, {
      keyboard: true,
      backdrop: false
    })
  })

  emitter.on('toggleOffCanvas', (value) => {
    value.id = value.id ? value.id : 'Missing id, check your configuration!!'
    openOffCanvas(
      value.id,
      value.definitionExpected,
      value.date,
      value.fromCanvas,
      value.dashboard,
      value.template
    )
  })

  watch(refNavigate, () => {
    offcanvasInstance.value.hide()
  })

  const goBack = async () => {
    if (definitionStack.value.length > 0) {
      emitter.emit('toggleLoading', { value: true })
      await offcanvasInstance.value.hide()
      await delay(200)
      currentDefinition.value = definitionStack.value.pop()
      await offcanvasInstance.value.show()
      emitter.emit('toggleLoading', { value: false })
      document.addEventListener('click', handleDocumentClick)
    }
  }

  const clearDefinitionStack = () => {
    definitionStack.value = []
  }

  const getProcessedDefinition = async (id, definitionExpected) => {
    try {
      const result = await getDefinitionById(id, definitionExpected)
      return processDefinition(result, id)
    } catch (error) {
      handleErrors('error getting definition')
      return null
    }
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  const handleCloseOffcanvas = async () => {
    await offcanvasInstance.value.hide()
    await delay(200)
    currentDefinition.value = {}
    document.removeEventListener('click', handleDocumentClick)
    return
  }

  const openOffCanvas = async (id, title, date, fromCanvas, dashboard, template) => {
    isDashboard.value = dashboard
    dueDate.value = date

    titleTemplate.value = template

    if (dueDate.value !== undefined && dueDate.value !== null) {
      if (!actualPlanYear.value?.plan_start_date) {
        yearOfActualPlan.value = ''
        handleErrors('actualPlanYear or plan_start_date is not defined')
      } else {
        const [dueMonth, dueDay] = dueDate.value.split('/').map(Number)
        const [planMonth, planDay, planYear] = actualPlanYear.value.plan_start_date
          .split('/')
          .map(Number)

        yearOfActualPlan.value = planYear
        if (dueMonth < planMonth || (dueMonth === planMonth && dueDay < planDay)) {
          yearOfActualPlan.value = (parseInt(planYear) + 1).toString()
        }
      }
    } else {
      yearOfActualPlan.value = ''
    }

    fromCanvas ? definitionStack.value.push(currentDefinition.value) : clearDefinitionStack()

    if (get(currentDefinition.value, 'id') === id) {
      await handleCloseOffcanvas()
      return
    }
    currentDefinition.value = {}
    fullDate.value = `${dueDate.value}/${yearOfActualPlan.value}`
    dateNotAvailable.value = compareDates(fullDate.value)

    //emitter.emit('toggleLoading', { value: true })
    await offcanvasInstance.value.hide()
    await delay(200)

    const processedDefinition =
      /\s/.test(id) ?
        { contentTitle: '', id, title, processedText: id }
      : await getProcessedDefinition(id, title)

    if (processedDefinition) {
      currentDefinition.value = processedDefinition || {}
    }

    if (fromCanvas) definitionStack

    document.addEventListener('click', handleDocumentClick)
    await offcanvasInstance.value.show()
    //emitter.emit('toggleLoading', { value: false })
  }

  const compareDates = (dateString) => {
    const [month, day, year] = dateString.split('/')
    const numericMonth = parseInt(month, 10)
    const numericDay = parseInt(day, 10)
    const numericYear = parseInt(year, 10)

    const date = new Date(numericYear, numericMonth - 1, numericDay)
    const today = new Date()
    date.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    if (date < today) {
      return true
    }

    return false
  }

  // handler to close the model if the clic is outside
  const handleDocumentClick = (event) => {
    const clickedElement = event.target
    const offcanvasElement = offcanvasRef.value
    const cursorStyle = window.getComputedStyle(clickedElement).cursor
    // If the clic is outside, we check if is link using the pointer style
    if (
      !offcanvasElement.contains(clickedElement) &&
      cursorStyle !== 'pointer' &&
      !clickedElement.closest('.no-close')
    ) {
      handleCloseOffcanvas()
    }
  }
</script>

<template>
  <div
    style="--bs-offcanvas-width: 511px"
    :aria-labelledby="currentDefinition.id + '-label'"
    :class="['offcanvas', `offcanvas-${canvasPosition}`]"
    ref="offcanvasRef"
    tabindex="-1"
    :id="get(currentDefinition, 'id', '')">
    <div class="offcanvas-header">
      <h5 v-if="titleTemplate == null" class="offcanvas-title">{{ currentDefinition?.title }}</h5>
      <h5 v-else class="offcanvas-title">{{ titleTemplate }}</h5>
      <div type="button" class="close-panel-button" @click="handleCloseOffcanvas()">
        <b>Close Panel</b>
        <i class="bi bi-x-lg"></i>
      </div>
    </div>

    <div class="offcanvas-body white-space-pre-wrap">
      <div>
        <div
          v-if="dueDate !== undefined"
          class="text-16 offcanvas-body__title d-flex align-items-center justify-content-between">
          <strong>Due {{ fullDate }}</strong>
          <div
            v-if="!dateNotAvailable && !isDashboard"
            @click="handleCloseOffcanvas()"
            class="d-flex align-items-center text-primary hand-cursor"
            data-bs-toggle="modal"
            data-source="offCanvas"
            data-bs-target="#addDueDateModal">
            <i class="bi bi-calendar-check"></i>
            <strong>&nbsp;&nbsp;Add Reminder to Calendar</strong>
          </div>
        </div>

        <div v-if="definitionStack.length > 0" @click="goBack" type="button" class="back">
          <i class="bi bi-chevron-left"></i>
          <b class="">Back to:{{ definitionStack[definitionStack.length - 1].title }}</b>
        </div>

        <BcsDynamicText
          v-if="currentDefinition && !get(currentDefinition, 'id', '').includes(' ')"
          :text="currentDefinition.id" />
        <div v-else v-html="currentDefinition?.processedText"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .row > * {
    padding: 0;
  }

  .back,
  .close-panel-button {
    padding: 0;
    color: #5c0cb8;
    font-size: 14px;
    flex-shrink: 0;
  }

  .back {
    margin-top: 2px;
    margin-bottom: 29px;
    i {
      margin-right: 5px;
    }
  }

  .close-panel-button {
    i {
      font-size: 11px;
      padding-left: 5px;
      -webkit-text-stroke: 1px;
    }
  }

  .spinner-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .spinner-border {
    margin: 0 auto;
    display: block;
  }
</style>
