<script setup>
  import { defineProps, defineEmits, computed } from 'vue'

  const props = defineProps({
    typeAlert: {
      type: String,
      default: 'success'
    },
    showAlert: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  })

  const emit = defineEmits(['update:showAlert'])

  const alertClass = computed(() => {
    switch (props.typeAlert) {
      case 'success':
        return 'alert-notification-success'
      case 'error':
        return 'alert-notification-error'
      case 'warning':
        return 'alert-notification-warning'
      case 'info':
        return 'alert-notification-info'
      default:
        return 'alert-notification-success'
    }
  })

  const alertIcon = computed(() => {
    switch (props.typeAlert) {
      case 'success':
        return 'bi-check-circle-fill'
      case 'error':
        return 'bi-x-circle-fill'
      case 'warning':
        return 'bi-exclamation-triangle-fill'
      case 'info':
        return 'bi-info-circle-fill'
      default:
        return 'bi-check-circle-fill'
    }
  })

  const closeAlert = () => {
    emit('update:showAlert', false)
  }
</script>

<template>
  <div
    v-if="showAlert"
    :class="['alert-notification', 'text-14', 'd-flex', 'justify-content-between', alertClass]">
    <div class="alert-notification__message d-flex">
      <i :class="['bi', alertIcon]"></i>
      <slot></slot>
    </div>

    <div v-if="props.showCloseButton" class="alert-notification__close" @click="closeAlert">
      <i class="bi bi-x-lg"></i>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .alert-notification {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    padding: 10px 12px;
    border-radius: 4px;
    &__message i {
      padding-right: 8px;
    }

    &__close {
      cursor: pointer;
    }

    &-success {
      background-color: #ddece5;
      border: 1px solid #198754;

      .alert-notification__message i,
      .alert-notification__message {
        color: #198754;
      }
    }

    &-error {
      background-color: #fae1e3;
      border: 1px solid #dc3545;
      .alert-notification__message i,
      .alert-notification__message {
        color: #dc3545;
      }
    }

    &-warning {
      background-color: #fff6da;
      border: 1px solid #d88201;
      .alert-notification__message i,
      .alert-notification__message {
        color: #d88201;
      }
    }

    &-info {
      background-color: #f1eff7;
      border: 1px solid #2e0c59;
      .alert-notification__message i,
      .alert-notification__message {
        color: #2e0c59;
      }
    }
  }
</style>
