<script setup>
  import { ref, computed, watch, onMounted, nextTick } from 'vue'
  import { RequestService } from '../services/RequestService'
  const props = defineProps({
    dropdownOptions: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Please select'
    },
    context: {
      type: Object,
      default: () => ({})
    }
  })

  watch(
    () => props.modelValue,
    (newValue) => {
      selectedOption.value = newValue
    }
  )

  const emit = defineEmits(['update:modelValue', 'change'])

  const isOpen = ref(false)
  const selectedOption = ref(props.modelValue)
  const dropdownRef = ref(null)
  const dropdownMenuRef = ref(null)
  const openUpwards = ref(false)
  const { handleErrors } = RequestService()

  const displayText = computed(() => {
    if (props.context.showCustom && props.context.customReminder !== null) {
      return `Custom: ${props.context.customReminder} days before`
    }
    const option = props.dropdownOptions.find((opt) => opt.value === selectedOption.value)
    return option ? option.text : props.placeholder
  })

  const toggleDropdown = () => {
    isOpen.value = !isOpen.value
    if (isOpen.value) {
      nextTick(() => {
        updateDropdownPosition()
      })
    }
  }

  const updateDropdownPosition = () => {
    const dropdownElement = dropdownRef.value
    const dropdownMenuElement = dropdownMenuRef.value
    if (!dropdownElement || !dropdownMenuElement) return

    const tableContainer = dropdownElement.closest('.table-container-events.card')
    if (!tableContainer) return

    const dropdownRect = dropdownElement.getBoundingClientRect()
    const containerRect = tableContainer.getBoundingClientRect()
    const spaceBelow = containerRect.bottom - dropdownRect.bottom
    const spaceAbove = dropdownRect.top - containerRect.top
    const dropdownHeight = dropdownMenuElement.offsetHeight
    openUpwards.value = spaceBelow < dropdownHeight && spaceAbove > spaceBelow
  }

  const selectOption = (option) => {
    selectedOption.value = option.value
    emit('update:modelValue', option.value)
    emit('change', option)
    isOpen.value = false
  }

  const isOptionDisabled = (option) => {
    if (typeof option.isDisabled === 'function') {
      try {
        return option.isDisabled(props.context)
      } catch (error) {
        handleErrors(('Error in isDisabled function:', error))
        return false
      }
    }
    return false
  }

  const computedOptions = computed(() => {
    let options = [...props.dropdownOptions]
    if (props.context.showCustom && props.context.customReminder !== null) {
      options.push({
        value: -2,
        text: `Custom: ${props.context.customReminder} days before`
      })
    }
    return options
  })

  const handleClickOutside = (event) => {
    if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
      isOpen.value = false
    }
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside)
    window.addEventListener('resize', updateDropdownPosition)
  })
</script>

<template>
  <div class="dropdown" ref="dropdownRef">
    <button
      type="button"
      class="btn btn-outline-secondary custom-btn fixed-width"
      @click="toggleDropdown"
      :class="{ show: isOpen }">
      <span :class="{ selected: selectedOption }" class="text-16 placeholder-dropdown text-truncate">
        {{ displayText }}
      </span>
      <i
        :class="[
          'bi',
          'ms-2',
          'text-primary',
          isOpen ? 'bi-caret-up-fill' : 'bi-caret-down-fill'
        ]"></i>
    </button>
    <ul
      ref="dropdownMenuRef"
      class="dropdown-menu fixed-width"
      :class="{
        show: isOpen,
        'dropdown-menu-up': openUpwards
      }"
      :style="{ bottom: openUpwards ? '100%' : 'auto', top: openUpwards ? 'auto' : '100%' }">
      <li v-for="option in computedOptions" :key="option.value">
        <a
          class="dropdown-item text-primary hand-cursor text-nowrap"
          @click="selectOption(option)"
          :class="{ disabled: isOptionDisabled(option) }">
          {{ option.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
  .placeholder-dropdown,
  .disabled {
    color: var(--bs-gray-600) !important;
  }

  .btn-outline-secondary.btn.show {
    background: transparent !important;
  }

  .fixed-width {
    width: 195px !important;
  }

  .custom-btn {
    transition: box-shadow 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.5rem;

    &:hover {
      background-color: transparent;
      color: var(--bs-secondary);
      border-color: var(--bs-secondary);
    }

    &:active,
    &:focus,
    &.show {
      background-color: transparent !important;
      color: var(--bs-secondary) !important;
      border-color: #5c0cb8 !important;
      box-shadow: 0 0 0 0.25rem #cac0ff !important;
    }
  }

  .text-truncate {
    max-width: 150px;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }

  .dropdown-menu {
    min-width: unset;
    padding: 0.5rem 0;
    position: absolute;
    max-height: 195px;
    overflow-y: auto;

    &.dropdown-menu-up {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 0.125rem;
    }

    .dropdown-item {
      padding: 0.25rem 1rem;
      white-space: normal;
      word-wrap: break-word;

      &:hover {
        background-color: #5c0cb8 !important;
        color: #fff !important;
      }
    }
  }
</style>
