import { ref, onMounted, inject } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { RequestService } from '../services/RequestService'
import { useSessionStorageStore } from './SessionStorage'
import { isEmpty, get, filter, uniqBy } from 'lodash'
import { stringMapValues } from '../utils'
import { useEmployerStore } from './EmployerStore'
const routes = {
  Home: {
    title: 'Home',
    name: 'home'
  },
  ListOfEmployers: {
    title: 'List of Employers',
    name: 'listOfEmployers'
  },
  DetailEmployer: {
    title: 'Employer Detail',
    name: 'detailEmployer:employerId',
    children: [
      {
        title: 'Employer Dashboard',
        name: 'employerDashboard', // 0
        breadCrumbs: ['listOfEmployers']
      },
      {
        title: 'Intake Questionnaire',
        name: 'intakeQuestionnaire', // 1
        breadCrumbs: ['listOfEmployers']
      },
      {
        title: 'Assessment Tool',
        name: 'assessmentTool', // 2
        breadCrumbs: ['listOfEmployers']
      },
      {
        title: 'Gap Report',
        name: 'gapReport', // 3
        breadCrumbs: ['listOfEmployers']
      },
      {
        title: 'Planning Tool',
        name: 'planningTool', // 4
        breadCrumbs: ['listOfEmployers']
      },
      {
        title: 'Calendar',
        name: 'bcsCalendar', // 5
        breadCrumbs: ['listOfEmployers']
      },
      {
        title: 'Notes',
        name: 'Notes', // 6
        breadCrumbs: ['listOfEmployers']
      }
    ]
  },
  helpAndResources: {
    title: 'Help and Resources',
    name: 'helpAndResources',
    breadCrumbs: ['${previousEmployerPage}']
  },
  frequentlyQuestions: {
    title: 'Frequently Asked Questions',
    name: 'frequentlyQuestions',
    breadCrumbs: ['${previousEmployerPage}', '${previousPage}']
  },
  glossaryTerms: {
    title: 'Glossary Terms',
    name: 'glossaryTerms',
    breadCrumbs: ['${previousEmployerPage}', '${previousPage}']
  },
  termsAndConditions: {
    title: 'Terms & Conditions',
    name: 'termsAndConditions',
    breadCrumbs: ['${previousPage}']
  },
  memberProfile: {
    title: 'Member Profile',
    name: 'memberProfile',
    breadCrumbs: ['${previousPage}']
  }
}

const getCompleteRouteData = (newPage) => {
  for (const mainRoute in routes) {
    if (newPage === routes[mainRoute].name) {
      return { ...routes[mainRoute], page: routes[mainRoute].name }
    }
    const children = get(routes, `${mainRoute}.children`, [])
    if (!isEmpty(children)) {
      const child = children.find((ch) => ch.name === newPage)
      if (child) {
        return { ...child, subPage: child.name, page: routes[mainRoute].name }
      }
    }
  }
  return {}
}

const getBreadCrumbs = (newPage, params) => {
  if (!newPage) return []
  const completeRouteData = getCompleteRouteData(newPage)
  let addEmployerList = false

  const breadCrumbs = get(completeRouteData, 'breadCrumbs', []).map((page) => {
    if (page.indexOf('$') !== -1) {
      let title = ''
      let name = stringMapValues(page, params)
      let subPage = ''

      // If name is a composite path then we parse its value to include the employer name and the formatted subpath name
      if (typeof name === 'string' && name.indexOf('/') != -1) {
        subPage = name.substring(name.indexOf('/') + 1, name.length)
        title = subPage.charAt(0).toUpperCase() + subPage.slice(1)
        title = params.actualEmployer?.value.name + ' ' + title
        title = title.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())
        // addEmployerList = get(completeRouteData, 'name', '') === routes.termsAndConditions.name
        addEmployerList = true
      } else if (page === '${previousPage}' && name !== 'home') {
        const completeRouteData = getCompleteRouteData(name)
        title = get(completeRouteData, 'title', '')
      }
      return {
        title,
        name,
        subPage,
        page: name
      }
    }
    return getCompleteRouteData(page)
  })

  completeRouteData.isDisabled = true
  let newBreadCrumbs = [
    ...(addEmployerList ? [routes.ListOfEmployers] : []),
    ...breadCrumbs,
    completeRouteData
  ]
  newBreadCrumbs = uniqBy(filter(newBreadCrumbs, (bc) => !!bc.title), 'name')
  return newBreadCrumbs
}

export const useNavigationStore = defineStore('navigation', () => {
  const sessionStorageStore = useSessionStorageStore()
  const employerStore = useEmployerStore()
  const emitter = inject('emitter')

  const { setEvent, getEvent } = sessionStorageStore
  const { actualEmployer } = storeToRefs(employerStore)

  const { createLog, handleErrors } = RequestService()
  const isSubpage = ref(false)

  const currentPage = ref(routes.Home.name)
  const currentSubPage = ref(null)
  const backStack = ref([])
  const backStackBreadCrumbs = ref([])
  const breadCrumbs = ref([])
  const forwardStack = ref([])
  const forwardStackBreadCrumbs = ref([])
  const internalQuery = ref({})
  const navigationParams = ref({ employerId: 0 })

  const refNavigate = ref(false)

  const checkIfRefresh = () => {
    const wasReloaded = getEvent('pageReloaded')
    if (wasReloaded === 'true') {
      setEvent('refresh', true)
    } else {
      setEvent('refresh', false)
    }
    setEvent('pageReloaded', true)
  }

  onMounted(() => {
    checkIfRefresh()
  })

  function getDynamicRoute(newPage, params = {}) {
    const keysOfParams = Object.keys(params)
    keysOfParams.forEach((key) => {
      if (newPage.includes(`:${key}`)) {
        newPage = newPage.replace(`:${key}`, params[key])
      }
    })
    return newPage
  }

  const ASSESSMENT_TOOL_VIEWED = 11
  const PLANNING_GUIDE_VIEWED = 12
  const CALENDAR_VIEWED = 13
  const FAQ_VIEWED = 17
  const GLOSSARY_VIEWED = 18

  const eventIdByPageName = new Map([
    ['assessmentTool', ASSESSMENT_TOOL_VIEWED],
    ['planningTool', PLANNING_GUIDE_VIEWED],
    ['bcsCalendar', CALENDAR_VIEWED],
    ['frequentlyQuestions', FAQ_VIEWED],
    ['glossaryTerms', GLOSSARY_VIEWED]
  ])

  function navigate({ newPage, subPage = null, params = {}, query = {}, saveEvent = false }) {
    setEvent('pageReloaded', false)
    emitter.emit('closeBcsModal')
    document.body.style.overflow = ''
    document.body.style.paddingRight = ''

    if (params.employerId) {
      setEvent('routeParamsId', params.employerId)
    }

    setEvent('route', newPage)
    setEvent('subroute', subPage)

    refNavigate.value = !refNavigate.value

    if (newPage !== currentPage.value || subPage !== currentSubPage.value) {
      isSubpage.value = false
      const keysOfParams = Object.keys(params)

      keysOfParams.forEach((key) => {
        if (newPage.includes(`:${key}`)) {
          newPage = newPage.replace(`:${key}`, params[key])
        }
        navigationParams.value[key] = params[key]
      })
      if (subPage) {
        if (newPage.includes('/')) {
          newPage = `${newPage.split('/')[0]}/${subPage}`
        } else {
          newPage = `${newPage}/${subPage}`
        }

        isSubpage.value = true
        currentSubPage.value = subPage
      }
      internalQuery.value = query
      backStack.value.push(currentPage.value)
      backStackBreadCrumbs.value.push(breadCrumbs.value)
      breadCrumbs.value = getBreadCrumbs(subPage || newPage, {
        previousEmployerPage: currentPage.value,
        previousPage: currentPage.value,
        actualEmployer,
        navigationParams: navigationParams.value
      })
      currentPage.value = newPage
      forwardStack.value = []
      forwardStackBreadCrumbs.value = []

      const eventId = eventIdByPageName.get(subPage || newPage)
      if (saveEvent && eventId) {
        createLog({
          eventId,
          logName: 'user_activity_log',
          employerId: actualEmployer?.value.id || null,
          agencyId: actualEmployer?.value.agency_id || null
        }).catch((err) => handleErrors(err))
      }
    }

    setEvent('isSubpage', isSubpage.value)
  }

  function goBack() {
    emitter.emit('closeBcsModal')
    if (backStack.value.length === 0) return
    forwardStack.value.push(currentPage.value)
    forwardStackBreadCrumbs.value.push(breadCrumbs.value)
    currentPage.value = backStack.value.pop()
    breadCrumbs.value = backStackBreadCrumbs.value.pop()
    if (currentPage.value.includes('/')) {
      currentSubPage.value = currentPage.value.split('/')[1]
    } else {
      currentSubPage.value = null
    }
  }

  function goBackN(times = 1) {
    for (let i = 0; i < times; i++) {
      goBack()
    }
  }

  function goForward() {
    emitter.emit('closeBcsModal')
    if (forwardStack.value.length === 0) return
    backStack.value.push(currentPage.value)
    backStackBreadCrumbs.value.push(breadCrumbs.value)
    currentPage.value = forwardStack.value.pop()
    breadCrumbs.value = forwardStackBreadCrumbs.value.pop()
    if (currentPage.value.includes('/')) {
      currentSubPage.value = currentPage.value.split('/')[1]
    } else {
      currentSubPage.value = null
    }
  }

  function getBreadcrumbs() {
    const backStackLength = customNavigation.value.backStack.length
    return customNavigation.value.backStack
      .slice(backStackLength - 3, backStackLength)
      .map((val) => {
        let texToShow = ''
        texToShow = val
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/^./, (str) => str.toUpperCase())
        return { route: val, display: texToShow }
      })
      .filter((val) => val.display !== 'Home')
  }

  const customNavigation = ref({
    routes,
    currentPage,
    currentSubPage,
    backStack,
    forwardStack,
    internalQuery,
    breadCrumbs,
    navigationParams,
    getDynamicRoute,
    navigate,
    goBack,
    goForward,
    getBreadcrumbs,
    goBackN
  })

  return {
    customNavigation,
    refNavigate
  }
})
