
export const FormatDateService = {
    /**
     * Converts time to 12-hour format with AM/PM
     * @param {Date|String} date - Date to format
     * @returns {String} Formatted time (example: "1:30 PM")
     */
    formatTo12Hours(date) {
        if (!(date instanceof Date)) {
            date = new Date(date)
        }
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        })
    },

    /**
 * Formats date to "Month Year" format
 * @param {Date|String} date - Date to format
 * @returns {String} Formatted date (example: "January 2025")
 */
    formatToMonthYear(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        return date.toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric'
        });
    },

    /**
     * Formats date to "Month Day, Year" format
     * @param {Date|String} date - Date to format
     * @returns {String} Formatted date (example: "January 28, 2025")
     */
    formatToFullDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
    },

    /**
     * Determines if a date is today, yesterday or another day
     * @param {Date|String} date - Date to evaluate
     * @returns {String} 'today', 'yesterday' or 'other'
     */
    checkDateType(date) {
        if (!(date instanceof Date)) {
            date = new Date(date)
        }
        const now = new Date()
        const yesterday = new Date(now)
        yesterday.setDate(now.getDate() - 1)

        if (date.toDateString() === now.toDateString()) return 'today'
        if (date.toDateString() === yesterday.toDateString()) return 'yesterday'
        return 'other'
    },

    /**
     * Formats date to "Today/Yesterday at HH:MM AM/PM" or "Month Day, Year at HH:MM AM/PM"
     * @param {Date|String} date - Date to format
     * @returns {String} Formatted date (example: "Today at 1:30 PM" or "January 28, 2025 at 1:30 PM")
     */
    formatToReadableDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        const dateType = this.checkDateType(date);
        const time = this.formatTo12Hours(date);

        switch (dateType) {
            case 'today':
                return `Today at ${time}`
            case 'yesterday':
                return `Yesterday at ${time}`
            default:
                return `${this.formatToFullDate(date)} at ${time}`
        }
    }
}