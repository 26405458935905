<script setup>
  import { useNotesStore } from '../../stores/NotesStore.js'
  import { ref } from 'vue'
  import { useEmployerStore } from '../../stores/EmployerStore'
  import { storeToRefs } from 'pinia'
  import { getTodayFormatted } from '../../utils'

  const notesStore = useNotesStore()
  const {
    cancelCreatingNote,
    createEmployerNote,
    crudNote,
    getEmployerNotes,
    clearSelectedNote    
  } = notesStore

  const employerStore = useEmployerStore()
  const { actualEmployer } = storeToRefs(employerStore)
  const { userData } = storeToRefs(notesStore)

  const noteDescription = ref('')

  const cancelCreate = () => {
    cancelCreatingNote()
  }

  const createNote = async () => {
    const data = {
      employer_id: actualEmployer.value.id,
      note: noteDescription.value,
      date: getTodayFormatted()
    }

    crudNote()
    cancelCreatingNote()
    await createEmployerNote(data)
    await getEmployerNotes()
    clearSelectedNote()
  }
</script>

<template>
  <div class="create-note d-flex align-items-center h-100">
    <div class="create-content w-100">
      <h5>{{ userData.fullname }}</h5>

      <textarea
        class="form-control"
        rows="10"
        v-model="noteDescription"
        placeholder="Note content"></textarea>

      <span
        class="remaining-characters text-12 d-flex justify-content-end"
        :class="{ 'error-hint': noteDescription.length > 1300 }">
        {{ noteDescription ? 1300 - noteDescription.length : 1300 }} characters remaining
      </span>
      <div class="action-buttons d-flex justify-content-end">
        <button class="btn btn-info text-14 cancel-btn fw-medium" @click="cancelCreate">
          Cancel Create
        </button>
        <button
          @click="createNote"
          class="btn btn-primary text-14 save-btn"
          :disabled="noteDescription.length === 0 || noteDescription.length > 1300">
          Create Note
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .create-note {
    padding: 0 20px;

    textarea {
      margin-top: 30px;
    }
  }

  .remaining-characters {
    font-weight: 700;

    margin: 10px 0;
  }

  .action-buttons {
    gap: 15px;
  }
</style>
