<script setup>
  import { defineEmits, inject, ref, onMounted, reactive, watch } from 'vue'
  import AssessmentSteps from './AssessmentSteps.vue'
  import Footer from './FooterSection.vue'
  import { vElementVisibility } from '@vueuse/components'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { usePositionStateStore } from '../stores/PositionStateStore'
  import { storeToRefs } from 'pinia'
  import BcsDynamicText from './BcsDynamicText.vue'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import { markCheckbox } from '../utils'
  import { useEmployerStore } from '../stores/EmployerStore'
  import { vMaska } from 'maska'
  import { RequestService } from '../services/RequestService'
  import { ScoreCalculationsService } from '../services/ScoreCalculationsService'
  import AlertNotification from './AlertNotification.vue'
  import { sortBy, isEqual, some, isEmpty, uniq } from 'lodash'
  const emitter = inject('emitter')
  const emit = defineEmits(['toggleLoading', 'inFocus'])

  const customNavigation = inject('customNavigation')

  const questionStore = useQuestionsStore()
  const positionStateStore = usePositionStateStore()

  const { assessmentQuestions, assessKeys, erisaDate, intakeQuestions, isLoading } =
    storeToRefs(questionStore)

  const {
    saveQuestions,
    clearQuestions,
    showQuestion,
    hideDueICHRA,
    getCompletionStatus,
    isAssessmentComplete,
    isValidDate
  } = questionStore

  const employerStore = useEmployerStore()
  const { actualEmployer } = storeToRefs(employerStore)
  const { createLog, handleErrors } = RequestService()
  const { saveScore } = ScoreCalculationsService()

  const rawMaskVal = reactive({})

  const { setPositionState, getPositionState } = positionStateStore

  const componentKey = ref(0)
  const showAlertClearAssessment = ref(false)

  const visibleSectionsRefs = ref([])
  const scrollContRef = ref()

  const saving = ref(false)

  const lastTimeSavedAssessment = ref(null)
  const timeElapsedAssessment = ref('Not auto-saved yet ')
  const idSetIntervalSecondAssessment = ref(null)
  const idSetIntervalMinuteAssessment = ref(null)
  const intakeErisaAnswer = ref()

  const hideText = new Map([])

  async function saveAssessment({ activeLoading = true, noteId = null }) {
    emit('toggleLoading', activeLoading)
    saving.value = true
    try {
      await saveQuestions('assessment', actualEmployer.value.id, noteId)
      saving.value = false
      componentKey.value += 1
      emit('toggleLoading', false)
      const ASSESSMENT_UPDATED = 6
      const ASSESSMENT_COMPLETED = 19

      const logEntry = {
        eventId: ASSESSMENT_UPDATED,
        logName: 'user_activity_log',
        employerId: actualEmployer.value.id,
        agencyId: actualEmployer.value.agency_id
      }

      if (isAssessmentComplete()) {
        logEntry.eventId = ASSESSMENT_COMPLETED
        saveScore(actualEmployer.value.id)
      }
      await createLog(logEntry)
    } catch (err) {
      handleErrors(err)
    }
  }

  function autoSaveAssessment({ noteId = null }) {
    saveAssessment({ activeLoading: false, noteId })
    lastTimeSavedAssessment.value = new Date()
    timeElapsedAssessment.value = 'Auto-saved just now'

    if (idSetIntervalSecondAssessment.value) {
      clearInterval(idSetIntervalSecondAssessment.value)
    }
    if (idSetIntervalMinuteAssessment.value) {
      clearInterval(idSetIntervalMinuteAssessment.value)
    }
    let countSeconds = 0
    idSetIntervalSecondAssessment.value = setInterval(() => {
      countSeconds++
      let now = new Date()
      let diff = now - lastTimeSavedAssessment.value
      let seconds = Math.floor(diff / 1000)
      timeElapsedAssessment.value = `Auto-saved ${seconds} secs ago`
      if (countSeconds > 59) {
        clearInterval(idSetIntervalSecondAssessment.value)
        idSetIntervalMinuteAssessment.value = setInterval(() => {
          let now = new Date()
          let diff = now - lastTimeSavedAssessment.value
          let minutes = Math.floor(diff / 60000)
          timeElapsedAssessment.value = `Auto-saved ${minutes} mins ago`
        }, 60000)
      }
    }, 10000)
  }

  function generateReport() {
    saveAssessment({})
    customNavigation.value.navigate({
      newPage: customNavigation.value.currentPage,
      subPage: customNavigation.value.routes.DetailEmployer.children[3].name
    })
  }

  function clearAssessment() {
    clearQuestions('assessment')
    showAlertClearAssessment.value = false
    saveAssessment({})
  }

  onMounted(() => {
    if (!isLoading) {
      intakeErisaAnswer.value = Object.values(intakeQuestions.value)[0][1].answer
    }
    const scrollPosition = getPositionState(actualEmployer.value.id, 'assessmentTool')
    if (scrollPosition && formAssessment.value instanceof HTMLElement) {
      formAssessment.value.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      })
    }
  })

  watch(
    () => isLoading.value,
    (newIsLoading) => {
      if (!newIsLoading) {
        intakeErisaAnswer.value = Object.values(intakeQuestions.value)[0][1].answer
      }
    }
  )

  const formAssessment = ref(null)
  const lastVisibleQuestion = ref('q-0')

  const controlVisibilityOfSection = ref([])
  function onElementVisibilityParent(keyIndex, isVisible) {
    controlVisibilityOfSection.value[keyIndex] = isVisible
  }

  function getVisibleSection(reInvoke = true) {
    const scrollPosition = formAssessment.value.scrollTop
    setPositionState(actualEmployer.value.id, 'assessmentTool', scrollPosition)
    // la variable "controlVisibilityOfSection" es un array de booleanos que controla si la sección está visible
    // el índice del array corresponde al índice de la sección
    // entonces creo un array con los índices de las secciones que están visibles luego filtro los índices que quedaron
    let indexVisibleSections = controlVisibilityOfSection.value
      .map((isVisible, index) => {
        return isVisible ? index : -1
      })
      .filter((index) => index > -1)
    // luego obtengo el bounding de cada sección visible y obtengo el top de cada una
    let topOfVisibleSections = {}
    indexVisibleSections.forEach((index) => {
      let section = visibleSectionsRefs.value[index]
      let bounding = section.getBoundingClientRect()
      topOfVisibleSections[index] = bounding.top
    })
    // para luego saber cual es la sección más cercana a la parte superior de la pantalla
    let closest = Object.keys(topOfVisibleSections).reduce((indexA, indexB) => {
      if (topOfVisibleSections[indexA] > 150 && topOfVisibleSections[indexA] < 350) {
        return indexA
      }
      if (topOfVisibleSections[indexB] > 150 && topOfVisibleSections[indexB] < 350) {
        return indexB
      }
      return Math.abs(topOfVisibleSections[indexA]) < Math.abs(topOfVisibleSections[indexB]) ?
          indexA
        : indexB
    }, 0)
    // y finalmente actualizo el valor de la variable "lastVisibleQuestion" con el id de la sección más cercana
    lastVisibleQuestion.value = `q-${closest}`
    if (reInvoke) {
      setTimeout(() => {
        getVisibleSection(false)
      }, 50)
    }
  }

  const getClass = (key, keyIndex) => {
    return {
      active: lastVisibleQuestion.value === `q-${keyIndex}`,
      [getCompletionStatus(key, keyIndex)]: true
    }
  }

  function handleClickCheckBox(event, question, optionIndex) {
    if (!event.target.closest('.words-for-definition')) {
      markCheckbox(question, optionIndex)
      autoSaveAssessment({})
    }
  }

  function handleRadioClick(event, question, optionIndex) {
    if (!event.target.closest('.words-for-definition')) {
      question.answer = optionIndex
      autoSaveAssessment({})
    }
  }

  function isHiddenGroupStart(questions, index) {
    if (index === 0) {
      return true
    } else if (showQuestion(questions[index - 1]).show) {
      return true
    } else if (!isEqual(sortBy(showQuestion(questions[index - 1]).questionsHideReasons), sortBy(showQuestion(questions[index]).questionsHideReasons))) {
      return true
    } else {
      return false
    }
  }

  function getHiddenGroupRange(questions, startIndex) {
    let endIndex = startIndex
    let id = questions[startIndex].id
    let reasons = []
    let questionsHideReasons = null

    let startObj = showQuestion(questions[startIndex])
    while (endIndex < questions.length) {
      let endObj = showQuestion(questions[endIndex])
      if (!endObj.show && isEqual(sortBy(startObj.questionsHideReasons), sortBy(endObj.questionsHideReasons))) {
        if (!isEmpty(endObj.reasons)) {
          reasons.push(...endObj.reasons)

          if (questionsHideReasons === null) {
            questionsHideReasons = endObj.questionsHideReasons
          }
        }
        endIndex++
      } else {
        break
      }
    }

    let message =
      some(questionsHideReasons, (questionId) => questionId <= 13) ?
      'hidden due to answer(s) provided on intake questionnaire'
      : 'hidden due to previously provided answer(s) on assessment'

    if (!isEmpty(reasons)) {
      hideText.set('' + id, uniq(reasons).join('\n\n'))
    }

    if (endIndex === startIndex + 1) {
      return `Question ${startIndex + 1} is ${message}`
    }
    return `Questions ${startIndex + 1}-${endIndex} ${message}`
  }
</script>

<template>
  <div class="col-10 px-0">
    <div class="mt-0 px-0">
      <form
        class="wizard-form scroll-fix"
        ref="formAssessment"
        @submit.prevent="() => saveAssessment({})"
        @scroll="getVisibleSection">
        <div class="steps-banner">
          <div class="container-fluid px-3">
            <div class="row">
              <div class="col-8">
                <h1 class="my-3 text-secondary">
                  {{ actualEmployer.name }} BCS Pro Gap Analysis Tool
                </h1>
                <span class="py-2 text-14">
                  <BcsDynamicText text="assessmentToolInstructionalText" />
                </span>
              </div>
              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <!-- <a
                    class="hand-cursor d-inline my-3 px-2 border-end text-decoration-none text-14 text-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#pendingFunctionModal">
                    <strong>View Sample Report</strong>
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="steps-banner sticky-top pt-3 mt-3">
          <div class="row">
            <AssessmentSteps
              :key="componentKey"
              :activeStep="lastVisibleQuestion"></AssessmentSteps>
            <div class="col-2">
              <button type="submit" class="btn btn-primary" :disabled="saving">
                {{ saving ? 'Saving...' : 'Save Draft' }}
                <i class="bi bi-arrow-right-short"></i>
              </button>
              <br />
              <span class="text-12 text-end d-inline-block">{{ timeElapsedAssessment }}</span>
            </div>
          </div>
          <hr />
        </div>
        <div class="form-container scroll-padding px-0" ref="scrollContRef">
          <template v-for="(key, keyIndex) in assessKeys" :key="keyIndex">
            <div
              :class="`row mx-3`"
              ref="visibleSectionsRefs"
              v-if="!hideDueICHRA(assessmentQuestions[key][0]['short_title'])"
              :id="keyIndex">
              <div class="col-1 w-auto pt-1">
                <span
                  class="scroll-padding circle"
                  :id="keyIndex + 1"
                  :class="getClass(key, keyIndex)">
                  {{ keyIndex + 1 }}
                </span>
              </div>
              <div
                class="col-11"
                v-element-visibility="[
                  (state) => onElementVisibilityParent(keyIndex, state),
                  { scrollTarget: formAssessment }
                ]">
                <span :id="`q-${keyIndex}`" class="pt-2 header-22 d-block mb-3">
                  <strong>
                    <TextWithDefinitions :text="assessmentQuestions[key][0]['subtitle']" />
                  </strong>
                </span>
                <span class="pb-3 text-16">
                  {{ key }}
                </span>
                <div
                  class="alert-assesment"
                  v-if="assessmentQuestions[key][0]['id'] === 13 && intakeErisaAnswer === 1">
                  <AlertNotification :showCloseButton="false" typeAlert="info">
                    <strong><BcsDynamicText text="erisaAlertNotification" /></strong>
                  </AlertNotification>
                </div>
                <div
                  v-for="(question, questionIndex) in assessmentQuestions[key]"
                  :class="showQuestion(question).show ? 'my-3' : 'my-2'"
                  :key="questionIndex">
                  <div class="row pt-2" v-if="showQuestion(question).show">
                    <div class="col-5">
                      <span class="question-title text-14">
                        <strong>
                          <TextWithDefinitions
                            :text="`Q${questionIndex + 1}: ${question.question}`" />
                        </strong>
                      </span>
                      <div
                        class="mt-2 erisa-date-container"
                        :class="{ selected: question.answer === 0 && question.type === 4 }">
                        <template v-if="question.type === 0">
                          <div
                            v-for="(option, optionIndex) in question.options"
                            class="form-check form-check-inline"
                            :key="optionIndex"
                            @click="handleRadioClick($event, question, optionIndex)">
                            <input
                              class="form-check-input"
                              type="radio"
                              :id="question.id + '' + optionIndex + 'radio'"
                              v-model="question.answer"
                              :value="optionIndex"
                              :checked="question.answer === optionIndex"
                            />
                            <label class="form-check-label text-16">
                              <TextWithDefinitions :text="option" />
                            </label>
                          </div>
                        </template>
                        <template v-else-if="question.type === 1">
                          <div
                            v-for="(option, optionIndex) in question.options"
                            class="form-check"
                            :key="option"
                            @click="handleClickCheckBox($event, question, optionIndex)">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :id="question.id + '' + optionIndex + 'check'"
                              v-model="question.answer"
                              :value="optionIndex" />
                            <label class="form-check-label text-16">
                              <TextWithDefinitions :text="option" />
                            </label>
                          </div>
                        </template>
                        <div v-else-if="question.type === 2">
                          <select
                            name="cars"
                            class="form-select"
                            @change="() => autoSaveAssessment({})"
                            v-model="question.answer">
                            <option
                              v-for="(option, i) in question.options"
                              :value="option"
                              class="text-16"
                              :key="i">
                              <TextWithDefinitions :text="option" />
                            </option>
                          </select>
                        </div>
                        <template v-if="question.type === 4">
                          <div
                            v-for="(option, optionIndex) in question.options"
                            class="form-check form-check-inline"
                            :key="optionIndex"
                            @click="(question.answer = optionIndex), autoSaveAssessment({})">
                            <input
                              class="form-check-input"
                              type="radio"
                              :id="question.id + '' + optionIndex + 'radio'"
                              v-model="question.answer"
                              :value="optionIndex" />
                            <label class="form-check-label text-16">
                              <TextWithDefinitions :text="option" />

                              <input
                                v-if="
                                  optionIndex === 0 &&
                                  (question.answer === 0 || question.answer > 10)
                                "
                                type="text"
                                class="form-control text-16 prueba-2"
                                v-model="erisaDate"
                                v-maska="rawMaskVal"
                                data-maska="##/##/####"
                                placeholder="e.g., MM/DD/YYYY"
                                :class="{ 'is-invalid': !isValidDate(erisaDate) }"
                                @change="autoSaveAssessment({})" />
                            </label>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="col-7">
                      <span class="text-14"><strong>Notes</strong></span>
                      <textarea
                        class="form-control mt-2"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model="question.notes"
                        @change="() => autoSaveAssessment({ noteId: question.id })"></textarea>
                    </div>
                  </div>
                  <div v-else-if="isHiddenGroupStart(assessmentQuestions[key], questionIndex)">
                    <div class="row">
                      <div class="col-12">
                        <div class="text-14 p-3 bg-gray-100 text-gray-500">
                          <strong>
                            {{
                              getHiddenGroupRange(assessmentQuestions[key], questionIndex) + '   '
                            }}
                          </strong>
                          <span
                            class="show-question-link ml-1"
                            id="hide-reasonLink"
                            aria-controls="#hide-reason"
                            @click="
                              emitter.emit('toggleOffCanvas', {
                                id: hideText.get('' + question.id),
                                definitionExpected: 'Reason for hidden question(s)'
                              })
                            ">
                            Show reason(s)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </template>
          <div class="d-flex justify-content-end mb-4">
            <button
              type="button"
              class="btn btn-info me-3"
              @click="showAlertClearAssessment = true">
              <i class="bi bi-x-circle"></i>
              Clear Form
            </button>

            <button
              type="button"
              :disabled="!isAssessmentComplete()"
              class="btn btn-primary me-3"
              @click="generateReport">
              Generate Report
              <i class="bi bi-chevron-right"></i>
            </button>
          </div>

          <div class="row d-flex mt-3 2">
            <div class="col-12 p-0">
              <Footer class="px-5 mx-n2"></Footer>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div v-if="showAlertClearAssessment" class="col-10 overlay-white-left">
    <div class="card mt-5 d-flex mx-auto">
      <div
        class="card-body text-center d-flex flex-column justify-content-center align-items-center">
        <h5 class="card-title header-22">
          <i class="bi bi-exclamation-circle-fill text-danger"></i>
          &nbsp;
          <strong>Warning</strong>
        </h5>
        <p class="card-text text-14 text-gray-700">
          Are you sure you want to clear all assessment answers? This cannot be undone.
        </p>

        <div class="d-flex justify-content-end mb-4">
          <button type="button" class="btn btn-info me-3" @click="showAlertClearAssessment = false">
            Cancel
          </button>

          <button type="button" class="btn btn-primary me-3" @click="clearAssessment">
            Yes, Clear All Answers
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .form-check-input {
    border: 1px solid var(--bs-gray-600, #6c757d) !important;
  }

  .question-title {
    flex-basis: 100%;
  }

  .floating-draft {
    position: relative;
    right: -15.5vw;
  }

  .steps-banner {
    background-color: white;
  }

  .wizard-container {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .step-container {
    width: 10%;
  }

  .circle {
    line-height: 45px;
    -moz-border-radius: 50%;
    border: solid 3px;
    border-color: var(--bs-primary);
    background-color: #ffffff;
    color: var(--bs-primary);
    text-align: center;
    font-size: 20px;

    width: 30px; /* Adjust circle size */
    height: 30px; /* Adjust circle size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .circle.filled {
    background-color: var(--bs-dark);
    color: white;
    border: 2px solid var(--bs-dark);
  }

  .circle.active {
    box-shadow: 0 0 0 4px #7b61ff78;
  }

  .empty {
    div {
      background-color: #ffffff; /* Adjust empty circle color */
      border: 2px solid var(--bs-secondary); /* Adjust empty circle border color */
      i {
        visibility: hidden;
      }
    }
    p {
      color: var(--bs-secondary);
    }
  }

  .partial {
    div {
      box-shadow:
        inset 0 0 0 8px #ffffff,
        inset 0 0 0 80px #007bff;
      i {
        visibility: hidden;
      }
    }
  }

  .scroll-padding {
    scroll-padding-top: 13vh;
    scroll-margin-top: 13vh;
  }

  .scroll-fix {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 94vh;
  }

  //For tmp hjiding reason off canvas
  .box-shadow-offcanvas-definition {
    box-shadow: -5px 0px 8px 0px #0000001a;
  }

  .words-for-definition {
    color: var(--bs-primary);
    cursor: pointer;
    text-decoration: none;
    border-bottom: 3px dotted var(--bs-primary);
    font-weight: bold;
  }

  .show-question-link {
    color: var(--bs-primary);
    cursor: pointer;
    text-decoration: underline;
  }

  .erisa-date-container {
    .form-check-inline {
      display: inline-block;
      margin-right: 1rem;
    }

    &.selected {
      .form-check-inline:not(:first-child) {
        display: block;
        margin-right: 0;
        margin-top: 0.5rem;
      }

      .erisa-date-input {
        display: block;
        margin-top: 0.5rem;
        margin-left: 1.5rem;
      }
    }
  }

  .alert-assesment {
    margin-top: 13px;
  }
</style>
