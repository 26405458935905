import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { handleErrors } from '../utils/errorHandler'

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)
  const loading = ref(false)
  const error = ref('')
  const isAuthenticated = ref(false)
  const isRefreshing = ref(false)
  const refreshSubscribers = ref([])

  // Callback function to notify subscribers when token is refreshed
  function onRefreshed(token) {
    refreshSubscribers.value.forEach(callback => callback(token))
    refreshSubscribers.value = []
  }

  // Add callback to subscribers list for token refresh
  function subscribeToTokenRefresh(callback) {
    refreshSubscribers.value.push(callback)
  }

  async function login(username, password) {
    loading.value = true
    error.value = ''

    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(auth, username, password)
      user.value = userCredential.user
      isAuthenticated.value = true
      const { accessToken: token, uid } = userCredential.user
      localStorage.setItem('userToken', token)
      localStorage.setItem('userUid', uid)
      return { success: true }
    } catch (err) {
      error.value = err.message
      handleErrors(err.code, err.message)
      return { success: false, error: err }
    } finally {
      loading.value = false
    }
  }

  async function refreshToken() {
    // If already refreshing, wait for it to complete
    if (isRefreshing.value) {
      return new Promise(resolve => {
        subscribeToTokenRefresh(token => {
          resolve(token)
        })
      })
    }

    isRefreshing.value = true

    try {
      const auth = getAuth()
      const currentUser = auth.currentUser
      if (!currentUser) {
        throw new Error('No user found')
      }
      
      // Force token refresh from Firebase
      const newToken = await currentUser.getIdToken(true)
      localStorage.setItem('userToken', newToken)
      isRefreshing.value = false
      onRefreshed(newToken)
      return newToken
    } catch (err) {
      isRefreshing.value = false
      handleErrors('refreshToken', err)
      logout()
      throw err
    }
  }

  function logout() {
    try {
      const auth = getAuth()
      auth.signOut()
      user.value = null
      isAuthenticated.value = false
      localStorage.removeItem('userToken')
      localStorage.removeItem('userUid')
    } catch (error) {
      handleErrors('logout', error)
      throw error
    }
  }

  async function checkAuth() {
    try {
      const auth = getAuth()
      const currentUser = auth.currentUser

      if (!currentUser) {
        return false
      }

      const token = await currentUser.getIdToken()
      const uid = currentUser.uid

      // Update localStorage with fresh data
      localStorage.setItem('userToken', token)
      localStorage.setItem('userUid', uid)
      isAuthenticated.value = true
      return true
    } catch (error) {
      handleErrors('checkAuth', error)
      isAuthenticated.value = false
      return false
    }
  }

  return {
    user,
    loading,
    error,
    isAuthenticated,
    login,
    logout,
    checkAuth,
    refreshToken
  }
})
