<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { RequestService } from '../shared/services/RequestService'
import BcsDropdown from '../shared/components/BcsDropdown.vue'
import { handleErrors } from '../shared/utils/errorHandler'

const { simpleGet } = RequestService()

const months = ref('1')
const year = ref(new Date().getFullYear())
const noResults = ref(false)
const showReport1 = ref(false)
const showReport2 = ref(false)
const loading = ref(false)
const responseData = reactive({
    founds: [],
    totalFounds: 0
})
const results = ref('')
const results2 = ref('')

const monthsOptions = [
    { value: '1', text: 'January' },
    { value: '2', text: 'February' },
    { value: '3', text: 'March' },
    { value: '4', text: 'April' },
    { value: '5', text: 'May' },
    { value: '6', text: 'June' },
    { value: '7', text: 'July' },
    { value: '8', text: 'August' },
    { value: '9', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' }
]

const hasData = computed(() => responseData.founds.length > 0)

const groupByAgency = (data) => {
    return data.reduce((agencies, item) => {
        if (!agencies[item.agencyName]) {
            agencies[item.agencyName] = { logs: [], total: 0 }
        }
        agencies[item.agencyName].logs.push(item);
        agencies[item.agencyName].total += 1
        return agencies
    }, {})
}

const downloadCSV = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')

    Object.assign(link, {
        href: url,
        download: filename,
        style: { visibility: 'hidden' }
    })

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const getTimestamp = () => {
    const date = new Date()
    return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`
}

const generateCSV1 = () => {
    if (!hasData.value) return
    const groupedData = groupByAgency(responseData.founds)
    const csvRows = Object.entries(groupedData)
        .map(([agencyName, data]) => `"${agencyName}",${data.total}`)
    const csvContent = ['Agency Name,Completed Assessments', ...csvRows].join('\n')
    downloadCSV(csvContent, `report1_${getTimestamp()}.csv`)
};

const generateCSV2 = () => {
    if (!hasData.value) return;
    const csvRows = responseData.founds
        .map(log => `"${log.agencyName || ''}","${log.employerName || ''}","${log.timestamp || ''}","${log.userName || ''}"`)
    const csvContent = ['Agency Name,Employer Name,Timestamp,User Name', ...csvRows].join('\n')
    downloadCSV(csvContent, `report2_${getTimestamp()}.csv`)
}

const handleSubmit = async (event) => {
    event.preventDefault()
    loading.value = true
    noResults.value = false
    showReport1.value = false
    showReport2.value = false

    try {
        const response = await simpleGet(
            'Reports',
            `${import.meta.env.VITE_API_BASE_URL}?_rest=Reports&eventId=19&month=${months.value}&year=${year.value}`
        )

        if (response.status === 200 && response.data) {
            Object.assign(responseData, response.data.object)

            if (responseData.totalFounds === 0) {
                noResults.value = true;
                return
            }

            const groupedData = groupByAgency(responseData.founds)
            results.value = groupedData
            results2.value = responseData.founds
            showReport1.value = true
            showReport2.value = true
        } else {
            noResults.value = true;
            handleErrors('API Error or unexpected response:', response)
        }
    } catch (error) {
        handleErrors('Error in API request:', error)
        noResults.value = true
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    year.value = new Date().getFullYear()
})
</script>

<template>
    <div class="container">
        <h1 class="text-center">Reports Test</h1>
        <form @submit="handleSubmit">
            <div class="form-group">
                <label class="fw-bold text-gray-700" for="months">Choose a month:</label>
                <BcsDropdown v-model="months" id="months" :dropdown-options="monthsOptions">
                </BcsDropdown>
            </div>
            <div class="form-group">
                <label class="fw-bold text-gray-700" for="year">Enter year:</label>
                <input style="color: var(--bs-gray-600)" class="form-control text-16  form-input" type="number"
                    id="year" v-model="year" min="1900" max="2100" required>
            </div>
            <button @click="handleSubmit" :class="{ 'disabled': loading }" :disabled="loading"
                class="btn btn-primary btn-action-profile d-flex justify-content-center align-items-center">
                <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                {{ loading ? 'Loading...' : 'Generate Report' }}
            </button>
        </form>

        <div v-if="noResults" class="no-results text-center fw-bold">
            <h2>NO RESULTS FOUND</h2>
        </div>

        <div v-if="showReport1" class="report">
            <div class="report-header">
                <h4 class="fw-bold text-primary">REPORT 1</h4>
                <button class="btn btn-primary" @click="generateCSV1" :disabled="loading || !hasData">
                    Generate CSV
                </button>
            </div>
            <div v-for="(value, key) in results" :key="key" class="d-flex justify-content-between mt-3">
                <div class="text-primary fw-bold">
                    {{ key }}
                </div>
                <div class="fw-bold">
                    Completed Assessments: {{ value.total }}
                </div>
            </div>
        </div>

        <div v-if="showReport2" class="report">
            <div class="report-header">
                <h4 class="fw-bold text-primary">REPORT 2</h4>
                <button class="btn btn-primary" @click="generateCSV2" :disabled="loading || !hasData">
                    Generate CSV
                </button>
            </div>
            <div class="d-flex justify-content-between mt-3">

                <table class="table table-hover mb-1 border-0">
                    <thead>
                        <tr class="table-active">
                            <th scope="col" class="text-16 ps-4">
                                <strong>Agency Name</strong>
                            </th>
                            <th scope="col" class="text-16 ps-4">
                                <strong>Employer Name</strong>
                            </th>
                            <th scope="col" class="text-16 ps-4">
                                <strong>Timestamp</strong>
                            </th>
                            <th scope="col" class="text-16 ps-4">
                                <strong>User Name</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-for="log in results2" :key="log.id">
                        <tr>
                            <td>{{ log.agencyName }}</td>
                            <td>{{ log.employerName }}</td>
                            <td>{{ log.timestamp }}</td>
                            <td>{{ log.userName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
:deep {
    .dropdown {
        button {
            border: 1px solid var(--bs-gray-300) !important;
        }

        button,
        ul {
            width: 100% !important;
        }
    }
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.no-results {
    padding: 20px;
    background-color: #ffebee;
    border-radius: 4px;
    color: var(--bs-danger);
}

.report {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

table {

    border-collapse: collapse;
    margin-top: 15px;

    th,
    td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #ced4da;
        color: white;
    }

}
</style>