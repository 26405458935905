<script setup>
  import { defineProps, onMounted, inject } from 'vue'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { storeToRefs } from 'pinia'

  const props = defineProps(['activeStep'])

  const customNavigation = inject('customNavigation')

  const questionStore = useQuestionsStore()
  const { assessmentQuestions, assessKeys, erisaDate } = storeToRefs(questionStore)
  const { getCompletionStatus, hideDueICHRA, isValidDate } = questionStore

  onMounted(() => {
    if (customNavigation.value.internalQuery.step) {
      let stepIndex = assessKeys.value.indexOf(customNavigation.value.internalQuery.step)
      if (stepIndex > -1) {
        manualScroll(stepIndex + 1)
      }
    }
  })

  const getClass = (key, keyIndex) => {
    const questions = assessmentQuestions.value[key]
    const erisaQuestion = questions.find((q) => q.type === 4)
    if (erisaQuestion?.answer === 0) {
      if (!isValidDate(erisaDate.value)) {
        return {
          active: props.activeStep == `q-${keyIndex}`,
          [getCompletionStatus(key, keyIndex)]: false
        }
      }
    }
    return {
      active: props.activeStep == `q-${keyIndex}`,
      [getCompletionStatus(key, keyIndex)]: true
    }
  }

  const manualScroll = (step) => {
    const el = document.getElementById(step)
    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
</script>

<template>
  <div class="col-10 wrap d-flex align-items-center">
    <div class="wizard-container" id="steps-container">
      <template v-for="(key, keyIndex) in assessKeys" :key="keyIndex">
        <div
          v-if="!hideDueICHRA(assessmentQuestions[key][0]['short_title'])"
          class="step-container px-2">
          <a
            rel="noopener"
            target="_self"
            v-on:click.prevent.stop="manualScroll(keyIndex + 1)"
            :href="'#' + (keyIndex + 1)"
            class="text-decoration-none"
            v-custom-tooltip="assessmentQuestions[key][0]['short_title']">
            <div class="wizard-step" :class="getClass(key, keyIndex)">
              <div class="circle mx-auto">
                <i class="bi bi-check-lg"></i>
              </div>
              <p class="mb-0 mt-2">
                {{ `${keyIndex + 1}. ${assessmentQuestions[key][0]['short_title']}` }}
              </p>
            </div>
          </a>
        </div>
      </template>

      <!-- Add more steps as needed -->
    </div>
  </div>
</template>

<style scoped lang="scss">
  .circle {
    width: 50px;
    height: 50px;
    line-height: 45px;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: solid 3px;
    border-color: var(--bs-heading-color);
    background-color: #ffffff;
    color: var(--bs-heading-color);
    text-align: center;
    display: block;
    font-size: 20px;
  }

  .steps-banner {
    background-color: white;
  }

  .wrap:before {
    content: '';
    position: absolute;
    top: 37%;
    left: 5%;
    border-top: 1px solid var(--bs-gray-300);
    background: var(--bs-gray-300);
    width: 74%;
    transform: translateY(-50%);
  }

  .wizard-container {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .step-container {
    width: 10%;
  }

  .wizard-form {
  }

  .wizard-step {
    position: relative;
    flex: 1;
    text-align: center;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .wizard-step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: 50px; /* Adjust line length */
    height: 2px; /* Adjust line thickness */
    background-color: #007bff; /* Adjust line color */
  }

  .circle {
    width: 30px; /* Adjust circle size */
    height: 30px; /* Adjust circle size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .wizard-step:hover {
    div {
      border: 2px solid var(--bs-primary);
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      line-height: 130%;
      color: var(--bs-primary);
    }
  }

  .empty {
    div {
      background-color: #ffffff; /* Adjust empty circle color */
      border: 2px solid var(--bs-gray-300); /* Adjust empty circle border color */
      i {
        visibility: hidden;
      }
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: var(--bs-gray-600);
    }
  }

  .partial {
    div {
      box-shadow:
        inset 0 0 0 8px #ffffff,
        inset 0 0 0 80px var(--bs-primary);
      i {
        visibility: hidden;
      }
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: var(--bs-primary);
    }
  }

  .empty.active,
  .partial.active {
    div {
      box-shadow:
        inset 0 0 0 8px #ffffff,
        inset 0 0 0 80px var(--bs-primary),
        0 0 0 4px #7b61ff78;
      border: 2px solid var(--bs-primary) !important;
      i {
        visibility: hidden;
      }
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: var(--bs-primary);
    }
  }

  .filled {
    div {
      background-color: var(--bs-dark); /* Adjust filled circle color */
      color: #ffffff; /* Adjust filled circle text color */
      border: 2px solid var(--bs-dark);
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: var(--bs-dark);
    }
  }

  .filled.active {
    div {
      background-color: var(--bs-dark); /* Adjust filled circle color */
      color: #ffffff; /* Adjust filled circle text color */
      border: 2px solid var(--bs-dark);
      box-shadow: 0 0 0 4px #7b61ff78;
    }
    p {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: var(--bs-dark);
    }
  }
</style>
