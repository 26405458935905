import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { RequestService } from '../services/RequestService'
import { useEmployerStore } from './EmployerStore'

export const useNotesStore = defineStore('selectedNote', () => {
  const { simpleGet, post, put, del, createLog, handleErrors } = RequestService()

  const selectedNote = ref(null)
  const selectTab = ref({
    name: 'All',
    index: 0
  })
  const deleteNote = ref(false)
  const isCreatingNote = ref(false)
  const newNoteCreated = ref(false)
  const employerNotes = ref([])

  function setSelectedNote(note) {
    selectedNote.value = note
  }

  function clearSelectedNote() {
    selectedNote.value = null
  }

  function setSelectedTab(tab, index) {
    selectTab.value.name = tab
    selectTab.value.index = index
  }

  function clearSelectedTab() {
    selectTab.value = null
  }

  function crudNote() {
    newNoteCreated.value = !newNoteCreated.value
  }

  function setDeleteNote() {
    deleteNote.value = true
  }

  function clearDeleteNote() {
    deleteNote.value = false
  }

  const employerStore = useEmployerStore()
  const { actualEmployer, userData } = storeToRefs(employerStore)

  const convertDate = (dateString) => {
    if (!dateString) return ''
    const [year, month, day] = dateString.split('-')
    const date = new Date(year, month - 1, day)
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`

    return formattedDate
  }

  function startCreatingNote() {
    isCreatingNote.value = true
  }

  function cancelCreatingNote() {
    isCreatingNote.value = false
  }

  async function getEmployerNotes() {
    try {

      if (!actualEmployer.value) {
        handleErrors('Waiting for employer data to be loaded...')
        return
      }
      const response = await simpleGet(
        'getEmployerNotes',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Notes&id=${actualEmployer.value.id}`
      )

      employerNotes.value = response.data.results
      employerNotes.value = employerNotes.value
        .filter(
          (obj) =>
            obj.note !== ' ' && obj.note !== '' && obj.note !== undefined && obj.note !== null
        )
        .map((note) => ({
          ...note,
          category: 'General',
          dateFormatted: convertDate(note.date),
          user: userData.value?.fullname
        }))
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  async function createEmployerNote(data) {
    try {
      let response = await post(
        'createEmployerNote',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Notes`,
        data
      )
      employerNotes.value.push(data)
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  async function editEmployerNote(id, data) {
    try {
      let response = await put(
        'editEmployerNote',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Notes`,
        data
      )
      const noteIndex = employerNotes.value.findIndex((note) => note.id === id)
      if (noteIndex !== -1) {
        employerNotes.value[noteIndex] = {
          ...employerNotes.value[noteIndex],
          ...data,
          id: id
        }
        const GENERAL_NOTES_UPDATED = 9
        await createLog({
          eventId: GENERAL_NOTES_UPDATED,
          logName: 'user_activity_log',
          employerId: actualEmployer.value.id,
          agencyId: actualEmployer.value.agency_id
        })
      }
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  async function deleteEmployerNote(id) {
    try {
      let response = await del(
        'deleteEmployerNote',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Notes&id=${id}`
      )
      const index = employerNotes.value.findIndex((note) => note.id === id)
      if (index !== -1) {
        employerNotes.value.splice(index, 1)
      }
      return response.data.results
    } catch (error) {
      handleErrors(error)
    }
  }

  return {
    selectedNote,
    isCreatingNote,
    newNoteCreated,
    employerNotes,
    deleteNote,
    userData,
    selectTab,
    setSelectedNote,
    clearSelectedNote,
    startCreatingNote,
    cancelCreatingNote,
    getEmployerNotes,
    createEmployerNote,
    crudNote,
    deleteEmployerNote,
    editEmployerNote,
    setDeleteNote,
    clearDeleteNote,
    convertDate,
    setSelectedTab,
    clearSelectedTab
  }
})
