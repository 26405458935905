<script setup>
import { ref, inject, nextTick, onMounted, watch, computed } from 'vue'
import BcsModal from '../BcsModal.vue'
import emptyPhoto from '@/assets/images/upload-photo.svg'
import flipVerticalIcon from '@/assets/images/flip-vertical.svg'
import flipHorizontalIcon from '@/assets/images/flip-horizontal.svg'
import { handleErrors } from '../../utils/errorHandler'
import { useEmployerStore } from '../../stores/EmployerStore'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { storeToRefs } from 'pinia'
import { FirebaseStorageService } from '../../services/FirebaseStorageService'
import { useProfileStore } from '../../stores/ProfileStore'

const centerModalRef = ref(null)
const emitter = inject('emitter')
const fileInput = ref(null)
const imageUpload = ref(false)
const noPhoto = ref(false)
const selectedImage = ref(null)
const userName = ref('')
const employerStore = useEmployerStore()
const profileStore = useProfileStore()
const { hideAlert } = profileStore
const { userData } = storeToRefs(employerStore)
const loadingState = ref(false)
const { updateImage, deleteOldImage } = FirebaseStorageService()
const uid = localStorage.getItem('userUid')

// Cropper refs
const cropperInstance = ref(null)
const imageElement = ref(null)
const zoom = ref(50)
const rotation = ref(0)
const flipHorizontal = ref(false)
const flipVertical = ref(false)

const profilePhotoUrl = computed(() =>
    userData.value?.temporaryPhotoURL ? userData.value.temporaryPhotoURL : userData.value?.profilePhotoUrl)

onMounted(() => {
    const slider = document.querySelector('.purple-slider')
    if (slider) {
        const percentage = (zoom.value - slider.min) / (slider.max - slider.min) * 100
        slider.style.background = `linear-gradient(to right, var(--bs-primary) ${percentage}%, #e0e0e0 ${percentage}%)`
    }

    const modal = centerModalRef.value.$el
    if (modal) {
        modal.addEventListener('shown.bs.modal', () => {
            hideAlert()
        })
    }
})

emitter.on('togglePhotoModal', async () => {
    if (centerModalRef.value) {
        centerModalRef.value.openModal()
    }
})

watch(userData, (newUserData) => {
    if (newUserData) {
        userName.value = newUserData.fullname || ''
    }
}, { immediate: true })


const handleFile = (file) => {
    const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/heic', 'image/heif'];
    if (!acceptedTypes.includes(file.type)) {
        handleErrors('image not valid')
        return
    }
    selectedImage.value = URL.createObjectURL(file)
    imageUpload.value = true

    nextTick(() => {
        initCropper()
    })
}

const handleZoom = (value) => {
    zoom.value = value;
    const zoomValue = 0.3 + (value / 100) * 1.7
    cropperInstance.value.zoomTo(zoomValue)
    const slider = event.target
    const percentage = (value - slider.min) / (slider.max - slider.min) * 100
    slider.style.background = `linear-gradient(to right, var(--bs-primary) ${percentage}%, #e0e0e0 ${percentage}%)`
}

const initCropper = () => {
    if (cropperInstance.value) {
        cropperInstance.value.destroy()
    }
    cropperInstance.value = new Cropper(imageElement.value, {
        aspectRatio: 1,
        viewMode: 1,
        dragMode: 'move',
        autoCropArea: 0.9,
        minCropBoxWidth: 160,
        minCropBoxHeight: 160,
        cropBoxMovable: false,
        cropBoxResizable: false,
        guides: false,
        center: false,
        highlight: true,
        background: true,
        zoomOnWheel: false,
        movable: true,
        rotatable: true,
        scalable: true,
        zoomable: true,
        responsive: true,
        toggleDragModeOnDblclick: false,
        minZoom: 0.3,
        maxZoom: 2,
        ready() {
            const initialZoom = 0.3 + (50 / 100) * 1.7;
            cropperInstance.value.zoomTo(initialZoom);
            const containerData = cropperInstance.value.getContainerData()
            const canvasData = cropperInstance.value.getCanvasData()
            const left = (containerData.width - canvasData.width) / 2
            const top = (containerData.height - canvasData.height) / 2
            cropperInstance.value.setCanvasData({
                left: left,
                top: top
            })
        }
    })
}

const rotateRight = () => {
    rotation.value = (rotation.value + 90) % 360
    cropperInstance.value.rotateTo(rotation.value)
}

const rotateLeft = () => {
    rotation.value = (rotation.value - 90) % 360
    cropperInstance.value.rotateTo(rotation.value)
}

const flipH = () => {
    flipHorizontal.value = !flipHorizontal.value
    cropperInstance.value.scaleX(flipHorizontal.value ? -1 : 1)
}

const flipV = () => {
    flipVertical.value = !flipVertical.value;
    cropperInstance.value.scaleY(flipVertical.value ? -1 : 1)
}

const getCroppedImage = () => {
    if (!cropperInstance.value) return null
    return cropperInstance.value.getCroppedCanvas({
        width: 160,
        height: 160,
    }).toDataURL()
}

const onDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) handleFile(files[0])
}

const openFilePicker = () => fileInput.value.click()

const onInputChange = (e) => {
    const file = e.target.files[0]
    if (file) handleFile(file)
}

const closeAndClearModal = () => {
    centerModalRef.value.closeModal()
    imageUpload.value = false
    noPhoto.value = false
    zoom.value = 50
    const slider = document.querySelector('.purple-slider')
    if (slider) {
        slider.style.background = `linear-gradient(to right, var(--bs-primary) 50%, #e0e0e0 50%)`
    }
    if (cropperInstance.value) {
        cropperInstance.value.destroy()
    }
    if (selectedImage.value) {
        URL.revokeObjectURL(selectedImage.value)
        selectedImage.value = null
    }
}

const handleSave = async () => {
    try {
        if (noPhoto.value) {
            employerStore.updateTemporaryPhoto('/user_silhouette.png')
            await handleRemovePhoto()
            closeAndClearModal()
            return
        }
        const image = getCroppedImage()
        if (!image) return
        loadingState.value = true
        employerStore.updateTemporaryPhoto(image)
        await updateImage('profile-photos', `${uid}.png`, image)
        closeAndClearModal()
    } catch (error) {
        handleErrors('Error updating profile photo')
    } finally {
        loadingState.value = false
    }
}

const handleRemovePhoto = async () => {
    try {
        loadingState.value = true
        await deleteOldImage('profile-photos', `${uid}.png`)
    } catch (error) {
        handleErrors('Error removing profile photo')
    } finally {
        loadingState.value = false
    }
}

</script>

<template>
    <BcsModal modalSize="medium" hideCloseButton @modalButtonClick="() => null" @closeButtonClicked="closeAndClearModal"
        ref="centerModalRef">
        <template #header>
            <div class="d-flex w-100 justify-content-between align-items-center">
                <span class="modal-title header-22 ms-2">
                    <strong>Edit Profile Photo</strong>
                </span>
                <button type="button" class="btn-close" @click="closeAndClearModal" aria-label="Close"></button>
            </div>
        </template>

        <div v-if="!imageUpload" class="text-start">
            <div class="d-flex content">
                <div class="d-flex flex-column align-items-center">
                    <div class="profile-icon">
                        <img v-if="noPhoto" src="/user_silhouette.png" alt="User Silhouette"
                            class="img-fluid user-silhouette" />
                        <img v-else :src="profilePhotoUrl ? profilePhotoUrl : '/user_silhouette.png'"
                            :alt="profilePhotoUrl">
                    </div>
                    <div class="username-title fw-bold">{{ userName }}</div>
                    <div v-if="profilePhotoUrl && profilePhotoUrl !== '/user_silhouette.png'" @click="noPhoto = true"
                        style="white-space: nowrap" class="fs-16 error-hint fw-bold remove-photo">
                        <i class="bi bi-trash3-fill"></i>
                        Remove Photo
                    </div>
                </div>

                <div class="info-photo">
                    <div class="fw-bold">Profile Photo</div>
                    <div class="info-photo-terms">
                        Accepted file types: JPG, JPEG, PNG, GIF, HEIC, HEIF. For best results, upload
                        an image that's 160px by 160px.
                    </div>

                    <div class="upload-photo text-center" @click="openFilePicker" @dragover.prevent
                        @drop.prevent="onDrop">
                        <img :src="emptyPhoto" alt="upload-photo">
                        <input type="file" ref="fileInput" hidden accept=".jpg,.jpeg,.png,.gif,.heic,.heif"
                            @change="onInputChange">

                        <div class="upload-photo-action text-primary fw-bold">
                            <i class="bi bi-upload"></i>
                            Upload Photo
                        </div>
                        <div>or drag and drop your files here</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="d-flex">
            <div class="d-flex flex-column">
                <div class="text-start fw-bold username">{{ userName }}</div>

                <div class="cropper-container">
                    <div class="d-flex gap-4 justify-content-center align-items-center">
                        <div class="image-preview">
                            <img ref="imageElement" :src="selectedImage" class="img-fluid" alt="Image to crop">
                        </div>
                    </div>
                </div>
            </div>

            <div class="edit-controls">
                <div class="d-flex edit-photo-title">
                    <div class="fw-bold">Edit Photo</div>
                </div>

                <div class="d-flex gap-4 mb-4">
                    <button @click="rotateRight" class="rotate-btn">
                        <i class="bi bi-arrow-clockwise"></i>
                        <span>Right</span>
                    </button>

                    <button @click="rotateLeft" class="rotate-btn">
                        <div class="">
                            <i class="bi bi-arrow-counterclockwise"></i>
                        </div>
                        <span>Left</span>
                    </button>

                    <button @click="flipH" class="rotate-btn">
                        <img :src="flipHorizontalIcon" alt="flip-horizontal">
                        <div class="d-flex flex-column">
                            <span>Flip</span>
                            <span>Horizontal</span>
                        </div>
                    </button>

                    <button @click="flipV" class="rotate-btn">
                        <img :src="flipVerticalIcon" alt="flip-vertical">
                        <div class="d-flex flex-column">
                            <span>Flip</span>
                            <span>Vertical</span>
                        </div>
                    </button>
                </div>

                <label style="float: left;margin-top: 20px;">Zoom</label>
                <div class="zoom-component">
                    <input type="range" v-model="zoom" @input="handleZoom($event.target.value)" min="0" max="100"
                        class="purple-slider">
                    <span>{{ zoom }}%</span>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-info" @click="closeAndClearModal">Cancel</button>
            <button type="button" class="btn btn-primary" :disabled="(!imageUpload && !noPhoto) || loadingState"
                @click="handleSave">
                <span v-if="loadingState" class="spinner-border spinner-border-sm me-2" role="status"></span>
                {{ loadingState ? 'Saving...' : 'Save' }}
            </button>
        </div>
    </BcsModal>
</template>


<style scoped lang="scss">
input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 6px;
}

.username {
    padding-left: 2rem;
    margin-top: 20px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #4285f4;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type="range"].purple-slider {
    background: linear-gradient(to right, var(--bs-primary) 50%, #e0e0e0 50%);
}

input[type="range"].purple-slider::-webkit-slider-thumb {
    background: var(--bs-primary);
}

.edit-photo-title {
    margin-top: 40px;
    margin-bottom: 20px;
}

:deep {
    .modal-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }

    .alert-content {
        margin-bottom: 0 !important;
    }

    .modal-header {
        border-bottom: none !important;
    }

    .modal-content {
        border-radius: 4px !important;
    }

    .profile-icon {
        margin-bottom: initial !important;
    }
}

.btn-close {
    i {
        font-size: 12px;
    }
}

.username-title {
    font-size: 20px;
    margin-top: 13px;
    white-space: nowrap;
}

.content {
    padding: 33px 35px 29px 25px;
    gap: 20px;
}

.fs-16 {
    font-size: 16px;
}

.zoom-component {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    float: inline-start;
    width: 100%;
}

.remove-photo {
    margin-top: 14px;
    cursor: pointer;
}

.info-photo {
    &-terms {
        margin-top: 6px;
    }
}

.upload-photo {
    height: 296px;
    border-radius: 12px;
    border: 1px dashed var(--Gray-600, #6C757D);
    margin-top: 16px;
    cursor: pointer;

    img {
        margin-top: 84px;
    }

    &-action {
        margin-top: 16px;
        margin-bottom: 4px;
        cursor: pointer;

        i {
            text-shadow: 0 0 1px currentColor;
            padding-right: 3.1px;
        }
    }
}

.cropper-container {
    padding: 10px 2rem 2rem 2rem;
    direction: initial !important;
    font-size: 0 !important;
    line-height: 0 !important;

    :deep(.cropper-view-box) {
        border-radius: 50%;
        outline: none;
        border: 3px solid #fff;
    }

    :deep(.cropper-face) {
        border-radius: 50%;
        opacity: 0.1;
    }

    :deep(.cropper-center) {
        opacity: 1;
        width: 2px;
        height: 2px;
        background-color: #fff;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            background-color: #fff;
        }

        &::before {
            width: 2px;
            height: 10px;
            left: 0;
            top: -4px;
        }

        &::after {
            width: 10px;
            height: 2px;
            left: -4px;
            top: 0;
        }
    }

    :deep(.cropper-modal) {
        background-color: #fff;
    }
}

.image-preview {
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #000;

    img {
        max-width: 100%;
        height: auto;
        display: block;
    }
}

.edit-controls {
    width: 100%;
    padding: 0 20px;

    i {
        font-size: 37px;
        color: var(--bs-primary);
        text-shadow: 0 0 1px currentColor;

    }
}

.rotate-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    span {
        font-size: 0.875rem;
    }

}

.zoom-control {
    margin-top: 2rem;
    label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    span {
        font-size: 0.875rem;
    }
}
</style>
