import {  onMounted, ref } from 'vue'
import { defineStore } from 'pinia'
import { handleErrors } from '../utils/errorHandler'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { isEmpty } from 'lodash'

export const useLocationStore = defineStore('LocationStore', () => {

    const dropdownStatesOptions = ref([])
    const db = getDatabase(getApp())
    

    onMounted(async () => {
        await getUsaStatesOptions()
    })

    const formattedLocation = (address, stateCode) => {
        const state = dropdownStatesOptions.value.find(state => state.text === stateCode)
        const stateName = state ? state.value : stateCode;
        const trimmedAddress = address ? address.trim() : '';
        const trimmedState = stateName ? stateName.trim() : '';

        if (!isEmpty(trimmedAddress) && !isEmpty(trimmedState)) {
            return `${trimmedAddress}, ${trimmedState}`;
        }
        return trimmedAddress || trimmedState || '';
    }

    const getUsaStatesOptions = async () => {
        try {
            const dropdownRef = dbRef(db, 'SYSTEM_PARAMS/USA_STATES')
            const snapshot = await dbGet(dropdownRef)
            if (snapshot.exists()) {
                dropdownStatesOptions.value = snapshot.val()
                return dropdownStatesOptions.value
            }
        } catch (err) {
            handleErrors('Error fetching usa states:', err)
            return []
        }
    }


    return {
        formattedLocation,
        getUsaStatesOptions,
        
    }
})
