import { get } from 'lodash'

export function markCheckbox(question, option) {
  if (Array.isArray(question.answer)) {
    if (question.answer.includes(option)) {
      question.answer = question.answer.filter((item) => item !== option)
    } else {
      question.answer.push(option)
    }
  } else {
    question.answer = [option]
  }
}

export function formatDateToSQLInChicagoTime(date) {
  const options = {
    timeZone: 'America/Chicago',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }

  const chicagoTime = date.toLocaleString('en-US', options)

  // The result of localString method looks like: "MM/DD/YYYY, HH:MM:SS"
  const [datePart, timePart] = chicagoTime.split(', ')
  const [month, day, year] = datePart.split('/')

  return `${year}-${month}-${day} ${timePart}`
}

export function stringMapValues(template = '', params = {}) {
  return template.replace(/\$\{(\w+)\}/g, (match, key) => {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      return get(params, key, match)
    } else {
      return match // Return original placeholder if key doesn't exists
    }
  })
}

export const processDefinition = (definition, id) => {
  const title = definition.title || 'No title'
  let contentTitle = ''
  let processedText = ''

  if (!definition.body || typeof definition.body !== 'string') {
    return null
  }

  processedText = definition.body.replace(
    /<definition([^>]*)>([\s\S]*?)<\/definition>/g,
    (match, attributes, content) => {
      contentTitle = content
      const idMatch = attributes.match(/id="([^"]*)"/)
      const id = idMatch ? idMatch[1] : ''
      return `<span class="words-for-definition" id="${id}" data-definition-id="${id}">${content.trim()}</span>`
    }
  )

  processedText = processedText.replace(/<(?!\/?strong\b|\/?span\b)[^>]*>/g, '')

  processedText = processedText.replace(
    /(https?:\/\/[^\s\n]+)/g,
    '<a href="$1" target="_blank">$1</a>'
  )

  return {
    title,
    processedText,
    contentTitle,
    id
  }
}

export function removeHTMLTags(str = '') {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}

export const isValidHexColor = (color) => {
  const hexRegex = /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return hexRegex.test(color)
}

export function getTodayFormatted() {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const ACTIVITY_NEWEST_TO_OLDEST = 1
export const ACTIVITY_OLDEST_TO_NEWEST = 2
export const ORDER_A_TO_Z = 3
export const ORDER_Z_TO_A = 4
export const ORDER_NEWEST_TO_OLDEST = 5
export const ORDER_OLDEST_TO_NEWEST = 6
