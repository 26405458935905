<script setup>
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useEmployerStore } from '../../stores/EmployerStore'
import { useAuthStore } from '../../stores/AuthStore'
import { useRouter } from 'vue-router'
import { handleErrors } from '../../utils/errorHandler'

const router = useRouter()
const securityData = ref({
    username: '',
    password: '12345',
})

const editSection = ref(false)
const employerStore = useEmployerStore()
const authStore = useAuthStore()
const { userData } = storeToRefs(employerStore)
const emitter = inject('emitter')

const goToResetPassword = async () => {
    try {
        const encodedEmail = btoa(securityData.value.username) 
        const url = router.resolve({
            name: 'login',
            query: { 
                resetPass: 'true',
                e: encodedEmail 
            }
        })
        window.open(url.href, '_blank')
    } catch (error) {
        handleErrors('logout', error)
    }
}

const initializeUserData = async () => {
    const user = authStore.user
    if (user && !userData.value?.fullname) {
        await employerStore.getEmployer(user.employerId)
    }
}

const maskedUsername = computed(() => {
    if (!securityData.value.username) return ''
    const firstFour = securityData.value.username.slice(0, 4)
    const stars = '*'.repeat(7)
    return firstFour + stars
})


onMounted(async () => {
    await initializeUserData()
})

watch(userData, (newUserData) => {
    if (newUserData) {
        const data = {
            username: newUserData.email || '',
        }
        Object.assign(securityData.value, data)
    }
}, { immediate: true })
</script>

<template>
    <div class="section pl-135 pr-40 d-flex align-items-center">
        <div class="header-22 fw-bold">
            Security
        </div>
        <div v-if="!editSection" class="edit" @click="editSection = true">
            Edit
        </div>

    </div>

    <div class="section-info pl-135 pr-40" style="gap: 6px!important; width: 78%;">
        <div class="row-section-security">
            <label>Username</label>
            <div class="section-value">
                {{ maskedUsername }}
            </div>

            <div v-if="editSection" class="go-back"
                @click="emitter.emit('toggleContactModal')">
                Contact Us to Reset Username (Email) <i class="bi bi-chevron-right"></i>
            </div>
        </div>

        <div class="row-section-security">
            <label>Password</label>
            <div class="section-value">
               ***********
            </div>

            <div @click="goToResetPassword" v-if="editSection" class="go-back">
                Reset Password <i class="bi bi-chevron-right"></i>
            </div>
        </div>

        <div v-if="editSection" class="form-row cancel-section">
            <div class="input-wrapper d-flex buttons-container">
                <button style="margin-left: 190px;" @click="editSection = false"
                    class="btn btn-info d-flex justify-content-center align-items-center btn-action-profile">
                    <i class="bi bi-x-circle rounded-circle d-flex justify-content-center align-items-center"></i>
                    Cancel
                </button>
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss">
.view-password {
    display: flex;
    width: 30px;
    height: 21px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 3.2px;
    border: 1px solid var(--Eggplant---Accent-Light, #F7F1FD);
    background: var(--Eggplant---Accent-Light, #F7F1FD);
    color: var(--bs-primary);
}

.row-section-security {
    display: grid;
    grid-template-columns: 21% 20% 59%;
    align-items: center;
    gap: 16px;
}

.cancel-section {
    margin-top: 16px;
}
</style>