<script setup>
  import Breadcrumbs from './BreadCrumbs.vue'
  import BcsDynamicText from './BcsDynamicText.vue'
  import { ref, onMounted, computed } from 'vue'
  import Footer from './FooterSection.vue'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import { useGlossaryStore } from '../stores/GlossaryStore'
  import { storeToRefs } from 'pinia'

  const glossaryStore = useGlossaryStore()
  const { fetchGlossaryData } = glossaryStore
  const { glossaryData } = storeToRefs(glossaryStore)

  const selectedLetter = ref('')
  const groupedTerms = ref({})
  const glossaryTerms = ref([])
  const availableLetters = ref([])
  const showInformation = ref({
    show: false,
    title: '',
    subtitle: '',
    body: ''
  })

  const displayLetters = computed(() => {
    const allLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    const availableSet = new Set(availableLetters.value.filter(letter => /[A-Z]/.test(letter)))
    const hasNumbers = Object.keys(groupedTerms.value).includes('#')
    
    return [
      { letter: '#', available: hasNumbers },
      ...allLetters.map(letter => ({
        letter,
        available: availableSet.has(letter)
      }))
    ]
  })

  const handleInformation = (term) => {
    scrollToTop(0, 'instant')
    showInformation.value.show = true
    showInformation.value.title = term.title
    showInformation.value.body = term.body
    showInformation.value.subtitle = term.subtitle
  }

  function groupInsuranceTermsByFirstLetter(terms) {
    if (!terms || typeof terms !== 'object') return {}

    return Object.entries(terms).reduce((grouped, [title, term]) => {
      const firstLetter = title[0].toUpperCase()
      if (/^\d/.test(firstLetter)) {
        if (!grouped['#']) grouped['#'] = []
        grouped['#'].push({ ...term, title })
      } else {
        if (!grouped[firstLetter]) grouped[firstLetter] = []
        grouped[firstLetter].push({ ...term, title })
      }
      return grouped
    }, {})
  }

  onMounted(async () => {
    await fetchGlossaryData()
    glossaryTerms.value = glossaryData.value
    groupedTerms.value = groupInsuranceTermsByFirstLetter(glossaryTerms.value)
    availableLetters.value = Object.keys(groupedTerms.value).sort((a, b) => {
      if (a === '#') return -1
      if (b === '#') return 1
      return a.localeCompare(b)
    })
  })

  const selectLetter = (letter) => {
    selectedLetter.value = letter
    const element = document.getElementById(`letter-${letter}`)
    if (element) {
      const headerHeight = document.querySelector('.sticky-header').offsetHeight
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight - 50

      scrollToTop(offsetPosition, 'smooth')
    }
  }

  const scrollToTop = (position, behavior) => {
    window.scrollTo({ top: position, behavior: behavior })
  }
</script>

<template>
  <div class="page-container">
    <div class="content-wrap">
      <div
        v-if="!showInformation.show"
        :class="{ heigh100: !showInformation.show }"
        class="container-fluid mx-5 px-5 mt-4">
        <div class="sticky-header">
          <div class="row">
            <div class="col-8">
              <Breadcrumbs></Breadcrumbs>
              <p class="text-28 title">
                <med>Glossary of Terms</med>
              </p>
              <p class="text-14">
                <BcsDynamicText text="glossaryPageIntro" />
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-10">
              <div class="alphabet-container">
                <template v-for="(letterObj, index) in displayLetters" :key="letterObj.letter">
                  <button
                    class="letter-button"
                    :class="{
                      'text-primary': letterObj.available,
                      'letter-disabled': !letterObj.available,
                      'selected': selectedLetter === letterObj.letter
                    }"
                    :disabled="!letterObj.available"
                    @click="selectLetter(letterObj.letter)">
                    {{ letterObj.letter }}
                  </button>
                  <span v-if="index < displayLetters.length - 1" class="separator">|</span>
                </template>
                <button
                  class="letter-button top-button text-primary"
                  @click="scrollToTop(0, 'smooth')">
                  Top
                  <i class="bi bi-chevron-up"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="glossary-content">
          <div class="row">
            <div class="col-10">
              <div v-for="(terms, letter) in groupedTerms" :key="letter" class="glossary__group">
                <div :id="'letter-' + letter" class="glossary__group-header">
                  <strong>{{ letter }}</strong>
                </div>
                <div class="glossary__group-terms">
                  <div
                    v-for="term in terms"
                    :key="term.title"
                    @click="handleInformation(term)"
                    class="glossary__group-terms__item d-flex align-items-center col-8">
                    <div>
                      <h5 class="text-16 text-primary">
                        <strong>{{ term.title }}</strong>
                      </h5>
                      <p class="text-14">{{ term.subtitle }}</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="showInformation.show" class="container-fluid mx-5 px-5 mt-4">
        <div class="row">
          <div class="col-8">
            <Breadcrumbs></Breadcrumbs>
            <p class="text-28">
              <med>Glossary of Terms</med>
            </p>
            <div
              style="margin-top: 28px"
              class="go-back d-flex"
              @click="showInformation.show = false">
              <i class="bi bi-chevron-left"></i>
              Back to All Terms
            </div>

            <div class="definition-text white-space-pre-wrap">
              <div class="definition-text__title header-22">
                <strong>{{ showInformation.title }}</strong>
              </div>
              <div class="definition-text__subtitle text-14">
                {{ showInformation.subtitle }}
              </div>

              <div class="definition-text__body text-14">
                <TextWithDefinitions :text="showInformation.body" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Footer class="px-5 mx-n3"></Footer>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  // .definition-text :deep {
  //   h1,
  //   h2,
  //   h3 {
  //     font-size: 14px !important;
  //     font-weight: 800;
  //   }
  // }

  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content-wrap {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }

  .title {
    margin-top: 15px;
  }

  .sticky-header {
    position: sticky;
    top: 50px;
    background-color: white;
    padding: 20px 0;
    z-index: 3;
  }

  .glossary-content {
    margin-top: 20px;
  }

  .alphabet-container {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    align-items: center;
  }

  .letter-button {
    border: none;
    background-color: transparent;
    font-weight: 700;

    &:hover {
      background-color: var(--bs-primary);
      color: #fff !important;
    }
  }

  .separator {
    color: var(--bs-gray-400);
    font-weight: 200;
  }

  .letter-disabled {
    color: var(--bs-gray-500);
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      color: var(--bs-gray-500)!important;
    }
  }

  .top-button {
    font-size: 14px;

    i {
      padding-left: 10px;
    }
  }

  .definition-text {
    margin-top: 20px;

    &__title {
      margin-bottom: 40px;
      margin-top: 30px;
    }

    &__subtitle {
      margin-bottom: 30px;
    }
  }

  .glossary__group {
    &-header {
      background-color: var(--bs-gray-100);
      padding: 7px 0 7px 20px;
      border-bottom: 1px solid var(--bs-gray-400);
      font-size: 22px;
    }

    &-terms {
      padding-top: 30px;
      padding-bottom: 30px;

      &__item {
        padding: 10px;
        cursor: pointer;
        position: relative;
        padding-right: 50px;

        &:hover {
          border-radius: 4px;
          border: 1px solid #8f38f2;
          background-color: #f7f1fd;
          color: var(--bs-primary);
        }

        p {
          margin: 0;
        }

        .bi-chevron-right {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
        }

        &:hover .bi-chevron-right {
          opacity: 1;
        }
      }
    }
  }
</style>
