<script setup>
import Footer from './FooterSection.vue'
import Breadcrumbs from './BreadCrumbs.vue'
import BcsDynamicText from './BcsDynamicText.vue'
import glossaryIcon from '@/assets/images/dictionary.svg'
import faqsIcon from '@/assets/images/help-1.svg'
import { inject, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useEmployerStore } from '../stores/EmployerStore'
import {
  getDatabase,
  ref as dbRef,
  get as dbGet,
} from 'firebase/database'
const customNavigation = inject('customNavigation')
const employerStore = useEmployerStore()
import { getApp } from 'firebase/app'
import { handleErrors } from '../utils/errorHandler'
import { useAuthStore } from '../stores/AuthStore'


const { actualEmployer, userData } = storeToRefs(employerStore)
const db = getDatabase(getApp())
const helpAndResourcesIntro = ref()
const authStore = useAuthStore()
const emitter = inject('emitter')


onMounted(async () => {
  await getHelpResourcesIntro()
  await initializeUserData()

})

const initializeUserData = async () => {
  const user = authStore.user
  if (user && !userData.value?.fullname) {
    await employerStore.getEmployer(user.employerId)
  }
}

const getHelpResourcesIntro = async () => {
  try {
    const helpRef = dbRef(db, 'BCS/Home/Help and Resources/helpResourcesPageIntro/default_value')
    const snapshot = await dbGet(helpRef)
    if (snapshot.exists()) {
      helpAndResourcesIntro.value = snapshot.val()
    }
  } catch (err) {
    handleErrors('Error fetching help and resources page intro', err)
  }
}


const handleDynamicClick = (event) => {
  if (event.target.classList.contains('contact-us')) {
    emitter.emit('toggleContactModal')
  }
}

</script>

<template>
  <div class="container-fluid mx-5 px-5 mt-4 help-resources-container">
    <div class="row">
      <div class="col-6">
        <Breadcrumbs></Breadcrumbs>
        <p class="help-resources-title text-28">Welcome to our Help & Resources Center</p>
      </div>
    </div>

    <div class="row">
      <div class="col-7">

        <p class="subtitle-help-resources" v-html="helpAndResourcesIntro" @click="handleDynamicClick">
        </p>


        <div class="d-flex boxes__container">
          <div class="boxes__container-help-resources d-flex align-items-center flex-column">
            <div class="icon-background d-flex justify-content-center align-items-center">
              <img :src="faqsIcon" class="icon-help1" />
            </div>

            <h5>Frequently Asked Questions</h5>
            <p>
              <BcsDynamicText text="helpResourcesCardFaqsIntro" />
            </p>

            <button @click="
              customNavigation.navigate({
                newPage: customNavigation.routes.frequentlyQuestions.name,
                saveEvent: true,
                actualEmployer
              })
              " class="btn btn-primary">
              Get Your Questions Answered
            </button>
          </div>

          <div class="boxes__container-help-resources d-flex align-items-center flex-column">
            <div class="icon-background d-flex justify-content-center align-items-center">
              <img :src="glossaryIcon" class="icon-dictionary" />
            </div>

            <h5>Glossary of Terms</h5>
            <p>
              <BcsDynamicText text="helpResourcesCardGlosaryIntro" />
            </p>
            <button class="btn btn-primary" @click="
              customNavigation.navigate({
                newPage: customNavigation.routes.glossaryTerms.name,
                saveEvent: true,
                actualEmployer
              })
              ">
              View Definitions
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<style scoped lang="scss">
:deep .contact-us {
  text-decoration: underline;
  cursor: pointer;
}

.boxes__container {
  margin-top: 40px;
  gap: 21px;
  height: auto;

  &-help-resources {
    border: 1px solid var(--bs-gray-300);
    width: 50%;
    border-radius: 4px;
    text-align: center;

    padding: 19px;

    h5,
    p {
      margin: 0;
    }

    h5 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 5px;
    }

    button {
      margin-top: 16px;
      height: 28px;
      width: 215px;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    p {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}

.help-resources-title {
  margin-top: 54px;
}

.help-resources-container {
  overflow-y: scroll;
  height: 95vh !important;
}

.subtitle-help-resources {
  font-size: 14px;
  margin: 0;
}

.col-container-help {
  width: 779px;
}

.icon-background {
  background-color: var(--bs-gray-200);
  width: 59px;
  height: 59px;
  border-radius: 50%;
  // margin-top: 19px;

  // .icon-help1{
  //     margin-top: 19px;
  // }
}
</style>
