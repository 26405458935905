<script setup>
  import { defineEmits, reactive, ref, inject, watch, computed, onBeforeMount } from 'vue'
  import { vMaska } from 'maska'
  import Footer from './FooterSection.vue'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { storeToRefs } from 'pinia'
  import { useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/yup'
  import { object, string, date } from 'yup'
  import { markCheckbox } from '../utils'
  import BcsDynamicText from './BcsDynamicText.vue'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import { useEmployerStore } from '../stores/EmployerStore'
  import { RequestService } from '../services/RequestService'
  import { useSessionStorageStore } from '../stores/SessionStorage'

  const { createLog, handleErrors } = RequestService()

  const emit = defineEmits(['toggleLoading', 'inFocus'])

  const customNavigation = inject('customNavigation')

  const questionStore = useQuestionsStore()

  const sessionStorageStore = useSessionStorageStore()
  const { getRefresh } = sessionStorageStore

  const { intakeQuestions, mainAssessKey } = storeToRefs(questionStore)

  const { saveQuestions, isIntakeComplete, clearQuestions } = questionStore

  const {
    defineField,
    setFieldValue,
    setFieldTouched,
    isFieldValid,
    isFieldTouched,
    errors,
    validate
  } = useForm({
    validationSchema: toTypedSchema(
      object({
        name: string().min(4).required(),
        ein: string()
          .required()
          .matches(/^[0-9]{2}-[0-9]{7}$/),
        plan_start_date: date()
          .typeError('Please enter a valid date')
          .required()
          .test('is-date-complete', 'Invalid date format', () => {
            if (!plan_start_date.value) return true
            const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/g
            return dateRegex.test(plan_start_date.value)
          })
      })
    )
  })

  const employerStore = useEmployerStore()
  const { actualEmployer, actualPlanYear } = storeToRefs(employerStore)

  const [plan_start_date] = defineField('plan_start_date')

  watch(
    () => actualEmployer?.value?.name,
    (newVal) => {
      setFieldValue('name', newVal)
      setFieldTouched('name', true)
      validate()
    },
    { immediate: true }
  )

  watch(
    () => actualEmployer?.value?.ein,
    (newVal) => {
      setFieldValue('ein', newVal)
      setFieldTouched('ein', true)
      validate()
    },
    { immediate: true }
  )

  watch(
    () => actualPlanYear?.value?.plan_start_date,
    (newVal) => {
      setFieldValue('plan_start_date', newVal)
      setFieldTouched('plan_start_date', true)
      validate()
    }
  )

  const hasErrors = computed(() => {
    return Object.keys(errors.value).length > 0
  })

  const saving = ref(false)

  const rawMaskVal = reactive({})

  const lastTimeSavedIntake = ref(null)
  const timeElapsedIntake = ref('Not auto-saved yet ')
  const idSetIntervalSecondIntake = ref(null)
  const idSetIntervalMinuteIntake = ref(null)

  function autoSaveIntake() {
    saveIntake(false)
    lastTimeSavedIntake.value = new Date()
    timeElapsedIntake.value = 'Auto-saved just now'

    if (idSetIntervalSecondIntake.value) {
      clearInterval(idSetIntervalSecondIntake.value)
    }
    if (idSetIntervalMinuteIntake.value) {
      clearInterval(idSetIntervalMinuteIntake.value)
    }
    let countSeconds = 0
    idSetIntervalSecondIntake.value = setInterval(() => {
      countSeconds++
      let now = new Date()
      let diff = now - lastTimeSavedIntake.value
      let seconds = Math.floor(diff / 1000)
      timeElapsedIntake.value = `Auto-saved ${seconds} secs ago`
      if (countSeconds > 59) {
        clearInterval(idSetIntervalSecondIntake.value)
        idSetIntervalMinuteIntake.value = setInterval(() => {
          let now = new Date()
          let diff = now - lastTimeSavedIntake.value
          let minutes = Math.floor(diff / 60000)
          timeElapsedIntake.value = `Auto-saved ${minutes} mins ago`
        }, 60000)
      }
    }, 10000)
  }

  async function saveIntake(nextPage) {
    //first we update the employer name, ein or compliance date
    saving.value = true
    try {
      await saveQuestions('intake', actualEmployer.value.id)
      //once we are done, we change the page
      saving.value = false
      if (nextPage) {
        customNavigation.value.navigate({
          newPage: customNavigation.value.currentPage,
          subPage: customNavigation.value.routes.DetailEmployer.children[2].name
        })
      }
      const INTAKE_QUESTIONNAIRE_UPDATED = 7
      await createLog({
        eventId: INTAKE_QUESTIONNAIRE_UPDATED,
        logName: 'user_activity_log',
        employerId: actualEmployer.value.id,
        agencyId: actualEmployer.value.agency_id
      })
    } catch (error) {
      handleErrors(error)
    }
  }

  function answeredQuestions() {
    /*if (initialAnswers.value && initialAnswers.value) {
      return Object.entries(initialAnswers.value).filter(
        (val) => typeof val[1] === 'number' || (val[1] && val[1].length > 0)
      ).length
    }*/
    return isIntakeComplete()
  }

  async function cleanInitialForm() {
    emit('toggleLoading', true)
    clearQuestions('intake')
    await saveQuestions('intake', actualEmployer.value.id)
    emit('toggleLoading', false)
  }

  function isInitialDataComplete() {
    if (
      actualEmployer.value.name &&
      actualEmployer.value.name.length > 3 &&
      actualEmployer.value.ein &&
      actualEmployer.value.ein.length === 10 &&
      actualPlanYear.value?.plan_start_date &&
      actualPlanYear.value.plan_start_date.length === 10
    ) {
      return true
    }
    return false
  }
</script>

<template>
  <div class="col-10 scroll-fix">
    <div>
      <form @submit.prevent>
        <!-- Form header -->
        <div class="container-fluid px-3">
          <div class="row">
            <div class="col-7">
              <h1 class="my-3 text-secondary">{{ actualEmployer.name }} Intake Questionnaire</h1>
              <span class="py-2 text-14">
                <BcsDynamicText text="intakeQuestionnaireInstructionalText" />
              </span>
            </div>
            <div class="col-5">
              <div class="d-flex justify-content-end">
                <!-- <a
                  class="hand-cursor d-inline my-3 px-2 border-end text-decoration-none text-14 text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#pendingFunctionModal">
                  <strong>View Sample Report</strong>
                </a> -->
              </div>
              <div class="d-grid gap-1 col-4 float-end mt-3 w-100">
                <button
                  @click="saveIntake(false)"
                  class="btn btn-primary text-16 ms-auto"
                  :disabled="saving || !isInitialDataComplete() || hasErrors">
                  <i class="bi bi-bookmark"></i>
                  <med>{{ saving ? ' Saving...' : ' Save Draft' }}</med>
                </button>
                <span class="text-12 text-end">{{ timeElapsedIntake }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid px-3 mt-3">
          <div class="mb-3 pt-2">
            <span class="question-title text-14">
              <strong>Employer Legal Name</strong>
            </span>
            <input
              type="text"
              class="form-control text-16 w-50"
              v-model="actualEmployer.name"
              :class="{ 'is-invalid': !isFieldValid('name') && isFieldTouched('name') }" />
          </div>
          <div class="d-flex flex-row align-items-center flex-wrap">
            <div class="mb-3">
              <span class="question-title text-14">
                <strong>Employer Federal Tax ID Number</strong>
              </span>
              <input
                type="text"
                class="form-control text-16"
                v-model="actualEmployer.ein"
                :class="{ 'is-invalid': !isFieldValid('ein') && isFieldTouched('ein') }"
                v-maska="rawMaskVal"
                data-maska="##-#######"
                placeholder="e.g., 12-3456789" />
            </div>
            <div class="ms-4 mb-3">
              <span class="question-title text-14">
                <strong>Plan Start Date</strong>
              </span>

              <template v-if="actualPlanYear?.plan_start_date">
                <input
                  type="text"
                  class="form-control text-16"
                  :class="{
                    'is-invalid':
                      !isFieldValid('plan_start_date') && isFieldTouched('plan_start_date')
                  }"
                  v-model="actualPlanYear.plan_start_date"
                  v-maska="rawMaskVal"
                  data-maska="##/##/####" />
              </template>
            </div>
          </div>
          <div
            class="mb-3"
            v-for="(question, questionIndex) in intakeQuestions[mainAssessKey]"
            :key="question.id">
            <span class="question-title text-14">
              <strong>{{ question.question }}</strong>
            </span>
            <div class="row">
              <div :class="question.type > 1 ? 'col-2' : 'col-12'">
                <div>
                  <template v-if="question.type === 0">
                    <div
                      v-for="(option, optionIndex) in question.options"
                      class="form-check form-check-inline"
                      :key="optionIndex"
                      @click="(question.answer = optionIndex), autoSaveIntake()">
                      <input
                        class="form-check-input"
                        type="radio"
                        :id="question.id + '' + optionIndex + 'radio'"
                        :name="questionIndex + 'radio'"
                        v-model="question.answer"
                        :value="optionIndex" />
                      <label class="form-check-label text-16">
                        <TextWithDefinitions :text="option" />
                      </label>
                    </div>
                  </template>
                  <template v-else-if="question.type === 1">
                    <div
                      v-for="(option, optionIndex) in question.options"
                      :key="optionIndex"
                      @click="markCheckbox(question, optionIndex), autoSaveIntake()"
                      class="form-check">
                      <input
                        class="form-check-input text-16"
                        type="checkbox"
                        :id="option"
                        v-model="question.answer"
                        :value="optionIndex" />
                      <label class="form-check-label text-16">
                        <TextWithDefinitions :text="option" />
                      </label>
                    </div>
                  </template>
                  <div v-else-if="question.type === 2">
                    <select
                      name="cars"
                      class="form-select text-16"
                      @change="autoSaveIntake"
                      v-model="question.answer">
                      <option v-for="(option, i) in question.options" :value="i" :key="i">
                        <TextWithDefinitions :text="option" />
                      </option>
                    </select>
                  </div>
                  <div v-else-if="question.type === 3">
                    <input
                      class="form-control text-16"
                      type="number"
                      @change="autoSaveIntake"
                      v-model="question.answer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div class="d-grid gap-2 d-md-block text-center">
          <button
            type="button"
            :disabled="answeredQuestions() === 0"
            @click="cleanInitialForm"
            class="btn btn-info me-2">
            <i class="bi bi-x-circle"></i>
            Clear Form
          </button>
          <button
            type="button"
            :disabled="!isIntakeComplete() || !isInitialDataComplete() || hasErrors"
            class="btn btn-primary ms-2"
            @click="saveIntake(true)">
            Start Assessment
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
        <div class="row d-flex mt-3 1">
          <div class="col-12 p-0">
            <Footer class="px-4"></Footer>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .form-check-input {
    border: 1px solid var(--bs-gray-600, #6c757d) !important;
  }

  .question-title {
    flex-basis: 100%;
  }

  .floating-draft {
    position: relative;
    right: -15.5vw;
  }

  .steps-banner {
    background-color: white;
  }

  .wizard-container {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .step-container {
    width: 10%;
  }

  .circle {
    line-height: 45px;
    -moz-border-radius: 50%;
    border: solid 3px;
    border-color: var(--bs-primary);
    background-color: #ffffff;
    color: var(--bs-primary);
    text-align: center;
    font-size: 20px;

    width: 30px; /* Adjust circle size */
    height: 30px; /* Adjust circle size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .circle.filled {
    background-color: var(--bs-dark);
    color: white;
    border: 2px solid var(--bs-dark);
  }

  .circle.active {
    box-shadow: 0 0 0 4px #7b61ff78;
  }

  .empty {
    div {
      background-color: #ffffff; /* Adjust empty circle color */
      border: 2px solid var(--bs-secondary); /* Adjust empty circle border color */
      i {
        visibility: hidden;
      }
    }
    p {
      color: var(--bs-secondary);
    }
  }

  .partial {
    div {
      box-shadow:
        inset 0 0 0 8px #ffffff,
        inset 0 0 0 80px #007bff;
      i {
        visibility: hidden;
      }
    }
  }

  .scroll-padding {
    scroll-padding-top: 13vh;
    scroll-margin-top: 13vh;
  }

  .scroll-fix {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 94vh;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
