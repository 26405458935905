<script setup>
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useEmployerStore } from '../../stores/EmployerStore'
import { useAuthStore } from '../../stores/AuthStore'
import { vMaska } from 'maska'
import BcsDropdown from '../BcsDropdown.vue'
import { useLocationStore } from '../../stores/LocationStore'
import { useProfileStore } from '../../stores/ProfileStore'
import { handleErrors } from '../../utils/errorHandler'

const locationStore = useLocationStore()
const profileStore = useProfileStore()
const { updateRealData, updateUserData } = profileStore
const { getUsaStatesOptions, formattedLocation } = locationStore
const editSection = ref(false)
const phoneMask = '(###) ###-####'
const loadingState = ref(false)
const contactData = ref({
    email: '',
    phone: '',
    location: {
        city: '',
        state: ''
    }
})

const locationFormatted = computed(() => {
    const { city, state } = contactData.value.location
    return (city || state) ? formattedLocation(city, state) : ''
})

const originalData = ref({
    email: '',
    phone: '',
    location: {
        city: '',
        state: ''
    }
})

const employerStore = useEmployerStore()
const authStore = useAuthStore()
const { userData } = storeToRefs(employerStore)
const dropdownStatesOptions = ref([])

onMounted(async () => {
    const truncateText = document.querySelector('.placeholder-dropdown')
    if (truncateText) {
        truncateText.classList.remove('text-truncate')
    }
    await initializeUserData()
    const data = {
        email: userData.value?.email || '',
        phone: userData.value?.phone || '',
        location: {
            city: userData.value?.location?.city || '',
            state: userData.value?.location?.state || '',
        },
    }
    Object.assign(contactData.value, data)
    originalData.value = JSON.parse(JSON.stringify(data))
})

const initializeUserData = async () => {
    const user = authStore.user
    if (user && !userData.value?.fullname) {
        await employerStore.getEmployer(user.employerId)
    }
}

const handleSaveContactInfo = async () => {
    try {
        loadingState.value = true
        const location = {
            ...contactData.value.location,
            city: contactData.value.location.city.trim()
        }
        const { phone } = contactData.value
        await updateRealData({ location, phone })
        await updateUserData({ phone })
        editSection.value = false
    } catch (error) {
        handleErrors('Error saving contact information:', error)
    } finally {
        loadingState.value = false
    }
}

const formattedPhone = computed(() => {
    const numbers = contactData.value.phone.replace(/\D/g, '');
    return numbers.length === 10 ? `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6)}` : contactData.value.phone;
})

const isPhoneValid = computed(() => {
    const phoneDigits = contactData.value.phone.replace(/\D/g, '');
    return phoneDigits.length === 10;
})

const isCityValid = computed(() => {
    return contactData.value.location.city.trim().length > 0;
})

const clearInput = (field) => {
    if (field.includes('.')) {
        const [parent, child] = field.split('.')
        contactData.value[parent][child] = ''
    } else {
        contactData.value[field] = ''
    }
}

const handleEdit = () => {
    originalData.value = JSON.parse(JSON.stringify(contactData.value))
    editSection.value = true
}

const handleCancel = () => {
    contactData.value = JSON.parse(JSON.stringify(originalData.value))
    editSection.value = false
}

onMounted(async () => {
    const truncateText = document.querySelector('.placeholder-dropdown')
    if (truncateText) {
        truncateText.classList.remove('text-truncate')
    }
    await initializeUserData()
    const data = {
        email: userData.value?.email || '',
        phone: userData.value?.phone || '',
        location: {
            city: userData.value?.location?.city || '',
            state: userData.value?.location?.state || '',
        },
    }
    Object.assign(contactData.value, data)
    originalData.value = JSON.parse(JSON.stringify(data))
})

const disabledSave = computed(() => {
    return !contactData.value.email ||
        !contactData.value.phone ||
        !contactData.value.location.city ||
        !contactData.value.location.state ||
        !isPhoneValid.value ||
        !isCityValid.value
})

watch(userData, async (newUserData) => {
    if (newUserData) {
        const data = {
            email: newUserData.email || '',
            phone: newUserData.phone || '',
            location: {
                city: newUserData?.location?.city || '',
                state: newUserData?.location?.state || '',
            }
        };
        Object.assign(contactData.value, data);
        originalData.value = JSON.parse(JSON.stringify(data))
        dropdownStatesOptions.value = await getUsaStatesOptions()
    }
}, { immediate: true })
</script>

<template>
    <div class="section pl-135 pr-40 d-flex align-items-center justify-content-between">
        <div class="header-22 fw-bold">
            Contact Info
        </div>

        <div v-if="!editSection" class="edit" @click="handleEdit">
            Edit
        </div>
    </div>

    <div class="section-info pl-135 pr-40">
        <div class="section-row">
            <label>Email</label>
            <div class="section-value">
                {{ contactData.email }}
            </div>
        </div>

        <div class="section-row">
            <label>Phone</label>
            <div class="section-value" v-if="!editSection">
                {{ formattedPhone }}
            </div>
            <div class="section-value" v-if="editSection">
                <div class="input-wrapper" style="width: 28%;">
                    <input type="text" class="form-control text-16 form-input" 
                        :class="{ 'is-invalid': contactData.phone && !isPhoneValid }"
                        v-model="contactData.phone" 
                        v-maska
                        :data-maska="phoneMask" 
                        placeholder="(XXX) XXX-XXXX">
                    <button v-if="contactData.phone" class="clear-button" @click="clearInput('phone')">
                        <i class="bi bi-x-circle-fill"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="section-row">
            <label>Location</label>
            <div class="section-value" v-if="!editSection">
                {{ locationFormatted }}
            </div>
            <div class="section-value d-flex align-items-center" v-if="editSection" style="gap: 22px;">
                <div class="input-wrapper" style="width: 45%;">
                    <input type="text" class="form-control text-16 form-input" 
                        :class="{ 'is-invalid': contactData.location.city && !isCityValid }"
                        v-model="contactData.location.city"
                        placeholder="City">
                    <button v-if="contactData.location.city" class="clear-button" @click="clearInput('location.city')">
                        <i class="bi bi-x-circle-fill"></i>
                    </button>
                </div>
                <div>
                    <BcsDropdown :placeholder="contactData.location.state ? contactData.location.state : ''"
                        :dropdown-options="dropdownStatesOptions" v-model="contactData.location.state" />
                </div>
            </div>
        </div>

        <div v-if="editSection" class="form-row">
            <span></span>
            <div class="input-wrapper d-flex buttons-container">
                <button @click="handleSaveContactInfo" :class="{ 'disabled': disabledSave || loadingState }"
                    :disabled="disabledSave || loadingState"
                    class="btn btn-primary btn-action-profile d-flex justify-content-center align-items-center">
                    <span v-if="loadingState" class="spinner-border spinner-border-sm me-2" role="status"></span>
                    <i v-if="!loadingState"
                        class="bi bi-check-circle-fill d-flex justify-content-center align-items-center"></i>
                    {{ loadingState ? 'Saving...' : 'Save Changes' }}
                </button>
                <button @click="handleCancel"
                    class="btn btn-info btn-action-profile d-flex justify-content-center align-items-center">
                    <i class="bi bi-x-circle rounded-circle d-flex justify-content-center align-items-center"></i>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
:deep .dropdown {
    button {
        background: var(--Gray-White, #FFF);
        width: 67px !important;
        border: 1px solid var(--bs-gray-600) !important;

        .text-truncate {
            max-width: max-content !important;
            overflow: visible !important;
        }
    }

    .placeholder-dropdown {
        &:not(.selected) {
            color: var(--bs-gray-600) !important;
        }

        &.selected {
            color: var(--bs-primary) !important;
        }
    }

    i {
        font-size: 12px !important;
    }

    .dropdown-menu {
        width: 67px !important;
    }
}

</style>