<script setup>
  import { inject, ref } from 'vue'
  import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
  import { getDatabase, ref as dbRef, set } from 'firebase/database'
  import { getApp } from 'firebase/app'
  import Papa from 'papaparse'
  import { RequestService } from '../shared/services/RequestService'
  import { get } from 'lodash'
  import { EmailUtilsService } from '../shared/services/EmailUtilsService'
  const { getEmailTemplate, getEmailRecipient } = EmailUtilsService()
  const auth = getAuth()
  const db = getDatabase(getApp())
  const csvFile = ref(null)
  const loading = ref(false)
  const processedUsers = ref([])
  const errors = ref([])
  const functionsBaseUrl = inject('functionsBaseUrl')

  const { post, handleErrors } = RequestService()

  const generateRandomPassword = () => {
    const length = 12
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*'
    let password = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      password += charset[randomIndex]
    }
    return password
  }

  const processCSV = async (file) => {
    return new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const validUsers = []
          const invalidUsers = []
          
          // First, find duplicate emails
          const emailCount = {}
          const duplicateEmails = new Set()
          
          // Filter out empty rows and count emails
          const nonEmptyRows = results.data.filter(user => {
            // Check if any field has a value
            return Object.values(user).some(value => value && value.trim() !== '')
          })

          nonEmptyRows.forEach((user) => {
            if (user.Email) {
              const email = user.Email.toLowerCase()
              emailCount[email] = (emailCount[email] || 0) + 1
              if (emailCount[email] > 1) {
                duplicateEmails.add(email)
              }
            }
          })

          // Now process each non-empty user
          nonEmptyRows.forEach((user) => {
            const errors = []
            
            // Validate required fields
            if (!user.Email) {
              errors.push('Email is required')
            }
            if (!user.Agency) {
              errors.push('Agency is required')
            }
            if (!user['First Name'] && !user['Last Name']) {
              errors.push('At least First Name or Last Name is required')
            }

            // Check for duplicate emails
            if (user.Email && duplicateEmails.has(user.Email.toLowerCase())) {
              errors.push('Duplicate email in CSV file')
            }

            if (errors.length > 0) {
              invalidUsers.push({
                ...user,
                errors: errors.join(', ')
              })
            } else {
              validUsers.push(user)
            }
          })

          resolve({ validUsers, invalidUsers })
        }
      })
    })
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]
    if (file) {
      csvFile.value = file
    }
  }

  const processUser = async (userData) => {
    const password = generateRandomPassword()
    try {
      // First, try to create user in old database
      const oldDbResponse = await post(
        'createUser',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=User`,
        { ...userData, password }
      )

      // If user wasn't created in old database, stop here
      if (!oldDbResponse || !get(oldDbResponse, 'data.object.id')) {
        return {
          success: false,
          email: userData.Email,
          error: 'Error creating user in old database'
        }
      }

      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, userData.Email, password)

      // Create user in Realtime Database with modx_id
      const firstName = userData['First Name'] || ''
      const lastName = userData['Last Name'] || ''

      await set(dbRef(db, `USERS/${userCredential.user.uid}`), {
        modx_id: get(oldDbResponse, 'data.object.id', null),
        email: userData.Email,
        agency_id: userData.Agency,
        firstName,
        lastName,
        fullname: [firstName, lastName].filter(Boolean).join(' '),
        role: userData['Role/Title'] || '',
        phone: userData['Phone'] || ''
      })

      // Send custom email through our Firebase function
      await post('sendEmail', `${functionsBaseUrl}/email`, {
        type: 'AUTH',
        subtype: 'PASSWORD_CREATION',
        email: userData.Email,
        emailTemplate: await getEmailTemplate('PASSWORD_CREATION'),
        emailRecipient: await getEmailRecipient('TEST_EMAIL_RECIPIENT')
      })

      return {
        success: true,
        email: userData.Email,
        status: 'Successfully created'
      }
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        return {
          success: false,
          email: userData.Email,
          error: 'Email already in use'
        }
      }
      return {
        success: false,
        error: error.message,
        email: userData.Email
      }
    }
  }

  const createUsers = async (users) => {
    const batchSize = 10
    const results = []

    for (let i = 0; i < users.length; i += batchSize) {
      const batch = users.slice(i, i + batchSize)
      const batchPromises = batch.map((user) => processUser(user))
      const batchResults = await Promise.all(batchPromises)
      results.push(...batchResults)
    }

    return results
  }

  const processUsers = async () => {
    if (!csvFile.value) {
      handleErrors('Error processing users: ', new Error('Please select a CSV file'))
      return
    }

    loading.value = true
    processedUsers.value = []
    errors.value = []

    try {
      const { validUsers, invalidUsers } = await processCSV(csvFile.value)

      // Show invalid users as errors
      invalidUsers.forEach(user => {
        errors.value.push({
          email: user.Email || 'no email',
          error: user.errors
        })
      })

      // Process only valid users
      if (validUsers.length > 0) {
        const results = await createUsers(validUsers)

        for (const result of results) {
          if (result.success) {
            processedUsers.value.push({
              email: result.email,
              status: result.status
            })
          } else {
            errors.value.push({
              email: result.email,
              error: result.error
            })
          }
        }
      }
    } catch (error) {
      handleErrors('Error processing users: ', error)
      errors.value.push({
        email: 'System Error',
        error: 'Error processing CSV file'
      })
    } finally {
      loading.value = false
    }
  }
</script>

<template>
  <div class="main-container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 text-center my-3">
        <h1 class="mt-1 text-primary">Create Users</h1>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="card-title header-20 text-secondary">
              <strong>Bulk User Upload</strong>
            </h5>
          </div>
          <div class="card-body">
            <div class="mb-4">
              <p>
                Upload a CSV file with the following columns:
                <strong>First Name, Last Name, Email, Role/Title, Agency</strong>
              </p>
              <p class="text-muted">The first row should contain the column names.</p>
            </div>

            <div class="mb-4">
              <input
                type="file"
                class="form-control"
                id="csvFile"
                accept=".csv"
                @change="handleFileUpload"
                :disabled="loading" />
            </div>
            <button
              class="btn btn-primary"
              @click="processUsers"
              :disabled="!csvFile || loading">
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"></span>
              {{ loading ? 'Processing...' : 'Process Users' }}
            </button>

            <!-- Success Messages -->
            <div v-if="processedUsers.length > 0" class="mt-3">
              <h6>Successfully Processed Users:</h6>
              <ul class="list-group">
                <li
                  v-for="user in processedUsers"
                  :key="user.email"
                  class="list-group-item list-group-item-success">
                  {{ user.email }} - {{ user.status }}
                </li>
              </ul>
            </div>

            <!-- Error Messages -->
            <div v-if="errors.length > 0" class="mt-3">
              <h6>Errors:</h6>
              <ul class="list-group">
                <li
                  v-for="error in errors"
                  :key="error.email"
                  class="list-group-item list-group-item-danger">
                  {{ error.email }} - {{ error.error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .main-container {
    background-color: #f5f5f5;
    height: 100vh;
  }
</style>
