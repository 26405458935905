<script setup>
  import { ref, watch, computed, defineEmits, inject, onMounted } from 'vue'
  import * as bootstrap from 'bootstrap'
  const emit = defineEmits(['modalButtonClick', 'closeButtonClicked'])
  const props = defineProps({
    modalType: {
      type: String,
      default: ''
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalButton: {
      type: String,
      default: ''
    },
    modalSize: {
      type: String,
      default: 'medium'
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  })

  const modalRef = ref(null)
  let bootstrapModal = null
  const emitter = inject('emitter')
  
  onMounted(() => {
    emitter.on('closeBcsModal', () => {
      closeModal()
    })
  })

  const openModal = () => {
    if (bootstrapModal) {
      bootstrapModal.show()
    }
  }
  const clickButton = () => {
    emit('modalButtonClick')
  }
  const handleBtnClose = () => {
    emit('closeButtonClicked')
    closeModal()
  }
  const closeModal = () => {
    if (bootstrapModal) {
      bootstrapModal.hide()
    }
  }
  const modalClasses = computed(() => {
    const baseClasses = {
      icon: 'bi icon-modal',
      title: 'alert-title text-center',
      button: 'btn'
    }
    switch (props.modalType) {
      case 'success':
        return {
          icon: `${baseClasses.icon} bi-check-circle text-success`,
          title: `${baseClasses.title} text-success`,
          button: `${baseClasses.button} btn-success`
        }
      case 'error':
        return {
          icon: `${baseClasses.icon} bi-exclamation-circle text-danger`,
          title: `${baseClasses.title} text-danger`,
          button: `${baseClasses.button} btn-danger`
        }
      case 'warning':
        return {
          icon: `${baseClasses.icon} bi-exclamation-triangle warning-text`,
          title: `${baseClasses.title} warning-text`,
          button: `${baseClasses.button} btn-warning`
        }
      case 'info':
        return {
          icon: `${baseClasses.icon} bi-info-circle info-text`,
          title: `${baseClasses.title} info-text`,
          button: `${baseClasses.button} btn-primary`
        }
      case 'confirmation':
        return {
          title: `${baseClasses.title} info-text`,
          button: `${baseClasses.button} btn-primary`
        }
      default:
        return baseClasses
    }
  })
  defineExpose({ openModal, closeModal })
  watch(
    () => modalRef.value,
    (el) => {
      if (el && typeof bootstrap !== 'undefined') {
        bootstrapModal = new bootstrap.Modal(el)
        el.addEventListener('hidden.bs.modal', () => {
          emit('closeButtonClicked')
        })
      }
    },
    { immediate: true }
  )
</script>
<template>
  <div
    class="modal fade mt-5"
    ref="modalRef"
    tabindex="-1"
    role="dialog"
    aria-labelledby="BcsModal"
    aria-hidden="true">
    <div class="modal-dialog custom-modal-dialog" :class="[props.customClass, props.modalSize]" role="document">
      <div class="modal-content" :class="props.customClass">
        <div class="modal-header bg-gray-100" v-if="$slots.header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-end" v-if="!props.hideCloseButton">
            <button type="button" class="close btn" @click="handleBtnClose" aria-label="Close">
              <i class="bi bi-x-lg" aria-hidden="true"></i>
            </button>
          </div>
          <div class="text-center">
            <slot name="body-header"></slot>
          </div>
          <div class="text-center">
            <i :class="modalClasses.icon"></i>
          </div>
          <div :class="modalClasses.title" v-if="props.modalTitle">
            <strong>{{ props.modalTitle }}</strong>
          </div>
          <div class="alert-content text-14 text-center">
            <slot></slot>
          </div>
          <div class="text-center" v-if="props.modalButton">
            <button @click="clickButton" type="button" :class="modalClasses.button">
              {{ props.modalButton }}
            </button>
          </div>
          <div class="text-center">
            <slot name="footer-body"></slot>
          </div>
        </div>
        <div v-if="showFooter" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .icon-modal {
    font-size: 70px;
  }
  .warning-text {
    color: #d88201;
  }
  .btn-warning {
    background-color: #d88201;
    color: #fff;
  }
  .alert-title {
    font-size: 22px;
  }
  .alert-content {
    margin-bottom: 21px;
    margin-top: 10px;
  }
  .small {
    max-width: 555px;
    border-radius: 10px;
  }
  .medium {
    max-width: 800px;
    border-radius: 10px;
  }
  .modal-body {
    padding: 0;
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 30px;
  }
  .info-text {
    color: #2e0c59;
  }
  .modal-footer {
    border-top: 1px solid #dee2e6;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }
</style>