<script setup>
import ActivityInfo from './ActivityInfo.vue'
import ContactInfo from './ContactInfo.vue'
import HeaderProfile from './HeaderProfile.vue'
import SecurityInfo from './SecurityInfo.vue'
import Footer from '../FooterSection.vue'
import { onMounted, ref, watch } from 'vue'
import { useAuthStore } from '../../stores/AuthStore'
import { useEmployerStore } from '../../stores/EmployerStore'
import { storeToRefs } from 'pinia'
import { useLocationStore } from '../../stores/LocationStore'
import BcsDynamicText from '../BcsDynamicText.vue'
import BcsEditPhoto from './BcsEditPhoto.vue'

const locationStore = useLocationStore()
const { formattedLocation } = locationStore

const employerStore = useEmployerStore()
const authStore = useAuthStore()
const { userData } = storeToRefs(employerStore)

const data = ref({
    companyImg: '',
    companyName: '',
    companyLocation: ''
})

onMounted(async () => {
    await initializeUserData()
})

const initializeUserData = async () => {
    const user = authStore.user
    if (user && !userData.value?.fullname) {
        await employerStore.getEmployer(user.employerId)
    }
}

watch(userData, ({ agencyLocation, logoBase64, companyName }) => {
    data.value = {
        companyImg: logoBase64,
        companyName,
        companyLocation: (agencyLocation?.city || agencyLocation?.state) ? formattedLocation(agencyLocation.city, agencyLocation.state) : ''
    }

}, { immediate: true })

</script>

<template>
    <div class="member-profile-container">
        <div class="row">
            <div class="col-8 p-none">
                <HeaderProfile />
                <ContactInfo />
                <ActivityInfo />
                <SecurityInfo />
                <BcsEditPhoto />
            </div>

            <div class="col-4 company-container">

                <div class="container-img d-flex justify-content-center">
                    <div class="company-img-container" :style="{ 'background-image': `url(${data.companyImg})` }">
                    </div>
                </div>

                <div class="text-center">
                    <div class="header-22 fw-bold agency-name">{{ data.companyName }}</div>
                    <div class="agency-location">{{ data.companyLocation }}</div>
                </div>

                <div class="helpers-container ">

                    <div class="helper  d-flex align-items-center">
                        <i class="bi bi-question-circle-fill text-primary"></i>
                        <p class=" text-14 white-space-pre-wrap text-center">
                            <BcsDynamicText text="canWhiteLabelDocuments" />
                        </p>
                    </div>

                    <div class="helper d-flex align-items-center">
                        <i class="bi bi-question-circle-fill text-primary"></i>
                        <p class=" text-14 white-space-pre-wrap text-center">
                            <BcsDynamicText text="howDoIChangeMyCompanyOrLogo" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer class="px-5 mx-n3" :show-margin-top="false"></Footer>

</template>

<style lang="scss">
.helper {
    gap: 8px;
}



.helpers-container {
    display: grid;
    justify-content: center;
    gap: 8px;
    margin-top: 14px;
}

.profile-icon {
    width: 103px;
    height: 103px;
    position: relative;
    margin-bottom: auto;

    img {
        border-radius: 50%;
        object-fit: cover;
        height: 100%;
    }

}

.company-container {
    .agency-name {
        margin-top: 22px;
    }

    .agency-location {
        margin-top: 9px;
    }
}

.company-img-container {
    margin-top: 80px;
    width: 294px;
    height: 296px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.buttons-container {
    gap: 8px;
}

.form-row {
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    span {
        font-weight: 500;
        font-size: 14px;
    }
}

.btn-action-profile {
    gap: 8px;
}

.input-wrapper {
    position: relative;
    width: 71%;

    input {
        border: 1px solid var(--Gray-600, #6C757D);
    }


}

.form-input {
    width: 100%;
    padding: 8px 36px 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 16px;
}

.clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--bs-gray-600);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 16px;
    }

    &:hover {
        color: var(--bs-gray-800);
    }
}

.member-profile-container {
    .section-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 0 40px 0;
    }

    .section-row {
        display: grid;
        grid-template-columns: 120px 1fr;
        align-items: center;
        gap: 1rem;
    }

    label {
        font-weight: 700;
        font-size: 1rem;
    }

    .p-none {
        padding: 0 !important;
    }

    .section {
        padding: 7px 0;
        background-color: var(--bs-gray-100);
        border-bottom: 1px solid var(--Gray-400, #C5CDD4);
    }

    .edit {
        margin-left: auto;
        font-size: 14px;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: -0.28px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: var(--bs-primary);
        cursor: pointer;
    }

    .header-22 {
        font-size: 22px;
    }

    .header-32 {
        font-size: 32px;
        font-weight: 700;
    }


    .pl-135 {
        padding-left: 135px;
    }

    .pr-40 {
        padding-right: 40px;
    }

    .company-container {
        background-color: var(--bs-gray-100);
        // height: 100vh;
        border-left: 1px solid var(--Gray-400, #C5CDD4);
    }
}
</style>

<style scoped lang="scss">

:deep{
    .is-invalid {
        background-image: none!important;
        padding-right: 0.75rem!important;
    }
}

:deep {
    .words-for-definition {
        border-bottom: 0 !important;
        font-weight: normal !important;
    }
}

p {
    margin: 0 !important;
    padding: 0 !important;
}
</style>