<script setup>
  import Footer from './FooterSection.vue'
  import Breadcrumbs from './BreadCrumbs.vue'
  import BcsDynamicText from './BcsDynamicText.vue'
  import { useFaqsStore } from '../stores/FaqsStore'
  import { onMounted, ref } from 'vue'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import BcsSearchDropdown from './BcsSearchDropdown.vue'

  const faqStore = useFaqsStore()
  const { getFaqs } = faqStore
  const selectedAccordion = ref(null)
  const faqSections = ref([])
  const displayedFaqSections = ref([])
  const flattenedFaqs = ref([])

  onMounted(async () => {
    const remoteFaqs = await getFaqs()

    if (remoteFaqs.value) {
      faqSections.value = Object.entries(remoteFaqs.value).map(([key, section]) => ({
        ...section,
        questionTitle: key,
        FaqList: section.FaqList ? JSON.parse(section.FaqList) : []
      }))
      displayedFaqSections.value = [...faqSections.value]
      
      flattenedFaqs.value = faqSections.value.flatMap(section => 
        section.FaqList.map(faq => ({
          ...faq,
          sectionId: section.sectionId
        }))
      )
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })

  const toggleAccordion = (index) => {
    if (selectedAccordion.value === index) {
      selectedAccordion.value = null
    } else {
      selectedAccordion.value = index
    }
  }

  const handleSearch = ({ results }) => {
    displayedFaqSections.value = faqSections.value
      .map(section => ({
        ...section,
        FaqList: section.FaqList.filter(faq => 
          results.some(r => r.MIGX_id === faq.MIGX_id)
        )
      }))
      .filter(section => section.FaqList.length > 0)

    if (selectedAccordion.value) {
      const [sectionId, faqId] = selectedAccordion.value.split('-')
      const sectionExists = displayedFaqSections.value.some(s => s.sectionId === sectionId)
      const faqExists = displayedFaqSections.value.some(s =>
        s.FaqList.some(f => f.MIGX_id === faqId)
      )
      if (!sectionExists || !faqExists) {
        selectedAccordion.value = null
      }
    }
  }

  const handleSelect = (faq) => {
    selectedAccordion.value = `${faq.sectionId}-${faq.MIGX_id}`
  }

  const handleClear = () => {
    displayedFaqSections.value = [...faqSections.value]
    selectedAccordion.value = null
  }
</script>

<template>
  <div class="container-fluid mx-5 px-5 mt-4">
    <div class="row">
      <div class="col-6">
        <Breadcrumbs></Breadcrumbs>
        <p class="text-28">
          <med>Frequently Asked Questions</med>
        </p>
        <p class="text-14">
          <BcsDynamicText text="faqsPageIntro" />
        </p>

        <div style="margin-bottom: 50px;">
          <BcsSearchDropdown
          placeholder="Search FAQs by keyword or sentence"
          :items="flattenedFaqs"
          :searchFields="['faqTitle', 'faqBody']"
          :maxSuggestions="10"
          @search="handleSearch"
          :arrayTerm="'faqTitle'"
          @select="handleSelect"
          @clear="handleClear"
        />
        </div>
        

        <div v-for="section in displayedFaqSections" :key="section.MIGX_id">
          <p class="header-22">
            <strong>{{ section.sectionName }}</strong>
          </p>

          <!-- accordions -->
          <div class="accordion" :id="'accordion' + section.sectionId">
            <div
              v-for="faq in section.FaqList"
              :key="section.sectionId + '-' + faq.MIGX_id"
              class="accordion-item">
              <h2 class="accordion-header" :id="'heading' + section.sectionId + faq.MIGX_id">
                <button
                  class="accordion-button"
                  :class="{
                    collapsed: selectedAccordion !== section.sectionId + '-' + faq.MIGX_id
                  }"
                  type="button"
                  @click="toggleAccordion(section.sectionId + '-' + faq.MIGX_id)"
                  :aria-expanded="selectedAccordion === section.sectionId + '-' + faq.MIGX_id"
                  :aria-controls="'collapse' + section.sectionId + faq.MIGX_id">
                  <strong class="text-primary">{{ faq.faqTitle }}</strong>
                </button>
              </h2>
              <div
                :id="'collapse' + section.sectionId + faq.MIGX_id"
                class="accordion-collapse collapse"
                :class="{ show: selectedAccordion === section.sectionId + '-' + faq.MIGX_id }"
                :aria-labelledby="'heading' + section.sectionId + faq.MIGX_id"
                :data-bs-parent="'#accordion' + section.sectionId">
                <div class="accordion-body">
                  <p class="text-14">
                    <TextWithDefinitions :key="faq.faqBody" :text="faq.faqBody" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end accordions -->
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <Footer class="px-5 mx-n3"></Footer>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .accordion {
    margin-bottom: 41px;
  }

  .accordion-button.collapsed {
    border: none;
  }

  .accordion-button {
    border: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: var(--bs-gray-100);
  }

  .accordion-collapse {
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
  }

  .accordion-collapse.show {
    max-height: 1000px;
  }

  .input-group {
    margin-bottom: 50px;
  }

  .text-14,
  .header-22 {
    margin-bottom: 30px;
  }

  :deep{
  .search-container{
    input{
     color: var(--bs-primary);
    }
  }
}
</style>
