<script setup>
  import {
    defineEmits,
    onBeforeMount,
    onMounted,
    ref,
    watch,
    computed,
    reactive,
    inject
  } from 'vue'
  import Footer from './FooterSection.vue'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { storeToRefs } from 'pinia'
  import { RemindersCalendarService } from '../services/RemindersCalendarService'
  import { useEmployerStore } from '../stores/EmployerStore'
  import BcsDynamicText from './BcsDynamicText.vue'
  import AlertNotification from './AlertNotification.vue'
  import DatePicker from './DatePicker.vue'
  import BcsDropdown from './BcsDropdown.vue'
  import { nextTick } from 'vue'
  import { removeHTMLTags } from '../utils'
  import { RequestService } from '../services/RequestService'
  import { map, get } from 'lodash'
  import WhiteLabelModal from './WhiteLabelModal.vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'
  import { isValidHexColor } from '../utils'
  
  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore
  const emit = defineEmits(['toggleLoading', 'inFocus'])

  const emitter = inject('emitter')
  const toggleLoadingWithMessage = inject('toggleLoadingWithMessage', () => {})

  const { nameMonths, getReminders, getEvents, getEmails, postReminders, getGoogleReminders } =
    RemindersCalendarService()

  const questionStore = useQuestionsStore()
  const employerStore = useEmployerStore()

  const {
    getPlanningSectionGroup,
    changeLastPlanningSectionGroup,
    isIntakeComplete
  } = questionStore
  const { lastPlanningSectionGroup, intakeQuestions } = storeToRefs(questionStore)
  const { actualEmployer, actualPlanYear, lastPlanYear, userData, actualPlanYearId } = storeToRefs(employerStore)
  const { changeLastPlanYear } = employerStore
  const { createLog, downloadPdf, handleErrors, getRealtimeDatabaseValue } = RequestService()
  const actualMonth = nameMonths[new Date().getMonth()]
  // const actualYear = new Date().getFullYear()

  const itemsIsSticky = ref(true)
  const mainContainer = ref(null)
  const eventDefinition = ref(null)
  const title = ref('')
  const weeks = ref(['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'])
  const months = ref()
  const beforeMonthsEvents = ref()
  const showFullDescriptionSingleModal = ref(false)
  const modalReminderSource = ref('')
  const selectedDays = ref('')
  const sortDirectionEvents = ref('asc')
  const selectedDateCalendar = ref(null)
  const events = ref([])
  const selectAllEvents = ref(false)
  const descriptionEvent = ref('')
  const reminderSendSucess = ref(false)
  const recorderReminderSend = ref([])
  const googleReminders = ref([])
  const yearOfActualPlan = ref('')
  const showEventDefinition = ref({
    show: false,
    definition: ''
  })
  const showRemiderSentModal = ref(false)
  const whiteLabelModalContent = ref('')
  const modalRef = ref(null)
  const showGooogleReminders = ref(false)
  const showGoogleReminderInformation = ref({
    show: false,
    event: ''
  })
  const scrollPosition = ref(0)
  const templateID = ref('')

  const todayDate = ref(
    new Date()
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .split('/')
      .sort((a, b) => b.length - a.length)
      .join('-')
  )

  function insertDefaultEmails() {
    if (actualEmployer.value && actualEmployer.value.contact_emails) {
      validEmails.value = actualEmployer.value.contact_emails
        .split(',')
        .map((email) => email.trim())
    }
  }

  onMounted(async () => {
    templateID.value = await getRealtimeDatabaseValue('SYSTEM_PARAMS/PDF_TEMPLATE_ID/CALENDAR')
    yearOfActualPlan.value = actualPlanYear.value?.plan_start_date?.split('/')[2] || ''

    if (actualPlanYear.value?.plan_start_date) {
      await updateMonths()
      updateEvents()
    }

    insertDefaultEmails()
    getModalDueDateSource()

    nextTick(() => {
      const addDueDateModal = document.getElementById('addDueDateModal')
      if (addDueDateModal) {
        addDueDateModal.addEventListener('show.bs.modal', clearInfoModal)
      }

      const reminderSendModal = document.getElementById('reminders-sent')
      if (reminderSendModal) {
        reminderSendModal.addEventListener('show.bs.modal', clearInfoRemindersSend)
      }
    })

    recipients.value = await getEmails()
    googleReminders.value = await getGoogleReminders(actualEmployer.value?.id)
  })

  function clearInfoRemindersSend() {
    showGooogleReminders.value = false
    showGoogleReminderInformation.value = {
      show: false,
      event: ''
    }
  }

  function clearInfoModal() {
    validEmails.value = []
    newEmail.value = ''
    selectedDays.value = ''
    selectedRecipients.value = []
    stateModal.currentStep = 'reminder'
    events.value.forEach((event) => {
      event.selected = false
      event.reminder = ''
      event.customReminder = null
    })

    selectAllEvents.value = false
    reminderSendSucess.value = false
    selectedDateCalendar.value = null
    insertDefaultEmails()

    showEventDefinition.value = {
      show: false,
      definition: ''
    }
  }

  function getModalDueDateSource() {
    const addDueDateModal = document.getElementById('addDueDateModal')
    addDueDateModal.addEventListener('show.bs.modal', (event) => {
      const button = event.relatedTarget
      const source = button.getAttribute('data-source')

      if (source === 'calendar') {
        modalReminderSource.value = 'calendar'
      } else if (source === 'offCanvas') {
        modalReminderSource.value = 'OffCanvas'
      }
    })
  }

  const scrollHandler = (e) => {
    if (e.target.scrollTop > 110 && itemsIsSticky.value) {
      itemsIsSticky.value = false
    } else if (e.target.scrollTop < 1 && !itemsIsSticky.value) {
      itemsIsSticky.value = true
    }
  }

  const isCustomDateSelection = computed(() => {
    return selectedDays.value === -1 || hasCustomDateReminder.value
  })

  const toggleOffCanvas = (id, definitionExpected, date) => {
    emitter.emit('toggleOffCanvas', { id, definitionExpected, date })
  }

  function toggleAllEvents() {
    const newSelectedState = selectAllEvents.value
    filteredEvents.value.forEach((event) => {
      event.selected = newSelectedState
    })
  }

  const formattedEventDate = (dateString, excludeOptions = {}) => {
    if (!dateString) return ''

    const [month, day] = dateString.split('/').map(Number)
    let year = parseInt(yearOfActualPlan.value)

    if (actualPlanYear.value && actualPlanYear.value.plan_start_date) {
      const [planMonth, planDay] = actualPlanYear.value.plan_start_date.split('/').map(Number)
      if (month < planMonth || (month === planMonth && day < planDay)) {
        year++
      }
    } else {
      handleErrors('actualPlanYear or plan_start_date is undefined, using current year')
      year = new Date().getFullYear()
    }

    const date = new Date(year, month - 1, day)

    const optionsFormatDate = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }

    Object.keys(excludeOptions).map((excludeOption) => {
      if (excludeOptions[excludeOption]) {
        delete optionsFormatDate[excludeOption]
      }
    })

    let formattedDate = date.toLocaleDateString('en-US', optionsFormatDate)

    if (!excludeOptions.weekday) {
      formattedDate = formattedDate.replace(/,/, '')
    }
    return formattedDate
  }

  watch(
    () => actualPlanYear.value,
    async (newValue) => {
      if (newValue) {
        await updateMonths()
        updateEvents()
        yearOfActualPlan.value = actualPlanYear.value?.plan_start_date?.split('/')[2] || ''
      }
    }
  )

  const planYearChanged = computed(() => {
    let userAlreadyEnterInCalendar = sessionStorage.getItem('userAlreadyEnterInCalendar')
    if (userAlreadyEnterInCalendar === 'true') {
      return actualPlanYear.value?.plan_start_date !== lastPlanYear.value?.plan_start_date
    } else {
      sessionStorage.setItem('userAlreadyEnterInCalendar', 'true')
      return false
    }
  })

  const hasCustomDateReminder = computed(() => {
    return filteredEvents.value.some((event) => event.reminder === -1)
  })

  const handleCancelCustomDate = () => {
    selectedDateCalendar.value = null
    selectedCustomDateEvent.value = null

    stateModal.currentStep = 'reminder'

    if (modalReminderSource.value === 'calendar') {
      events.value.forEach((event) => {
        if (event.reminder === -1) {
          event.reminder = ''
          event.customReminder = null
          event.customDate = null
          event.showCustom = false
        }
      })
    } else if (modalReminderSource.value === 'OffCanvas') {
      selectedDays.value = ''
    }
  }

  const selectedCustomDateEvent = computed(() => {
    return filteredEvents.value.find((event) => event.reminder === -1) || {}
  })

  const titlesOptions = {
    'SMALL GROUP': 'For small groups, 1-50 employees, fully insured',
    'SMALL GROUP - SELF INSURED':
      'For self-insured small groups, including level-funded (1-50 employees)',
    'LARGE GROUPS': 'For large groups, 51+ employees, fully insured',
    'LARGE GROUP - SELF INSURED':
      'For self-insured large groups, 51+ employees, including level-funded'
  }

  const sectionType = {
    'LARGE GROUP - SELF INSURED': 2,
    'LARGE GROUPS': 0,
    'SMALL GROUP - SELF INSURED': 1,
    'SMALL GROUP': 3
  }

  function findEventInCalendar(event, calendar) {
    for (const month in calendar) {
      for (const week in calendar[month]) {
        for (const evt of calendar[month][week]) {
          if (evt.name === event.name) {
            return evt
          }
        }
      }
    }

    return null
  }

  function handleShowDefinitionModal(event) {
    showEventDefinition.value = {
      show: true,
      event: event
    }
  }

  function compareCalendars(currentCalendar, previousCalendar) {
    for (const month in currentCalendar) {
      for (const week in currentCalendar[month]) {
        for (const event of currentCalendar[month][week]) {
          const prevEvent = findEventInCalendar(event, previousCalendar)
          if (prevEvent && prevEvent.date !== event.date) {
            event.dateChanged = true
          }
        }
      }
    }

    return currentCalendar
  }

  function removeKeyDateChanged(calendar) {
    for (const month in calendar) {
      for (const week in calendar[month]) {
        for (let event of calendar[month][week]) {
          delete event.dateChanged
        }
      }
    }
  }

  const sortedEvents = computed(() => {
    return [...googleReminders.value].sort((a, b) => {
      const comparison = a.due_date.localeCompare(b.due_date)
      return sortDirectionEvents.value === 'asc' ? comparison : -comparison
    })
  })

  //TODO we are updating the values inside filtered events this needs to be fixed
  const filteredEvents = computed(() => {
    let today = new Date()
    today.setHours(0, 0, 0, 0)

    const getEventDate = (eventDateStr, referenceYear, planMonth, planDay) => {
      const [eventMonth, eventDay] = eventDateStr.split('/').map(Number)
      let eventYear = referenceYear

      if (eventMonth < planMonth || (eventMonth === planMonth && eventDay < planDay)) {
        eventYear++
      }

      return new Date(eventYear, eventMonth - 1, eventDay)
    }

    const sortEvents = (eventsArray, planYear, planMonth, planDay) => {
      return eventsArray.sort((a, b) => {
        const dateA = getEventDate(a.date, planYear, planMonth, planDay)
        const dateB = getEventDate(b.date, planYear, planMonth, planDay)
        return sortDirectionEvents.value === 'asc' ? dateA - dateB : dateB - dateA
      })
    }

    if (actualPlanYear.value && actualPlanYear.value.plan_start_date) {
      const [planMonth, planDay, planYear] = actualPlanYear.value.plan_start_date
        .split('/')
        .map(Number)
      const planStartDate = new Date(planYear, planMonth - 1, planDay)
      today = planStartDate >= today ? planStartDate : today

      const filtered = events.value.filter((event) => {
        const eventDate = getEventDate(event.date, planYear, planMonth, planDay)
        return eventDate >= today
      })

      return sortEvents(filtered, planYear, planMonth, planDay)
    } else {
      handleErrors(
        'actualPlanYear.value or plan_start_date is undefined, using current date for filtering'
      )
      const filtered = events.value.filter((event) => {
        const [month, day] = event.date.split('/').map(Number)
        const eventDate = new Date(today.getFullYear(), month - 1, day)
        return eventDate >= today
      })

      return sortEvents(filtered, today.getFullYear(), today.getMonth() + 1, today.getDate())
    }
  })

  const selectedEventsCount = computed(() => {
    const count = filteredEvents.value.filter((event) => event.selected).length
    return count
  })

  const handleSelectChange = (option, event) => {
    const selectedValue = option.value
    if (selectedValue === -1) {
      event.showCustom = true
      event.reminder = -1
      selectedCustomDateEvent.value = { ...event }      
      selectedDateCalendar.value = event.customDate || null
      const eventElement = document.getElementById(`event-${event.id}`)
      if (eventElement) {
        scrollPosition.value = eventElement.offsetTop
      }
    } else {
      event.showCustom = false
      event.reminder = selectedValue
      event.customReminder = null
      event.customDate = null
      selectedCustomDateEvent.value = null
      selectedDateCalendar.value = null
    }

    const index = events.value.findIndex((e) => e.id === event.id)
    if (index !== -1) {
      events.value[index] = { ...event }
      events.value = [...events.value]
    }
  }

  const formatDateGoogleReminders = (dateString) => {
    if (!dateString) return ''
    const [year, month, day] = dateString.split('-')
    const date = new Date(year, month - 1, day)

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const formattedDate = `${daysOfWeek[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`

    return formattedDate
  }

  const calculateDaysDifference = (endDate, startDate) => {
    let start = new Date(startDate)
    let end = new Date(endDate)

    if (start.getTime() === end.getTime()) return 0
    if (end < start) [start, end] = [end, start]

    let workingDays = 0
    for (let date = new Date(start); date < end; date.setDate(date.getDate() + 1)) {
      if (date.getDay() !== 0 && date.getDay() !== 6) workingDays++
    }

    return workingDays
  }

  const handleSingleEventCustomDate = () => {
    let diffDays = calculateDaysDifference(
      formattedEventDate(eventDefinition.value.date),
      selectedDateCalendar.value
    )
    selectedDays.value = diffDays
    stateModal.currentStep = 'recipients'

    return
  }

  const handleClickFooterModal = () => {    
    if (stateModal.currentStep === 'preview') {
      sendReminders().then(() => {
        const DUE_DATE_REMINDER_SENT = 4
        createLog({
          eventId: DUE_DATE_REMINDER_SENT,
          logName: 'user_activity_log',
          employerId: actualEmployer.value.id,
          agencyId: actualEmployer.value.agency_id
        }).catch((error) => handleErrors(error))
      })
      return
    }

    if (hasCustomDateReminder.value) {
      if (modalReminderSource.value === 'calendar') {
        handleCustomDateSelection()
      }
    } else if (modalReminderSource.value === 'OffCanvas') {
      if (stateModal.currentStep === 'reminder') {
        handleSingleEventCustomDate()
        stateModal.currentStep = 'recipients'
      } else if (stateModal.currentStep === 'recipients') {
        if (validEmails.value.length === 0) {
          addEmail(newEmail.value)
          newEmail.value = ''
        }
        stateModal.currentStep = 'preview'
      }
    } else {
      addEmail(newEmail.value)
      newEmail.value = ''
      stateModal.currentStep = computedFooterModalButton.value.click
    }
  }

  const handleCustomDateSelection = () => {
    if (selectedDateCalendar.value && selectedCustomDateEvent.value) {
      const event = selectedCustomDateEvent.value
      const dueDate = formattedEventDate(event.date)
      const reminderDate = selectedDateCalendar.value
      const diffDays = calculateDaysDifference(dueDate, reminderDate)

      const updatedEvent = {
        ...event,
        customReminder: diffDays,
        reminder: -2,
        customDate: selectedDateCalendar.value,
        showCustom: true
      }

      const index = events.value.findIndex((e) => e.id === event.id)
      if (index !== -1) {
        events.value[index] = updatedEvent
        events.value = [...events.value]

        nextTick(() => {
          const tableContainer = document.querySelector('.table-container-events')
          if (tableContainer) {
            tableContainer.scrollTop = scrollPosition.value - 50
          }
        })
      }
    }

    selectedDateCalendar.value = null
    selectedCustomDateEvent.value = null
  }

  function formatDateToSendReminder(dateString) {    
    if (!dateString) return ''
    const [month, day] = dateString.split('/').map(Number)
    let year = parseInt(yearOfActualPlan.value)

    if (actualPlanYear.value && actualPlanYear.value.plan_start_date) {
      const [planMonth, planDay] = actualPlanYear.value.plan_start_date.split('/').map(Number)

      if (month < planMonth || (month === planMonth && day < planDay)) {
        year++
      }
    } else {
      handleErrors('actualPlanYear.value or plan_start_date is undefined, using current year')
      year = new Date().getFullYear()
    }
    const formattedMonth = month.toString().padStart(2, '0')
    const formattedDay = day.toString().padStart(2, '0')    
    return `${year}-${formattedMonth}-${formattedDay}`
  }

  const selectedEventsInfo = computed(() => {
    return filteredEvents.value
      .filter((event) => event.selected)
      .map((event) => {
        const endDate = formatDateToSendReminder(event.date)
        const daysToReminder = event.customReminder || event.reminder
        const startDate =
          daysToReminder == 0 ? todayDate.value : subtractDaysFromDate(endDate, daysToReminder)

        return {
          name: event.name,
          date: event.date,
          reminder: event.reminder,
          customDate: event.customDate,
          customReminder: event.customReminder,
          validEmails: validEmails.value,
          startDate: startDate
        }
      })
  })

  function daysDifferenceGoogleReminder(date1, date2) {
    if (!date1 || !date2) {
      return
    }

    const [year1, month1, day1] = date1.split('-').map(Number)
    const [year2, month2, day2] = date2.split('-').map(Number)

    const d1 = new Date(year1, month1 - 1, day1)
    const d2 = new Date(year2, month2 - 1, day2)

    let days = 0
    const [startDate, endDate] = d1 < d2 ? [d1, d2] : [d2, d1]

    let currentDate = new Date(startDate)
    currentDate.setDate(currentDate.getDate())

    while (currentDate < endDate) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        days++
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return days
  }

  function subtractDaysFromDate(dateString, daysToSubtract) {
    
    const [year, month, day] = dateString.split('-').map(Number)
    let date = new Date(Date.UTC(year, month - 1, day))

    while (daysToSubtract > 0) {
      date.setUTCDate(date.getUTCDate() - 1)
      if (date.getUTCDay() !== 6 && date.getUTCDay() !== 0) {
        daysToSubtract--
      }
    }

    const resultYear = date.getUTCFullYear()
    const resultMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const resultDay = date.getUTCDate().toString().padStart(2, '0')    
    return `${resultYear}-${resultMonth}-${resultDay}`
  }

  const updateEvents = async () => {
    let numberStartMonth = parseInt(actualPlanYear.value?.plan_start_date.split('/')[0]) - 1
    let finalSectionGroup = getPlanningSectionGroup()
    let type = sectionType[finalSectionGroup]

    events.value = (await getEvents(numberStartMonth, type)).map((event) => ({
      ...event,
      reminder: '',
      selected: false,
      customReminder: null,
      customDate: null,
      showCustom: false
    }))
  }

  const updateMonths = async () => {
    let numberStartMonth = parseInt(actualPlanYear.value?.plan_start_date.split('/')[0]) - 1
    let finalSectionGroup = getPlanningSectionGroup()
    let type = sectionType[finalSectionGroup]
    title.value = titlesOptions[finalSectionGroup]
    months.value = await getReminders(numberStartMonth, type)
    getBeforeMonthsEvents()
  }

  async function exportCalendarToCsv() {
    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-')
      return `${month}/${day}/${year}`
    }

    const formatDueEvents = (events, body) => {
      const ongoingReqs =
        body ?
          body
            .split('<strong>')
            .filter((section, index) => section.trim() && index > 0)
            .map((section) => {
              const [title, description] = section.split('</strong>')
              return {
                title: removeHTMLTags(title).trim(),
                description: removeHTMLTags(description).trim()
              }
            })
        : []

      return events
        .map((event, index) => {
          const dueDate = formatDate(formatDateToSendReminder(event.date))
          const ongoingReq = ongoingReqs[index] || { title: '', description: '' }
          return `"${dueDate}","${event.name}","${ongoingReq.title}","${ongoingReq.description}"\n`
        })
        .join('')
    }

    const CSVHeader = `Due Date,Description,Ongoing Requirement Title,Ongoing Requirement Description\n`

    const csvContent =
      CSVHeader +
      formatDueEvents(
        events.value,
        (await getDefinitionById('ongoingRequirementsDefinition', 'Ongoing Requirements'))?.body
      )

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const fileName = `${actualEmployer.value.name} BCS Pro Compliance Calendar Export.csv`

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName)
    } else {
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  async function sendReminders() {
    const sendGoogleReminder = async (eventDefinition) => {
      const endDate = formatDateToSendReminder(eventDefinition.date)
      const transformedEmails = [
        ...selectedRecipients.value,
        ...validEmails.value
          .filter((email) => email.trim() !== '')
          .map((email) => ({ email: email.trim() }))
      ].filter((attendee) => attendee.email !== '')
      const getDescriptionToMultiplesReminders = await getDefinitionById(
        eventDefinition.body,
        eventDefinition.name
      )

      const daysToReminder =
        selectedDays.value ? selectedDays.value
        : eventDefinition.customReminder ? eventDefinition.customReminder
        : eventDefinition.reminder
      const body = {
        summary: eventDefinition.name,
        description:
          descriptionEvent.value ? descriptionEvent.value : getDescriptionToMultiplesReminders.body,
        end: {
          date:
            daysToReminder == 0 ? todayDate.value : subtractDaysFromDate(endDate, daysToReminder)
        },
        start: {
          date:
            daysToReminder == 0 ? todayDate.value : subtractDaysFromDate(endDate, daysToReminder)
        },
        attendees: transformedEmails,
        dueDate: endDate,
        employerId: actualEmployer.value.id
      }
      try {
        await postReminders(body)
        createLog({
          logName: 'sent_reminder_log',
          employerId: actualEmployer.value.id,
          planYearId: actualPlanYearId.value,
          agencyId: actualEmployer.value.agency_id,
          origin: 'bcsCalendar',
          role: userData.value.role ||'',
          body
        })
        googleReminders.value = await getGoogleReminders(actualEmployer.value.id)
        reminderSendSucess.value = true
        recorderReminderSend.value.push({
          name: body.summary,
          success: true
        })
      } catch (error) {
        handleErrors(error)
        reminderSendSucess.value = false
      }
    }

    if (eventDefinition.value && filteredEvents.value.filter((val) => val.selected).length === 0) {
      sendGoogleReminder(eventDefinition.value)
    } else {
      filteredEvents.value.forEach((event) => {
        if (event.selected) {
          sendGoogleReminder(event)
          // event.selected = false
        }
      })
    }
    showRemiderSentModal.value = true
  }

  const getBeforeMonthsEvents = async () => {
    if (planYearChanged.value) {
      let numberStartMonth = parseInt(lastPlanYear.value?.plan_start_date.split('/')[0]) - 1
      let beforeSectionGroup = lastPlanningSectionGroup.value
      let type = sectionType[beforeSectionGroup]
      beforeMonthsEvents.value = await getReminders(numberStartMonth, type)
      compareCalendars(months.value, beforeMonthsEvents.value)
    }
  }

  const allSelectedEventsHaveReminder = computed(() => {
    return filteredEvents.value
      .filter((event) => event.selected)
      .every((event) => event.reminder !== '')
  })

  const scrollTop = () => {
    mainContainer.value.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const stateModal = reactive({
    //flow:
    //reminder - recipients- preview - notificate
    currentStep: 'reminder'
  })

  const recipients = ref([])
  const selectedRecipients = ref([])

  const reminderDaysOption = computed(() => {
    const baseOptions = [
      { value: 0, text: 'Today' },
      { value: 1, text: '1 day before' },
      { value: 3, text: '3 days before' },
      { value: 5, text: '5 days before' },
      { value: 10, text: '10 days before' },
      { value: -1, text: 'Custom date' }
    ]

    return baseOptions.map((option) => {
      return {
        ...option,
        isDisabled: (event) => {
          if (option.value === -1) return false
          const today = new Date()
          const dueDate = new Date(formattedEventDate(event.date))
          const daysDifference = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24))
          return daysDifference <= option.value
        }
      }
    })
  })

  const handleCompleteTextUpdate = (newValue) => {
    descriptionEvent.value = newValue
  }

  const computedFooterModalButton = computed(() => {
    switch (stateModal.currentStep) {
      case 'recipients':
        return {
          name: 'Preview Reminders >',
          click: 'preview'
        }
      case 'preview':
        return {
          name: 'Send Reminders',
          click: ''
        }

      default:
        return {
          name: 'Preview Reminder',
          click: 'preview'
        }
    }
  })

  // other recipient emails input

  const newEmail = ref('')
  const validEmails = ref([])

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const isValidEmail = computed(() => {
    return newEmail.value.length > 0 && emailRegex.test(newEmail.value)
  })

  function checkEmail(event) {
    if (event.key === 'Tab' || event.key === 'Enter' || event.key === ',') {
      newEmail.value = newEmail.value.trim().replace(/,$/, '')
      if (isValidEmail.value && newEmail.value.length > 0) {
        addEmail(newEmail.value)
        newEmail.value = ''
        setTimeout(() => {
          focusInput()
        })
      }
    }
  }

  function addEmail(email) {
    if(email!==''){
      validEmails.value.push(email)
    }
  }

  function focusInput() {
    const input = document.getElementById('email-input')
    input.focus()
  }

  function toggleSortEvents() {
    sortDirectionEvents.value = sortDirectionEvents.value === 'asc' ? 'desc' : 'asc'
  }

  function removeEmail(index) {
    validEmails.value.splice(index, 1)
    focusInput()
  }

  function updateSelectAllEvents() {
    selectAllEvents.value = filteredEvents.value.every((event) => event.selected)
  }

  function closeBanner() {
    changeLastPlanYear(actualPlanYear.value)
    removeKeyDateChanged(months.value)
    changeLastPlanningSectionGroup()
  }

  function formatEventsForPdf(monthsObject) {
    const calendarEvents = []
    for (let monthName in monthsObject) {
      const monthEvents = []
      for (let week in monthsObject[monthName]) {
        for (let eventIndex in monthsObject[monthName][week]) {
          let dateRef = monthsObject[monthName][week][eventIndex]
          monthEvents.push({
            date: formattedEventDate(dateRef.date, { weekday: true }),
            name: dateRef.name
          })
        }
      }
      calendarEvents.push({ month: monthName, events: monthEvents })
    }
    return calendarEvents
  }

  async function downloadCalendarPdf({ printWithCustomLogo = false }) {
    if (!templateID.value) {
      handleErrors('Calendar', new Error('Template ID not found'))
      return
    }
    closeModalDownload()
    toggleLoadingWithMessage(true, {
      title: 'Downloading calendar...',
      body: 'This could take a minute or two. Thanks for your \n patience while we create the good stuff.'
    })

    const ongoingRequirements = await getDefinitionById(
      'ongoingRequirementsDefinition',
      'Ongoing Requirements'
    )

    const colorValidation = isValidHexColor(userData.value.whitelabelColor)
    if (!colorValidation && printWithCustomLogo) {
      handleErrors('Calendar', new Error('Invalid whitelabel color'))
    }
    const whitelabelColor = printWithCustomLogo && userData.value.whitelabelColor && colorValidation
    ? userData.value.whitelabelColor
    : ''
    const data = {
      agencyInfo: {
        name: userData.value.companyName,
        ein: userData.value.ein,
        is_cap_company: userData.value.CAPCompany,
        location: userData.value.agencyLocation
      },
      employer: actualEmployer.value.name,
      title: title.value,
      medicalPlan: Object.values(intakeQuestions.value)[0][4].options[
        Object.values(intakeQuestions.value)[0][4].answer
      ],
      ein: actualEmployer.value.ein,
      controlledGroup: Object.values(intakeQuestions.value)[0][0].options[
        Object.values(intakeQuestions.value)[0][0].answer
      ],
      erisaPlan: Object.values(intakeQuestions.value)[0][1].options[
        Object.values(intakeQuestions.value)[0][1].answer
      ],
      newHire: Object.values(intakeQuestions.value)[0][3].options[
        Object.values(intakeQuestions.value)[0][3].answer
      ],
      coverages: map(Object.values(intakeQuestions.value)[0][5].answer, (ans) =>
        get(Object.values(intakeQuestions.value)[0][5].options, ans)
      ),
      fullTimeEmployers: Object.values(intakeQuestions.value)[0][2].answer,
      planYear: actualPlanYear.value.plan_start_date,
      customLogo: userData.value.logoBase64 || '',
      logoSize: userData.value.logoSize || '',
      printWithCustomLogo,
      whitelabelColor,
      isIntakeComplete: isIntakeComplete(),
      coverPage: {},
      evPage: {
        page: '1',
        description: `The ${printWithCustomLogo ? '' : 'BCS Pro'} compliance calendar provides important due dates pertaining to the ${actualEmployer.value.name}'s group health plan. Refer to this calendar throughout the plan year to make sure you stay on top of plan obligations.`,
        cal: formatEventsForPdf(months.value)
      },
      ongoingRequirementsPage: {
        page: '2',
        mainTitle: 'Ongoing Requirements',
        ongoingRequirements: ongoingRequirements.body
      }
    }

    const CALENDAR_DOWNLOADED = 20
    const eventData = {
      eventId: CALENDAR_DOWNLOADED,
      logName: 'user_activity_log',
      employerId: actualEmployer.value.id,
      agencyId: actualEmployer.value.agency_id
    }

    downloadPdf({
      eventData,
      fileName: `BCS Pro ${actualEmployer.value.name} Calendar.pdf`,
      templateID: templateID.value,
      data
    })
  }

  async function handleDownloadPdf() {
    if (userData.value.logoBase64) {
      whiteLabelModalContent.value = await getDefinitionById(
        'whiteLabelModalContentConfirmation',
        'White label modal content'
      )
      openModalDownloadPdf()
      return
    }
    downloadCalendarPdf({ printWithCustomLogo: false })
  }

  function openModalDownloadPdf() {
    modalRef.value.openModal()
  }

  function closeModalDownload() {
    modalRef.value.closeModal()
  }
</script>

<template>
  <div v-if="showRemiderSentModal" class="col-10 overlay-white-left">
    <div class="card mt-5 d-flex mx-auto">
      <div
        class="card-body modal-reminder-sent text-center d-flex flex-column justify-content-center align-items-center">
        <h5 class="card-title header-22">
          <i class="bi bi-check-circle icon-created"></i>
          <div class="modal-body-notification-title">
            <b>Reminder Created!</b>
          </div>
        </h5>
        <p class="card-text text-16 text-gray-700">Your selected reminders were sent!</p>
        <button @click="showRemiderSentModal = false" class="btn btn-primary">
          <i class="bi bi-x-circle"></i>
          &nbsp; Close
        </button>
      </div>
    </div>
  </div>

  <div
    class="col-10 px-0 mt-2 scroll-fix"
    id="mainContainer"
    ref="mainContainer"
    @scroll="scrollHandler">
    <div>
      <div class="mx-4 mt-3 mb-4">
        <div
          v-if="planYearChanged"
          class="alert alert-success border border-success py-2 box-shadow-alert alert-dismissible fade show"
          role="alert">
          <i class="bi bi-check-circle-fill"></i>
          <strong>
            &nbsp;The {{ actualEmployer.name }} Calendar has been successfully updated based on
            changes made to the employer information. Changed requirements are highlighted below.
          </strong>
          <button
            type="button"
            class="btn-close py-0 pt-4 btn-sm"
            @click="closeBanner()"
            data-bs-dismiss="alert"
            aria-label="Close"></button>
        </div>
      </div>

      <div class="container-fluid pe-0 ps-3.75">
        <div class="sticky-top bg-white">
          <div class="ps-4" style="padding-right: 10px; margin-bottom: 26px">
            <AlertNotification v-model:showAlert="reminderSendSucess">
              <strong>
                <span style="padding-right: 8px">
                  Calendar {{ selectedEventsCount > 1 ? 'reminders have' : 'reminder has' }} been
                  sent!
                </span>
                <a
                  href=""
                  @click="reminderSendSucess = false"
                  data-bs-toggle="modal"
                  data-bs-target="#reminders-sent">
                  View {{ selectedEventsCount > 1 ? 'Reminders' : 'Reminder' }} Sent ({{
                    selectedDays !== '' ? '1' : selectedEventsCount
                  }})
                </a>
              </strong>
            </AlertNotification>
          </div>

          <div class="d-flex justify-content-between pb-2" :class="{ 'pt-4.2': itemsIsSticky }">
            <div class="w-75 d-flex flex-column justify-content-end ps-4">
              <span class="mb-0 text-28">
                <med>{{ actualEmployer.name }} Calendar</med>
              </span>
              <p class="mt-1 mb-0 header-22">
                <med>{{ title }}</med>
              </p>
              <p class="mt-2 mb-2 header-22">
                <med>Plan Year: {{ actualPlanYear?.plan_start_date }}</med>
              </p>
              <template v-if="itemsIsSticky">
                <p class="py-2 text-14 white-space-pre-wrap">
                  <BcsDynamicText text="calendarInstructionalText" />
                </p>
              </template>
            </div>
            <div class="w-75 d-flex flex-column align-items-end me-4">
              <div>
                <button type="button" class="btn btn-info me-2" @click="handleDownloadPdf">
                  <i class="bi bi-download"></i>
                  Download PDF
                </button>
                <button type="button" class="btn btn-primary" @click="exportCalendarToCsv">
                  <i class="bi bi-check2-square"></i>
                  Export CSV
                </button>
              </div>
            </div>
          </div>

          <template v-if="itemsIsSticky">
            <div class="w-100 d-flex justify-content-between pb-2 ps-4 me-4 pe-4">
              <div class="d-flex flex-row align-items-center flex-wrap">
                <div class="mb-3">
                  <button
                    class="btn btn-primary text-14"
                    data-bs-toggle="modal"
                    data-source="calendar"
                    data-bs-target="#addDueDateModal">
                    <i class="bi bi-calendar-plus-fill"></i>
                    &nbsp;Add Due Date Reminders to External Calendars
                  </button>
                </div>

                <div class="ms-4 mb-3">
                  <button
                    data-bs-toggle="modal"
                    @click="showGooogleReminders = true"
                    data-bs-target="#reminders-sent"
                    v-if="googleReminders?.length > 0"
                    class="btn btn-info text-14">
                    <i class="bi bi-check2-circle"></i>
                    <strong>&nbsp; View Reminders Sent ( {{ googleReminders?.length }} )</strong>
                  </button>
                </div>
              </div>
              <div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#pendingFunctionModal"
                  class="btn text-primary text-14">
                  <i class="bi bi-arrows-fullscreen"></i>
                  <strong>&nbsp;&nbsp;View Full Page Calendar</strong>
                </button>
              </div>
            </div>
          </template>
        </div>
        <div class="container-fluid ps-0">
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="py-3 text-14 text-body-color text-center bg-light width-94">
                      <strong>{{ yearOfActualPlan }}</strong>
                    </th>
                    <th
                      scope="col"
                      class="py-3 text-14 text-body-color text-center bg-light width-214"
                      v-for="week in weeks"
                      :key="week">
                      <strong>{{ week }}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(dates, month) in months"
                    :key="month"
                    :class="{ 'active-month': month == actualMonth }">
                    <td class="py-1 bg-light min-h-159 text-14 text-body-color">
                      <strong>{{ month }}</strong>
                    </td>
                    <template v-for="i in weeks.length" :key="i">
                      <td
                        class="p-0 vertical-align-top"
                        :class="{
                          'active-month-last-td': month == actualMonth && i == weeks.length
                        }">
                        <template v-if="dates[`Week ${i}`]">
                          <div class="d-flex flex-column min-h-159 p-2">
                            <template v-for="event in dates[`Week ${i}`]" :key="event.name">
                              <div
                                :id="`${event.body}-${event.name}`"
                                @click="
                                  (eventDefinition = event),
                                    toggleOffCanvas(event.body, event.name, event.date)
                                "
                                class="d-flex justify-content-between my-2 text-12 text-primary hand-cursor">
                                <strong class="mb-0">{{ event.name }}</strong>
                                <strong class="ms-3 mb-0">{{ event.date }}</strong>
                              </div>

                              <div
                                v-if="
                                  recorderReminderSend.some(
                                    (reminder) => reminder.name === event?.name && reminder.success
                                  ) ||
                                  googleReminders?.some((reminder) => reminder.name === event?.name)
                                "
                                class="reminder-sent-pill">
                                Reminder Sent
                              </div>

                              <div v-if="event.dateChanged">
                                <small
                                  class="d-inline-flex mb-3 px-2 py-1 text-success-emphasis bg-success-subtle rounded-2 text-12">
                                  <i>Date changed</i>
                                </small>
                              </div>
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            class="d-flex flex-column min-h-159"
                            :class="{ 'bg-gray-200': month != actualMonth }"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="p-0 position-relative button-top w-0">
          <button
            @click="scrollTop()"
            class="btn btn-primary rounded-circle d-flex justify-content-center align-items-center">
            <div>
              <i class="bi bi-chevron-up position-relative"></i>
              <span class="text-12 position-relative">
                <strong>Top</strong>
              </span>
            </div>
          </button>
        </div>
        <div class="p-0">
          <Footer class="px-4"></Footer>
        </div>
      </div>
    </div>

    <!--Modal add due date STEP 1-->
    <!-- TODO, this needs to be a new component -->
    <div
      class="modal fade modal-event"
      id="addDueDateModal"
      tabindex="-1"
      aria-labelledby="addDueDateModalLabel"
      aria-hidden="true"
      style="--bs-modal-width: 919px">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header px-4 d-flex flex-column bg-gray-200">
            <div class="d-flex w-100 justify-content-between">
              <span class="modal-title header-22" id="addDueDateModalLabel">
                <div v-if="stateModal.currentStep === 'reminder' && !showEventDefinition.show">
                  <i class="bi bi-alarm"></i>
                  <strong>Add Due Date Reminders to External Calendar</strong>
                </div>

                <div v-if="stateModal.currentStep === 'reminder' && showEventDefinition.show">
                  <i class="bi bi-journal-bookmark-fill"></i>
                  <strong>Definition</strong>
                </div>

                <div v-if="stateModal.currentStep === 'recipients'">
                  <i class="bi bi-person-plus-fill"></i>
                  <strong>Add Recipients of Due Date Reminder</strong>
                </div>

                <div v-if="stateModal.currentStep === 'preview'">
                  <i class="bi bi-eye"></i>
                  <strong>Preview Due Date Reminder</strong>
                </div>
              </span>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <p
              v-if="!showFullDescriptionSingleModal && !showEventDefinition.show"
              class="me-auto mb-0 text-16 text-gray-700 mt-1">
              <BcsDynamicText :text="'calendarDescriptionAddReminderText'" />
            </p>
          </div>
          <div
            class="modal-body"
            :style="{ 'margin-bottom': showEventDefinition.show ? '20px' : '' }">
            <div
              v-if="stateModal.currentStep == 'preview'"
              class="preview-body-title"
              style="margin-top: 7px; margin-bottom: 10px">
              Reminders will show as an all day event or task, usually at the top of the recipient’s
              calendar day.
            </div>

            <!--  reminder modal : opt1 (multiple) -->

            <div v-if="stateModal.currentStep === 'reminder' && modalReminderSource == 'calendar'">
              <div
                v-if="hasCustomDateReminder"
                class="d-flex justify-content-center text-center calendar">
                <div>
                  <h4>Custom Reminder Date Selected: {{ selectedDateCalendar }}</h4>
                  <h5>{{ selectedCustomDateEvent?.name }}</h5>

                  <p>Due Date: {{ formattedEventDate(selectedCustomDateEvent?.date) }}</p>

                  <div class="d-flex justify-content-center custom-datepicker-container">
                    <DatePicker
                      v-model:selectedDate="selectedDateCalendar"
                      :dueDate="selectedCustomDateEvent?.date" />
                  </div>
                </div>
              </div>

              <div v-else :class="['table-container-events', { card: !showEventDefinition.show }]">
                <table v-if="!showEventDefinition.show" class="table table-borderless">
                  <thead class="thead-custom-red">
                    <tr>
                      <th scope="col" class="" style="padding-left: 20px">
                        <input
                          v-model="selectAllEvents"
                          @change="toggleAllEvents"
                          class="form-check-input text-16"
                          type="checkbox" />
                      </th>
                      <th scope="col" class="second-column text-secondary">Item</th>
                      <th scope="col" class="third-column text-secondary">
                        Due

                        <i
                          @click="toggleSortEvents"
                          :class="{
                            'bi-sort-down': sortDirectionEvents === 'asc',
                            'bi-sort-up': sortDirectionEvents === 'desc'
                          }"
                          style="padding-left: 5px; color: var(--bs-primary); cursor: pointer"
                          class="bi"></i>
                      </th>
                      <th scope="col" class="text-secondary">Reminder (business days)</th>
                      <th scope="col" class="text-secondary">Reminder Date</th>
                    </tr>
                  </thead>

                  <tbody class="text-14">
                    <tr v-for="event in filteredEvents" :key="event.id" :id="`event-${event.id}`">
                      <td style="padding-left: 20px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="event.selected"
                          @change="updateSelectAllEvents" />
                      </td>
                      <td
                        @click="handleShowDefinitionModal(event)"
                        style="color: var(--bs-primary); cursor: pointer">
                        <strong>{{ event.name }}</strong>
                      </td>
                      <td :style="{ fontWeight: event.selected ? 700 : 'normal' }" class="text-secondary">
                        {{ formattedEventDate(event.date) }}
                      </td>
                      <td>
                        <BcsDropdown
                          :dropdown-options="reminderDaysOption"
                          v-model="event.reminder"
                          @change="(option) => handleSelectChange(option, event)"
                          :context="event" />
                      </td>

                      <td :style="{ fontWeight: event.selected ? 700 : 'normal' }" class="text-secondary">
                        <div v-if="event.reminder === 0">
                          {{ formatDateGoogleReminders(todayDate) }}
                        </div>
                        <div v-else>
                          {{
                            event.reminder !== '' && event.customReminder == null ?
                              formatDateGoogleReminders(
                                subtractDaysFromDate(
                                  formatDateToSendReminder(event.date),
                                  event.reminder
                                )
                              )
                            : event.customReminder !== null && event.reminder == -2 ?
                              formatDateGoogleReminders(
                                subtractDaysFromDate(
                                  formatDateToSendReminder(event.date),
                                  event.customReminder
                                )
                              ) 
                            : '--' 
                          }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- DEFINITION MULTIPLES EVENTS -->
                <div v-else-if="showEventDefinition.show">
                  <div
                    style="margin-top: 13px; padding-left: 26px"
                    class="back-to-main d-flex"
                    @click="showEventDefinition.show = false">
                    <i class="bi bi-chevron-left"></i>
                    Back to Main Reminder Selection
                  </div>

                  <div :style="{ padding: showEventDefinition.show ? '30px' : '' }">
                    <h5>{{ showEventDefinition.event.name }}</h5>
                    <p>Due Date: {{ formattedEventDate(showEventDefinition.event.date) }}</p>

                    <div class="text-14" style="padding-left: 4px">
                      <BcsDynamicText :text="showEventDefinition.event.body"></BcsDynamicText>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- reminder modal: opt2 ( single) -->

            <div
              v-if="stateModal.currentStep === 'reminder' && modalReminderSource == 'OffCanvas'"
              class="">
              <div v-if="!showFullDescriptionSingleModal && selectedDays !== -1">
                <div class="calendar-moda-item-single">
                  <div class="calendar-moda-item-single__header">
                    <div class="calendar-moda-item-single__header-txt">Item</div>

                    <div class="calendar-moda-item-single__header-txt">Due</div>

                    <div class="calendar-moda-item-single__header-txt">Reminder</div>
                  </div>

                  <div class="calendar-moda-item-single__body text-14">
                    <div class="calendar-moda-item-single__body-info">
                      <div class="calendar-moda-item-single__body-info-txt">
                        <strong>{{ eventDefinition.name }}</strong>
                      </div>

                      <div class="calendar-moda-item-single__body-info-txt">
                        {{ formattedEventDate(eventDefinition.date) }}
                      </div>

                      <div class="calendar-moda-item-single__body-info-txt">
                        <BcsDropdown
                          :dropdown-options="reminderDaysOption"
                          v-model="selectedDays"
                          @change="(option) => handleSelectChange(option, event)"
                          :context="event" />
                      </div>
                    </div>

                    <div class="calendar-moda-item-single__body-description">
                      <div class="truncate-text">
                        <BcsDynamicText
                          @update:completeText="handleCompleteTextUpdate"
                          :text="eventDefinition.body"></BcsDynamicText>
                      </div>

                      <div
                        @click="showFullDescriptionSingleModal = true"
                        class="calendar-moda-item-single__body-description-btn">
                        Read full description >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="padding: 0 20px">
                <div
                  v-if="showFullDescriptionSingleModal && selectedDays !== -1"
                  class="show-information-single-modal">
                  <div class="back-to-main d-flex" @click="showFullDescriptionSingleModal = false">
                    <i class="bi bi-chevron-left"></i>
                    Back to Main Reminder Selection
                  </div>

                  <div class="show-information-single-modal__full-information">
                    <h5>{{ eventDefinition.name }}</h5>
                    <p>Due Date: {{ formattedEventDate(eventDefinition.date) }}</p>

                    <div class="text-14" style="padding-left: 4px">
                      <BcsDynamicText :text="eventDefinition.body"></BcsDynamicText>
                    </div>
                  </div>
                </div>
              </div>

              <!-- show calendar -->
              <div
                v-if="selectedDays == -1"
                class="d-flex justify-content-center text-center calendar">
                <div>
                  <h4>Custom Reminder Date Selected: {{ selectedDateCalendar }}</h4>
                  <h5>{{ eventDefinition.name }}</h5>

                  <p>Due Date: {{ formattedEventDate(eventDefinition.date) }}</p>

                  <div class="d-flex justify-content-center custom-datepicker-container">
                    <DatePicker
                      v-model:selectedDate="selectedDateCalendar"
                      :dueDate="eventDefinition.date" />
                  </div>
                </div>
              </div>
            </div>

            <!-- recipients modal -->

            <div class="recipients-modal" v-if="stateModal.currentStep === 'recipients'">
              <b>{{ selectedDays !== '' ? 'Reminder Date:' : 'Reminders:' }}</b>
              <p v-if="selectedDays !== ''" style="margin-top: 4px" class="text-14">
                Note: The date listed here represents the reminder for Form 8508, but you’ve also
                opted that a reminder be sent
                <span v-if="selectedDays == 0">today</span>
                <span v-else>
                  {{ selectedDays }} business {{ selectedDays > 1 ? 'days' : 'day ' }} before
                </span>
                all items that are due for this plan year.
              </p>

              <div v-if="selectedDays !== ''" class="current-reminder text-14">
                <p>
                  <span v-if="selectedDays == 0">Today</span>
                  <span v-else>
                    {{ selectedDays }} business {{ selectedDays > 1 ? 'days' : 'day ' }} before
                  </span>
                  <span>{{ formattedEventDate(eventDefinition.date) }}</span>
                </p>
                <button @click="stateModal.currentStep = 'reminder'" class="btn btn-info text-1">
                  Change
                </button>
              </div>

              <div v-else class="current-reminders text-14">
                You’ve chosen
                <strong>{{ selectedEventsCount }}</strong>
                items to be reminded of from the BCS Compliance Calendar. To review those items, go
                back using the button below.
              </div>

              <div class="internal-recipients">
                <p class="text-14">
                  <b>Internal Recipient(s):</b>
                </p>

                <div class="internal-recipients__options">
                  <div
                    v-for="(recipient, index) in recipients"
                    :key="index"
                    class="internal-recipients__options-option">
                    <input
                      class="form-check-input text-16"
                      type="checkbox"
                      :id="'recipient-' + index"
                      :value="recipient"
                      v-model="selectedRecipients" />
                    <label class="form-check-label text-16" :for="'recipient-' + index">
                      {{ recipient.email }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="other-recipients text-14" style="margin-top: 20px">
                <b>Other Recipient(s)</b>

                <p style="margin-top: 3px">
                  Type an email and press enter or separate with commas. Enter as many emails as
                  needed.
                </p>

                <div
                  class="other-recipients__emails"
                  :class="{
                    'not-valid': !isValidEmail && newEmail.length !== 0
                  }">
                  <div class="valid-email__container" @click="focusInput">
                    <div v-for="(email, index) in validEmails" :key="index" class="valid-email">
                      {{ email }}
                      <i @click.stop="removeEmail(index)" class="bi bi-x-lg"></i>
                    </div>
                    <input
                      id="email-input"
                      type="text"
                      v-model="newEmail"
                      @keyup="checkEmail"
                      @keydown.tab="checkEmail"
                      class="email-input"
                      placeholder="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- preview modal -->

          <div class="preview-modal px-4.5" v-if="stateModal.currentStep === 'preview'">
            <div class="preview-modal__reminder-date fill-preview">
              <div class="preview-modal__reminder-date-title">
                <span class="title-preview" @click="stateModal.currentStep = 'reminder'">
                  <i class="bi bi-pencil-square"></i>
                  Edit
                </span>
                <b class="">{{ selectedDays !== '' ? 'Reminder:' : 'Reminders:' }}</b>
              </div>

              <div class="response-preview">
                <div v-if="selectedDays !== ''">
                  <span v-if="selectedDays == 0">Today</span>
                  <span v-else>
                    {{ selectedDays }} business {{ selectedDays > 1 ? 'days' : 'day ' }} before
                  </span>
                </div>
                <div style="color: var(--bs-gray-700)" v-else>
                  You’ve chosen
                  <strong>{{ selectedEventsCount }}</strong>
                  items to be reminded of from the BCS Compliance Calendar.
                </div>
              </div>
            </div>
            <div class="preview-modal__employer fill-preview">
              <b class="preview-modal__reminder-date-title pl-79">Employer:</b>
              <div>{{ actualEmployer.name }}</div>
            </div>

            <div class="preview-modal__recipients fill-preview">
              <div class="preview-modal__internal-recipients">
                <div class="preview-modal__reminder-date-title">
                  <span class="title-preview" @click="stateModal.currentStep = 'recipients'">
                    <i class="bi bi-pencil-square"></i>
                    Edit
                  </span>
                  <b class="">Internal Recipient(s):</b>
                </div>

                <div>
                  <div v-for="recipient in selectedRecipients" :key="recipient">
                    {{ recipient.email }}
                  </div>
                </div>
              </div>
              <div class="preview-modal__other-recipients" style="margin-top: 8px">
                <b class="preview-modal__reminder-date-title pl-79">Other Recipient(s):</b>

                <div class="other-emails-preview">
                  <div v-for="email in validEmails" :key="email">
                    {{ email }}
                  </div>
                </div>
              </div>
            </div>

            <div class="preview-modal__from fill-preview">
              <b class="preview-modal__reminder-date-title pl-79">From:</b>
              <div><BcsDynamicText text="reminderEmailFrom"></BcsDynamicText></div>
            </div>
            <div class="preview-modal__title fill-preview" v-if="selectedDays !== ''">
              <b class="preview-modal__reminder-date-title pl-79">Title:</b>
              <div>
                {{ eventDefinition.name }} is Due in {{ selectedDays }}
                {{ selectedDays > 1 ? 'days' : 'day ' }}
              </div>
            </div>
            <div class="preview-modal__message" style="padding-bottom: 12px; padding-top: 12px">
              <b class="preview-modal__reminder-date-title pl-79">
                {{ selectedDays !== '' ? 'Message:' : 'Title & Message:' }}
              </b>

              <div v-if="selectedDays !== ''" class="truncate-text">
                Good day! This is a reminder that
                <BcsDynamicText :text="eventDefinition.body"></BcsDynamicText>
              </div>

              <div v-else>
                <BcsDynamicText text="titleMessagePreviewReminder"></BcsDynamicText>
              </div>
            </div>
          </div>

          <!-- notification modal -->

          <!-- footer modal 2 buttons/3 buttons -->
          <div
            v-if="
              stateModal.currentStep === 'reminder' &&
              !showFullDescriptionSingleModal &&
              stateModal.currentStep === 'reminder' &&
              !isCustomDateSelection &&
              !showEventDefinition.show
            "
            class="modal-footer d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-bs-dismiss="modal"
              @click="handleCancel">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="
                (selectedDays === '' && selectedEventsCount < 1) ||
                (selectedEventsCount > 0 && !allSelectedEventsHaveReminder)
              "
              @click="stateModal.currentStep = 'recipients'">
              Choose Recipients >
            </button>
          </div>

          <div
            v-if="
              stateModal.currentStep === 'recipients' ||
              stateModal.currentStep === 'preview' ||
              (stateModal.currentStep === 'reminder' && isCustomDateSelection)
            "
            class="modal-footer d-flex justify-content-between align-items-center">
            <div>
              <button
                @click="
                  !isCustomDateSelection ?
                    (stateModal.currentStep = 'reminder')
                  : handleCancelCustomDate()
                "
                v-if="stateModal.currentStep === 'recipients' || isCustomDateSelection"
                type="button"
                :class="[!isCustomDateSelection ? 'btn-info back-to-reminder-btn btn' : 'go-back']">
                <span>&lt;</span>
                {{ !isCustomDateSelection ? 'Back to Reminder Selection' : 'Cancel and Go Back' }}
              </button>
            </div>
            <div>
              <button
                v-if="!isCustomDateSelection"
                type="button"
                class="btn btn-outline-primary me-2"
                data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :disabled="
                  (stateModal.currentStep === 'recipients' &&
                    selectedRecipients.length === 0 &&
                    !isValidEmail &&
                    validEmails.length === 0) ||
                  (validEmails.length !== 0 && newEmail.length !== 0 && !isValidEmail)
                "
                :data-bs-dismiss="stateModal.currentStep === 'preview' ? 'modal' : null"
                @click="handleClickFooterModal">
                <i
                  v-if="stateModal.currentStep === 'preview'"
                  class="bi bi-calendar-check-fill"></i>
                {{ isCustomDateSelection ? 'Choose Custom Date' : computedFooterModalButton.name }}
              </button>
            </div>
          </div>

          <!-- end footer modal -->

          <!-- end footer modal -->
        </div>
      </div>
    </div>

    <!-- modal succes reminder -->

    <div
      class="modal fade"
      id="reminders-sent"
      tabindex="-1"
      aria-labelledby="reminders-sent"
      aria-hidden="true"
      style="--bs-modal-width: 853px">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header px-4 d-flex flex-column bg-gray-200">
            <div class="d-flex w-100 justify-content-between">
              <span class="modal-title header-22" id="reminders-sent-label">
                <div>
                  <i class="bi bi-calendar-check"></i>
                  <strong>
                    {{
                      selectedDays !== '' && showGoogleReminderInformation.show ?
                        eventDefinition.name
                      : showGoogleReminderInformation.show ?
                        showGoogleReminderInformation.event.name
                      : 'Due Date Reminders Sent'
                    }}
                  </strong>
                </div>
              </span>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>

            <p class="me-auto mb-0 text-16 text-gray-700 mt-1">
              <BcsDynamicText text="titleModalRemindersSent" />
            </p>
          </div>
          <div class="modal-body">
            <div
              v-if="!showGoogleReminderInformation.show"
              :class="['card', { 'table-container-events': selectedDays === '' }]">
              <table class="table table-reminders">
                <thead class="table-reminders-sent thead-custom-red">
                  <tr>
                    <th style="padding-left: 20px" scope="col" class="second-column">Item</th>
                    <th scope="col" class="third-column">
                      Due
                      <i
                        v-if="selectedDays == ''"
                        @click="toggleSortEvents"
                        :class="{
                          'bi-sort-down': sortDirectionEvents === 'asc',
                          'bi-sort-up': sortDirectionEvents === 'desc'
                        }"
                        style="padding-left: 5px; color: var(--bs-primary); cursor: pointer"
                        class="bi"></i>
                    </th>
                    <th scope="col" class="">Reminder Scheduled For</th>
                    <th scope="col" class="">Reminder Sent</th>
                  </tr>
                </thead>

                <tbody class="text-14">
                  <template v-if="showGooogleReminders">
                    <tr v-for="event in sortedEvents" :key="event.id">
                      <td
                        @click="
                          (showGoogleReminderInformation.show = true),
                            (showGoogleReminderInformation.event = event)
                        "
                        style="padding-left: 20px; color: var(--bs-primary); cursor: pointer">
                        <strong>{{ event.name }}</strong>
                      </td>
                      <td>
                        {{ formatDateGoogleReminders(event.due_date) }}
                      </td>
                      <td style="width: 220px">
                        <span v-if="todayDate == event.start_date">
                          <!-- {{ formatDateGoogleReminders(todayDate)}} ( {{ daysDifferenceGoogleReminder(event.due_date, event?.start_date) }}    business days before) -->
                          Today
                        </span>
                        <span v-else>
                          {{ formatDateGoogleReminders(event?.start_date) }}
                          (
                          {{ daysDifferenceGoogleReminder(event.due_date, event?.start_date) }}
                          business days before)
                        </span>
                      </td>
                      <td>
                        {{ formatDateGoogleReminders(event.reminder_date.slice(0, 10)) }}
                      </td>
                    </tr>
                  </template>

                  <template v-else-if="selectedDays === '' && !showGooogleReminders">
                    <tr
                      v-for="(event, index) in selectedEventsInfo"
                      :key="event.name"
                      :class="{ 'border-bottom': index !== selectedEventsInfo.length - 1 }">
                      <td
                        @click="
                          (showGoogleReminderInformation.show = true),
                            (showGoogleReminderInformation.event = event)
                        "
                        style="padding-left: 20px; color: var(--bs-primary); cursor: pointer">
                        <strong>{{ event.name }}</strong>
                      </td>
                      <td>
                        {{ formattedEventDate(event.date) }}
                      </td>
                      <td>
                        <span v-if="event.reminder == 0">Today</span>

                        <span v-else>
                          {{
                            event.customReminder ?
                              `${event.customReminder} business day before`
                            : `${event.reminder} business day before`
                          }}
                        </span>
                      </td>

                      <td>
                        {{
                          new Date()
                            .toLocaleDateString('en-US', {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })
                            .replace(',', '')
                        }}
                      </td>
                    </tr>
                  </template>

                  <tr v-else-if="selectedDays !== '' && !showGooogleReminders">
                    <td
                      @click="showGoogleReminderInformation.show = true"
                      style="padding-left: 20px; color: var(--bs-primary); cursor: pointer">
                      <strong>{{ eventDefinition.name }}</strong>
                    </td>

                    <td>
                      {{ formattedEventDate(eventDefinition.date) }}
                    </td>
                    <td>
                      <div v-if="selectedDays == 0">Today</div>
                      <div v-else>
                        {{ selectedDays }} business {{ selectedDays > 1 ? 'days' : 'day' }} before
                      </div>
                    </td>
                    <td>
                      {{
                        new Date()
                          .toLocaleDateString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })
                          .replace(',', '')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <template v-else-if="showGoogleReminderInformation.show">
              <div
                style="padding-top: 10px; padding-bottom: 15px"
                class="back-to-main d-flex"
                @click="showGoogleReminderInformation.show = false">
                <i class="bi bi-chevron-left"></i>
                Back to Due Date Reminders Sent
              </div>

              <div class="preview-modal__reminder-date fill-preview">
                <div class="preview-modal__reminder-date-title">
                  <b class="">Reminder For:</b>
                </div>

                <div class="response-preview">
                  <span>
                    {{
                      selectedDays !== '' ?
                        eventDefinition.name
                      : showGoogleReminderInformation.event.name
                    }}
                  </span>
                </div>
              </div>
              <div class="preview-modal__employer fill-preview">
                <b class="preview-modal__reminder-date-title">Due Date:</b>
                <div v-if="selectedDays !== ''">
                  {{ formattedEventDate(eventDefinition.date) }}
                </div>

                <div v-else>
                  {{
                    showGoogleReminderInformation.event.date ?
                      formattedEventDate(showGoogleReminderInformation.event.date)
                    : formatDateGoogleReminders(showGoogleReminderInformation.event.due_date)
                  }}
                </div>
              </div>

              <div class="preview-modal__from fill-preview">
                <b class="preview-modal__reminder-date-title">Reminder Scheduled For:</b>
                <div v-if="showGoogleReminderInformation.event.reminder == 0">
                  {{ formatDateGoogleReminders(todayDate) }} ({{
                    daysDifferenceGoogleReminder(
                      formatDateToSendReminder(showGoogleReminderInformation.event.date),
                      todayDate
                    )
                  }}
                  business day before )
                </div>

                <div v-else-if="showGoogleReminderInformation.event.startDate">
                  {{ formatDateGoogleReminders(showGoogleReminderInformation.event.startDate) }} (
                  {{
                    showGoogleReminderInformation.event.reminder ?
                      showGoogleReminderInformation.event.reminder
                    : showGoogleReminderInformation.event.customReminder
                  }}
                  business day before )
                </div>

                <div v-else-if="selectedDays !== ''">
                  <div v-if="selectedDays == 0">
                    {{ formatDateGoogleReminders(todayDate) }} (
                    {{
                      daysDifferenceGoogleReminder(
                        formatDateToSendReminder(eventDefinition.date),
                        todayDate
                      )
                    }}
                    business day before)
                  </div>

                  <div v-else>
                    {{
                      formatDateGoogleReminders(
                        subtractDaysFromDate(
                          formatDateToSendReminder(eventDefinition.date),
                          selectedDays
                        )
                      )
                    }}
                    ({{ selectedDays }} business day before)
                  </div>
                </div>

                <div v-else>
                  {{ formatDateGoogleReminders(showGoogleReminderInformation.event?.start_date) }} (
                  {{
                    daysDifferenceGoogleReminder(
                      showGoogleReminderInformation.event.due_date,
                      showGoogleReminderInformation.event?.start_date
                    )
                  }}
                  business days before)
                </div>
              </div>

              <div class="preview-modal__from fill-preview">
                <b class="preview-modal__reminder-date-title">Reminder Sent:</b>
                <div>
                  {{
                    showGoogleRedminderInformation?.event?.reminder_date ?
                      formatDateGoogleReminders(
                        showGoogleReminderInformation?.event?.reminder_date.slice(0, 10)
                      )
                    : new Date()
                        .toLocaleDateString('en-US', {
                          weekday: 'short',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })
                        .replace(',', '')
                  }}
                </div>
              </div>

              <div class="preview-modal__from fill-preview">
                <b class="preview-modal__reminder-date-title">Employer:</b>
                <div>{{ actualEmployer.name }}</div>
              </div>

              <div class="preview-modal__recipients fill-preview">
                <div class="preview-modal__other-recipients" style="margin-top: 8px">
                  <b class="preview-modal__reminder-date-title">Other Recipient(s):</b>

                  <div class="other-emails-preview">
                    <div>
                      {{
                        showGoogleReminderInformation.event.target_emails ?
                          showGoogleReminderInformation.event.target_emails
                        : validEmails.filter((email) => email.trim() !== '').join(', ')
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="preview-modal__title fill-preview" style="border: none !important">
                <b class="preview-modal__reminder-date-title">From:</b>
                <div>
                  <BcsDynamicText text="reminderEmailFrom"></BcsDynamicText>
                </div>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- white label modal -->
  <WhiteLabelModal
    :handleDownload="downloadCalendarPdf"
    :onCloseModal="closeModalDownload"
    :logoSize="userData.logoSize"
    :logoBase64="userData.logoBase64"
    ref="modalRef" />
</template>

<style scoped lang="scss">
  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%235C0CB8' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3e%3cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");
    border-color: var(--bs-gray-600);
    color: var(--bs-gray-600);
    width: 195px;
  }

  .vertical-align-top {
    vertical-align: top;
  }

  .w-0 {
    width: 0;
  }

  .bg-gray-200 {
    background-color: var(--bs-gray-200);
  }

  .text-body-color {
    color: #212529;
  }

  .width-94 {
    width: 94px;
  }

  .width-214 {
    width: 214px;
  }

  .min-h-159 {
    min-height: 159px;
  }

  .active-month {
    border-left: 4px solid #a193cc;

    td {
      background-color: #f7f1fd80;
      border-top: 1px solid #521b98;
      border-bottom: 1px solid #521b98;
      border-right: 1px solid rgb(222, 226, 230);
      border-left: 1px solid rgb(222, 226, 230);
    }
  }

  .active-month-last-td {
    border-right: 1px solid #521b98 !important;
  }

  .box-shadow-alert {
    box-shadow: 0px 8px 16px 0px #00000026;
  }

  .modal-title {
    i {
      margin-right: 11px;
    }
  }

  .scroll-fix {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 94vh;
  }

  .list-group-item-calendar:hover {
    cursor: pointer;
    background-color: #521b98;
    color: white;

    .btn-calendar {
      display: block !important;
    }
  }

  .list-group-item-calendar.active {
    background-color: #5c0cb8;
    color: white;
  }

  .current-reminder {
    border: 1px solid var(--bs-gray-300);
    padding: 12px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 34px;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .internal-recipients {
    margin-top: 20px;

    &__options {
      &-option {
        margin-bottom: 8px;

        input {
          margin-right: 8px;
        }
      }
    }
  }

  .other-recipients {
    &__emails {
      border: 1px solid var(--bs-gray-700);
      padding: 10px;
      border-radius: 4px;
    }
  }

  .valid-email__container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    height: auto;
    cursor: text;
  }

  .valid-email {
    padding: 6px 12px 6px 12px;
    background-color: #f7f1fd;
    border: 1px solid #5c0cb8;
    border-radius: 40px;
    color: #5c0cb8;
    display: flex;
    align-items: center;
    gap: 15px;

    i {
      cursor: pointer;
      -webkit-text-stroke: 1px;
    }
  }

  .email-input {
    border: none;
    background-color: transparent;
    outline: none;
    flex-grow: 1;
    min-width: 50px;
  }

  .time {
    display: flex;
    gap: 4px;
    align-items: center;

    b {
      color: #9ca3af;
      font-size: 12px;
    }
  }

  .preview-modal {
    &__title,
    &__reminder-date,
    &__message,
    &__internal-recipients,
    &__other-recipients,
    &__employer,
    &__from {
      display: flex;
      font-size: 14px;
    }
  }

  .preview-body-title {
    display: flex;
    align-items: center;
    gap: 60px;

    .time-container {
      padding: 16px 23px;
      border: 1px solid var(--bs-gray-400);
      border-radius: 14px;
      box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.1));
    }

    .circle {
      width: 10px;
      height: 10px;
      background-color: #f9d06d;
      border-radius: 50%;
    }
  }

  .calendar-moda-item-single {
    border: 1px solid var(--bs-gray-400);
    border-radius: 4px;

    &__header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      font-size: 16px;
      font-weight: 700;
      background-color: var(--bs-gray-100);
      padding: 10px 0 10px 20px;
      border-bottom: 1px solid var(--bs-gray-400);
    }

    &__body {
      &-info {
        display: grid;
        align-items: center;
        padding: 10px 0 10px 20px;
        grid-template-columns: repeat(3, 1fr);

        select {
          width: 195px;
        }
      }

      &-description {
        padding: 0px 250px 9px 20px;

        &-btn {
          margin-top: 20px;
          font-weight: 700;
          color: var(--bs-primary);
          cursor: pointer;
          margin-bottom: 14px;
        }
      }
    }
  }

  .show-information-single-modal {
    padding-top: 13px;
    padding-bottom: 29px;

    &__full-information {
      margin-top: 29px;
    }
  }

  .back-to-main {
    color: var(--bs-primary);
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;

    i {
      padding-right: 5px;
    }
  }

  .back-to-reminder-btn {
    border-color: var(--bs-primary);

    span {
      color: #212529;
    }
  }

  .preview-modal__reminder-date-title {
    min-width: 240px;
  }

  .pl-79 {
    padding-left: 79px;
  }

  .fill-preview {
    border-bottom: 1px solid var(--bs-gray-400);
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .truncate-text {
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title-preview {
    padding-right: 26px;
    color: var(--bs-primary);
    font-weight: 700;
    cursor: pointer;

    i {
      padding-right: 5px;
    }
  }

  .thead-custom-red th {
    background-color: var(--bs-gray-100);
    border-radius: 4px;
    border-bottom: 1px solid var(--bs-gray-400);
  }

  .table-reminders-sent th {
    border-radius: 4px;
    border-bottom: 1px solid var(--bs-gray-400);
  }

  .table-reminders {
    margin-bottom: 0 !important;
  }

  .second-column {
    width: 250px !important;
  }

  .third-column {
    width: 190px !important;
  }

  .current-reminders {
    color: var(--bs-gray-700);
  }

  .recipients-modal {
    padding: 0 40px;
  }

  .modal-event {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .border-bottom {
    border-bottom: 1px solid #c5cdd4;
  }

  .go-back {
    color: var(--bs-primary);
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    background: none;
    border: none;
  }

  .calendar {
    margin-top: 12px;
    margin-bottom: 12px;
    h4 {
      font-size: 20px;
    }
    h5,
    p {
      font-size: 16px;
    }

    h5,
    h4 {
      font-weight: 700;
    }

    p {
      font-weight: 400;
    }
  }

  .table-container-events {
    height: auto;
    height: 364px;
    overflow-y: scroll;
  }

  .reminder-sent-pill {
    color: #198754;
    background-color: #d1e7dd;
    border-radius: 4px;
    width: 96px;
    font-size: 12px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }

  .not-valid {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.75rem);
  }

  .modal-reminder-sent {
    .icon-created {
      font-size: 50px;
    }

    .card-title {
      color: #198754;
    }
  }
</style>
