import { useQuestionsStore } from '../stores/QuestionsStore'
import { storeToRefs } from 'pinia'
import { RequestService } from '../services/RequestService'
import { useEmployerStore } from '../stores/EmployerStore'

const { put } = RequestService()

export const ScoreCalculationsService = () => {
  const questionStore = useQuestionsStore()
  const employerStore = useEmployerStore()

  const { assessmentQuestions } = storeToRefs(questionStore)
  const { showQuestion, getAnswerById, hideDueICHRA } = questionStore
  const { actualEmployer } = storeToRefs(employerStore)

  function calculateSectionScore() {
    const scores = []
    Object.values(assessmentQuestions.value).forEach((section) => {
      let wrongAnswers = []
      let sectionShort = section[0].short_title
      let sectionWeight = section[0].section_weight
      let dollarRisk = section[0].dollar_risk

      if (!hideDueICHRA(sectionShort)) {
        //first we filter out the non visible questions
        let visibleQuestions = section.filter((val) => {
          if (val.hideLogic !== null) {
            return showQuestion(val).show
          }
          return true
        })

        //And find the wrong answers
        wrongAnswers = visibleQuestions.filter((val) => {
          if (val.wrongAnswer !== null) {
            if (val.answer !== null) {
              let storedAnswer = val.answer
              let includes = false
              let wrongOptions = JSON.parse(val.wrongAnswer).values
              if (storedAnswer instanceof Array) {
                includes = storedAnswer.some((r) => wrongOptions.includes(r))
              } else {
                includes = wrongOptions.includes(storedAnswer)
              }
              if (includes) {
                return val
              }
            }
          }
        })

        //now we make the calculations
        const average =
          visibleQuestions.reduce((a, b) => a + parseFloat(b.score), 0) / visibleQuestions.length
        //const percFactorPerQuestion = 1 / sectionQuestions.length
        const questionWeights = visibleQuestions.map((question) => {
          let scoreContribution = 0
          if (question.answer !== null && question.answer !== undefined && question.answer !== '') {
            let includeQuestion = false
            if (question.answer instanceof Array && question.answer.length > 0) {
              includeQuestion = true
            } else if (!(question.answer instanceof Array) && !isNaN(question.answer)) {
              includeQuestion = true
            }
            if (includeQuestion) {
              let questionWeight = question.score / average
              let questionScore = 0.01 * (questionWeight * (100 / visibleQuestions.length))
              scoreContribution = questionScore * 10
            }
          }
          return [question.id, scoreContribution]
        })
        //now we filter the wrong answers from the question weights using the wrongAnswers array
        let sectionScore = questionWeights.reduce((a, b) => {
          if (wrongAnswers.length > 0) {
            if (wrongAnswers.some((val) => val.id === b[0])) {
              return a + 0
            } else {
              return a + parseFloat(b[1])
            }
          } else {
            return a + parseFloat(b[1])
          }
        }, 0)
        //Finally we calculate the dollar risk using the json object inside the sections and the response to teu questions
        let monRisk = calculateMonetaryRisk(dollarRisk)
        scores.push({
          name: sectionShort,
          weight: sectionWeight,
          score: (sectionScore * 10).toFixed(2), //Math.round(sectionScore * 10),
          color:
            sectionScore >= 7.5 ? 'success'
            : sectionScore >= 5.0 ? 'warning'
            : 'danger',
          dollarRisk: monRisk - ((monRisk * sectionScore) / 10).toFixed(2)
        })
      }
    })
    return scores
  }

  const calculateMonetaryRisk = (riskJson) => {
    const riskMatrix = JSON.parse(riskJson)
    //we load the answer for nrOfEmployees and insuranceType
    let currentInsurance
    let insuranceType = getAnswerById(11)
    let noEmployees = getAnswerById(9)
    //first we check if either of the answers is null
    if (
      noEmployees === null ||
      insuranceType === null ||
      noEmployees === undefined ||
      insuranceType === undefined
    ) {
      return 0
    }
    //now we check the insurance type
    switch (insuranceType) {
      case 0:
        currentInsurance = 'FI' // Fully insured
        break
      case 1:
        currentInsurance = 'SI' // Self insured
        break
      case 2:
        currentInsurance = 'LF' // Level funded
        break
      case 3:
        currentInsurance = 'DK' // Don't know
        break
    }
    //no we go over the risk matrix and calculate the risk
    let returnValue = null
    if (riskMatrix) {
      riskMatrix.forEach((risk) => {
        if (risk.insuranceType.includes(currentInsurance) && returnValue === null) {
          let noEmployeesArray = risk.employees.split(':')
          if (noEmployeesArray.length === 2) {
            let relation = noEmployeesArray[0]
            if (relation === 'under' && noEmployees < parseInt(noEmployeesArray[1])) {
              returnValue = risk.value
            } else if (relation === 'over' && noEmployees > parseInt(noEmployeesArray[1])) {
              returnValue = risk.value
            }
          } else {
            returnValue = risk.value
          }
        }
      })
    }
    //here we calculate
    /*SI/LF/DK/FI */
    return returnValue
  }

  function calculateOverallScore(scores) {
    //The below values are to validate here that the employer has the ichra
    let ichraQuestion = getAnswerById(12)
    if (ichraQuestion !== undefined) {
      if (typeof ichraQuestion === 'object' && ichraQuestion.value !== undefined) {
        ichraQuestion = JSON.parse(ichraQuestion.value)
      }
      let includesIchra = ichraQuestion.includes(9)
      let sectionsAvg = scores.reduce((acc, val) => {
        if (!includesIchra && val.name === 'ICHRA') {
          return acc
        } else {
          return acc + parseFloat(val.weight)
        }
      }, 0)
      sectionsAvg = sectionsAvg / (includesIchra ? scores.length : scores.length - 1)

      let weightedAvgScore = scores.reduce((acc, val) => {
        if (!includesIchra && val.name === 'ICHRA') {
          return acc
        } else {
          let weight = parseFloat(val.weight)
          let weighRelative = weight / sectionsAvg
          let weightedAvgScore = weighRelative * val.score
          return acc + weightedAvgScore
        }
      }, 0)
      let finalScore = weightedAvgScore / (includesIchra ? scores.length : scores.length - 1)
      return finalScore.toFixed(2)
    } else {
      return 0
    }
  }

  async function saveScore(employerId) {
    const scores = calculateSectionScore()
    const totalComplianceRisk = scores.reduce((acc, val) => {
      return acc + val.dollarRisk
    }, 0)
    actualEmployer.value['total_compliance_risk'] = totalComplianceRisk
    actualEmployer.value['percentage_compliance_risk'] = calculateOverallScore(scores)
    await put(
      'updateEmployer',
      `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers&id=${employerId}`,
      actualEmployer.value
    )
  }

  return {
    calculateSectionScore,
    calculateOverallScore,
    saveScore
  }
}
