<script setup>
  import { computed } from 'vue'

  const props = defineProps(['percent'])

  const fillColor = computed(() => {
    if (props.percent >= 75) return 'success'
    if (props.percent >= 50) return 'warning'
    return 'danger'
  })
</script>

<template>
  <div class="circle" :style="`--color-fill: var(--bs-${fillColor})`">
    <div class="percent">
      <svg>
        <circle cx="78" cy="78" r="70"></circle>
        <circle cx="78" cy="78" r="70" :style="`--percent: ${props.percent}`"></circle>
      </svg>
      <div class="number">
        <h3>{{ props.percent }}%</h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .circle .percent {
    position: relative;
  }

  .circle {
    --percent: 0;
    --radio: 70px;
    --stroke-width: 16px;
    --cir-length: calc(2 * 3.141592 * var(--radio));
    --container-size: calc(var(--radio) * 2 + var(--stroke-width));
  }

  .circle svg {
    position: relative;
    width: var(--container-size);
    height: var(--container-size);
    transform: rotate(-90deg);
  }

  .circle svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #e9ecef;
    stroke-width: var(--stroke-width);
    stroke-linecap: round;
  }

  .circle svg circle:last-of-type {
    stroke-dasharray: var(--cir-length);
    stroke-dashoffset: calc(var(--cir-length) - (var(--cir-length) * var(--percent)) / 100);
    stroke: var(--color-fill);
  }

  .circle .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 120px;
  }

  .circle .number h3 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 40px !important;
    color: var(--color-fill);
    min-width: 120px !important;
  }
</style>
