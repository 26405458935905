<script setup>
import { ref, watch, onMounted } from 'vue'

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Search...'
  },
  items: {
    type: Array,
    required: true
  },
  // Array of field names to search in each item
  // Example: ['name', 'description', 'email']
  searchFields: {
    type: Array,
    required: true
  },
  // Field name to display in the dropdown
  // Example: 'name'
  arrayTerm: {
    type: String,
    default: 'name'
  },
  maxSuggestions: {
    type: Number,
    default: 10
  },
  searchButtonText: {
    type: String,
    default: 'Search'
  },
  clearButtonColor: {
    type: String,
    default: 'var(--bs-primary)'
  },
  showResults: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['search', 'select', 'clear'])

const searchTerm = ref('')
const effectiveSearchTerm = ref('')
const suggestions = ref([])
const showSearchResults = ref(false)
const totalResults = ref(0)
const searchContainer = ref(null)
const isFocused = ref(false)

const handleClickOutside = (event) => {
  if (searchContainer.value && !searchContainer.value.contains(event.target)) {
    suggestions.value = []
    isFocused.value = false
  }
}

onMounted(() => {    
  document.addEventListener('click', handleClickOutside)
})

const handleFocus = () => {
  isFocused.value = true
}

const handleBlur = (event) => {
  if (searchContainer.value && !searchContainer.value.contains(event.relatedTarget)) {
    isFocused.value = false
  }
}

const performSearch = () => {
  searchTerm.value = searchTerm.value.trim()
  if(searchTerm.value.length <1)   return
  if (!searchTerm.value) {
    suggestions.value = []
    showSearchResults.value = false
    effectiveSearchTerm.value = ''
    emit('clear')
    return
  }
  const term = searchTerm.value.toLowerCase()
  const results = props.items.filter(item => 
    props.searchFields.some(field => 
      String(item[field]).toLowerCase().includes(term)
    )
  )

  totalResults.value = results.length
  showSearchResults.value = true
  effectiveSearchTerm.value = searchTerm.value
  suggestions.value = []
  emit('search', { term: searchTerm.value, results })
}

const updateSuggestions = () => {
  if (!searchTerm.value) {
    suggestions.value = []
    return
  }

  const term = searchTerm.value.toLowerCase()
  suggestions.value = props.items
    .filter(item =>
      props.searchFields.some(field =>
        String(item[field]).toLowerCase().includes(term)
      )
    )
    .map(item => ({
      text: item[props.arrayTerm],
      item
    }))
    .filter((value, index, self) => 
      self.findIndex(s => s.text === value.text) === index
    )
    .slice(0, props.maxSuggestions)
}

const selectSuggestion = (suggestion) => {
  searchTerm.value = suggestion.text
  effectiveSearchTerm.value = suggestion.text
  performSearch()
  emit('select', suggestion.item)
}

const clearSearch = () => {
  searchTerm.value = ''
  effectiveSearchTerm.value = ''
  suggestions.value = []
  showSearchResults.value = false
  emit('clear')
}

watch(() => props.items, () => {
  updateSuggestions()
}, { deep: true })

defineExpose({
  clearSearch
})

</script>

<template>
  <div class="input-group search-group" :class="{ 'is-focused': isFocused }">
    <div class="search-container" ref="searchContainer" tabindex="0">
      <input
        type="text"
        v-model="searchTerm"
        @input="updateSuggestions"
        @keyup.enter="performSearch"
        @focus="handleFocus"
        @blur="handleBlur"
        class="form-control search-input"
        :placeholder="placeholder"
        aria-label="Search" />
      <button v-if="searchTerm" @click="clearSearch" class="clear-button">
        <i class="bi bi-x-circle-fill" :style="{ color: clearButtonColor }"></i>
      </button>
      <ul v-if="suggestions.length > 0" class="suggestions-list">
        <li
          v-for="suggestion in suggestions"
          :key="suggestion.text"
          @click="selectSuggestion(suggestion)">
          {{ suggestion.text }}
        </li>
      </ul>
    </div>
    <button 
      type="button" 
      class="btn btn-primary search-button" 
      @click="performSearch">
      <i class="bi bi-search"></i>
      {{ searchButtonText }}
    </button>
  </div>

  <div v-if="showSearchResults && showResults" class="results-for-search">
    <h5 class="search-results-text">
      {{ totalResults }} {{ totalResults === 1 ? 'Result' : 'Results' }} for
      {{ effectiveSearchTerm }}
    </h5>
    <span @click="clearSearch" class="clear-search-link">Clear Search</span>
  </div>
</template>

<style scoped lang="scss">
.search-group {
  &.is-focused {
    border-color: #7B61FF66;
    outline: 0;
    border-radius: 4px;
    box-shadow: 0 0 0 0.25rem #7B61FF66;
  }
}

.form-control:focus{
  box-shadow: none!important;
}

.search-container {
  position: relative;
  flex: 1;
}

.search-input {
  padding-right: 30px;
  border-radius: 4px 0 0 4px;
  height: 38px;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: var(--bs-gray-800);
  }
}

.search-button {
  border-radius: 0 4px 4px 0;
  height: 38px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;

  li {
    padding: 10px;
    cursor: pointer;
    color: var(--bs-primary);

    &:hover {
      background-color: var(--bs-gray-100);
    }
  }
}

.results-for-search {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 50px;

  .search-results-text {
    font-weight: 700;
    margin-bottom: 0;
    flex: 1;
    padding-right: 15px;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .clear-search-link {
    color: var(--bs-primary);
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    flex-shrink: 0;
    align-self: flex-start;
  }
}
</style>