<script setup>
  import elipseImage from '@/assets/images/elipse.png'
  import medidorRotadoIcon from '@/assets/images/icon-medidor-rotado.svg'

  const props = defineProps(['value', 'keyName'])

  function interpolate(points, value, key1 = 'x', key2 = 'y') {
    // if value is minor or equal than the first point, return the first point
    if (value <= points[0][key1]) {
      return points[0][key2]
    }
    // if value is major or equal than the last point, return the last point
    if (value >= points.at(-1)[key1]) {
      return points.at(-1)[key2]
    }

    // find the two points where the value is between
    let point1, point2
    for (let i = 0; i < points.length - 1; i++) {
      if (value >= points[i][key1] && value <= points[i + 1][key1]) {
        point1 = points[i]
        point2 = points[i + 1]
        break
      }
    }

    // interpolate the value
    const m = (point2[key2] - point1[key2]) / (point2[key1] - point1[key1])
    const b = point1[key2] - m * point1[key1]
    return m * value + b
  }

  function getRightBottom(value) {
    const points = [
      { v: 0, r: 46, b: -12 },
      { v: 8.5, r: 46, b: -7.5 },
      { v: 17, r: 45, b: -3.5 },
      { v: 25, r: 44, b: 1 },
      { v: 33.5, r: 42, b: 4 },
      { v: 42, r: 39.5, b: 6 },
      { v: 50, r: 37, b: 6 },
      { v: 58.5, r: 34.5, b: 6 },
      { v: 67, r: 32.5, b: 4 },
      { v: 75, r: 30.5, b: 1 },
      { v: 83.5, r: 28.5, b: -3 },
      { v: 92, r: 28, b: -8 },
      { v: 100, r: 27.5, b: -12 }
    ]
    return {
      r: interpolate(points, value, 'v', 'r'),
      b: interpolate(points, value, 'v', 'b')
    }
  }

  const getMedidorStyles = (value) => {
    const rotation = -180 + (180 * value) / 100
    const positionRight = getRightBottom(value).r
    const positionBottom = getRightBottom(value).b

    return `
      transform: rotate(${rotation}deg);
      right: ${positionRight}%;
      bottom: ${positionBottom}%;
    `
  }

  // const getShape1Styles = (value) => {
  //   const points = [
  //     { x: 0, y: 999999999 },
  //     { x: 8.5, y: -93 },
  //     { x: 10, y: -77},
  //     { x: 15, y: -49},
  //     { x: 17, y: -43 },
  //     { x: 25, y: -25 },
  //     { x: 33.5, y: -14.5 },
  //     { x: 42, y: -6.9 },
  //     { x: 50, y: -0 },
  //     { x: 58.5, y: 7 },
  //     { x: 67, y: 15 },
  //     { x: 75, y: 25 },
  //     { x: 83.5, y: 43 },
  //     { x: 92, y: 92 },
  //     { x: 100, y: 999999999 }
  //   ]
  //   let rotation = -180 + (180 * value) / 100
  //   if (value <= 50) {
  //     rotation = (rotation*-1) - 90
  //   } else {
  //     rotation = (rotation+90) * -1
  //   }
  //   const positionLeft = interpolate(points, value)

  //   return `
  //     transform: skew(${rotation}deg);
  //     left: ${positionLeft}%;
  //   `
  // }
</script>

<template>
  <div class="position-relative d-flex justify-content-center">
    <!-- style="border-bottom: 1px solid green;" -->

    <!-- <div class="shape-2"></div> -->
    <div class="container-for-graphic">
      <!-- <div class="shape-1" :style="getShape1Styles(props.value)"></div> -->
      <div
        role="progressbar"
        :id="`progress-${props.keyName}`"
        aria-valuenow="33"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 200px; height: 100px"
        :class="'value-' + props.value"
        :style="`--value: ${props.value}; --primary: ${
          props.value >= 75 ? '#198754'
          : props.value >= 50 ? '#ffc107'
          : '#dc3545'
        }`">
        <img :src="elipseImage" style="width: 60%; position: absolute" />
      </div>
      <img
        :src="medidorRotadoIcon"
        width="52"
        class="medidor"
        :style="getMedidorStyles(props.value)" />
    </div>
  </div>
</template>

<style scoped>
  @keyframes progress {
    0% {
      --percentage: 0;
    }
    100% {
      --percentage: var(--value);
    }
  }

  @property --percentage {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
  }

  [role='progressbar'] {
    --percentage: var(--value);
    --primary: #198754; /*#dc3545*/ /*#ffc107*/
    --secondary: #dee2e6;
    --size: 200px;
    /* animation: progress 2s 0.5s forwards; */
    width: var(--size);
    aspect-ratio: 2 / 1;
    border-radius: 50% / 100% 100% 0 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  [role='progressbar']::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: conic-gradient(
      from 0.75turn at 50% 100%,
      var(--primary) calc(var(--percentage) * 1% / 2),
      transparent calc(var(--percentage) * 1% / 2) calc(var(--percentage) * 1% / 2 + 1%),
      var(--secondary) calc(var(--percentage) * 1% / 2 + 1%)
    );
    mask: radial-gradient(at 50% 100%, #000 54%, #000 54.5%);
    mask-mode: alpha;

    /* -webkit-mask: radial-gradient(at 0% 100%, #0000 0%, #000 0.5%); */
    -webkit-mask: radial-gradient(at 50% 100%, #0000 54%, #000 54.5%);

    -webkit-mask-mode: alpha;
  }

  .value-0::before {
    background: conic-gradient(from 0.75turn at 50% 100%, #dc3545 0%, #dee2e6 0%) !important;
  }

  .shape-1 {
    position: absolute;
    left: 0px;

    height: 100px;
    width: 100px;
    border-bottom: 1px solid red;
    border-right: 1px solid red;
  }

  .shape-2 {
    position: absolute;
    width: 0px;
    height: 100px;
    border-right: 1px solid blue;
  }

  .container-for-graphic {
    width: 200px;
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .medidor {
    position: absolute;
    right: 37%;
    bottom: -10%;
  }
</style>
