<script setup>
  import { ref, computed, onMounted, watch, nextTick } from 'vue'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { useEmployerStore } from '../stores/EmployerStore'
  import { RemindersCalendarService } from '../services/RemindersCalendarService'
  import { storeToRefs } from 'pinia'
  import BcsTooltip from './BcsTooltip.vue'
  import { RequestService } from '../services/RequestService'

  const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const SECTION_TYPES = {
    'LARGE GROUP - SELF INSURED': 2,
    'LARGE GROUPS': 0,
    'SMALL GROUP - SELF INSURED': 1,
    'SMALL GROUP': 3
  }

  const props = defineProps({
    allowedDates: {
      type: Array,
      default: () => []
    },
    startDate: {
      type: Date,
      required: false,
      default: () => new Date()
    },
    endDate: {
      type: Date,
      required: false,
      default: () => new Date()
    }
  })

  const emit = defineEmits(['dateSelected', 'nextReminders', 'dateRange', 'calendarType'])

  const questionStore = useQuestionsStore()
  const employerStore = useEmployerStore()
  const { getReminders } = RemindersCalendarService()
  const { loadQuestionsAndAnswers, getPlanningSectionGroup } = questionStore
  const { handleErrors } = RequestService()
  const { actualPlanYear } = storeToRefs(employerStore)

  const currentMonth = ref('')
  const remindersMonths = ref()
  const filteredReminders = ref([])
  const nextEvents = ref([])
  const nextEventsPlanYear = ref([])
  const year = ref()
  const endOfPlanYear = ref()
  const calendarDays = ref([])
  const activeTooltipDate = ref(null)
  const calendarType = ref()

  const dateUtils = {
    getMonday(date) {
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1)
      return new Date(date.setDate(diff))
    },

    formatDate(dateString) {
      const [month, day] = dateString.split('/').map(Number)
      const date = new Date(2000, month - 1, day)
      const monthAbbr = date.toLocaleString('en-US', { month: 'short' })
      return `${monthAbbr} ${day.toString().padStart(2, '0')}`
    },

    convertDate(dateString) {
      if (!actualPlanYear.value?.plan_start_date) {
        handleErrors('actualPlanYear or plan_start_date is undefined')
        return new Date()
      }
      const [month, day] = dateString.split('/').map(Number)
      const [planMonth, planDay, planYear] = actualPlanYear.value.plan_start_date
        .split('/')
        .map(Number)
      const date = new Date(planYear, month - 1, day)
      const planStartDate = new Date(planYear, planMonth - 1, planDay)
      if (date < planStartDate) {
        date.setFullYear(planYear + 1)
      }
      return date
    }
  }

  const effectiveStartDate = computed(() => {
    if (!actualPlanYear.value?.plan_start_date) {
      return props.startDate
    }
    const [month, day, year] = actualPlanYear.value.plan_start_date.split('/').map(Number)
    const planDate = new Date(year, month - 1, day)
    const today = new Date()
    return planDate <= today ? today : planDate
  })
  
const effectiveEndDate = computed(() => {
  const planStartDateStr = actualPlanYear.value?.plan_start_date
  if (!planStartDateStr) {
    return props.endDate
  }
  const [month, day, year] = planStartDateStr.split('/').map(Number)
  const planStartDate = new Date(year, month - 1, day)
  const today = new Date()
  const diffDays = Math.floor((endOfPlanYear.value - today) / (1000 * 60 * 60 * 24))

  // TYPE OF CALENDAR 
  // 1 : today > planStartDate = ( Current Plan Year ) Plan year is in progress. We're currently within the normal plan year.
  // 2 : planStartDate > today (Future Plan Year) Plan year hasn't started yet. We're showing the upcoming plan year. 
  // 3 : planStartDate <= today && diffDays < 30 && endOfPlanYear.value >= today:  Plan year ending soon. Less than 30 days remain in the current plan year.
  // 4 : endOfPlanYear < today = Plan year has already ended.
  
  let  end
  if (endOfPlanYear.value < today) {
    [calendarType.value, end] = [4, new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())]
  } else if (planStartDate <= today && diffDays < 30 && endOfPlanYear.value >= today) {
    [calendarType.value, end] = [3, endOfPlanYear.value]
  } else if (today > planStartDate) {
    [calendarType.value, end] = [1, new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())]
  } else if (planStartDate > today) {
    [calendarType.value, end] = [2, new Date(year, month, day)]
  }  
  return end
})

  const dateRangeTitle = computed(() => {
    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric'
    })

    emit('dateRange', {
      startDate: effectiveStartDate.value,
      endDate: effectiveEndDate.value,
      endOfPlanYear: endOfPlanYear.value,
      calendarType: calendarType.value
    })

    const startFormatted = formatter.format(effectiveStartDate.value)
    const endFormatted = formatter.format(effectiveEndDate.value)
    const startYear = effectiveStartDate.value.getFullYear()
    const endYear = effectiveEndDate.value.getFullYear()
    return endYear > startYear ?
        `${startFormatted}, ${startYear} - ${endFormatted}, ${endYear}`
      : `${startFormatted} - ${endFormatted}, ${endYear}`
  })

  const daysWithTooltip = computed(() => {
    return calendarDays.value.map((day) => ({
      ...day,
      needsTooltip: day.hasEvents
    }))
  })

  const calendarLogic = {
    isDateInRange(date) {
      const startOfDay = new Date(date)
      startOfDay.setHours(0, 0, 0, 0)
      const comparableStart = new Date(effectiveStartDate.value)
      comparableStart.setHours(0, 0, 0, 0)
      const comparableEnd = new Date(effectiveEndDate.value)
      comparableEnd.setHours(23, 59, 59, 999)
      return startOfDay >= comparableStart && startOfDay <= comparableEnd
    },

    isDateAllowed(date) {
      return (
        this.isDateInRange(date) &&
        props.allowedDates.some(
          (allowedDate) =>
            allowedDate.getDate() === date.getDate() &&
            allowedDate.getMonth() === date.getMonth() &&
            allowedDate.getFullYear() === date.getFullYear()
        )
      )
    },

    getDayClasses(day) {
      const normalizedDayDate = new Date(day.date)
      normalizedDayDate.setHours(0, 0, 0, 0)
      const normalizedStartDate = new Date(effectiveStartDate.value)
      normalizedStartDate.setHours(0, 0, 0, 0)
      const isStartDate = normalizedDayDate.getTime() === normalizedStartDate.getTime()
      return {
        'day-cell': true,
        inactive: !day.isInRange,
        active: day.isInRange,
        allowed: day.isAllowed && day.isInRange,
        today: isStartDate,
        'event-reminder': day.hasEvents
      }
    }
  }

  const getRemindersByDate = (date) => {
    if (!date || !filteredReminders.value) return []
    return filteredReminders.value.filter((reminder) => {
      const reminderDate = dateUtils.convertDate(reminder.date)
      return (
        reminderDate.getDate() === date.getDate() &&
        reminderDate.getMonth() === date.getMonth() &&
        reminderDate.getFullYear() === date.getFullYear()
      )
    })
  }

  const generateCalendarDays = () => {
    let days = []
    const start = new Date(effectiveStartDate.value)
    const end = new Date(effectiveEndDate.value)
    end.setHours(23, 59, 59, 999)
    let currentDate = dateUtils.getMonday(new Date(start))
    while (currentDate <= end) {
      const dateToAdd = new Date(currentDate)
      const events = getRemindersByDate(dateToAdd)
      days.push({
        date: dateToAdd,
        isCurrentMonth: dateToAdd.getMonth() === start.getMonth(),
        isInRange: calendarLogic.isDateInRange(dateToAdd),
        isAllowed: calendarLogic.isDateAllowed(dateToAdd),
        events: events,
        hasEvents: events.length > 0
      })
      currentDate.setDate(currentDate.getDate() + 1)
    }
    calendarDays.value = days
  }

  const reminderHandler = {
    async updateMonths() {
      const numberStartMonth = parseInt(actualPlanYear.value?.plan_start_date.split('/')[0]) - 1
      await loadQuestionsAndAnswers()
      const sectionGroupResult = getPlanningSectionGroup()
      const type = SECTION_TYPES[sectionGroupResult]
      remindersMonths.value = await getReminders(numberStartMonth, type)
    },

    extractEvents(remindersMonths) {
      if (!remindersMonths) return
      filteredReminders.value = []
      nextEvents.value = []
      const startDate = new Date(effectiveStartDate.value)
      startDate.setHours(0, 0, 0, 0)
      endOfPlanYear.value = new Date(actualPlanYear.value?.plan_start_date)
      endOfPlanYear.value.setFullYear(endOfPlanYear.value.getFullYear() + 1)
      endOfPlanYear.value.setDate(endOfPlanYear.value.getDate() - 1)
      const endDate = new Date(effectiveEndDate.value)
      endDate.setHours(23, 59, 59, 999)
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      Object.values(remindersMonths).forEach((month) => {
        Object.values(month).forEach((week) => {
          if (Array.isArray(week)) {
            week.forEach((item) => {
              if (typeof item === 'object' && item !== null && 'id' in item && 'type' in item) {
                const itemDate = dateUtils.convertDate(item.date)
                itemDate.setHours(0, 0, 0, 0)
                filteredReminders.value.push(item)
                const isInRange =
                  itemDate.getTime() >= startDate.getTime() &&
                  itemDate.getTime() <= endDate.getTime()
                if (isInRange) {
                  nextEvents.value.push({
                    ...item,
                    formatDate: dateUtils.formatDate(item.date)
                  })                  
                }

                const isInRangePlanYear =
                  itemDate.getTime() >= today.getTime() &&
                  itemDate.getTime() <= endOfPlanYear.value.getTime()
                if (isInRangePlanYear) {
                  nextEventsPlanYear.value = nextEventsPlanYear.value || []
                  nextEventsPlanYear.value.push({
                    ...item,
                    formatDate: dateUtils.formatDate(item.date)
                  })
                }
              }
            })
          }
        })
      })
      nextEvents.value.sort((a, b) => {
        const dateA = dateUtils.convertDate(a.date)
        const dateB = dateUtils.convertDate(b.date)
        return dateA - dateB
      })
      emit('nextReminders', { nextEventsMonth: nextEvents.value, nextEventsPlanYear: nextEventsPlanYear.value })
    },
    filterReminders() {
      this.extractEvents(remindersMonths.value)
      generateCalendarDays()
    }
  }

  const handleDateClick = (day) => {
    if (day.isAllowed && day.isInRange) {
      emit('dateSelected', day.date)
    }
  }

  const handleTooltipOpen = (date) => {
    activeTooltipDate.value = date
  }

  const handleClickOutside = (event) => {
    const tooltips = document.querySelectorAll('.tooltip')
    let clickedInside = false
    tooltips.forEach((tooltip) => {
      if (tooltip.contains(event.target)) {
        clickedInside = true
      }
    })
    if (!clickedInside) {
      activeTooltipDate.value = null
    }
  }

  onMounted(async () => {
    if (actualPlanYear.value?.plan_start_date) {
    const [month, day, year] = actualPlanYear.value.plan_start_date.split('/').map(Number);
    endOfPlanYear.value = new Date(year, month - 1, day);
    endOfPlanYear.value.setFullYear(endOfPlanYear.value.getFullYear() + 1);
    endOfPlanYear.value.setDate(endOfPlanYear.value.getDate() - 1);
  }
    generateCalendarDays()
    if (actualPlanYear.value?.plan_start_date) {
      year.value = actualPlanYear.value?.plan_start_date?.split('/')[2] || ''
      await reminderHandler.updateMonths()
    }
    currentMonth.value = new Date().getMonth()
    reminderHandler.filterReminders()
    document.addEventListener('click', handleClickOutside)
  })

  watch(
    () => actualPlanYear?.value?.plan_start_date,
    async (newDate) => {
      if (newDate) {
        generateCalendarDays()
        reminderHandler.extractEvents()
        await reminderHandler.updateMonths()
        currentMonth.value = new Date().getMonth()
        reminderHandler.filterReminders()
      }
    }
  )

  watch([remindersMonths, filteredReminders], () => {
    nextTick(() => {
      generateCalendarDays()
    })
  })
</script>

<template>
  <div class="custom-date-picker">
    <div class="date-picker-container">
      <div class="date-range-title text-14">
        {{ dateRangeTitle }}
      </div>

      <div class="weekdays-grid text-14">
        <div v-for="day in WEEK_DAYS" :key="day" class="weekday">
          {{ day }}
        </div>
      </div>

      <div class="days-grid text-14">
        <template v-for="day in daysWithTooltip" :key="day.date">
          <BcsTooltip
            v-if="day.needsTooltip"
            :content="{
              date: day.date,
              events: day.events
            }"
            :is-active="activeTooltipDate && day.date.getTime() === activeTooltipDate.getTime()"
            @tooltip-open="handleTooltipOpen">
            <div
              class="text-14"
              :class="calendarLogic.getDayClasses(day)"
              @click.stop="handleDateClick(day)">
              {{ day.date.getDate() }}
            </div>
          </BcsTooltip>

          <div
            v-else
            class="text-14"
            :class="calendarLogic.getDayClasses(day)"
            @click="handleDateClick(day)">
            {{ day.date.getDate() }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .date-picker-container {
    padding: 15px 5px 10px 5px;
    margin: 0 auto;
    min-height: 229px;
  }

  .date-range-title {
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 400;
    color: #333;
  }

  .weekdays-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .weekday {
    text-align: center;
    font-size: 14px;
    color: var(--bs-gray-700);
    font-weight: 400;
    padding: 0.5rem 0;
  }

  .days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
  }

  .day-cell {
    height: 1.5rem;
    margin-bottom: 4px;
    width: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: default;
  }

  .day-cell.inactive {
    color: #d1d5db;
  }

  .day-cell.active {
    color: var(--bs-gray-700);
  }

  .day-cell.allowed {
    cursor: pointer;
  }

  .day-cell.allowed:hover {
    background-color: #f3f4f6;
    border-radius: 9999px;
  }

  .today {
    background-color: var(--bs-gray-300);
    border-radius: 50%;
    border: 1px solid var(--bs-gray-400);
  }

  .event-reminder {
    background-color: #fff6da;
    border-radius: 50%;
    color: var(--bs-primary) !important;
    font-weight: 700 !important;
    border: none !important;
    cursor: pointer;
  }

  .event-reminder:active {
    background-color: #ffe7b3;
  }
</style>
