<script setup>
  import { defineEmits, inject } from 'vue'

  import AssessmentTool from './AssessmentTool.vue'

  import GapAnalysisReport from './GapAnalysisReport.vue'

  const emit = defineEmits(['toggleLoading', 'inFocus'])
  const customNavigation = inject('customNavigation')
</script>

<template>
  <AssessmentTool
    v-if="
      customNavigation.currentSubPage === customNavigation.routes.DetailEmployer.children[2].name
    "
    @toggleLoading="(isOn) => emit('toggleLoading', isOn)"></AssessmentTool>

  <GapAnalysisReport
    v-if="
      customNavigation.currentSubPage === customNavigation.routes.DetailEmployer.children[3].name
    "
    @toggleLoading="(isOn) => emit('toggleLoading', isOn)"></GapAnalysisReport>
</template>

<style scoped lang="scss">
  .form-check-input {
    border: 1px solid var(--bs-gray-600, #6c757d) !important;
  }

  .question-title {
    flex-basis: 100%;
  }

  .floating-draft {
    position: relative;
    right: -15.5vw;
  }

  .steps-banner {
    background-color: white;
  }

  .wizard-container {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .step-container {
    width: 10%;
  }

  .circle {
    line-height: 45px;
    -moz-border-radius: 50%;
    border: solid 3px;
    border-color: var(--bs-primary);
    background-color: #ffffff;
    color: var(--bs-primary);
    text-align: center;
    font-size: 20px;

    width: 30px; /* Adjust circle size */
    height: 30px; /* Adjust circle size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .circle.filled {
    background-color: var(--bs-dark);
    color: white;
    border: 2px solid var(--bs-dark);
  }

  .circle.active {
    box-shadow: 0 0 0 4px #7b61ff78;
  }

  .empty {
    div {
      background-color: #ffffff; /* Adjust empty circle color */
      border: 2px solid var(--bs-secondary); /* Adjust empty circle border color */
      i {
        visibility: hidden;
      }
    }
    p {
      color: var(--bs-secondary);
    }
  }

  .partial {
    div {
      box-shadow:
        inset 0 0 0 8px #ffffff,
        inset 0 0 0 80px #007bff;
      i {
        visibility: hidden;
      }
    }
  }

  .scroll-padding {
    scroll-padding-top: 13vh;
    scroll-margin-top: 13vh;
  }

  .scroll-fix {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 94vh;
  }
</style>
