<script setup>
  import { defineEmits, inject, ref, computed, onBeforeMount, onMounted, watch } from 'vue'
  import Footer from './FooterSection.vue'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { usePositionStateStore } from '../stores/PositionStateStore'
  import { storeToRefs } from 'pinia'
  import { useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/yup'
  import { object, string, date } from 'yup'
  import BcsDynamicText from './BcsDynamicText.vue'
  import { useEmployerStore } from '../stores/EmployerStore'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import { RequestService } from '../services/RequestService'
  import { isNumber, reduce, get, map } from 'lodash'
  import { processDefinition, removeHTMLTags } from '../utils'
  import WhiteLabelModal from './WhiteLabelModal.vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'
  import { isValidHexColor } from '../utils'

  const emit = defineEmits(['toggleLoading', 'inFocus'])
  const toggleLoadingWithMessage = inject('toggleLoadingWithMessage', () => {})

  const customNavigation = inject('customNavigation')
  const questionStore = useQuestionsStore()
  const positionStateStore = usePositionStateStore()
  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore

  const { planningQuestions, planningKeys, intakeQuestions } = storeToRefs(questionStore)

  const { saveQuestions, isIntakeComplete } = questionStore

  const { setPositionState, getPositionState } = positionStateStore

  const employerStore = useEmployerStore()
  const { actualEmployer, actualPlanYear, userData } = storeToRefs(employerStore)
  const { createLog, downloadPdf, handleErrors, getRealtimeDatabaseValue } = RequestService()

  const { defineField, setFieldValue, setFieldTouched, errors, validate } = useForm({
    validationSchema: toTypedSchema(
      object({
        name: string().min(4).required(),
        plan_start_date: date()
          .typeError('Please enter a valid date')
          .required()
          .test('is-date-complete', 'Invalid date format', () => {
            if (!plan_start_date.value) return true
            const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/g
            return dateRegex.test(plan_start_date.value)
          })
      })
    )
  })

  const mainContainer = ref(null)

  const tabKeyToDownload = ref(null)

  const modalRef = ref(null)

  const whiteLabelModalContent = ref('')

  const [plan_start_date] = defineField('plan_start_date')

  const templateID = ref('')

  watch(
    () => actualEmployer.value.name,
    (newVal) => {
      setFieldValue('name', newVal)
      setFieldTouched('name', true)
      validate()
    },
    { immediate: true }
  )

  watch(
    () => actualPlanYear.value?.plan_start_date,
    (newVal) => {
      setFieldValue('plan_start_date', newVal)
      setFieldTouched('plan_start_date', true)
      validate()
    }
  )

  const hasErrors = computed(() => {
    return Object.keys(errors.value).length > 0
  })

  onMounted(async () => {
    templateID.value = await getRealtimeDatabaseValue('SYSTEM_PARAMS/PDF_TEMPLATE_ID/PLANNING')
    let count = 0
    let interval = setInterval(() => {
      count++
      try {
        const tabPosition = getPositionState(actualEmployer.value.id, 'planningToolTab')
        const tabContent = myTabContent.value?.querySelector(`#tab-pane-${tabPosition}`)
        if (tabContent) {
          showContentTab(tabPosition)
          const scrollPosition = getPositionState(actualEmployer.value.id, 'planningToolScroll')
          if (scrollPosition) {
            mainContainer.value.scrollTo({ top: scrollPosition, behavior: 'smooth' })
          }
          clearInterval(interval)
        }
      } finally {
        if (count >= 100) {
          clearInterval(interval)
        }
      }
    }, 100)
  })

  const titlesOptions = {
    'SMALL GROUP': 'For small groups, 1-50 employees, fully insured',
    'SMALL GROUP - SELF INSURED':
      'For self-insured small groups, including level-funded (1-50 employees)',
    'LARGE GROUPS': 'For large groups, 51+ employees, fully insured',
    'LARGE GROUP - SELF INSURED':
      'For self-insured large groups, 51+ employees, including level-funded'
  }
  const title = computed(() => {
    if (Object.values(planningQuestions.value)[0]) {
      let firstQuestionAndAnswer = Object.values(planningQuestions.value)[0][0]
      return titlesOptions[firstQuestionAndAnswer.section_group]
    }
    return ''
  })

  const saving = ref(false)

  const lastTimeSavedPlanning = ref(null)
  const timeElapsedPlanning = ref('Not auto-saved yet ')
  const idSetIntervalSecondPlanning = ref(null)
  const idSetIntervalMinutePlanning = ref(null)

  const scrollTop = () => {
    mainContainer.value.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function autoSavePlanning({ noteId = null }) {
    savePlanning({ showLoading: false, noteId })
    lastTimeSavedPlanning.value = new Date()
    timeElapsedPlanning.value = 'Auto-saved just now'

    if (idSetIntervalSecondPlanning.value) {
      clearInterval(idSetIntervalSecondPlanning.value)
    }
    if (idSetIntervalMinutePlanning.value) {
      clearInterval(idSetIntervalMinutePlanning.value)
    }
    let countSeconds = 0
    idSetIntervalSecondPlanning.value = setInterval(() => {
      countSeconds++
      let now = new Date()
      let diff = now - lastTimeSavedPlanning.value
      let seconds = Math.floor(diff / 1000)
      timeElapsedPlanning.value = `Auto-saved ${seconds} secs ago`
      if (countSeconds > 59) {
        clearInterval(idSetIntervalSecondPlanning.value)
        idSetIntervalMinutePlanning.value = setInterval(() => {
          let now = new Date()
          let diff = now - lastTimeSavedPlanning.value
          let minutes = Math.floor(diff / 60000)
          timeElapsedPlanning.value = `Auto-saved ${minutes} mins ago`
        }, 60000)
      }
    }, 10000)
  }

  async function savePlanning({ goHome = false, showLoading = true, noteId = null }) {
    if (showLoading) {
      emit('toggleLoading', true)
    }
    try {
      saving.value = true
      await saveQuestions('planning', actualEmployer.value.id, noteId)
      saving.value = false
      if (goHome) {
        customNavigation.value.navigate({
          newPage: customNavigation.value.currentPage,
          subPage: customNavigation.value.routes.DetailEmployer.children[0].name
        })
      }
      const PLANNING_GUIDE_UPDATED = 5
      createLog({
        eventId: PLANNING_GUIDE_UPDATED,
        logName: 'user_activity_log',
        employerId: actualEmployer.value.id,
        agencyId: actualEmployer.value.agency_id
      })
    } catch {
      emit('toggleLoading', false)
    }
    emit('toggleLoading', false)
  }

  const itemsIsSticky = ref(false)
  const myTabContent = ref(null)
  const ulParent = ref(null)

  const showContentTab = (nameTab) => {
    setPositionState(actualEmployer.value.id, 'planningToolTab', nameTab)
    const allButtons = ulParent.value.querySelectorAll('button')
    for (let i = 0; i < allButtons.length; i++) {
      allButtons[i].classList.remove('active')
    }
    const triggerEl = ulParent.value.querySelector(
      `#myTab button[data-bs-target="#tab-pane-${nameTab}"]`
    )
    if (triggerEl) {
      triggerEl.classList.add('active')
    }
    const allDivContent = myTabContent.value.querySelectorAll('div .tab-pane')
    for (let i = 0; i < allDivContent.length; i++) {
      allDivContent[i].classList.remove('show')
      allDivContent[i].classList.remove('active')
    }
    const tabContent = myTabContent.value.querySelector(`#tab-pane-${nameTab}`)
    tabContent.style.opacity = 0
    tabContent.classList.add('show')
    setTimeout(() => {
      tabContent.style.opacity = 1
    }, 100)
    tabContent.classList.add('active')
  }

  const ignoreNextScrollTop = ref(false)
  function setPositionScroll() {
    var scrollTop = mainContainer.value.scrollTop
    setPositionState(actualEmployer.value.id, 'planningToolScroll', scrollTop)
    if (scrollTop > 50 && !itemsIsSticky.value) {
      itemsIsSticky.value = true
      ignoreNextScrollTop.value = true
      return
    } else if (scrollTop < 1 && itemsIsSticky.value && !ignoreNextScrollTop.value) {
      itemsIsSticky.value = false
    }
    ignoreNextScrollTop.value = false
  }

  function toggleQuestion(question) {
    if (Array.isArray(question.answer)) {
      question.answer[0] = !question.answer[0]
    } else {
      question.answer = [false]
    }
    autoSavePlanning({})
  }

  function isShowQuestion(question) {
    return Array.isArray(question.answer) ? question.answer[0] : true
  }

  function saveOptionCheckboxInQuestion(question, optionIndex) {
    if (Array.isArray(question.answer)) {
      if (question.answer.includes(optionIndex)) {
        question.answer = question.answer.filter((item) => item !== optionIndex)
      } else {
        question.answer.push(optionIndex)
      }
    } else {
      question.answer = [true, optionIndex]
    }

    autoSavePlanning({})
  }

  function getSelectedQuestions(isCsv = false) {
    return (planningQuestions.value[tabKeyToDownload.value] || []).reduce((acc, item) => {
      if (isShowQuestion(item)) {
        const discussionPoint = removeHTMLTags(
          processDefinition({ body: item.question })?.processedText
        )
        acc.push({
          [isCsv ? 'discussionPoints' : 'discussionPoint']: discussionPoint,
          notes: item.notes || '',
          responsibleParty: reduce(
            item.answer,
            (acc, ans) => {
              if (isNumber(ans)) {
                acc.push(item.options[ans - 1])
              }
              return acc
            },
            []
          ).join(', ')
        })
      }
      return acc
    }, [])
  }

  async function exportToCsv(tabKey) {
    tabKeyToDownload.value = tabKey
    const questionsSelectedTab = getSelectedQuestions(true)

    let csvContent = 'Discussion Points,Notes,Responsible Party\n'
    questionsSelectedTab.forEach((row) => {
      csvContent += `"${row.discussionPoints}","${row.notes}","${row.responsibleParty}"\n`
    })

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const fileName = `Compliance Planning Guide, ${tabKey}, ${actualEmployer.value.name}.csv`

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName)
    } else {
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const printTitles = new Map([
    [
      'State Law Considerations related to health plan benefits (not exhaustive)',
      'State Law Considerations (not exhaustive)'
    ]
  ])

  async function downloadPlanningPdf({ printWithCustomLogo = false }) {
    if (!templateID.value) {
      handleErrors('Planning tool', new Error('Template ID not found'))
      return
    }
    closeModalDownload()
    toggleLoadingWithMessage(true, {
      title: 'Downloading Planning Guide...',
      body: 'This could take a minute or two. Thanks for your \n patience while we create the good stuff.'
    })

    const questionsSelectedTab = getSelectedQuestions(false)

    let headerBgSize = 'small'

    if (tabKeyToDownload.value !== 'Onboarding' && printWithCustomLogo) {
      headerBgSize = 'medium'
    } else if (tabKeyToDownload.value !== 'Onboarding' && !printWithCustomLogo) {
      headerBgSize = 'large'
    }

    const colorValidation = isValidHexColor(userData.value.whitelabelColor)
    if (!colorValidation && printWithCustomLogo) {
      handleErrors('Planning tool', new Error('Invalid whitelabel color'))
    }
    const whitelabelColor = printWithCustomLogo && userData.value.whitelabelColor && colorValidation
    ? userData.value.whitelabelColor
    : ''

    const data = {
      agencyInfo: {
        name: userData.value.companyName,
        ein: userData.value.ein,
        is_cap_company: userData.value.CAPCompany,
        location: userData.value.agencyLocation
      },
      employer: actualEmployer.value.name,
      title: title.value,
      medicalPlan: Object.values(intakeQuestions.value)[0][4].options[
        Object.values(intakeQuestions.value)[0][4].answer
      ],
      ein: actualEmployer.value.ein,
      controlledGroup: Object.values(intakeQuestions.value)[0][0].options[
        Object.values(intakeQuestions.value)[0][0].answer
      ],
      erisaPlan: Object.values(intakeQuestions.value)[0][1].options[
        Object.values(intakeQuestions.value)[0][1].answer
      ],
      newHire: Object.values(intakeQuestions.value)[0][3].options[
        Object.values(intakeQuestions.value)[0][3].answer
      ],
      coverages: map(Object.values(intakeQuestions.value)[0][5].answer, (ans) =>
        get(Object.values(intakeQuestions.value)[0][5].options, ans)
      ),
      fullTimeEmployers: Object.values(intakeQuestions.value)[0][2].answer,
      planYear: actualPlanYear.value.plan_start_date,
      customLogo: userData.value.logoBase64 || '',
      logoSize: userData.value.logoSize || '',
      printWithCustomLogo,
      whitelabelColor,
      isIntakeComplete: isIntakeComplete(),
      headerBgSize,
      selectedTabPage: {
        page: '1',
        mainTitle: printTitles.get(tabKeyToDownload.value) || tabKeyToDownload.value,
        questionsSelectedTab
      }
    }

    const PLANNING_GUIDE_DOWNLOADED = 21
    const eventData = {
      eventId: PLANNING_GUIDE_DOWNLOADED,
      logName: 'user_activity_log',
      employerId: actualEmployer.value.id,
      agencyId: actualEmployer.value.agency_id
    }

    downloadPdf({
      eventData,
      fileName: `BCS Pro ${actualEmployer.value.name} Planning Guide.pdf`,
      templateID: templateID.value,
      data
    })
  }

  function openModalDownloadPdf() {
    modalRef.value.openModal()
  }

  async function handleDownloadPdf(tabKey) {
    tabKeyToDownload.value = tabKey

    if (userData.value.logoBase64) {
      whiteLabelModalContent.value = await getDefinitionById(
        'whiteLabelModalContentConfirmation',
        'White label modal content'
      )
      openModalDownloadPdf()
      return
    }
    downloadPlanningPdf({ printWithCustomLogo: false })
  }

  function closeModalDownload() {
    modalRef.value.closeModal()
  }
</script>

<template>
  <div
    class="col-10 px-0 scroll-fix"
    ref="mainContainer"
    id="mainContainer"
    @scroll="setPositionScroll">
    <div>
      <div class="container-fluid px-0 planning-content">
        <div class="sticky-top bg-white">
          <div
            class="d-flex header-container justify-content-between pb-2"
            :class="{'pt-4.2': !itemsIsSticky, 'hidden': itemsIsSticky }">
            <div class="w-75 d-flex flex-column justify-content-end ps-4">
              <span class="mb-0 text-28">
                <med>{{ actualEmployer.name }} Planning Guide</med>
              </span>
              <p class="mt-1 header-22">
                <med>{{ title }}</med>
              </p>
              <span class="py-2 text-14">
                <BcsDynamicText text="planningToolInstructionalText" />
              </span>
            </div>
            <div class="w-50 d-flex flex-column align-items-end me-4">
              <div class="d-inline-flex">
                <div class="d-flex flex-column">
                  <button
                    @click="() => savePlanning({})"
                    class="btn btn-primary text-16"
                    :disabled="saving || hasErrors">
                    <i class="bi bi-bookmark"></i>
                    <med>&nbsp;&nbsp;{{ saving ? 'Saving...' : 'Save Draft' }}</med>
                  </button>
                  <span class="text-12 text-end">
                    {{ timeElapsedPlanning }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex pt-3">
            <ul class="nav nav-tabs" id="myTab" role="tablist" ref="ulParent">
              <li
                ref="liItems"
                v-for="(key, keyIndex) in planningKeys"
                class="nav-item"
                :class="{ 'ps-4': keyIndex === 0 }"
                role="presentation"
                :key="keyIndex">
                <button
                  class="nav-link border-top border-end border-start"
                  :class="{ active: keyIndex === 0 }"
                  :id="`tab-${keyIndex}`"
                  @click="showContentTab(keyIndex)"
                  data-bs-toggle="tab"
                  :data-bs-target="`#tab-pane-${keyIndex}`"
                  type="button"
                  role="tab"
                  :aria-controls="`tab-pane-${keyIndex}`"
                  :aria-selected="false">
                  {{ planningQuestions[key][0].short_title }}
                </button>
              </li>
            </ul>

            <div class="planning-header-line d-flex justify-content-end">
              <div class="d-inline-flex mb-1 me-4" v-if="itemsIsSticky">
                <button
                  @click="() => savePlanning({})"
                  class="btn btn-primary text-16"
                  :disabled="saving">
                  <i class="bi bi-bookmark"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="tab-content" id="myTabContent" ref="myTabContent">
            <div
              v-for="(key, keyIndex) in planningKeys"
              class="tab-pane fade"
              :class="{ show: keyIndex === 0, active: keyIndex === 0 }"
              :id="`tab-pane-${keyIndex}`"
              role="tabpanel"
              :aria-labelledby="`tab-${keyIndex}`"
              tabindex="0"
              :key="keyIndex">
              <div class="pe-4 me-0">
                <div class="w-100 d-flex title-tab-container justify-content-between me-4">
                  <p class="header-22 fw-bold ps-4">{{ key }}</p>

                  <div class="">
                    <button
                      type="button"
                      class="btn btn-info me-2"
                      @click="() => handleDownloadPdf(key)">
                      <i class="bi bi-download"></i>
                      Download PDF
                    </button>
                    <button type="button" @click="() => exportToCsv(key)" class="btn btn-info">
                      <i class="bi bi-check2-square"></i>
                      Export CSV
                    </button>
                  </div>
                </div>

                <!-- <p class="text-16 mt-2 mb-4 ps-4 ms-3">
                  <BcsDynamicText text="planningGuideIntro" />
                </p> -->

                <div class="row pt-3 pb-2 border-top border-bottom">
                  <div class="col-4 ps-5">
                    <h6 class="text-14 ps-4.5">
                      <strong>Discussion Points</strong>
                    </h6>
                  </div>
                  <div class="col-4 ps-4.75">
                    <h6 class="text-14">
                      <strong>Notes</strong>
                    </h6>
                  </div>
                  <div class="col-4 ps-4.75">
                    <h6 class="text-14">
                      <strong>Responsible Party</strong>
                    </h6>
                  </div>
                </div>

                <div
                  class="row mb-2 ps-2 ms-3"
                  v-for="(question, questionIndex) in planningQuestions[key]"
                  :key="questionIndex">
                  <div class="col-4">
                    <div class="row mt-3">
                      <div class="col-1 ps-0">
                        <div
                          @click="toggleQuestion(question)"
                          class="view-question d-flex justify-content-center align-items-center text-primary hand-cursor bg-light rounded-circle">
                          <i v-if="isShowQuestion(question)" class="bi bi-eye-slash-fill"></i>
                          <i v-else class="bi bi-eye"></i>
                        </div>
                      </div>
                      <div
                        class="col-11 text-14"
                        :class="isShowQuestion(question) ? '' : 'text-gray-500'">
                        <TextWithDefinitions :text="question.question" />
                      </div>
                    </div>
                  </div>
                  <div class="col-4" v-if="isShowQuestion(question)">
                    <div class="row mt-3">
                      <div class="col-12">
                        <textarea
                          class="form-control"
                          rows="4"
                          placeholder="Enter notes"
                          v-model="question.notes"
                          @change="() => autoSavePlanning({ noteId: question.id })"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" v-if="isShowQuestion(question)">
                    <div class="row mt-3">
                      <div class="col-12">
                        <div
                          v-for="(option, optionIndex) in question.options"
                          :key="option"
                          @click="saveOptionCheckboxInQuestion(question, optionIndex + 1)"
                          class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :id="question.id + '' + optionIndex + 1 + 'check'"
                            :checked="question.answer.includes(optionIndex + 1)" />
                          <label class="form-check-label text-16">{{ option }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4 py-2" />
                <div class="d-flex justify-content-between mb-4">
                  <button
                    v-if="keyIndex !== 0"
                    type="button"
                    class="btn btn-outline-primary bg-info"
                    @click="showContentTab(keyIndex - 1)">
                    <i class="bi bi-chevron-left"></i>
                    {{ planningKeys[keyIndex - 1] }}
                  </button>
                  <div v-else></div>
                  <button
                    v-if="keyIndex !== planningKeys.length - 1"
                    type="button"
                    class="btn btn-outline-primary bg-info"
                    @click="showContentTab(keyIndex + 1)">
                    {{ planningKeys[keyIndex + 1] }}&nbsp;
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column">
        <div class="p-0 position-relative button-top w-0">
          <button
            @click="scrollTop()"
            class="btn btn-primary rounded-circle d-flex justify-content-center align-items-center">
            <div>
              <i class="bi bi-chevron-up position-relative"></i>
              <span class="text-12 position-relative">
                <strong>Top</strong>
              </span>
            </div>
          </button>
        </div>
        <div class="p-0">
          <Footer class="px-4"></Footer>
        </div>
      </div>
    </div>
  </div>
  <!-- white label modal -->

  <WhiteLabelModal
    :handleDownload="downloadPlanningPdf"
    :onCloseModal="closeModalDownload"
    :logoBase64="userData.logoBase64"
    :logoSize="userData.logoSize"
    ref="modalRef" />
</template>

<style scoped lang="scss">
  .text-gray-500 {
    color: #adb5bd;
  }
  .view-question {
    height: 32px;
    width: 32px;
    position: relative;
    top: -8px;
  }
  .planning-header-line {
    flex-grow: 1;
    --bs-nav-tabs-border-width: var(--bs-border-width);
    --bs-nav-tabs-border-color: var(--bs-border-color);
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  }
  .form-check-input {
    border: 1px solid var(--bs-gray-600, #6c757d) !important;
  }

  .question-title {
    flex-basis: 100%;
  }

  .floating-draft {
    position: relative;
    right: -15.5vw;
  }

  .steps-banner {
    background-color: white;
  }

  .wizard-container {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .circle {
    line-height: 45px;
    -moz-border-radius: 50%;
    border: solid 3px;
    border-color: var(--bs-primary);
    background-color: #ffffff;
    color: var(--bs-primary);
    text-align: center;
    font-size: 20px;

    width: 30px; /* Adjust circle size */
    height: 30px; /* Adjust circle size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .circle.filled {
    background-color: var(--bs-dark);
    color: white;
    border: 2px solid var(--bs-dark);
  }

  .circle.active {
    box-shadow: 0 0 0 4px #7b61ff78;
  }

  .empty {
    div {
      background-color: #ffffff; /* Adjust empty circle color */
      border: 2px solid var(--bs-secondary); /* Adjust empty circle border color */
      i {
        visibility: hidden;
      }
    }
    p {
      color: var(--bs-secondary);
    }
  }

  .partial {
    div {
      box-shadow:
        inset 0 0 0 8px #ffffff,
        inset 0 0 0 80px #007bff;
      i {
        visibility: hidden;
      }
    }
  }

  .scroll-padding {
    scroll-padding-top: 13vh;
    scroll-margin-top: 13vh;
  }

  button.btn-outline-primary.bg-info:hover {
    background-color: var(--bs-primary) !important;
  }
  .sidebar {
    width: 228px;
    height: 100vh;
    background-color: #f7f1fd;
  }
  .pt-4\.2 {
    padding-top: 27px;
  }
  .scroll-fix {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 94vh;
  }

  :deep {
    .text-gray-500 {
      .words-for-definition {
        color: #adb5bd;
        cursor: none;
        pointer-events: none;
        user-select: none;
        text-decoration: none;
      }

      .words-for-definition::after {
        position: unset;
      }
    }
  }

  :deep {
    .words-for-definition {
      display: inline-block;
      margin-bottom: 12px;
      position: relative;
      padding-left: 1.5em;
      text-align: left;
      border-bottom: none;
      text-decoration: 3px underline dotted;
      text-decoration-color: var(--bs-primary);
      text-underline-offset: 4px;
      line-height: 1.5;
    }

    .words-for-definition::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background-color: var(--bs-gray-600);
      border-radius: 50%;
    }
  }

  .title-tab-container {
    padding: 20px 0;
  }

  .planning-content {
    min-height: 590px;
  }

  .header-container {
    max-height: 200px;
    opacity: 1;
    overflow: hidden;
    transition: all 1s ease-in-out;
}
  
.hidden {
    max-height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    transition: all 1s ease-in-out;
}
</style>
