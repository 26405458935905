import { inject, ref } from 'vue'
import { defineStore } from 'pinia'
import { RequestService } from '../services/RequestService'

const { simpleGet, handleErrors } = RequestService()

export const useGlossaryStore = defineStore('glossary', () => {
  const glossaryData = ref(null)
  const loading = ref(false)
  const error = ref(null)
  const initialized = ref(false)
  const dataBaseRoute = inject('realdatabase')

  async function fetchGlossaryData(forceRefresh = false) {
    if (glossaryData.value && initialized.value && !forceRefresh) {
      return glossaryData.value
    }
    if (loading.value) {
      return glossaryData.value
    }
    loading.value = true
    error.value = null
    try {
      const token = localStorage.getItem('userToken')
      if (!token) {
        handleErrors('not found token')
        return null
      }
      const endpoint = `${dataBaseRoute}/GLOSSARY.json?auth=${token}`
      const response = await simpleGet('getDefinition', endpoint, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })

      glossaryData.value = response.data
      initialized.value = true
      return glossaryData.value
    } catch (err) {
      error.value = err
      handleErrors(err)
      return null
    } finally {
      loading.value = false
    }
  }

  function clearGlossaryData() {
    glossaryData.value = null
    initialized.value = false
  }

  return {
    glossaryData,
    loading,
    error,
    initialized,
    fetchGlossaryData,
    clearGlossaryData
  }
})
