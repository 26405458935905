<script setup>
  import { ref, computed, onMounted, inject } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { getAuth, confirmPasswordReset, checkActionCode } from 'firebase/auth'
  import {
    getDatabase,
    ref as dbRef,
    remove
  } from 'firebase/database'
  import { getApp } from 'firebase/app'
  import { RequestService } from '../shared/services/RequestService'
  import logo from '@/assets/images/loginLogo.png'
  import { EmailUtilsService } from '../shared/services/EmailUtilsService'

  const { getEmailTemplate, getEmailRecipient } = EmailUtilsService()
  const { handleErrors, post } = RequestService()
  const functionsBaseUrl = inject('functionsBaseUrl')

  const auth = getAuth()
  const db = getDatabase(getApp())
  const router = useRouter()
  const route = useRoute()
  const password = ref('')
  const showPassword = ref(false)
  const loading = ref(false)
  const success = ref('')
  const isValidCode = ref(true)
  const showExpiredLinkError = ref(false)

  const isResetPassword = computed(() => route.path === '/resetPassword')

  // Password validation rules
  const hasMinLength = computed(() => password.value.length >= 12)
  const hasUppercase = computed(() => /[A-Z]/.test(password.value))
  const hasLowercase = computed(() => /[a-z]/.test(password.value))
  const hasSymbol = computed(() => /[!@#$%^&*]/.test(password.value))
  const hasNumber = computed(() => /\d/.test(password.value))

  const isPasswordValid = computed(
    () =>
      hasMinLength.value &&
      hasUppercase.value &&
      hasLowercase.value &&
      hasSymbol.value &&
      hasNumber.value
  )

  const createPassword = async () => {
    if (!isPasswordValid.value || !isValidCode.value) return

    loading.value = true

    try {
      // Get oobCode from URL
      const oobCode = route.query.oobCode

      if (!oobCode) {
        showExpiredLinkError.value = true
        return
      }

      // Verify action code and get user info
      const info = await checkActionCode(auth, oobCode)
      const email = info.data.email

      // Confirm password reset with new password
      await confirmPasswordReset(auth, oobCode, password.value)

      // Delete the pending oobCode after successful password update
      const pendingCodesRef = dbRef(db, `TEMP/PENDING_OOB_CODES/${oobCode}`)
      await remove(pendingCodesRef)
      // Send custom email through our Firebase function
      await post('sendEmail', `${functionsBaseUrl}/email`, {
        type: 'AUTH',
        subtype:
          isResetPassword.value ? 'PASSWORD_RESET_CONFIRMATION' : 'PASSWORD_CREATION_CONFIRMATION',
        email,
        emailTemplate: await getEmailTemplate(isResetPassword.value ? 'PASSWORD_RESET_CONFIRMATION' : 'PASSWORD_CREATION_CONFIRMATION'),
        emailRecipient: await getEmailRecipient('TEST_EMAIL_RECIPIENT')
      })

      // Redirect to login with status=created to show success message
      router.replace({
        name: 'login',
        query: {
          status: isResetPassword.value ? 'updated' : 'created'
        }
      })
    } catch (err) {
      handleErrors('Error creating password:', err)
    } finally {
      loading.value = false
    }
  }

  const resendEmail = async (oobCode) => {
    if (oobCode) {
      post('resendEmail', `${functionsBaseUrl}/email`, { 
        type: 'AUTH', 
        data: { oobCode }, 
        emailTemplate: await getEmailTemplate('PASSWORD_RESET'),
        emailRecipient: await getEmailRecipient('TEST_EMAIL_RECIPIENT')
      })
    }
    showExpiredLinkError.value = false
    success.value = 'A new password update link has been sent to your email.'
  }

  // Validate action code on mount
  onMounted(async () => {
    const oobCode = route.query.oobCode

    if (!oobCode) {
      showExpiredLinkError.value = true
      isValidCode.value = false
      return
    }

    loading.value = true
    try {
      await checkActionCode(auth, oobCode)
      isValidCode.value = true
      showExpiredLinkError.value = false
    } catch (err) {
      isValidCode.value = false
      showExpiredLinkError.value = true
    } finally {
      loading.value = false
    }
  })
</script>

<template>
  <div
    class="container-fluid bg-dark min-vh-100 d-flex align-items-center justify-content-between flex-column pt-5">
    <div
      class="bg-white rounded-3 mt-3 shadow login-card py-5 px-form height-login-card">
      <div>
        <!-- Logo -->
        <div class="text-center my-4">
          <img :src="logo" alt="BCS Pro Logo" class="img-fluid" style="max-width: 200px" />
        </div>

        <div class="text-center mb-1 pt-2 header-28" :class="{ 'mb-4': isResetPassword }">
          <med>{{ isResetPassword ? 'Enter Your New Password' : 'Welcome to BCS Pro!' }}</med>
        </div>
        <p v-if="!isResetPassword" class="text-center mb-4 text-14">
          Please create a login password to access your account.
        </p>

        <form @submit.prevent="createPassword">
          <!-- Password Input -->
          <div class="mb-3">
            <label for="newPassword" class="form-label text-14">
              <strong>New Password</strong>
            </label>
            <div class="position-relative" style="border: 1px solid #ced4da; border-radius: 6px">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="newPassword"
                v-model="password"
                placeholder="Enter new password"
                class="form-control border-0"
                autocomplete="off" />
              <button
                type="button"
                @click="showPassword = !showPassword"
                class="show-hide-button text-14 py-1 text-primary bg-info">
                <i v-if="showPassword" class="bi bi-eye-slash me-1"></i>
                <i v-else class="bi bi-eye me-1"></i>
                <med>&nbsp;{{ showPassword ? 'Hide' : 'Show' }}</med>
              </button>
            </div>
          </div>

          <!-- Password Requirements -->
          <div class="mb-4">
            <p class="mb-2 text-14"><strong>Password must have at least:</strong></p>
            <ul class="list-unstyled text-14">
              <li :class="{ 'text-success fw-bold': hasMinLength }">
                <i class="bi" :class="hasMinLength ? 'bi-check' : 'bi-dot'"></i>
                12 characters
              </li>
              <li :class="{ 'text-success fw-bold': hasUppercase }">
                <i class="bi" :class="hasUppercase ? 'bi-check' : 'bi-dot'"></i>
                One uppercase character
              </li>
              <li :class="{ 'text-success fw-bold': hasLowercase }">
                <i class="bi" :class="hasLowercase ? 'bi-check' : 'bi-dot'"></i>
                One lowercase character
              </li>
              <li :class="{ 'text-success fw-bold': hasSymbol }">
                <i class="bi" :class="hasSymbol ? 'bi-check' : 'bi-dot'"></i>
                One symbol
              </li>
              <li :class="{ 'text-success fw-bold': hasNumber }">
                <i class="bi" :class="hasNumber ? 'bi-check' : 'bi-dot'"></i>
                One number
              </li>
            </ul>
          </div>

          <!-- Error Messages -->
          <div v-if="showExpiredLinkError" class="alert alert-danger text-center">
            The password update link has expired or is invalid.
            <div class="text-primary hand-cursor text-decoration-underline" @click="resendEmail(route.query.oobCode)">
              <span>
                Email me a new link
              </span>
            </div>
          </div>
          <div v-else-if="success" class="alert alert-success">{{ success }}</div>
          <!-- Submit Button -->
          <button
            type="submit"
            class="btn btn-primary w-100 mb-4 header-20"
            :disabled="!isPasswordValid || loading || !isValidCode">
            <div v-if="loading" class="spinner-border spinner-border-sm me-2" role="status"></div>
            <med>{{ isResetPassword ? 'Update Password' : 'Create Password' }}</med>
          </button>
        </form>
      </div>
    </div>
    <div class="mb-4 text-white">
      <div class="text-center">
        <p class="text-12">
          2025 All Rights Reserved. Benefits Compliance Solutions is a registered trademark. Patent Pending.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .img-fluid {
    height: 57px;
  }
  .login-card {
    max-width: 596px;
    width: 100%;
  }
  .height-login-card {
    min-height: 600px;
  }
  .px-form {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .show-hide-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    border: none;
    border-radius: 3.2px;
    min-width: 70px;
  }
  .list-unstyled.text-14 li {
    margin-bottom: 0.2rem;
  }
  hr {
    border: 0;
    border-top: 2px solid rgba(43, 41, 41, 0.6);
  }
</style>
