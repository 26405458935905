import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { RequestService } from '../services/RequestService'
import { useEmployerStore } from './EmployerStore'
import { isEmpty, uniq, random } from 'lodash'
import { getTodayFormatted } from '../utils'
import * as Sentry from '@sentry/vue'
import axios from 'axios'

const { simpleGet, put, handleErrors } = RequestService()

export const useQuestionsStore = defineStore(
  'questions',
  () => {
    const employerStore = useEmployerStore()
    const assessmentQuestions = ref([])
    const intakeQuestions = ref([])
    const planningQuestions = ref([])
    const isLoading = ref(true)

    const lastPlanningSectionGroup = ref('')

    const assessKeys = ref([])
    const mainAssessKey = ref('')
    const erisaDate = ref('')

    const planningKeys = ref([])
    const mainPlaningKey = ref('')

    const actionItemsOfAssessment = computed(() => {
      let actionItems = []
      Object.values(assessmentQuestions.value).map((questions) => {
        questions.map((question) => {
          if (question.action_item && question.wrongAnswer && showQuestion(question).show) {
            const wrongAnswer = JSON.parse(question.wrongAnswer).values
            let titles = null
            let bodies = null
            let notes = []
            let responsibleParty = []
            let isActionItemArray = false
            try {
              titles = JSON.parse(question.action_item_title)
              bodies = JSON.parse(question.action_item)
              isActionItemArray = Array.isArray(titles) && Array.isArray(bodies)
              if (isActionItemArray && question.action_items_notes) {
                notes = JSON.parse(question.action_items_notes)
              }
              if (isActionItemArray && question.action_items_resp_party) {
                responsibleParty = JSON.parse(question.action_items_resp_party)
              }
            } catch (e) {
              if (isActionItemArray) {
                responsibleParty = []
              } else {
                isActionItemArray = false
              }
            }
            const hasWrongAnswer = Array.isArray(question.answer)
              ? question.answer.some((answer) => wrongAnswer.includes(answer))
              : wrongAnswer.includes(question.answer)

            if (hasWrongAnswer) {
              if (isActionItemArray) {
                titles.forEach((title, index) => {
                  actionItems.push({
                    title: title,
                    body: bodies[index],
                    originalQuestion: question,
                    action_items_notes: notes[index],
                    arrayIndex: index,
                    responsibleParty: Array.isArray(responsibleParty[index]) ? responsibleParty[index] : [],
                    key: random(1, 1000, true)
                  })
                })
              } else {
                actionItems.push({
                  title: question.action_item_title,
                  body: question.action_item,
                  originalQuestion: question,
                  action_items_notes: question.action_items_notes,
                  responsibleParty: question.action_items_resp_party,
                  key: random(1, 1000, true)
                })
              }
            }
          }
        })
      })
      return actionItems
    })

    let allAnswers = []
    let existingId = []

    const originalQuestions = ref([])

    function getAnswerById(id) {
      return allAnswers[id]
    }

    function normalizeAnswer(answer) {
      if (answer && answer.length > 0) {
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(answer)) {
          return answer
        }

        if (isNaN(answer)) {
          //its an array
          let returnValue = null
          try {
            returnValue = JSON.parse(answer)
          } catch (error) {
            Sentry.captureException(new Error(answer, error))
            returnValue = answer
          }
          return returnValue
        } else {
          return parseInt(answer)
        }
      }
    }

    function getCompletionStatus(section) {
      let updatedQuestions = assessmentQuestions.value[section].filter((val) => {
        if (val.hideLogic !== null) {
          return showQuestion(val).show
        }
        return true
      })
      let unansweredQuestions = updatedQuestions.filter((val) => {
        let obj = val.answer
        if (obj || obj === 0) {
          switch (typeof obj) {
            case 'number':
              return false
            case 'object':
            case 'string':
              return !(obj.length > 0)
          }
        } else {
          return true
        }
        return (
          obj !== null &&
          (typeof obj?.value === 'number' ||
            ((typeof obj?.value === 'string' || typeof obj?.value === 'object') &&
              obj?.value.length > 0))
        )
      })
      let status = ''
      if (unansweredQuestions.length === 0) {
        status = 'filled'
      }
      if (unansweredQuestions.length > 0) {
        status = 'partial'
      }
      if (unansweredQuestions.length === updatedQuestions.length) {
        status = 'empty'
      }
      return status
    }

    function showQuestion(question) {
      let hideLogic = question.hideLogic
      let show = true
      if (hideLogic) {
        const hideLogicObject = JSON.parse(hideLogic, true)
        const results = []
        hideLogicObject.values.forEach((val) => {
          let questionId =
            val.length === 3 ?
              typeof val[0] === 'string' ?
                val[1]
              : val[0]
            : val[0]
          let value = val.length === 3 ? val[2] : val[1]
          let currentQuestionState = getAnswerById(questionId)
          if (
            currentQuestionState !== null &&
            currentQuestionState !== undefined &&
            currentQuestionState !== ''
          ) {
            if (val.length === 3) {
              //results.push([val[0], currentQuestionState === value])
              if (typeof val[0] === 'string') {
                if (currentQuestionState instanceof Array) {
                  //we evaualte if weh are negating the zero
                  if (val[0] === 'not') {
                    results.push(!currentQuestionState.includes(Math.abs(value)))
                  } else {
                    results.push(
                      value < 0 ?
                        !currentQuestionState.includes(Math.abs(value))
                      : currentQuestionState.includes(value)
                    )
                  }
                } else {
                  results.push([
                    val[0],
                    val[0] === 'not' ?
                      currentQuestionState != value
                    : currentQuestionState == value
                  ])
                }
                //results.push([val[0], currentQuestionState === value])
              } else {
                //it has a boolean operator that we need to evaluate
                let operator = val[1]
                switch (operator) {
                  case '<=':
                    results.push(currentQuestionState <= value)
                    break
                  case '>=':
                    results.push(currentQuestionState >= value)
                    break
                  case '==':
                    results.push(currentQuestionState === value)
                    break
                  case '>':
                    results.push(currentQuestionState > value)
                    break
                  case '<':
                    results.push(currentQuestionState < value)
                    break
                }
              }
            } else {
              if (currentQuestionState instanceof Array) {
                //results.push(currentQuestionState.includes(value))
                results.push(
                  value < 0 ?
                    !currentQuestionState.includes(Math.abs(value))
                  : currentQuestionState.includes(value)
                )
              } else {
                results.push(currentQuestionState === value)
              }
            }
          } else {
            if (val.length === 3) {
              if (typeof val[0] === 'string') {
                results.push([val[0], false])
              } else {
                results.push(false)
              }
            } else {
              results.push(false)
            }
          }
        })

        if (results && results.length > 0) {
          let triggerIndex = 0
          const triggerIndexes = []
          show = results.reduce((acc, val, index) => {
            let localReturn = false
            const previousValue = acc instanceof Array ? acc[1] : acc
            if (val instanceof Array) {
              localReturn = val[0] === 'and' ? previousValue && val[1] : previousValue || val[1]
              // Just replace triggerIndex if the current "or" value is true, otherwise leave the previous one
              if (localReturn && val[1]) {
                triggerIndex = index
                triggerIndexes.push(index)
              }
            } else {
              localReturn = previousValue && val
              if (localReturn) {
                triggerIndexes.push(index)
              }
            }
            return localReturn
          })
          if (show && triggerIndexes.length === 0) {
            triggerIndexes.push(0)
          }
          let hideReasonsJson =
            question.hideReason !== null && show && question.hideReason !== undefined ?
              JSON.parse(question.hideReason)
            : { value: [] }
          // let reason =
          //   hideReasonsJson.value.length > 0 && show ? hideReasonsJson.value[triggerIndex] : ''
          let reason =
            hideReasonsJson.value.length > 0 && show ?
              triggerIndex >= hideReasonsJson.value.length ?
                hideReasonsJson.value[0]
              : hideReasonsJson.value[triggerIndex]
            : ''
          const reasons = hideReasonsJson.value.length > 0 && show
            ? uniq(triggerIndexes.map((idx) => idx >= hideReasonsJson.value.length ? hideReasonsJson.value[0] : hideReasonsJson.value[idx]))
            : []
          let questionHideReason =
            Number.isInteger(hideLogicObject.values[triggerIndex][0]) ?
              hideLogicObject.values[triggerIndex][0]
            : hideLogicObject.values[triggerIndex][1]

          const questionsHideReasons = uniq(triggerIndexes.map(
            (trgIndex) => Number.isInteger(hideLogicObject.values[trgIndex][0])
            ? hideLogicObject.values[trgIndex][0]
            : hideLogicObject.values[trgIndex][1]
          ))

          return { show: !show, hideReason: reason, questionHideReason, reasons, questionsHideReasons }
        } else {
          return { show, hideReason: '' }
        }
      } else {
        return { show, hideReason: '' }
      }
    }

    function clearQuestions(questionType) {
      const questionsToProcess =
        questionType === 'intake' ? intakeQuestions.value
        : questionType === 'assessment' ? assessmentQuestions.value
        : planningQuestions.value
      //we run a for and clear all values
      if (questionType === 'assessment') {
        erisaDate.value = ''
      }
      Object.values(questionsToProcess).forEach((section) => {
        section.forEach((element) => {
          switch (element.type) {
            case 0:
            case 4:
              element.answer = ref('')
              break
            case 1:
              element.answer = ref([])
              break
            case 2:
            case 3:
              element.answer = ref()
              break
          }
          allAnswers[element.id] = element.answer
        })
      })
    }

    function isQuestionsComplete(questions) {
      let pendingQuestions = questions.filter((val) => {
        if (val.answer !== undefined) {
          if (typeof val.answer === 'object') {
            return Object.keys(val.answer).length === 0
          }
          return val.answer === '' || val.answer === null || val.answer === undefined
        } else {
          return true
        }
      })
      return pendingQuestions.length > 0 ? false : true
    }

    function isIntakeComplete() {
      if (intakeQuestions.value.length === 0) {
        return false
      }
      return isQuestionsComplete(Object.values(intakeQuestions.value)[0])
    }

    function isAssessmentComplete() {
      if (assessmentQuestions.value.length === 0) {
        return false
      }
      let finalAssessmentQuestion = []
      Object.values(assessmentQuestions.value).forEach((section) => {
        section.forEach((question) => {
          if (showQuestion(question).show) {
            finalAssessmentQuestion.push(question)
          }
        })
      })
      return isQuestionsComplete(finalAssessmentQuestion)
    }

    function isAssessmentStarted() {
      const sections = Object.values(assessmentQuestions.value)
      if (sections.length === 0) {
        return false
      }
      return sections.some((questions) =>
        questions.some(
          (question) =>
            showQuestion(question).show &&
            ((Array.isArray(question.answer) && question.answer.length > 0) ||
              (typeof question.answer === 'string' && question.answer !== ''))
        )
      )
    }

    function isPlanningStarted() {
      if (planningQuestions.value.length === 0) {
        return false
      }
      let response = false
      loop1: for (let section in planningQuestions.value) {
        for (let question of planningQuestions.value[section]) {
          if (showQuestion(question).show) {
            if (Array.isArray(question.answer) && question.answer.length > 0) {
              response = true
              break loop1
            } else if (typeof question.answer === 'string' && question.answer !== '') {
              response = true
              break loop1
            }
          }
        }
      }
      return response
    }

    async function loadQuestionsAndAnswers() {
      isLoading.value = true

      try {
        const { actualEmployer, actualPlanYearId, actualAgencyId } = storeToRefs(employerStore)

        // const response = await simpleGet(
        //   'getQuestionsAndAnswers',
        //   `${import.meta.env.VITE_SERVICES_ENDPOINT}getQuestionsAndAnswers?planYearId=${actualPlanYearId.value}&employerId=${actualEmployer.value.id}&agencyId=${actualAgencyId.value}`
        // )

        // const inQuestions = response.data

        let endpoints = [
          `${import.meta.env.VITE_API_BASE_URL}?_rest=Questions&planYearId=${actualPlanYearId.value}`,
          `${import.meta.env.VITE_API_BASE_URL}?_rest=PlanYear&id=${actualPlanYearId.value}`,
          `${import.meta.env.VITE_API_BASE_URL}?_rest=PlanYear&employerId=${actualEmployer.value.id}&agencyId=${actualAgencyId.value}`
        ]

        await axios.all(endpoints.map((promise) => simpleGet('loadQuestionsAndAnswers', promise))).then(
          axios.spread((inQuestions) => {
            for (let i = 0; i < inQuestions.data.results.length; i++) {
              let element = inQuestions.data.results[i]
              if (!existingId.includes(element.id)) {
                element.options = JSON.parse(element.options).values
                allAnswers[element.id] = normalizeAnswer(element.answer)
                switch (element.type) {
                  case 0:
                    element.answer = element.answer ? ref(JSON.parse(element.answer)) : ref('')
                    break
                  case 1:
                    element.answer = element.answer ? ref(JSON.parse(element.answer)) : ref([])
                    break
                  case 2:
                  case 3:
                    element.answer = element.answer ? ref(JSON.parse(element.answer)) : ref()
                    break
                  case 4:
                    if (
                      element.answer !== '1' &&
                      element.answer !== '2' &&
                      !isEmpty(element.answer)
                    ) {
                      erisaDate.value = element.answer
                      element.answer = 0
                    } else if (isEmpty(element.answer)) {
                      erisaDate.value = ''
                      element.answer = ''
                    }
                    break
                }
                //we review the action_items_resp_party field
                element.action_items_resp_party =
                  element.action_items_resp_party && element.action_items_resp_party.length > 0 ?
                    JSON.parse(element.action_items_resp_party)
                  : ref([false, false, false])

                originalQuestions.value.push(element)
                existingId.push(element.id)
              }
            }

            intakeQuestions.value = groupBy(
              originalQuestions.value.filter((val) => val.section_id === 10),
              'title'
            )

            assessmentQuestions.value = groupBy(
              originalQuestions.value.filter((val) => val.section_id !== 10 && val.section_id < 13),
              'title'
            )
            assessKeys.value = Object.keys(assessmentQuestions.value)
            mainAssessKey.value = Object.keys(intakeQuestions.value)[0]

            //We initialize the planning Guide questions

            let finalSectionGroup = getPlanningSectionGroup() // 'SMALL GROUP', 'SMALL GROUP - SELF INSURED', 'LARGE GROUPS', 'LARGE GROUP - SELF INSURED',
            if (finalSectionGroup !== '') {
              planningQuestions.value = groupBy(
                originalQuestions.value.filter((val) => {
                  return (
                    val.section_group === finalSectionGroup &&
                    val.section_type === 0 &&
                    val.is_main === 0
                  )
                }),
                'title'
              )
              planningKeys.value = Object.keys(planningQuestions.value).sort((a, b) => {
                if (a === 'Onboarding') return -1
                if (b === 'Onboarding') return 1
                return 0
              })
              mainPlaningKey.value = Object.keys(planningQuestions.value)[0]
            }
          })
        )
      } catch (error) {
        handleErrors('Error saving questions:', error)
      } finally {
        isLoading.value = false
      }
    }

    async function saveQuestions(questionType, employerId, noteId = null) {
      try {
        const { actualEmployer, actualPlanYear } = storeToRefs(employerStore)

        const questionsToProcess =
          questionType === 'intake' ? intakeQuestions.value
          : questionType === 'assessment' ? assessmentQuestions.value
          : planningQuestions.value

        const responsesToStore = []
        Object.values(questionsToProcess).forEach((object) => {
          const innerQuestions = object.map((element) => {
            allAnswers[element.id] = element.answer
            if (element.type === 4) {
              return {
                question_id: element.id,
                employer_plan_years_id: actualPlanYear.value.id,
                answer: element.answer === 0 ? erisaDate.value : element.answer,
                employerId: employerId,
                notes: element.notes,
                note_updated_at: noteId === element.id ? getTodayFormatted() : (element.note_updated_at || ''),
              }
            }
            return {
              question_id: element.id,
              employer_plan_years_id: actualPlanYear.value.id,
              answer: JSON.stringify(element.answer),
              employerId: employerId,
              notes: element.notes,
              note_updated_at: noteId === element.id ? getTodayFormatted() : (element.note_updated_at || ''),
              action_items_notes: element.action_items_notes,
              action_items_resp_party: JSON.stringify(element.action_items_resp_party)
            }
          })
          responsesToStore.push(...innerQuestions)
        })

        // await put(
        //   'saveQuestions',
        //   `${import.meta.env.VITE_SERVICES_ENDPOINT}saveQuestions?employerId=${employerId}`,
        //   {
        //     questionType,
        //     actualEmployer: actualEmployer.value,
        //     actualPlanYear: actualPlanYear.value,
        //     answers: responsesToStore
        //   }
        // )
        if (questionType === 'intake' || questionType === 'planning') {
          actualEmployer.value['complianceYear'] = actualPlanYear.value.plan_start_date
          actualEmployer.value['complianceYearId'] = actualPlanYear.value.id
          const url = `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers&id=${employerId}`
          await put('putEmployer', url, actualEmployer.value)
        }

        const url = `${import.meta.env.VITE_API_BASE_URL}?_rest=Answers&id=${employerId}`
        await put('putAnswers', url, responsesToStore)
      } catch (error) {
        handleErrors('Error saving questions:', error)
      }
    }

    function getPlanningSectionGroup() {
      let insuranceType = getAnswerById(11)
      let noEmployees = getAnswerById(9)

      if (typeof insuranceType === 'object') {
        insuranceType = insuranceType.value
      }
      if (typeof noEmployees === 'object') {
        noEmployees = noEmployees.value
      }
      let finalSectionGroup = ''
      if (
        noEmployees &&
        (insuranceType != '' || insuranceType != null || insuranceType != undefined)
      ) {
        let insuranceTypes = ['FI', 'SI', 'LF', 'DK'] // Fully insured // Self insured // Level funded // Don't know
        let currentInsurance = insuranceTypes[insuranceType]
        if (noEmployees <= 50 && currentInsurance === 'FI') {
          finalSectionGroup = 'SMALL GROUP'
        } else if (noEmployees > 50 && currentInsurance === 'FI') {
          finalSectionGroup = 'LARGE GROUPS'
        } else if (noEmployees <= 50 && currentInsurance !== 'FI') {
          finalSectionGroup = 'SMALL GROUP - SELF INSURED'
        } else if (noEmployees > 50 && currentInsurance !== 'FI') {
          finalSectionGroup = 'LARGE GROUP - SELF INSURED'
        }
      }
      if (lastPlanningSectionGroup.value == '') {
        lastPlanningSectionGroup.value = finalSectionGroup
      }
      return finalSectionGroup
    }

    function changeLastPlanningSectionGroup() {
      lastPlanningSectionGroup.value = getPlanningSectionGroup()
    }

    function hideDueICHRA(key) {
      if (key === 'ICHRA') {
        let initialOptions = getAnswerById(12)
        if (typeof initialOptions === 'object' && initialOptions.value) {
          initialOptions = JSON.parse(initialOptions.value)
        }
        return !initialOptions?.includes(9)
      }
      return false
    }

    function isValidDate(date) {
      const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/
      return dateRegex.test(date)
    }

    function updateAssessmentQuestion(questionToUpdate) {
      Object.keys(assessmentQuestions.value).forEach((category) => {
        assessmentQuestions.value[category] = assessmentQuestions.value[category].map((question) =>
          question.id === questionToUpdate.id ?
            {
              ...question,
              action_items_notes: questionToUpdate.action_items_notes,
              action_items_resp_party: questionToUpdate.action_items_resp_party
            }
          : question
        )
      })
    }
    function resetQuestionStore() {
      assessmentQuestions.value = []
      intakeQuestions.value = []
      planningQuestions.value = []
      assessKeys.value = []
      mainAssessKey.value = ''
      planningKeys.value = []
      mainPlaningKey.value = ''
      originalQuestions.value = []
      allAnswers = []
      existingId = []
    }
    //aux functions
    const groupBy = (xs, key) => {
      return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    }

    return {
      allAnswers,

      intakeQuestions,

      assessmentQuestions,
      mainAssessKey,
      assessKeys,

      erisaDate,

      planningQuestions,
      mainPlaningKey,
      planningKeys,
      isLoading,
      actionItemsOfAssessment,
      originalQuestions,
      lastPlanningSectionGroup,
      resetQuestionStore,
      loadQuestionsAndAnswers,
      saveQuestions,
      clearQuestions,
      showQuestion,
      getPlanningSectionGroup,
      changeLastPlanningSectionGroup,
      getCompletionStatus,
      isIntakeComplete,
      isAssessmentComplete,
      isAssessmentStarted,
      isPlanningStarted,
      getAnswerById,
      hideDueICHRA,
      updateAssessmentQuestion,
      isValidDate
    }
  },
  {
    persist: true
  }
)
