import { defineStore, storeToRefs } from 'pinia'
import { inject, ref } from 'vue'
import { RequestService } from '../services/RequestService'
import { useEmployerStore } from './EmployerStore'

export const useProfileStore = defineStore('profile', () => {
    const { post, put, handleErrors } = RequestService()
    const uid = localStorage.getItem('userUid')
    const employerStore = useEmployerStore()
    const { userData } = storeToRefs(employerStore)
    const functionsBaseUrl = inject('functionsBaseUrl')
    const showSuccessAlert = ref(false)

    async function updateRealData(userData) {
        try {
            const response = await post(
                'updateUserData',
                `${functionsBaseUrl}/updateUserData`,
                userData, uid
            )
            updateStore(userData)
            return response.data
        } catch (error) {
            handleErrors(error)
        }
    }

    function updateStore(data) {
        userData.value = {
            ...userData.value,
            ...data
        }
    }

    function showAlert() {
        showSuccessAlert.value = true
    }

    function hideAlert() {
        showSuccessAlert.value = false
    }

    async function updateUserData(data) {
        try {
            updateStore(data)
            const response = await put(
                'updateUserData',
                `${import.meta.env.VITE_API_BASE_URL}?_rest=User`,
                data
            )
            return response.data.results
        } catch (error) {
            handleErrors(error)
        }
    }

    return {
        updateRealData,
        updateUserData,
        showAlert,
        hideAlert,
        showSuccessAlert
    }
})
