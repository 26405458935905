<script setup>
  import { ref, watch, onMounted } from 'vue'
  import { useNotesStore } from '../../stores/NotesStore.js'
  import { storeToRefs } from 'pinia'

  const notesStore = useNotesStore()
  const { selectTab } = storeToRefs(notesStore)

  const { clearSelectedNote, cancelCreatingNote, setSelectedTab } = notesStore

  const props = defineProps({
    tabNames: {
      type: Array,
      required: true
    }
  })

  onMounted(() => {
    selectedTab.value = selectTab.value.index
  })

  const emit = defineEmits(['tabSelected'])
  const selectedTab = ref(0)

  const showContentTab = (index) => {
    clearSelectedNote()
    cancelCreatingNote()
    selectedTab.value = index
    emit('tabSelected', props.tabNames[index])
  }

  watch(selectedTab, (newValue) => {
    setSelectedTab(props.tabNames[newValue], newValue)
    emit('tabSelected', props.tabNames[newValue])
  })
</script>

<template>
  <ul class="nav nav-tabs" id="myTab" role="tablist" ref="ulParent">
    <li
      v-for="(tabName, index) in tabNames"
      :key="index"
      class="nav-item"
      :class="{ 'ps-4': index === 0 }"
      role="presentation">
      <button
        class="nav-link border-top border-end border-start"
        :class="{ active: index === selectedTab }"
        :id="`tab-${index}`"
        @click="showContentTab(index)"
        data-bs-toggle="tab"
        :data-bs-target="`#tab-pane-${index}`"
        type="button"
        role="tab"
        :aria-controls="`tab-pane-${index}`"
        :aria-selected="index === selectedTab">
        {{ tabName }}
      </button>
    </li>
  </ul>
</template>

<style scoped lang="scss"></style>
