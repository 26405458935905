<script setup>
  import { onBeforeMount } from 'vue'
  import axios from 'axios'
  import { listAgencies, connectorConfig } from '@bcs-pro/data-connector'
  import { initializeApp, getApps } from 'firebase/app'
  import { getDataConnect } from 'firebase/data-connect'
  import { DataLoadService } from '../shared/services/DataLoadService'
  import loginLogo from '@/assets/images/loginLogo.png'

  const { loadData, deleteAllUsers } = DataLoadService()

  axios.defaults.withCredentials = true

  const firebaseConfig = {
    apiKey: 'AIzaSyCx6t_yr1rkuN6ZNFcyzy0zW-SBR96mN7I',
    authDomain: 'bcspro-qa.firebaseapp.com',
    projectId: 'bcspro-qa',
    storageBucket: 'bcspro-qa.firebasestorage.app',
    messagingSenderId: '643757639812',
    appId: '1:643757639812:web:8c49546d55cedbd2a85ad1',
    measurementId: 'G-0KCMZ0Q877'
  }

  // Initialize Firebase
  const apps = getApps()
  const app = apps.length === 0 ? initializeApp(firebaseConfig) : apps[0]
  const dataConnect = getDataConnect(app, connectorConfig)

  onBeforeMount(async () => {
    console.log(await listAgencies(dataConnect))
  })

  async function clickLoadData() {
    await loadData()
    alert('Data loaded successfully')
  }

  async function clickDeleteAllUser() {
    await deleteAllUsers()
  }
</script>

<template>
  <div class="container-fluid bg-dark pt-5 px-5">
    <div class="row d-flex justify-content-center">
      <div class="col-6">
        <div class="container bg-white rounded pb-5">
          <div class="row d-flex justify-content-center pt-5">
            <div class="col-md-6 text-center">
              <img id="logo" :src="loginLogo" class="img-fluid" alt="bcs logo" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center my-3">
              <h1>Welcome! to data load</h1>
            </div>
          </div>
          <div class="row d-flex justify-content-center mt-2">
            <div class="col-md-9">
              <div class="mb-3 text-center">
                <button
                  id="submitBUtton"
                  class="btn btn-primary w-100 header-20"
                  @click="clickLoadData">
                  Load Data
                </button>
              </div>
              <div class="mb-3 text-center">
                <button
                  id="submitBUtton"
                  class="btn btn-primary w-100 header-20"
                  @click="clickDeleteAllUser">
                  Delete All Users
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5 mt-5 text-white">
      <div class="col-12 text-center">
        <p class="text-12">
          2023 All Rights Reserved. Benefits Compliance Solutions is a registered trademark.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  body {
    background-color: #2e0c59 !important;
    height: 100vh;
  }

  body.body--light {
    color: black;
  }

  .q-parallax__media > img {
    min-width: 50% !important;
  }

  .card {
    height: 100%;
  }

  .hand-cursor {
    cursor: pointer;
  }

  .container-fluid {
    height: 100vh;
  }

  body {
    background: #000;
  }
  .card {
    border: none;
    height: 320px;
  }
  .forms-inputs {
    position: relative;
  }
  .forms-inputs .display-1 {
  }

  .pass-container {
    position: relative;
  }

  .view-pass {
    position: relative;
    float: right;
    margin-right: 1rem;
    top: -2.2rem;
  }

  span {
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 5px 10px;
    font-size: 15px;
    z-index: 999;
  }
  .forms-inputs input {
    height: 40px;
    border: 2px solid #eee;
  }
  .forms-inputs input:focus {
    box-shadow: none;
    outline: none;
    border: 2px solid #000;
  }
  .btn {
    height: 50px;
  }
  .success-data {
    display: flex;
    flex-direction: column;
  }
  .bxs-badge-check {
    font-size: 90px;
  }
  .loading-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    height: 100vh;
    width: 100vw;
    --bs-bg-opacity: 0.5;
  }
  .img-fluid {
    height: 60px;
  }
</style>
