<script setup>
  import { computed, inject, watch, ref } from 'vue'
  import { ScoreCalculationsService } from '../../services/ScoreCalculationsService'
  import { get, isEmpty } from 'lodash'
  import { useTemplateVariablesStore } from '../../stores/TemplateVariablesStore'
  import gapReportBlack from '@/assets/images/gap_report_black.svg'
  import gapReportGray from '@/assets/images/gap_report_gray.svg'
  import assessmentBlack from '@/assets/images/assessment_black.svg'
  import assessmentGray from '@/assets/images/assessment_gray.svg'
  import planningToolBlack from '@/assets/images/planning_tool_black.svg'
  import planningToolGray from '@/assets/images/planning_tool_gray.svg'

  const props = defineProps({
    isAssessmentStarted: {
      type: Boolean,
      default: false
    },
    isAssessmentComplete: {
      type: Boolean,
      default: false
    },
    isPlanningStarted: {
      type: Boolean,
      default: false
    },
    isIntakeComplete: {
      type: Boolean,
      default: false
    },
    isLoadingLastLogs: {
      type: Boolean,
      default: false
    },
    lastLogs: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    }
  })

  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore
  const { calculateSectionScore, calculateOverallScore } = ScoreCalculationsService()

  const customNavigation = inject('customNavigation')

  const intake = 1
  const asssessment = 2
  const gapReport = 3
  const planningGuide = 4

  const bodyText = ref({})

  const getLastWord = (text = '') => {
    const words = text.split(' ')
    return words[words.length - 1]
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const cardSettings = new Map([
    [
      'gapReport',
      {
        iconBlack: gapReportBlack,
        iconGray: gapReportGray,
        title: 'Gap Report Results',
        getDynamicFeatures: ({ isIntakeComplete, isAssessmentComplete, isAssessmentStarted }) => {
          const showScore = isIntakeComplete && isAssessmentComplete
          let totalComplianceRisk = 0
          let overallScore = 0
          if (showScore) {
            const scores = calculateSectionScore()
            totalComplianceRisk = scores.reduce((acc, val) => {
              return acc + val.dollarRisk
            }, 0)
            overallScore = calculateOverallScore(scores)
          }
          return {
            goTo: !isIntakeComplete ? intake : gapReport,
            isDisabledButton: isIntakeComplete && !isAssessmentComplete,
            isDisabledText: !isIntakeComplete || !isAssessmentComplete,
            textButton:
              !isIntakeComplete ? 'Complete Intake Questionnaire' : 'Return to Gap Report',
            buttonStyle: !isIntakeComplete ? 'text' : 'info-button',
            showScore,
            totalComplianceRisk,
            overallScore,
            bodyTextTv:
              !isIntakeComplete ? 'empDashboardGapCardNoIntakeText'
              : !isAssessmentStarted ? 'empDashboardGapCardNoAssessmentText'
              : 'empDashboardGapCardIncompleteAssessmentText'
          }
        }
      }
    ],
    [
      'assessment',
      {
        iconBlack: assessmentBlack,
        iconGray: assessmentGray,
        title: 'Assessment',
        getDynamicFeatures: ({ isIntakeComplete, isAssessmentStarted }) => ({
          goTo: !isIntakeComplete ? intake : asssessment,
          textButton:
            !isIntakeComplete ? 'Complete Intake Questionnaire'
            : isAssessmentStarted ? 'Return to Assessment'
            : 'Begin Assessment',
          isDisabledButton: false,
          isDisabledText: !isIntakeComplete,
          buttonStyle:
            !isIntakeComplete ? 'text'
            : isAssessmentStarted ? 'info-button'
            : 'filled-button',
          showStatus: isIntakeComplete && isAssessmentStarted,
          status: getLastWord(get(props, 'lastLogs.lastAssessment.description') || '') || 'Started',
          latestActivity: get(props, 'lastLogs.lastAssessment.formattedDate', ''),
          bodyTextTv:
            !isIntakeComplete ?
              'empDashboardAssessmentCardNoIntakeText'
            : 'empDashboardAssessmentCardNoAssessmentText'
        })
      }
    ],
    [
      'planningGuide',
      {
        iconBlack: planningToolBlack,
        iconGray: planningToolGray,
        title: 'Planning Guide',
        getDynamicFeatures: ({ isIntakeComplete, isPlanningStarted }) => ({
          goTo: !isIntakeComplete ? intake : planningGuide,
          textButton:
            !isIntakeComplete ? 'Complete Intake Questionnaire'
            : isPlanningStarted || !isEmpty(get(props, 'lastLogs.lastPlanning.description')) ?
              'Return to Planning Guide'
            : 'Begin Planning Guide',
          isDisabledButton: false,
          isDisabledText: !isIntakeComplete,
          buttonStyle:
            !isIntakeComplete ? 'text'
            : isPlanningStarted || !isEmpty(get(props, 'lastLogs.lastPlanning.description')) ?
              'info-button'
            : 'filled-button',
          showStatus:
            isIntakeComplete &&
            (!isEmpty(get(props, 'lastLogs.lastPlanning.description')) || isPlanningStarted),
          status: getLastWord(get(props, 'lastLogs.lastPlanning.description') || '') || 'Started',
          latestActivity: get(props, 'lastLogs.lastPlanning.formattedDate', ''),
          bodyTextTv: 'empDashboardPlanningtCardText'
        })
      }
    ]
  ])

  const cardData = computed(() => {
    const currentCardSettings = cardSettings.get(props.name)
    return {
      iconBlack: currentCardSettings.iconBlack,
      iconGray: currentCardSettings.iconGray,
      title: currentCardSettings.title,
      ...currentCardSettings.getDynamicFeatures({
        isIntakeComplete: props.isIntakeComplete,
        isPlanningStarted: props.isPlanningStarted,
        isAssessmentComplete: props.isAssessmentComplete,
        isAssessmentStarted: props.isAssessmentStarted
      })
    }
  })

  watch(
    () => cardData.value,
    async (newValue) => {
      if (bodyText.value.title !== newValue.bodyTextTv) {
        const definition = await getDefinitionById(newValue.bodyTextTv, newValue.bodyTextTv)
        bodyText.value = definition || {}
      }
    },
    { immediate: true }
  )
</script>

<template>
  <div class="card text-center h-100">
    <div
      class="bg-light card-header py-0 d-flex justify-content-center flex-column align-items-center">
      <div class="icon-bg">
        <img :src="cardData.isDisabledText ? cardData.iconGray : cardData.iconBlack" class="icon" />
      </div>
      <IconAsessment class="filter-black" />
      <p
        class="header-20 mb-0"
        :class="{ 'text-secondary': !cardData.isDisabledText, 'header-text': cardData.isDisabledText }">
        <strong>{{ cardData.title }}</strong>
      </p>
    </div>

    <div class="card-body pt-3 px-4">
      <!-- Score Section -->
      <div class="row d-flex justify-content-evenly px-5" v-if="cardData.showScore">
        <div class="col-12 col-xxl-6">
          <p class="header-40 mb-0">
            <med class="text-danger fw-bold text-nowrap">
              {{ formatter.format(Math.round(cardData.totalComplianceRisk / 100) * 100) }}
            </med>
          </p>
          <p class="mb-0 small">Total Compliance Risk</p>
        </div>
        <div class="col-12 col-xxl-6">
          <p class="header-40 mb-0">
            <med class="text-success fw-bold text-nowrap">
              {{ Math.round(cardData.overallScore) }}%
            </med>
          </p>
          <p class="card-text text-14">Overall (Average) Score</p>
        </div>
      </div>

      <!-- Status Section -->
      <div
        class="px-5 h-100 d-flex align-items-center flex-column justify-content-center"
        v-else-if="cardData.showStatus">
        <div v-if="isLoadingLastLogs" class="d-flex justify-content-center">
          <!-- Bootstrap 5 Spinner -->
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <p class="header-40 mb-0">
            <med class="text-success">{{ cardData.status }}</med>
          </p>
          <p class="card-text text-14">Latest Activity: {{ cardData.latestActivity }}</p>
        </div>
      </div>

      <!-- Text Section -->
      <p class="card-text text-14" :class="{ 'text-muted': cardData.isDisabledText }" v-else>
        {{ bodyText.body || '' }}
      </p>
    </div>

    <div class="card-footer d-flex justify-content-center pb-4">
      <button
        id="returnToAssessmentButton"
        :disabled="cardData.isDisabledButton"
        @click="
          customNavigation.navigate({
            newPage: customNavigation.currentPage,
            subPage: customNavigation.routes.DetailEmployer.children[cardData.goTo].name,
            saveEvent: true
          })
        "
        :class="{
          'text-danger text-decoration-none fw-bold d-flex justify-content-center align-items-center hand-cursor border border-0 bg-white':
            cardData.buttonStyle === 'text',
          'btn btn-primary': cardData.buttonStyle === 'filled-button',
          'btn btn-info fw-medium': cardData.buttonStyle === 'info-button'
        }">
        {{ cardData.textButton }}
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
  .icon-bg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 29px;
    height: 29px;
  }

  .header-text {
    color: var(--bs-gray-600);
  }

  .card-header {
    height: 120px;
  }

  .card-footer {
    background: transparent;
    border-top: 0px;
  }
</style>
