<script setup>
  import { ref, inject, onBeforeMount, watch, computed } from 'vue'
  import AssessmentQuestion from '../AssessmentQuestion.vue'
  import IntakeQuestionnaire from '../IntakeQuestionnaire.vue'
  import PlanningTool from '../PlanningTool.vue'
  import BcsCalendar from '../BcsCalendar.vue'
  import Footer from '../FooterSection.vue'
  import BcsNotes from '../Notes/BcsNotes.vue'
  import { useQuestionsStore } from '../../stores/QuestionsStore'
  import { useEmployerStore } from '../../stores/EmployerStore'
  import { storeToRefs } from 'pinia'
  import { useNotesStore } from '../../stores/NotesStore'
  import StatusCard from './StatusCard.vue'
  import { RequestService } from '../../services/RequestService'
  import BcsCalendarAtAGlance from '../BcsCalendarAtAGlance.vue'
  import { getTodayFormatted } from '../../utils'
  import { get } from 'lodash'
  import BcsDynamicText from '../BcsDynamicText.vue'

  const emit = defineEmits(['toggleLoading', 'allEmployers'])
  const props = defineProps(['employerId'])
  const customNavigation = inject('customNavigation')
  const isInfoLoaded = ref(false)

  const employerStore = useEmployerStore()
  const questionStore = useQuestionsStore()
  const notesStore = useNotesStore()
  const emitter = inject('emitter')

  const { getEmployer } = employerStore
  const { employerNotes, userData } = storeToRefs(notesStore)
  const { getEmployerNotes, createEmployerNote, crudNote, convertDate } =
    notesStore
  const { simpleGet } = RequestService()

  const { actualEmployer, actualPlanYear } = storeToRefs(employerStore)
  const {
    loadQuestionsAndAnswers,
    isIntakeComplete,
    isAssessmentComplete,
    isAssessmentStarted,
    isPlanningStarted
  } = questionStore

  const showAssessmentSbMenu = ref(false)
  const noteDescription = ref('')
  const lastLogs = ref(null)
  const isLoadingLastLogs = ref(false)
  const nextReminders = ref([])
  const nextRemindersPlanYear = ref([])
  const calendarDates = ref({
    startDate: null,
    endDate: null,
    endOfPlanYear: null,
    calendarType: null
  })

  const createNote = async () => {
    const data = {
      employer_id: actualEmployer.value.id,
      note: noteDescription.value,
      date: getTodayFormatted()
    }

    crudNote()
    await createEmployerNote(data)
    noteDescription.value = ''
    await getEmployerNotes()
  }

  const hasRequiredDataCalendar = computed(() => {
    return (
      calendarDates.value.startDate &&
      calendarDates.value.endDate &&
      Array.isArray(nextReminders.value)
    )
  })

  const copyCalendar = computed(() => {
    if (!hasRequiredDataCalendar.value) {
    return ''
  }
  const { calendarType, endOfPlanYear } = calendarDates.value
  const remindersCount = nextReminders.value.length
  let startDateConverted = ''
  if (actualPlanYear.value?.plan_start_date && endOfPlanYear) {
    const month = ('0' + (endOfPlanYear.getMonth() + 1)).slice(-2)
    const day = ('0' + endOfPlanYear.getDate()).slice(-2)
    const year = endOfPlanYear.getFullYear()
    startDateConverted = convertDateCalendar(`${month}/${day}/${year}`)
  }
  if (calendarType === 2) {
    return `There ${remindersCount === 1 ? 'is' : 'are'} <strong>${remindersCount} upcoming due date${remindersCount !== 1 ? 's' : ''}</strong> within the first 30 days of the plan year for this employer.`;
  } else if (calendarType === 1) {
    return `There ${remindersCount === 1 ? 'is' : 'are'} <strong>${remindersCount} upcoming due date${remindersCount !== 1 ? 's' : ''}</strong> within the next 30 days for this employer.`;
  } else if (calendarType === 3) {
    return `Your plan year ends on ${startDateConverted}. There ${remindersCount === 1 ? 'is' : 'are'} <strong>${remindersCount} upcoming due date${remindersCount !== 1 ? 's' : ''}</strong> within the rest of the plan year for this employer.`;
  } else {
    return ''
  }
  })

  const getLastLogs = async () => {
    isLoadingLastLogs.value = true
    try {
      const response = await simpleGet(
        'getLastLogsEmployer',
        `${import.meta.env.VITE_API_BASE_URL}?_rest=Data&id=last_logs&employerId=${props.employerId}`
      )
      lastLogs.value = response.data.object || {}
    } catch (error) {
      console.error('Error fetching last logs:', error)
      lastLogs.value = {}
    } finally {
      isLoadingLastLogs.value = false
    }
  }

  watch(
    () => customNavigation.value.currentSubPage,
    async (newVal) => {
      await loadQuestionsAndAnswers()
      if (
        newVal === customNavigation.value.routes.DetailEmployer.children[2].name ||
        newVal === customNavigation.value.routes.DetailEmployer.children[3].name
      ) {
        showAssessmentSbMenu.value = true
      } else if (newVal === customNavigation.value.routes.DetailEmployer.children[0].name) {
        getLastLogs()
      }
    }
  )

  const subMenuOfAssessment = ['assessmentTool', 'gapReport']

  onBeforeMount(async () => {
    emit('toggleLoading', true)
    await getEmployer(props.employerId)
    getEmployerNotes()
    await loadQuestionsAndAnswers()
    emit('toggleLoading', false)
    isInfoLoaded.value = true
    getLastLogs()
  })

  const handleDates = (startDate, endDate, endOfPlanYear, calendarType) => {
    calendarDates.value = { startDate, endDate, endOfPlanYear, calendarType }
  }

  function toggleLoading(isOn) {
    emit('toggleLoading', isOn)
  }

  const toggleOffCanvas = (id, definitionExpected, date) => {
    emitter.emit('toggleOffCanvas', { id, definitionExpected, date, dashboard: true })
  }

  const handleNextReminders = (nextEventsMonth, nextEventsPlanYear) => {    
    nextReminders.value = nextEventsMonth
    nextRemindersPlanYear.value = nextEventsPlanYear
  }

  const convertDateCalendar = (dateString) => {
    return dateString ?
        new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      : ''
  }
</script>

<template>
  <div class="container-fluid" v-if="actualEmployer">
    <div class="row">
      <div class="col-2 bg-light px-0 sticky-top text-primary d-flex flex-column">
        <div class="d-inline-flex flex-column w-100">
          <a
            @click="
              customNavigation.navigate({ newPage: customNavigation.routes.ListOfEmployers.name })
            "
            class="px-3 py-3 d-flex align-items-center text-decoration-none border-end hand-cursor">
            <i class="bi bi-chevron-left d-inline float-start"></i>
            <span class="d-inline float-center ms-2 menu-header text-primary">All Employers</span>
          </a>
          <hr class="my-0" />
          <ul class="nav flex-column">
            <li
              id="employerDashboarPrincipalButton"
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[0].name
                })
              "
              :class="
                (
                  customNavigation.currentSubPage ===
                  customNavigation.routes.DetailEmployer.children[0].name
                ) ?
                  'active'
                : 'border-end'
              "
              class="border-bottom">
              <div class="py-3 px-3 hand-cursor nav-item-sidebar">
                <i class="bi bi-ui-checks-grid"></i>
                <span class="d-inline float-center ms-2 text-16">
                  <med>Employer Dashboard</med>
                </span>
              </div>
            </li>
            <li
              id="intakePrincipalButton"
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[1].name
                })
              "
              :class="
                (
                  customNavigation.currentSubPage ===
                  customNavigation.routes.DetailEmployer.children[1].name
                ) ?
                  'active'
                : 'border-end'
              "
              class="border-bottom">
              <div class="py-3 px-3 hand-cursor nav-item-sidebar">
                <transition name="icon-transition" mode="out-in">
                  <i
                    v-if="isIntakeComplete() || !isInfoLoaded"
                    class="bi bi-list-check icon-transition"
                    key="list-check"></i>
                  <i
                    v-else
                    class="bi bi-exclamation-circle-fill text-danger icon-transition"
                    key="exclamation"></i>
                </transition>

                <span class="d-inline float-center ms-2 text-16">
                  <med>Intake Questionnaire</med>
                </span>
              </div>
            </li>

            <!--  -->

            <li
              :class="
                (
                  customNavigation.currentSubPage ===
                    customNavigation.routes.DetailEmployer.children[2].name ||
                  customNavigation.currentSubPage ===
                    customNavigation.routes.DetailEmployer.children[3].name
                ) ?
                  'bg-white'
                : 'bg-light'
              "
              class="border-bottom"
              aria-expanded="false">
              <div
                id="assessmentPrincipalButton"
                @click="showAssessmentSbMenu = !showAssessmentSbMenu"
                :class="showAssessmentSbMenu ? 'border-end border-bottom' : 'border-end'"
                class="py-3 px-3 hand-cursor nav-item-sidebar">
                <i class="bi bi-bar-chart"></i>
                <span class="d-inline float-center ms-2 text-16">
                  <med>Assessment</med>
                </span>
                <i
                  :class="`bi ${showAssessmentSbMenu ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} d-inline float-end`"></i>
              </div>
              <div
                class="collapse"
                :class="{ show: showAssessmentSbMenu }"
                id="home-collapse"
                style="">
                <ul class="btn-toggle-nav list-unstyled fw-normal small internal-nav">
                  <li
                    id="assessmentToolButton"
                    @click="
                      customNavigation.navigate({
                        newPage: customNavigation.currentPage,
                        subPage: customNavigation.routes.DetailEmployer.children[2].name,
                        saveEvent: true
                      })
                    "
                    class="py-3 hand-cursor ps-4 ms-2 internal-nav-item border-end border-bottom"
                    :class="
                      (
                        customNavigation.currentSubPage ===
                        customNavigation.routes.DetailEmployer.children[2].name
                      ) ?
                        'active'
                      : ''
                    ">
                    <span class="d-inline float-center ms-2 text-14">
                      <med>BCSPro Assessment Tool</med>
                    </span>
                  </li>
                  <li
                    id="gapReportButton"
                    @click="
                      customNavigation.navigate({
                        newPage: customNavigation.currentPage,
                        subPage: customNavigation.routes.DetailEmployer.children[3].name
                      })
                    "
                    class="py-3 hand-cursor ps-4 ms-2 internal-nav-item border-end"
                    :class="
                      (
                        customNavigation.currentSubPage ===
                        customNavigation.routes.DetailEmployer.children[3].name
                      ) ?
                        'active'
                      : ''
                    ">
                    <span class="d-inline float-center ms-2 text-14">
                      <med>BCSPro Gap Report</med>
                    </span>
                  </li>
                </ul>
              </div>
            </li>

            <li
              id="planningGuidePrincipalButton"
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[4].name,
                  saveEvent: true
                })
              "
              :class="
                (
                  customNavigation.currentSubPage ===
                  customNavigation.routes.DetailEmployer.children[4].name
                ) ?
                  'active'
                : 'border-end'
              "
              class="border-bottom">
              <div class="py-3 px-3 hand-cursor nav-item-sidebar">
                <i class="bi bi-flag"></i>
                <span class="d-inline float-center ms-2 text-16">
                  <med>Planning Guide</med>
                </span>
              </div>
            </li>
            <li
              id="complianceCalendarPrincipalButton"
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[5].name,
                  saveEvent: true
                })
              "
              :class="
                (
                  customNavigation.currentSubPage ===
                  customNavigation.routes.DetailEmployer.children[5].name
                ) ?
                  'active'
                : 'border-end'
              "
              class="border-bottom">
              <div class="py-3 px-3 hand-cursor nav-item-sidebar border-end">
                <i class="bi bi-calendar3"></i>
                <span class="d-inline float-center ms-2 text-16">
                  <med>Calendar</med>
                </span>
              </div>
            </li>
            <li
              class="border-bottom"
              id="notesPrincipalButton"
              :class="
                (
                  customNavigation.currentSubPage ===
                  customNavigation.routes.DetailEmployer.children[6].name
                ) ?
                  'active'
                : 'border-end'
              "
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[6].name
                })
              ">
              <div class="py-3 px-3 hand-cursor nav-item-sidebar border-end">
                <i class="bi bi-pencil"></i>
                <span class="d-inline float-center ms-2 text-16">
                  <med>Notes</med>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div class="h-100 border-end"></div>
      </div>

      <div
        v-if="
          customNavigation.currentSubPage ===
          customNavigation.routes.DetailEmployer.children[0].name
        "
        class="col-10 px-0 scroll-style">
        <div class="row d-flex justify-content-center px-3">
          <div class="col-12 text-left mt-4 mb-4 d-flex justify-content-between">
            <h1 class="d-inline-block align-middle">
              {{ actualEmployer.name }}
            </h1>

            <button
              v-if="!isIntakeComplete() && !isAssessmentComplete() && isInfoLoaded"
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[1].name
                })
              "
              class="btn complete-intake btn-danger">
              <i class="bi bi-list-check"></i>
              &nbsp; Complete Intake Questionnaire
            </button>
          </div>
        </div>
        <div class="row d-flex justify-content-center px-3">
          <div class="col-md-5">
            <div class="card h-100">
              <div class="card-header bg-light px-4">
                <h5 class="card-title text-left d-inline float-start header-22 mb-0">
                  <strong>Employer Details</strong>
                </h5>
                <a
                  class="d-inline float-end hand-cursor text-decoration-none text-14 mt-2"
                  data-bs-toggle="modal"
                  data-bs-target="#createUpdateEmployerModal">
                  <strong>Edit</strong>
                </a>
              </div>
              <div class="card-body px-4 rounded-2">
                <div class="row d-flex justify-content-center">
                  <div class="col-12 pt-2 mt-1">
                    <div class="row">
                      <div class="col-sm-5">
                        <p class="mb-2 text-truncate text-14">
                          <strong>Latest Activity</strong>
                        </p>
                      </div>
                      <div class="col-sm-7">
                        <p class="mb-0 text-truncate text-14 text-primary">
                          <strong>{{ get(actualEmployer, 'log_entry.eventDescription') || 'N/a' }}</strong>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <p class="mb-2 text-truncate text-14">
                          <strong>Employer Name</strong>
                        </p>
                      </div>
                      <div class="col-sm-7">
                        <p class="mb-0 text-truncate text-14">
                          {{ actualEmployer.name }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <p class="mb-2 text-truncate text-14">
                          <strong>EIN</strong>
                        </p>
                      </div>
                      <div class="col-sm-7">
                        <p class="mb-0 text-truncate text-14">
                          {{ actualEmployer.ein }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <p class="mb-2 text-truncate text-14">
                          <strong>Plan Year Start</strong>
                        </p>
                      </div>
                      <div class="col-sm-7">
                        <p class="mb-0 text-truncate text-14">
                          {{ actualPlanYear?.plan_start_date }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <p class="mb-2 text-truncate text-14">
                          <strong>Primary Contact</strong>
                        </p>
                      </div>
                      <div class="col-sm-7">
                        <p class="mb-0 text-truncate add-teamates-link text-14">
                          {{ actualEmployer.contact_name }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <p class="mb-2 text-truncate text-14">
                          <strong>Employer Emails</strong>
                        </p>
                      </div>
                      <div class="col-sm-7">
                        <p class="mb-0 text-truncate add-teamates-link text-14 text-primary">
                          {{ actualEmployer.contact_emails }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="card h-100">
              <div class="card-header bg-light px-4">
                <h5 class="card-title text-left d-inline float-start header-22 mb-0">
                  <strong>Calendar At-a-Glance</strong>
                </h5>
                <a
                  class="mt-2 d-inline float-end hand-cursor text-decoration-none text-14"
                  @click="
                    customNavigation.navigate({
                      newPage: customNavigation.currentPage,
                      subPage: customNavigation.routes.DetailEmployer.children[5].name,
                      saveEvent: true,
                      actualEmployer
                    })
                  ">
                  <strong>
                    Go to Full Compliance Calendar
                    <i class="bi bi-chevron-right"></i>
                  </strong>
                </a>
              </div>
              <div class="rounded-2 py-0">
                <div class="d-flex justify-content-center card-container">
                  <div class="col-md-6 calendar-events">
                    <div class="calendar-events__container">
                      <p class="text-14" v-html="copyCalendar"></p>
                      <ul class="nav flex-column mb-auto text-14 events-list">
                        <li
                          @click="toggleOffCanvas(reminder.body, reminder.name, reminder.date)"
                          :key="i"
                          v-for="(reminder, i) in nextReminders"
                          class="nav-item event-item mb-2 mt-1 px-3 py-2 bg-warning-subtle hand-cursor">
                          <p style="width: 60%" class="text-primary mb-0 text-truncate">
                            <strong>{{ reminder.name }}</strong>
                          </p>
                          <p class="text-primary mb-0 text-truncate">
                            <strong>{{ reminder.formatDate }}</strong>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <BcsCalendarAtAGlance
                      @dateRange="({ startDate, endDate, endOfPlanYear, calendarType }) => handleDates(startDate, endDate, endOfPlanYear, calendarType)"
                      @nextReminders="({nextEventsMonth, nextEventsPlanYear}) => handleNextReminders(nextEventsMonth, nextEventsPlanYear)"
                      v-if="actualPlanYear" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-between mt-4 px-3 center-modules">
          <div class="col-md-5">
            <StatusCard
              :isAssessmentComplete="isAssessmentComplete()"
              :isAssessmentStarted="isAssessmentStarted()"
              :isPlanningStarted="isPlanningStarted()"
              :isIntakeComplete="isIntakeComplete()"
              :isLoadingLastLogs="isLoadingLastLogs"
              :lastLogs="lastLogs"
              name="gapReport" />
          </div>

          <div class="col-md-7">
            <div class="row d-flex justify-content-between h-100">
              <div class="col-md-6">
                <StatusCard
                  :isAssessmentComplete="isAssessmentComplete()"
                  :isAssessmentStarted="isAssessmentStarted()"
                  :isPlanningStarted="isPlanningStarted()"
                  :isIntakeComplete="isIntakeComplete()"
                  :isLoadingLastLogs="isLoadingLastLogs"
                  :lastLogs="lastLogs"
                  name="assessment" />
              </div>
              <div class="col-md-6">
                <StatusCard
                  :isAssessmentComplete="isAssessmentComplete()"
                  :isAssessmentStarted="isAssessmentStarted()"
                  :isPlanningStarted="isPlanningStarted()"
                  :isIntakeComplete="isIntakeComplete()"
                  :isLoadingLastLogs="isLoadingLastLogs"
                  :lastLogs="lastLogs"
                  name="planningGuide" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 general-notes px-3">
          <div class="card h-100">
            <div class="card-header bg-light">
              <h5
                class="card-title text-left d-inline general-notes__title float-start header-22 mb-0">
                <strong>General Employer Notes</strong>

                <span
                  :style="{
                    color: employerNotes.length > 0 ? 'var(--bs-primary)' : 'var(--bs-gray-700)'
                  }"
                  class="text-14">
                  {{ employerNotes.length }} Notes
                </span>
              </h5>
            </div>
            <div class="rounded-2">
              <div class="row d-flex justify-content-center">
                <div class="col-md-6 general-notes__left border-end border-end-1">
                  <div class="general-notes__left-notes text-14">
                    <div v-if="employerNotes.length > 0">
                      <div v-for="note in employerNotes" :key="note.id" class="note-item">
                        <div class="d-flex justify-content-between">
                          <div>
                            <strong class="employer">{{ userData?.fullname }}</strong>
                          </div>
                          <div class="last-edit">
                            Last Edit:
                            {{
                              getTodayFormatted() === note.date ? 'Today' : convertDate(note.date)
                            }}
                          </div>
                        </div>

                        <div class="note">
                          {{ note.note }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-else
                      class="empty-notes d-flex justify-content-center align-items-center">
                      <div class="empty-notes-txt">
                        <BcsDynamicText text="employerNotesEmptyStateText" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="view-notes-page text-14 text-primary"
                    @click="
                      customNavigation.navigate({
                        newPage: customNavigation.currentPage,
                        subPage: customNavigation.routes.DetailEmployer.children[6].name
                      })
                    ">
                    View Notes Page
                    <i class="bi bi-chevron-right"></i>
                  </div>
                </div>
                <div class="col-md-6 py-1 text-14 general-notes__right">
                  <div class="info-message">
                    Enter general notes about employer, these will be simple instructions.
                  </div>

                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="noteDescription"
                    placeholder="Enter employer notes here."></textarea>

                  <span
                    :class="{ 'error-hint': noteDescription.length > 1300 }"
                    class="remaining-characters text-12 d-flex justify-content-end">
                    {{ noteDescription ? 1300 - noteDescription.length : 1300 }} characters
                    remaining
                  </span>
                  <div class="action-buttons d-flex justify-content-end">
                    <button
                      @click="noteDescription = ''"
                      class="btn btn-info text-14 cancel-btn fw-medium">
                      Reset
                    </button>
                    <button
                      :disabled="noteDescription.length > 1300 || noteDescription.length === 0"
                      @click="createNote"
                      class="btn btn-primary text-14 save-btn">
                      Save Note
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex mt-3 3">
          <div class="col-12 p-0">
            <Footer class="px-5 mx-n3"></Footer>
          </div>
        </div>
      </div>

      <IntakeQuestionnaire
        v-if="
          customNavigation.currentSubPage ===
          customNavigation.routes.DetailEmployer.children[1].name
        "
        @toggleLoading="(isOn) => emit('toggleLoading', isOn)"></IntakeQuestionnaire>

      <AssessmentQuestion
        v-if="subMenuOfAssessment.includes(customNavigation.currentSubPage)"
        @toggleLoading="(isOn) => toggleLoading(isOn)"></AssessmentQuestion>
      <PlanningTool
        v-if="
          customNavigation.currentSubPage ===
          customNavigation.routes.DetailEmployer.children[4].name
        "
        @toggleLoading="(isOn) => emit('toggleLoading', isOn)"></PlanningTool>
      <BcsCalendar
        v-if="
          customNavigation.currentSubPage ===
          customNavigation.routes.DetailEmployer.children[5].name
        "
        @toggleLoading="(isOn) => emit('toggleLoading', isOn)"></BcsCalendar>

      <BcsNotes
        v-if="
          customNavigation.currentSubPage ===
          customNavigation.routes.DetailEmployer.children[6].name
        "
        @toggleLoading="(isOn) => toggleLoading(isOn)" />

      <div
        v-if="
          !isIntakeComplete() &&
          isInfoLoaded &&
          customNavigation.currentSubPage ===
            customNavigation.routes.DetailEmployer.children[2].name
        "
        class="col-10 overlay-white-left">
        <div class="card mt-5 d-flex mx-auto">
          <div class="card-body text-center pt-5">
            <h5 class="card-title header-22">
              <i class="bi bi-exclamation-circle-fill text-danger"></i>
              &nbsp;
              <strong>Intake Questionnaire Incomplete</strong>
            </h5>
            <p class="card-text text-14 text-gray-700">
              It looks like the BCS Pro Intake Questionnaire has not been completed. We’ll need that
              information to make sure we’re asking the right questions for this group.
            </p>
            <button
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[1].name
                })
              "
              class="btn btn-primary">
              <i class="bi bi-pencil"></i>
              &nbsp; Complete Intake Questionnaire
            </button>
          </div>
        </div>
      </div>

      <!-- <div
        v-if="
          !isIntakeComplete() &&
          !isAssessmentComplete() && isInfoLoaded &&
          customNavigation.currentSubPage === customNavigation.routes.DetailEmployer.children[3].name
        "
        class="col-10 overlay-white-left">
        <div class="card mt-5 d-flex mx-auto">
          <div
            class="card-body text-center d-flex flex-column justify-content-center align-items-center">
            <h5 class="card-title header-22">
              <i class="bi bi-exclamation-circle-fill text-danger"></i>
              &nbsp;
              <strong>Intake Questionnaire And Assessment Tool Incomplete</strong>
            </h5>
            <p class="card-text text-14 text-gray-700">
              It looks like the BCS Pro Intake Questionnaire and Assessment Tool have not been
              completed. Please first fill out the Intake Questionnaire, followed by the assessment,
              to view this group’s BCS Pro Gap Report.
            </p>
            <button
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[1].name
                })
              "
              class="btn btn-primary">
              <i class="bi bi-pencil"></i>
              &nbsp; Complete Intake Questionnaire
            </button>
          </div>
        </div>
      </div> -->

      <div
        v-if="
          !isAssessmentComplete() &&
          isInfoLoaded &&
          customNavigation.currentSubPage ===
            customNavigation.routes.DetailEmployer.children[3].name
        "
        class="col-10 overlay-white-left">
        <div class="card mt-5 d-flex mx-auto">
          <div class="card-body text-center pt-5">
            <h5 class="card-title header-22">
              <i class="bi bi-exclamation-circle-fill text-danger"></i>
              &nbsp;
              <strong>Assessment Tool Incomplete</strong>
            </h5>
            <p class="card-text text-14 text-gray-700">
              It looks like the BCS Pro Assessment Tool has not been completed. Please finish the
              questions in the Assessment Tool before moving on to the BCS Pro Gap Report
            </p>
            <button
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[2].name,
                  saveEvent: true
                })
              "
              class="btn btn-primary">
              <i class="bi bi-pencil"></i>
              &nbsp; Complete Assessment Tool
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="
          !isIntakeComplete() &&
          isInfoLoaded &&
          customNavigation.currentSubPage ===
            customNavigation.routes.DetailEmployer.children[4].name
        "
        class="col-10 overlay-white-left">
        <div class="card mt-5 d-flex mx-auto">
          <div class="card-body text-center pt-5">
            <h5 class="card-title header-22">
              <i class="bi bi-exclamation-circle-fill text-danger"></i>
              &nbsp;
              <strong>Intake Questionnaire Incomplete</strong>
            </h5>
            <p class="card-text text-14 text-gray-700">
              It looks like the BCS Pro Intake Questionnaire has not been completed. We’ll need that
              information to provide you the right planning guide for this group.
            </p>
            <button
              @click="
                customNavigation.navigate({
                  newPage: customNavigation.currentPage,
                  subPage: customNavigation.routes.DetailEmployer.children[1].name
                })
              "
              class="btn btn-primary">
              <i class="bi bi-pencil"></i>
              &nbsp; Complete Intake Questionnaire
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .internal-nav .internal-nav-item.active {
    background-color: var(--bs-white);
    border-right: 0 !important;
    color: var(--bs-gray-700);
  }

  .internal-nav .internal-nav-item:not(.active):hover {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white);
  }

  .nav :not(.active) .nav-item-sidebar:hover {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white);
  }

  .nav .active .nav-item-sidebar {
    background-color: var(--bs-white);
    border-right: 0 !important;
  }
  .nav .nav-item.bg-warning-subtle:hover {
    background-color: #ffe69c !important;
    p {
      strong {
        color: #8f38f2 !important;
      }
    }
  }
  .menu-header {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .icon-bg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 29px;
    height: 29px;
  }

  .header-text {
    color: var(--bs-gray-600);
  }

  .card-body {
    height: 40%;
    max-height: 40%;
  }

  .center-modules {
    min-height: 300px;
  }

  .complete-intake {
    height: 35px;
    color: #fff;
    display: flex;
    align-items: center;
    // background-color: #dc3545;
  }

  .card-footer {
    background: transparent;
    border-top: 0px;
  }

  .scroll-style {
    overflow-y: scroll;
    height: 95vh;
  }

  .icon-transition-enter-active,
  .icon-transition-leave-active {
    transition: opacity 0.01s;
  }
  .icon-transition-enter,
  .icon-transition-leave-to {
    opacity: 0;
  }

  .general-notes {
    margin-top: 28px;

    &__title {
      span {
        font-weight: 700 !important;
        background-color: #fff;
        padding: 4.2px 7.8px;
        border-radius: 128392183092830923px;
        margin-left: 15px;
      }
    }

    &__left {
      padding: 0 !important;

      .view-notes-page {
        margin-left: 12px;
        padding: 24px 20px;
        font-weight: 700 !important;
        cursor: pointer;

        i {
          padding-left: 5px;
        }
      }

      &-notes {
        padding: 21px 30px 0 30px;

        height: 207px;
        overflow-y: scroll;

        .last-edit {
          color: var(--bs-gray-600);
          font-weight: 300;
        }

        .note {
          margin-top: 5px;
          color: var(--bs-gray-700);
          margin-bottom: 20px;
        }

        .employer {
          color: var(--bs-gray-700);
        }
      }
    }

    &__right {
      padding-right: 35px;
      padding-left: 21px;

      .info-message {
        color: var(--bs-gray-700);
        margin-top: 20px;
        margin-bottom: 8px;
      }
    }
  }

  .view-notes-page {
    border-top: 1px solid var(--bs-gray-400);
  }

  textarea {
    border-color: var(--bs-gray-600);
  }

  textarea::placeholder {
    color: var(--bs-gray-600);
    font-weight: 300;
  }

  .remaining-characters {
    margin-top: 2px;
    margin-bottom: 20px;
    color: var(--bs-gray-600);
  }

  .action-buttons {
    gap: 13px;
  }

  .empty-notes {
    height: 100%;

    &-txt {
      width: 382px;
      text-align: center;
      color: var(--bs-gray-700);
    }
  }

  .calendar-events {
    border-right: 1px solid var(--bs-gray-300);

    &__container {
      height: 100%;
      padding: 28px 35px;
      overflow-y: auto;
    }
  }

  .calendar-title {
    i {
      padding-right: 14px;
    }
  }

  .event-item {
    border-radius: 3428932489px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .card-container {
    max-height: 240px;
    height: 240px;
  }
</style>
