<script setup>
  import { ref, onMounted, watchEffect, nextTick, inject } from 'vue'
  import { computePosition, flip, shift, offset, arrow } from '@floating-ui/dom'

  const props = defineProps({
    content: {
      type: Object,
      required: true,
      default: () => ({
        date: new Date(),
        events: []
      })
    },
    isActive: {
      type: Boolean,
      default: false
    }
  })

  const emit = defineEmits(['tooltipOpen'])

  const tooltipRef = ref(null)
  const triggerRef = ref(null)
  const arrowRef = ref(null)
  const isVisible = ref(false)
  const emitter = inject('emitter')

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    })
  }

  const onClickEvent = (event) => {
    const { body, name, date } = event
    emitter.emit('toggleOffCanvas', { id: body, definitionExpected: name, date, dashboard: true })
  }

  const updatePosition = async () => {
    if (!triggerRef.value || !tooltipRef.value) return

    const { x, y, placement, middlewareData } = await computePosition(
      triggerRef.value,
      tooltipRef.value,
      {
        placement: 'top',
        middleware: [offset(8), flip(), shift({ padding: 5 }), arrow({ element: arrowRef.value })]
      }
    )

    Object.assign(tooltipRef.value.style, {
      left: `${x}px`,
      top: `${y}px`
    })

    if (middlewareData.arrow) {
      const { x: arrowX } = middlewareData.arrow

      const staticSide = {
        top: 'bottom',
        bottom: 'top'
      }[placement.split('-')[0]]

      Object.assign(arrowRef.value.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        bottom: staticSide === 'bottom' ? '-4px' : '',
        top: staticSide === 'top' ? '-4px' : ''
      })
    }
  }

  const showTooltip = () => {
    isVisible.value = true
    emit('tooltipOpen', props.content.date)
    nextTick(() => {
      updatePosition()
    })
  }

  watchEffect(() => {
    isVisible.value = props.isActive
    if (isVisible.value) {
      nextTick(() => {
        updatePosition()
      })
    }
  })

  onMounted(() => {
    window.addEventListener('scroll', updatePosition)
    window.addEventListener('resize', updatePosition)
  })
</script>

<template>
  <div ref="triggerRef" @mouseenter="showTooltip" class="tooltip-trigger">
    <slot></slot>
    <div v-show="isVisible" ref="tooltipRef" class="tooltip" role="tooltip">
      <div @click="$emit('tooltipOpen', null)" class="tooltip-close d-flex justify-content-end">
        <i class="bi bi-x"></i>
      </div>
      <div class="tooltip-content">
        <div class="tooltip-header">Due {{ formatDate(props.content.date) }}</div>
        <div class="tooltip-body">
          <div v-for="event in props.content.events" :key="event.id" class="reminder-item">
            <div @click="onClickEvent(event)" class="reminder-type">
              {{ event.name }}
            </div>
          </div>
        </div>
      </div>
      <div ref="arrowRef" class="tooltip-arrow"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .tooltip-trigger {
    display: inline-block;
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    pointer-events: auto;
    width: max-content;
    max-width: 300px;
    min-width: 200px;
    visibility: visible;
    opacity: 1;
    background: #000;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    color: white;

    font-size: 14px;

    .tooltip-content {
      position: relative;
      padding: 0 16px 12px 16px;
    }

    .tooltip-header {
      font-weight: 600;
    }

    .tooltip-body {
      max-height: 300px;
      overflow-y: auto;
    }

    .reminder-type {
      color: white;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #000;
    z-index: -1;
  }

  .tooltip-close {
    cursor: pointer;
    padding-right: 4px;
    i {
      font-size: 16px;
    }
  }
</style>
