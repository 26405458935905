<script setup>
  import { ref, onMounted, computed, watch, inject } from 'vue'
  import SemiCircularBarProgress from './SemiCircularBarProgress.vue'
  import CircularBarProgress from './CircularBarProgress.vue'
  import { useElementSize } from '@vueuse/core'
  import { useQuestionsStore } from '../stores/QuestionsStore'
  import { usePositionStateStore } from '../stores/PositionStateStore'
  import { storeToRefs } from 'pinia'
  import { ScoreCalculationsService } from '../services/ScoreCalculationsService'
  import BcsDynamicText from './BcsDynamicText.vue'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import { useEmployerStore } from '../stores/EmployerStore'
  import domtoimage from 'dom-to-image-more'
  import Footer from './FooterSection.vue'
  import { RequestService } from '../services/RequestService'
  import { useSessionStorageStore } from '../stores/SessionStorage'
  import * as bootstrap from 'bootstrap'
  import { debounce as _debounce, isNumber, get, map } from 'lodash'
  import WhiteLabelModal from './WhiteLabelModal.vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'
  import { isValidHexColor } from '../utils'

  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById, getDefinitionBodySync } = templateStore

  // const emit = defineEmits(['toggleLoading'])

  const questionStore = useQuestionsStore()
  const positionStateStore = usePositionStateStore()

  const { actionItemsOfAssessment, assessmentQuestions, intakeQuestions, isLoading } =
    storeToRefs(questionStore)
  const {
    showQuestion,
    hideDueICHRA,
    updateAssessmentQuestion,
    saveQuestions,
    isIntakeComplete,
    erisaDate
  } = questionStore

  const employerStore = useEmployerStore()
  const { actualEmployer, actualPlanYear, userData } = storeToRefs(employerStore)

  const { setPositionState, getPositionState } = positionStateStore

  const sessionStorageStore = useSessionStorageStore()
  const { getRefresh } = sessionStorageStore

  const { calculateSectionScore, calculateOverallScore } = ScoreCalculationsService()
  const { downloadPdf, handleErrors, getRealtimeDatabaseValue } = RequestService()

  const templateID = ref('')
  const itemsTab = ref([])
  const dropdownItemsTab = ref([])
  const totalCompRisk = ref(0)
  const answerModalRef = ref(null)
  let answerModal = null

  const customNavigation = inject('customNavigation')
  const emitter = inject('emitter')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const finalItemsTab = computed(() => {
    return itemsTab.value.concat(dropdownItemsTab.value)
  })

  const isGeneratingPDF = ref(false)

  const mainContainer = ref(null)

  const showFillActionItems = ref(false)

  const whiteLabelModalContent = ref('')

  const modalRef = ref(null)

  const lastWidthUlParent = ref(0)

  const ulParent = ref(null)
  const { width: wUlParent } = useElementSize(ulParent)

  const liSummary = ref(null)
  const { width: wliSummary } = useElementSize(liSummary)

  const liMore = ref(null)
  const { width: wliMore } = useElementSize(liMore)

  const liItems = ref([])
  const widthsLi = ref([])

  watch(
    () => liItems.value,
    () => {
      for (let i = 0; i < finalItemsTab.value.length; i++) {
        if (liItems.value[i]) {
          const { width } = useElementSize(liItems.value[i])
          widthsLi.value.splice(i, 1, width.value)
        } else {
          widthsLi.value.splice(i, 1, 0)
        }
      }
    },
    { deep: true, immediate: true }
  )

  const wTotalLi = computed(() => {
    return wliSummary.value + 24 + wliMore.value + widthsLi.value.reduce((a, b) => a + b, 0)
  })

  watch(wTotalLi, (newValue) => {
    if (newValue > 0 && newValue > wUlParent.value) {
      showHideTabs(wUlParent.value, wUlParent.value)
    }
  })

  watch(wUlParent, (newValue, oldValue) => {
    showHideTabs(oldValue, newValue)
  })

  function showHideTabs(oldWidthOfUl, newWidthOfUl) {
    if (oldWidthOfUl > 0 && newWidthOfUl > 0) {
      if (newWidthOfUl > oldWidthOfUl) {
        if (wUlParent.value > lastWidthUlParent.value + 100 && dropdownItemsTab.value.length > 0) {
          lastWidthUlParent.value = wUlParent.value
          itemsTab.value.push(dropdownItemsTab.value.pop())
        }
      } else {
        lastWidthUlParent.value = wUlParent.value
        if (wTotalLi.value > wUlParent.value && itemsTab.value.length > 1) {
          dropdownItemsTab.value.unshift(itemsTab.value.pop())
        }
      }
    }
  }

  let scores = []
  const itemsIsSticky = ref(false)
  const myTabContent = ref(null)
  const currentItemTabSelected = ref({})
  const overallScore = ref(0.0)

  const toggleLoadingWithMessage = inject('toggleLoadingWithMessage', () => {})

  watch(
    () => isLoading.value,
    (newIsLoading) => {
      if (!newIsLoading) {
        calculateScore()
      }
    }
  )

  onMounted(async () => {
    templateID.value = await getRealtimeDatabaseValue('SYSTEM_PARAMS/PDF_TEMPLATE_ID/GAP_REPORT')
    emitter.emit('toggleLoading', { value: true })

    // when the questions are already loaded
    if (!isLoading) {
      calculateScore()
    }

    emitter.emit('toggleLoading', { value: false })
  })

  const calculateScore = () => {
    scores = calculateSectionScore()

    totalCompRisk.value = scores.reduce((acc, val) => {
      return acc + val.dollarRisk
    }, 0)
    //We build the object to present information on each tab
    Object.values(assessmentQuestions.value).forEach((elements) => {
      let sectionShort = elements[0].short_title
      if (!hideDueICHRA(sectionShort)) {
        let questionWithAnswerAndNotes = elements.map((question) => {
          let textAnswer = 'Cant load'

          if (question.answer !== null || question.answer !== undefined) {
            let storedAnswer = question.answer
            if (Array.isArray(storedAnswer)) {
              textAnswer = storedAnswer.map((ans) => question.options[ans]).join(', ')
            } else {
              if (question.type === 4 && storedAnswer === 0) {
                textAnswer = question.options[storedAnswer] + ' ' + erisaDate
              } else {
                textAnswer = question.options[storedAnswer]
              }
            }
          } else {
            textAnswer = 'No answer'
          }
          return {
            ...question,
            answer: textAnswer
          }
        })
        const objScore = scores.find((score) => score.name === elements[0].short_title)
        let section = {
          name: elements[0].short_title,
          key: elements[0].short_title
            .toLowerCase()
            // eslint-disable-next-line no-useless-escape
            .replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, ''),
          badge: objScore.color,
          detail: elements[0].report_html,
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          numbers: 'P $40,000-$50,000',
          title: elements[0].title,
          subtitle: elements[0].subtitle,
          score: objScore.score,
          dollarRisk: formatter.format(Math.round(objScore.dollarRisk / 100) * 100),
          detailScore: 'This is above average for an employer of this size.',
          questionWithAnswerAndNotes: questionWithAnswerAndNotes
        }
        itemsTab.value.push(section)
      }
    })
    //finally we calculate the overal score
    overallScore.value = calculateOverallScore(scores)

    let count = 0
    let interval = setInterval(() => {
      count++
      try {
        const tabPosition = getPositionState(actualEmployer.value.id, 'gapAnalysisTab') || 'summary'
        const tabContent = myTabContent.value.querySelector(`#${tabPosition}-tab-pane`)
        if (tabContent) {
          showContentTab(tabPosition)
          const scrollPosition = getPositionState(actualEmployer.value.id, 'gapAnalysisScroll')
          if (scrollPosition) {
            mainContainer.value.scrollTo({ top: scrollPosition, behavior: 'smooth' })
          }
          clearInterval(interval)
        }
      } finally {
        if (count >= 100) {
          clearInterval(interval)
        }
      }
    }, 100)
  }

  function extractDueDate(actionItem) {
    const regexDefinition =
      /<definition\s+id\s*=\s*['"](\w+)['"]\s*(?:title\s*=\s*['"]([^'"]*)['"]\s*)?>([\s\S]*?)<\/definition>/g

    if (Array.isArray(actionItem)) {
      return actionItem
        .map((item) => {
          if (typeof item.date === 'string') {
            const defMatch = item.date.match(regexDefinition)
            return defMatch ? extractDateFromDefinition(item.date) : item.date
          }
          return ''
        })
        .filter(Boolean)
        .join(', ')
    } else if (actionItem && typeof result === 'object' && 'date' in actionItem) {
      const defMatch = actionItem.date.match(regexDefinition)
      return defMatch ? extractDateFromDefinition(actionItem.date) : actionItem.date
    } else if (actionItem && typeof result === 'string') {
      const defMatch = actionItem.match(regexDefinition)
      return defMatch ? extractDateFromDefinition(actionItem) : actionItem
    } else {
      return ''
    }
  }

  const showContentTab = (nameTab) => {
    setPositionState(actualEmployer.value.id, 'gapAnalysisTab', nameTab)
    const allButtons = ulParent.value.querySelectorAll('button')
    for (let i = 0; i < allButtons.length; i++) {
      allButtons[i].classList.remove('active')
    }
    const triggerEl = ulParent.value.querySelector(
      `#myTab button[data-bs-target="#${nameTab}-tab-pane"]`
    )
    if (triggerEl) {
      triggerEl.classList.add('active')
    }
    const allDivContent = myTabContent.value.querySelectorAll('div .tab-pane')
    for (let i = 0; i < allDivContent.length; i++) {
      allDivContent[i].classList.remove('show')
      allDivContent[i].classList.remove('active')
    }
    const tabContent = myTabContent.value.querySelector(`#${nameTab}-tab-pane`)
    tabContent.style.opacity = 0
    tabContent.classList.add('show')
    setTimeout(() => {
      tabContent.style.opacity = 1
    }, 100)
    tabContent.classList.add('active')
  }

  function extractDateFromDefinition(definitionString) {
    const match = definitionString.match(/>([^<]+)</)
    return match ? match[1] : null
  }

  function setPositionScroll() {
    var scrollTop = mainContainer.value.scrollTop
    setPositionState(actualEmployer.value.id, 'gapAnalysisScroll', scrollTop)
    if (scrollTop > 110 && !itemsIsSticky.value) {
      itemsIsSticky.value = true
    } else if (scrollTop < 1 && itemsIsSticky.value) {
      itemsIsSticky.value = false
    }
  }

  function actionItemsInclude(obj) {
    return actionItemsOfAssessment.value.some((actionItem) => {
      return actionItem?.body === obj.action_item
    })
  }

  const scrollTop = () => {
    mainContainer.value.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function exportActionItemsToCSV() {
    const csv = []

    actionItemsOfAssessment.value.forEach((actionItem) => {
      csv.push({
        'Action Item Title': actionItem.title.replace(/"/g, "'"),
        'Action Item Description': actionItem?.body.replace(/"/g, '""').replace(/[\r\n]/g, ' '),
        //TODO add the validation
        'Action Item Next Steps':
          actionItem?.action_items_notes ?
            actionItem?.action_items_notes
          : '',
        'Responsible Party': actionItem?.responsibleParty
          .reduce((total, currentValue, currentIndex) => {
            if (currentValue) {
              switch (currentIndex) {
                case 0:
                  total.push('Employer')
                  break
                case 1:
                  total.push('Broker')
                  break
                case 2:
                  total.push('TPA')
                  break
              }
            }
            if (currentIndex === 2 && total.length === 0) {
              total.push('')
            }
            return total
          }, [])
          .join(', '),

        'Due Date': (() => {
          const result = executeStoredFunction(actionItem?.originalQuestion.action_item_due_date)
          return extractDueDate(result)
        })()
      })
    })

    const header = Object.keys(csv[0]).join(',')
    const body = csv
      .map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(',')
      )
      .join('\n')

    const csvContent = `data:text/csv;charset=utf-8,${header}\n${body}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${actualEmployer.value.name} BCS Gap Report Action Items.csv`)
    document.body.appendChild(link)
    link.click()
  }

  function getLastPanelTabShowed() {
    const allDivContent = myTabContent.value.querySelectorAll('div .tab-pane')
    for (let i = 0; i < allDivContent.length; i++) {
      if (allDivContent[i].classList.contains('show')) {
        return allDivContent[i].id.replace('-tab-pane', '')
      }
    }
  }

  async function tabsToImageForPDF(key) {
    const node = document.getElementById(`${key}Section`)
    const adjustClone = (node, clone, after) => {
      if (node && !after && clone.id === `${key}Section`) {
        clone.style.width = '842px'
        clone.style.height = '350px'
      }
      return clone
    }
    const dataUrl = await domtoimage.toPng(node, {
      width: 842,
      height: 350,
      quality: 0.7,
      style: { width: '842px', height: '350px' },
      adjustClonedNode: adjustClone
    })
    return dataUrl
  }

  async function imgSectionScoreToImage() {
    const node = document.getElementById('imgSectionScore')
    const adjustClone = (node, clone, after) => {
      if (node && !after && clone.id === `imgSectionScore`) {
        clone.style.width = '1280px'
        clone.style.height = '620px'
        clone.style.margin = '0'
        clone.style.paddingBottom = '24px'
      }
      return clone
    }
    const dataUrl = await domtoimage.toPng(node, {
      width: 1280,
      height: 620,
      quality: 0.7,
      style: { paddingBottom: '24px', margin: 0, width: '1280px', height: '620px' },
      adjustClonedNode: adjustClone
    })
    // await fetch(dataUrl)
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob)
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', 'sectionScore.png')
    //     document.body.appendChild(link)
    //     link.click()
    //   })
    return dataUrl
  }

  function deleteTagsDefinition(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, '')
  }

  async function getDataForPDF({ printWithCustomLogo }) {
    const tabPages = []
    for (let i = 0; i < finalItemsTab.value.length; i++) {
      if (hideDueICHRA(finalItemsTab.value[i].name)) {
        continue
      }
      const image = await tabsToImageForPDF(finalItemsTab.value[i].key)
      tabPages.push({
        page: `${i + 3}-${i + 4}`,
        title: finalItemsTab.value[i].name,
        subtitleScore: `${finalItemsTab.value[i].name} Scores`,
        descriptionScore: deleteTagsDefinition(
          getDefinitionBodySync(`${finalItemsTab.value[i].key}RequirementsText`)
        ),
        image,
        subtitleAnswer: `${finalItemsTab.value[i].name} Answers`,
        descriptionAnswer: deleteTagsDefinition(
          getDefinitionBodySync('gapAnalysisModalMyAnswerText', {
            sectionName: finalItemsTab.value[i].name
          })
        ),
        answers: finalItemsTab.value[i].questionWithAnswerAndNotes
          .filter((obj) => showQuestion(obj).show)
          .map((obj, i) => {
            return {
              title: deleteTagsDefinition(`Q${i + 1}: ${obj.question}`),
              answer: deleteTagsDefinition(`Answer: ${obj.answer}`),
              notes: obj.notes ? `Notes: ${obj.notes}` : '',
              warnings: obj.warning && actionItemsInclude(obj) ? obj.warning : ''
            }
          })
      })
    }

    const colorValidation = isValidHexColor(userData.value.whitelabelColor)
    if (!colorValidation && printWithCustomLogo) {
      handleErrors('Gap Analysis Report', new Error('Invalid whitelabel color'))
    }
    const whitelabelColor = printWithCustomLogo && userData.value.whitelabelColor && colorValidation
    ? userData.value.whitelabelColor
    : ''

    return {
      agencyInfo: {
        name: userData.value.companyName,
        ein: userData.value.ein,
        is_cap_company: userData.value.CAPCompany,
        location: userData.value.agencyLocation
      },
      employer: actualEmployer.value.name,
      medicalPlan: Object.values(intakeQuestions.value)[0][4].options[
        Object.values(intakeQuestions.value)[0][4].answer
      ],
      ein: actualEmployer.value.ein,
      controlledGroup: Object.values(intakeQuestions.value)[0][0].options[
        Object.values(intakeQuestions.value)[0][0].answer
      ],
      erisaPlan: Object.values(intakeQuestions.value)[0][1].options[
        Object.values(intakeQuestions.value)[0][1].answer
      ],
      newHire: Object.values(intakeQuestions.value)[0][3].options[
        Object.values(intakeQuestions.value)[0][3].answer
      ],
      coverages: map(Object.values(intakeQuestions.value)[0][5].answer, (ans) =>
        get(Object.values(intakeQuestions.value)[0][5].options, ans)
      ),
      fullTimeEmployers: Object.values(intakeQuestions.value)[0][2].answer,
      planYear: actualPlanYear.value.plan_start_date,
      customLogo: userData.value.logoBase64 || '',
      logoSize: userData.value.logoSize || '',
      isIntakeComplete: isIntakeComplete(),
      printWithCustomLogo,
      whitelabelColor,
      tableOfContents: [
        {
          pages: '1',
          icon: 'success',
          title: 'Executive Summary & Your Overall Scores',
          description: ''
        },
        {
          pages: '2',
          icon: 'success',
          title: 'Action Items',
          description: 'Summarized list of all findings to take action from the assessment'
        },
        ...finalItemsTab.value.map((item, i) => {
          if (hideDueICHRA(item.name)) {
            return {}
          }
          return {
            pages: `${i + 3}-${i + 4}`,
            icon: item.badge,
            title: item.name,
            description: item.title
          }
        })
      ],
      summaryPage: {
        page: '1',
        mainTitle: 'Executive Summary & Your Overall Scores',
        summaryTitle: 'Executive Summary',
        summaryContent: deleteTagsDefinition(
          getDefinitionBodySync('gapAnalysisExecutiveSummary', {
            overallRiskScore: Math.round(overallScore.value),
            approxPenaltyAmount: formatter.format(Math.round(totalCompRisk.value / 100) * 100)
          })
        ),
        scoreTitle: 'Your Overall Scores',
        scoreContent: deleteTagsDefinition(getDefinitionBodySync('gapAnalysisYourScoresText')),
        image: await imgSectionScoreToImage()
      },
      actionItemsPage: {
        page: '2',
        title: 'Action Items',
        totalActionItems: actionItemsOfAssessment.value.length,
        sections: [
          {
            title: 'Action Items',
            content: deleteTagsDefinition(getDefinitionBodySync('gapAnalysisActionItemText')),
            actionItems: actionItemsOfAssessment.value.map((actionItem) => {
              return {
                title: actionItem?.title,
                content: actionItem?.body,
                actionNotes:
                  actionItem?.action_items_notes ?
                    actionItem?.action_items_notes
                  : '',
                resposibles: actionItem?.responsibleParty
                  .reduce((total, currentValue, currentIndex) => {
                    if (currentValue) {
                      switch (currentIndex) {
                        case 0:
                          total.push('Employer')
                          break
                        case 1:
                          total.push('Broker')
                          break
                        case 2:
                          total.push('TPA')
                          break
                      }
                    }
                    if (currentIndex === 2 && total.length === 0) {
                      total.push('')
                    }
                    return total
                  }, [])
                  .join(', '),
                actionDueDate: (() => {
                  const result = executeStoredFunction(
                    actionItem?.originalQuestion.action_item_due_date
                  )

                  const dueDateExtracted = [
                    {
                      date: extractDueDate(result)
                    }
                  ]
                  return dueDateExtracted
                })()
              }
            })
          }
        ]
      },
      tabPages
    }
  }

  function formatDate(date, excludeYear = false) {
    if (date) {
      let options = { year: 'numeric', month: 'long', day: 'numeric' }
      if (excludeYear) {
        delete options.year
      }
      return date.toLocaleDateString('en-US', options)
    }
    return ''
  }

  function executeStoredFunction(storedFunction) {
    if (storedFunction) {
      let returnValue = ''
      try {
        const getActionItemDueDate = new Function('planStartDate', 'formatDate', storedFunction)
        returnValue = getActionItemDueDate(actualPlanYear.value.plan_start_date, formatDate)
      } catch (error) {
        handleErrors('Error calulating due date!', error)
        returnValue = 'Error calulating due date!'
      }
      return returnValue
    }
    return ''
  }

  async function generateGapReportPDF({ printWithCustomLogo = false }) {
    if (!templateID.value) {
      handleErrors('Gap Analysis Report', new Error('Template ID not found'))
      return
    }
    const lastTabShowed = getLastPanelTabShowed()
    isGeneratingPDF.value = true
    closeModalDownload()
    toggleLoadingWithMessage(true, {
      title: 'Generating your report...',
      body: 'This could take a minute or two. Thanks for your \n patience while we create the good stuff.'
    })

    const data = await getDataForPDF({ printWithCustomLogo })

    const GAP_REPORT_DOWNLOADED = 1
    const eventData = {
      eventId: GAP_REPORT_DOWNLOADED,
      logName: 'user_activity_log',
      employerId: actualEmployer.value.id,
      agencyId: actualEmployer.value.agency_id
    }

    downloadPdf({
      eventData,
      fileName: `BCS Pro ${actualEmployer.value.name} Compliance Gap Analysis Report.pdf`,
      templateID: templateID.value,
      data
    }).finally(() => {
      isGeneratingPDF.value = false
      setTimeout(() => {
        showContentTab(lastTabShowed)
      }, 500)
    })
  }

  async function saveActionItemNote(text, question, arrayIndex) {
    if (isNumber(arrayIndex)) {
      try {
        const notes = JSON.parse(question.action_items_notes)
        notes[arrayIndex] = text
        question.action_items_notes = JSON.stringify(notes)
      } catch {
        const notes = []
        notes[arrayIndex] = text
        question.action_items_notes = JSON.stringify(notes)
      }
    } else {
      question.action_items_notes = text
    }
    updateAssessmentQuestion(question)
    await saveQuestions('assessment', actualEmployer.id)
  }

  async function saveActionItemRespParty(value, index, question, actItemIndex) {
    if (isNumber(actItemIndex)) {
      try {
        const completeRespParty = JSON.parse(question.action_items_resp_party)
        const currentResponsibleParty = Array.isArray(completeRespParty[actItemIndex]) ? completeRespParty[actItemIndex] : []
        currentResponsibleParty[index] = value
        completeRespParty[actItemIndex] = currentResponsibleParty
        question.action_items_resp_party = JSON.stringify(completeRespParty)
      } catch {
        const completeRespParty = []
        const currentResponsibleParty = []
        currentResponsibleParty[index] = value
        completeRespParty[actItemIndex] = currentResponsibleParty
        question.action_items_resp_party = JSON.stringify(completeRespParty)
      }
    } else {
      question.action_items_resp_party[index] = value
    }
    updateAssessmentQuestion(question)
    await saveQuestions('assessment', actualEmployer.id)
  }

  function openModalDownloadPdf() {
    modalRef.value.openModal()
  }

  async function handleDownloadPdf() {
    if (userData.value.logoBase64) {
      whiteLabelModalContent.value = await getDefinitionById(
        'whiteLabelModalContentConfirmation',
        'White label modal content'
      )
      openModalDownloadPdf()
      return
    }
    generateGapReportPDF({ printWithCustomLogo: false })
  }

  function closeModalDownload() {
    modalRef.value.closeModal()
  }

  function handleAnswerModal(open = false) {
    if (answerModal) {
      if (open) {
        answerModal.show()
      } else {
        answerModal.hide()
      }
    }
  }

  watch(
    () => answerModalRef.value,
    (el) => {
      if (el && typeof bootstrap !== 'undefined') {
        answerModal = new bootstrap.Modal(el)
      }
    },
    { immediate: true }
  )
</script>

<template>
  <main
    class="col-10 px-0 scroll-fix"
    ref="mainContainer"
    id="mainContainer"
    @scroll="setPositionScroll">
    <div class="sticky-top bg-white">
      <div class="container-fluid ps-4">
        <div class="d-flex justify-content-between pb-2" :class="{ 'pt-4.2': !itemsIsSticky }">
          <div class="w-75 d-flex flex-column justify-content-end">
            <h1 class="title-gap">{{ actualEmployer?.name }} Gap Analysis Report</h1>
            <p v-if="!itemsIsSticky" class="mt-1 text-14">
              <BcsDynamicText text="gapAnalysisInstructionalText" />
            </p>
          </div>
          <div class="action-buttons d-flex flex-column align-items-end me-4 mt-4">
            <div class="d-inline-flex">
              <button type="button" class="btn btn-info me-2" @click="handleDownloadPdf">
                <i class="bi bi-download"></i>
                Download PDF Full Report
              </button>
              <button type="button" class="btn btn-primary" @click="exportActionItemsToCSV()">
                <i class="bi bi-check2-square"></i>
                Export CSV Action Item List
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3">
        <ul class="nav nav-tabs" id="myTab" role="tablist" ref="ulParent">
          <li ref="liSummary" class="nav-item ps-4" role="presentation">
            <button
              class="nav-link active border-top border-end border-start"
              id="summary-tab"
              @click="showContentTab('summary')"
              data-bs-toggle="tab"
              data-bs-target="#summary-tab-pane"
              type="button"
              role="tab"
              aria-controls="summary-tab-pane"
              aria-selected="true">
              Summary & Action Items
            </button>
          </li>
          <li
            ref="liItems"
            v-for="(item, i) in itemsTab"
            class="nav-item"
            role="presentation"
            :key="i">
            <button
              class="nav-link border-top border-end border-start"
              :id="`${item.key}-tab`"
              @click="showContentTab(item.key)"
              data-bs-toggle="tab"
              :data-bs-target="`#${item.key}-tab-pane`"
              type="button"
              role="tab"
              :aria-controls="`${item.key}-tab-pane`"
              :aria-selected="false"
              v-if="!hideDueICHRA(item.name)">
              <i
                v-if="item.badge == 'danger'"
                class="bi bi-exclamation-circle-fill text-danger"></i>
              <i
                v-if="item.badge == 'warning'"
                class="bi bi-exclamation-triangle-fill text-warning"></i>
              {{ item.name }}
            </button>
          </li>
          <li ref="liMore" class="nav-item dropdown" v-if="dropdownItemsTab.length > 0">
            <a
              class="nav-link dropdown-toggle text-primary"
              data-bs-toggle="dropdown"
              role="button"
              aria-expanded="false">
              More
            </a>
            <ul class="dropdown-menu">
              <li v-for="(dropdownItemTab, i) in dropdownItemsTab" :key="i">
                <a
                  v-if="!hideDueICHRA(dropdownItemTab.name)"
                  class="dropdown-item hand-cursor"
                  @click="showContentTab(dropdownItemTab.key)">
                  {{ dropdownItemTab.name }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="container-fluid ps-4">
      <div class="tab-content" id="myTabContent" ref="myTabContent">
        <div
          class="tab-pane fade show active me-5 pe-5"
          id="summary-tab-pane"
          :class="isGeneratingPDF ? 'show active' : ''"
          role="tabpanel"
          aria-labelledby="summary-tab"
          tabindex="0">
          <div class="ps-3 pe-5 pt-5 me-5">
            <span class="header-22">
              <strong>Executive Summary</strong>
            </span>

            <p class="text-16 mt-3 white-space-pre-wrap">
              <BcsDynamicText
                key="gapAnalysisExecutiveSummary"
                text="gapAnalysisExecutiveSummary"
                :variables="{
                  overallRiskScore: Math.round(overallScore),
                  approxPenaltyAmount: formatter.format(Math.round(totalCompRisk / 100) * 100)
                }" />
            </p>

            <div>
              <span class="header-22 fw-bold me-3">Your Scores</span>
              <a class="hand-cursor text-primary text-decoration-none ms-2">
                <BcsDynamicText key="howAreScoreCalculatedText" text="howAreScoreCalculatedText" />
              </a>
              <i class="bi bi-info-circle text-primary ms-1"></i>
              <a class="hand-cursor text-primary text-decoration-none ms-4">
                <BcsDynamicText key="gapColorCodesText" text="gapColorCodesText" />
              </a>
              <i class="bi bi-info-circle text-primary ms-1"></i>
            </div>
            <p class="text-16 mt-3">
              <BcsDynamicText text="gapAnalysisYourScoresText" />
            </p>
            <div
              class="card px-4 mt-5 mb-4"
              id="imgSectionScore"
              :class="isGeneratingPDF ? 'width-1280' : ''">
              <div class="row" id="rowImgSectionScore">
                <div class="col-report py-2 border-end">
                  <span class="text-16">
                    <strong>Overall Compliance Score</strong>
                  </span>
                  <p class="text-12 mt-2">
                    <BcsDynamicText text="gapAnalysisSectionScoresText" />
                  </p>
                  <template v-for="(score, n) in scores" :key="n">
                    <div
                      v-if="!hideDueICHRA(score.name)"
                      class="row d-flex align-items-center text-end">
                      <div class="mx-3 p-0 col-2">
                        <span>{{ score.name }}</span>
                      </div>
                      <div class="p-0 col-8">
                        <div
                          class="progress my-2.75"
                          role="progressbar"
                          aria-label="Success example"
                          :aria-valuenow="score.score"
                          aria-valuemin="0"
                          aria-valuemax="100">
                          <div
                            :class="`progress-bar bg-${score.color}`"
                            :style="`width: ${Math.round(score.score)}%`"></div>
                        </div>
                      </div>
                      <div class="me-3 ms-1 p-0 col-1">
                        <span>{{ Math.round(score.score) }}%</span>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-report-2 py-4 d-flex">
                  <div class="row">
                    <div class="col-12 text-center">
                      <CircularBarProgress :percent="Math.round(overallScore)" class="mb-4" />
                      <span class="fw-bold">Overall (Average) Score</span>
                      <p class="mt-3">
                        <BcsDynamicText text="overallScoreCircleGraphText" />
                      </p>
                    </div>
                    <div class="col-12 border-top d-flex align-items-center">
                      <div class="text-center d-block mx-auto">
                        <h4 class="text-danger header-40 fw-bold mt-0">
                          {{ formatter.format(Math.round(totalCompRisk / 100) * 100) }}
                        </h4>
                        <span class="fw-bold">Total Compliance Risk</span>
                        <p class="mt-3">
                          <BcsDynamicText text="gapAnalysisTotalComplianceRiskText" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-5" />
            <template v-if="actionItemsOfAssessment.length > 0">
              <span class="header-22 fw-bold">
                ({{ actionItemsOfAssessment.length }}) Action Items
              </span>
              <p class="text-14 mt-2">
                <BcsDynamicText text="gapAnalysisActionItemText" />
              </p>

              <p class="text-14">Showing {{ actionItemsOfAssessment.length >= 10 ? 10 : actionItemsOfAssessment.length }} of {{ actionItemsOfAssessment.length }}</p>

              <ul class="list-group mt-3 mb-2 py-2">
                <li
                  v-for="(actionItem, i) of actionItemsOfAssessment.slice(
                    0,
                    showFillActionItems ? actionItemsOfAssessment.length : 10
                  )"
                  :key="i"
                  class="list-group-item item-list d-flex border-0">
                  <div class="text-warning fs-4">
                    <i class="bi bi-check-circle-fill"></i>
                  </div>
                  <div class="mx-4 d-flex justify-content-between item-report">
                    <div class="w-100">
                      <strong>#{{ i + 1 }} - {{ actionItem.title }}</strong>
                      <p class="mb-0 text-14 mt-2">
                        <span v-html="actionItem.body"></span>
                      </p>
                      <textarea
                        style="margin-top: 14px"
                        class="form-control action-item-text-area"
                        placeholder="Enter notes, direction, next steps, etc"
                        rows="3"
                        :value="actionItem.action_items_notes"
                        @change="
                          saveActionItemNote($event.target.value, actionItem.originalQuestion, actionItem.arrayIndex)
                        "></textarea>

                      <div class="text-14 mt-2 d-flex">
                        <strong>Responsible Party:</strong>

                        <div class="d-flex checkbox-responsible-party">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="actionItem.responsibleParty[0]"
                              @click="
                                saveActionItemRespParty(
                                  $event.target.checked,
                                  0,
                                  actionItem.originalQuestion,
                                  actionItem.arrayIndex
                                )
                              "
                              :id="'employeer-check' + actionItem.originalQuestion.id + actionItem.key" />
                            <label
                              class="form-check-label"
                              :for="'employeer-check' + actionItem.originalQuestion.id + actionItem.key">
                              <strong></strong>
                              Employer
                            </label>
                          </div>

                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="actionItem.responsibleParty[1]"
                              @click="
                                saveActionItemRespParty(
                                  $event.target.checked,
                                  1,
                                  actionItem.originalQuestion,
                                  actionItem.arrayIndex
                                )
                              "
                              :id="'broker-check' + actionItem.originalQuestion.id + actionItem.key" />
                            <label
                              class="form-check-label"
                              :for="'broker-check' + actionItem.originalQuestion.id + actionItem.key">
                              Broker
                            </label>
                          </div>

                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="actionItem.responsibleParty[2]"
                              @click="
                                saveActionItemRespParty(
                                  $event.target.checked,
                                  2,
                                  actionItem.originalQuestion,
                                  actionItem.arrayIndex
                                )
                              "
                              :id="'tpa-check' + actionItem.originalQuestion.id + actionItem.key" />
                            <label
                              class="form-check-label"
                              :for="'tpa-check' + actionItem.originalQuestion.id + actionItem.key">
                              TPA
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex due-action-items text-14">
                    <template v-if="actionItem?.originalQuestion.action_item_due_date">
                      <div>Due:</div>

                      <div class="d-flex flex-column">
                        <div
                          v-for="(dueDate, idx) of executeStoredFunction(
                            actionItem?.originalQuestion.action_item_due_date
                          ) || []"
                          :key="idx">
                          <div class="due-action-items__element">
                            <strong>
                              <TextWithDefinitions :text="dueDate.date || ''"></TextWithDefinitions>
                            </strong>
                            <p>
                              {{ dueDate.description || '' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </li>
              </ul>

              <p class="text-12">
                You’re viewing 10 of {{ actionItemsOfAssessment.length }} action items
              </p>
              <button
                v-if="actionItemsOfAssessment.length > 8"
                type="button"
                class="btn btn-info me-3"
                @click="showFillActionItems = !showFillActionItems">
                {{
                  showFillActionItems ?
                    `Collapse Additional Action Items`
                  : `Load All ${actionItemsOfAssessment.length} Action Items`
                }}
                <i
                  :class="
                    showFillActionItems ? 'bi bi-chevron-double-up' : 'bi bi-chevron-double-down'
                  "></i>
              </button>

              <p class="text-12 mt-3">
                Your scores are calculated using a risk-weighted-average method across the answered
                questions in each section.
                <br />
                The overall score also uses a risk-weighted-average calculation across all the
                sections of the assessment.
              </p>

              <button type="button" class="btn btn-primary" @click="exportActionItemsToCSV()">
                <i class="bi bi-check2-square"></i>
                &nbsp; Export CSV Action Item List
              </button>

              <hr />
            </template>
            <div class="py-4 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-outline-primary bg-info me-3"
                @click="showContentTab(finalItemsTab[0]?.key)">
                {{ finalItemsTab[0]?.name }}
                <i class="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          v-for="(item, j) in finalItemsTab"
          class="tab-pane fade"
          :class="isGeneratingPDF ? 'show active' : ''"
          :id="`${item.key}-tab-pane`"
          role="tabpanel"
          :aria-labelledby="`${item.key}-tab`"
          tabindex="0"
          :key="j">
          <div class="ps-3 pe-5 me-5">
            <p class="header-22 fw-bold mt-5">{{ item.name }}</p>

            <p class="text-16 mt-2 mb-5 white-space-pre-wrap">
              <BcsDynamicText :text="`${item.key}RequirementsText`" />
            </p>
            <div
              class="card px-4 mb-4 w-75"
              :id="`${item.key}Section`"
              :class="isGeneratingPDF ? 'with-842' : ''">
              <div class="row py-4">
                <div class="col-12">
                  <span class="text-16">
                    <strong>Scores</strong>
                  </span>
                  <p class="text-12 mt-3">
                    <BcsDynamicText :text="`${item.key}MonetaryRiskText`" />
                  </p>
                </div>
                <div class="col-6 py-4 text-center border-end">
                  <h4
                    class="header-40 fw-bold my-4"
                    :class="item.badge == 'success' ? 'text-success' : 'text-danger'">
                    {{ item.dollarRisk }}
                  </h4>
                  <span class="fw-bold">{{ item.title }}</span>
                  <p class="mt-3 text-center">
                    {{ item.subtitle }}
                  </p>
                </div>
                <div class="col-6 py-4 text-center">
                  <SemiCircularBarProgress :value="Math.round(item.score)" :keyName="item.key" />
                  <p class="fw-bold mt-3 mb-0">
                    {{ item.name + ' score: ' + Math.round(item.score) + '%' }}
                  </p>
                  <p class="mt-3">
                    <BcsDynamicText :text="`${item.key}SectionRiskScoreText`" />
                  </p>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-white text-primary mt-1"
              @click="(currentItemTabSelected = item), handleAnswerModal(true)">
              <i class="bi bi-chat-text-fill"></i>
              &nbsp; View My Answers
            </button>
            <hr class="my-4 py-2" />
            <div class="d-flex justify-content-between mb-4">
              <button
                type="button"
                class="btn btn-outline-primary bg-info me-3"
                @click="
                  showContentTab(finalItemsTab[j - 1] ? finalItemsTab[j - 1].key : 'summary')
                ">
                <i class="bi bi-chevron-left"></i>
                {{ finalItemsTab[j - 1] ? finalItemsTab[j - 1].name : 'Summary & Action Items' }}
              </button>

              <button
                v-if="finalItemsTab[j + 1] && !hideDueICHRA(finalItemsTab[j + 1].name)"
                type="button"
                class="btn btn-outline-primary bg-info me-3"
                @click="showContentTab(finalItemsTab[j + 1].key)">
                <i
                  v-if="finalItemsTab[j + 1]?.badge == 'danger'"
                  class="bi bi-exclamation-circle-fill text-danger"></i>
                <i
                  v-if="finalItemsTab[j + 1]?.badge == 'warning'"
                  class="bi bi-exclamation-triangle-fill text-warning"></i>
                &nbsp;{{ finalItemsTab[j + 1].name }}&nbsp;
                <i class="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column">
      <div class="p-0 position-relative button-top w-0">
        <button
          @click="scrollTop()"
          class="btn btn-primary rounded-circle d-flex justify-content-center align-items-center">
          <div>
            <i class="bi bi-chevron-up position-relative"></i>
            <span class="text-12 position-relative">
              <strong>Top</strong>
            </span>
          </div>
        </button>
      </div>
      <div class="p-0">
        <Footer class="px-4"></Footer>
      </div>
    </div>
  </main>

  <!-- modals section -->
  <div
    class="modal fade mt-5"
    id="answerModal"
    tabindex="-1"
    aria-labelledby="answerModalLabel"
    aria-hidden="true"
    ref="answerModalRef">
    <div class="modal-dialog modal-content">
      <div class="">
        <div class="modal-header px-4 d-flex flex-column bg-gray-100 mb-0">
          <div class="d-flex w-100 justify-content-between">
            <span class="modal-title header-22" id="answerModalLabel">
              <strong>My Answers: {{ currentItemTabSelected.name }}</strong>
            </span>
            <button
              type="button"
              class="btn-close"
              @click="() => handleAnswerModal(false)"
              aria-label="Close"></button>
          </div>
          <p class="me-auto mb-0">
            <BcsDynamicText
              v-if="currentItemTabSelected.name"
              :key="currentItemTabSelected.name"
              text="gapAnalysisModalMyAnswerText"
              :variables="{ sectionName: currentItemTabSelected.name }" />
          </p>
        </div>
        <div class="modal-body px-4 overflow-auto">
          <template v-for="(obj, i) in currentItemTabSelected.questionWithAnswerAndNotes" :key="i">
            <div v-if="showQuestion(obj).show">
              <p class="text-16 mb-2">
                <strong>
                  <TextWithDefinitions
                    :text="`Q${i + 1}: ${obj.question}`"
                    :isCenterModal="true"
                    :previousModalData="{
                      backToLabel: `All Answers: ${currentItemTabSelected.name}`,
                      handleModal: handleAnswerModal
                    }" />
                </strong>
              </p>
              <TextWithDefinitions
                class="text-16 mb-2"
                :key="obj.answer"
                :text="`Answer: ${obj.answer}`"
                :isCenterModal="true"
                :previousModalData="{
                  backToLabel: `All Answers: ${currentItemTabSelected.name}`,
                  handleModal: handleAnswerModal
                }" />
              <p class="text-16 mb-0" v-if="obj.notes">Notes: {{ obj.notes }}</p>
              <div v-if="obj.warning && actionItemsInclude(obj)" class="mt-2">
                <i class="bi bi-exclamation-triangle-fill text-warning me-2 position-relative"></i>
                <span class="text-16 bg-warning-300">&nbsp;{{ obj.warning }}</span>
              </div>
              <hr v-if="i < currentItemTabSelected.questionWithAnswerAndNotes.length - 1" />
            </div>
          </template>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-white text-primary"
            data-bs-dismiss="modal"
            @click="
              customNavigation.navigate({
                newPage: customNavigation.currentPage,
                subPage: customNavigation.routes.DetailEmployer.children[2].name,
                query: { step: currentItemTabSelected.title }
              })
            ">
            <a class="hand-cursor">Go to Assessment Form</a>
          </button>

          <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- white label modal -->
  <WhiteLabelModal
    :handleDownload="generateGapReportPDF"
    :onCloseModal="closeModalDownload"
    :logoBase64="userData.logoBase64"
    :logoSize="userData.logoSize"
    ref="modalRef" />
</template>

<style scoped lang="scss">
  .bg-warning-300 {
    background-color: #fff6da;
  }
  button.btn-outline-primary.bg-info:hover {
    background-color: var(--bs-primary) !important;
  }
  .sidebar {
    width: 228px;
    height: 100vh;
    background-color: #f7f1fd;
  }
  .pt-4\.2 {
    padding-top: 27px;
  }
  .scroll-fix {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 94vh;
  }
  .hidden-action-items {
    overflow-y: hidden;
  }
  .width-1280 {
    width: 1280px !important;
  }
  .with-842 {
    width: 842px !important;
  }

  .col-report {
    width: 68%;
  }
  .col-report-2 {
    width: 32%;
  }

  .action-item-text-area::placeholder {
    color: #6c757d;
  }

  .due-action-items {
    gap: 16px;
    width: 500px;
    padding-left: 50px;
  }

  .checkbox-responsible-party {
    padding-left: 16px;
    gap: 16px;
  }

  .item-list {
    border-bottom: 1px solid var(--bs-gray-400) !important;
    margin-bottom: 33px;
    padding-bottom: 30px;
  }

  .modal-content {
    border-radius: 10px;
    max-width: 800px;
    background-color: white;
  }

  .modal-body {
    max-height: 500px;
  }

  .modal-title {
    font-weight: bold;
    font-size: 1.25rem;
  }

  .btn-outline-primary {
    border-color: #6f42c1;
    color: #6f42c1;
  }

  .btn-link {
    color: #6f42c1;
  }

  .item-report {
    flex-basis: calc(33.333% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    margin: 10px;
    min-width: 480px;
    max-width: calc(100% - 500px);
  }

  .title-gap {
    font-size: 28px;
    margin-bottom: 8px;
  }

  .action-buttons {
    width: 65%;
  }
</style>
