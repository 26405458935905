import { inject, ref } from 'vue'
import { defineStore } from 'pinia'
import { RequestService } from '../services/RequestService'
const { simpleGet, handleErrors } = RequestService()

export const useFaqsStore = defineStore('faqs', () => {
  const faqs = ref([])
  const initialized = ref(false)
  const dataBaseRoute = inject('realdatabase')

  async function initializeFaqs() {
    if (initialized.value && faqs.value.length > 0) {
      return faqs.value
    }

    try {
      const token = localStorage.getItem('userToken')
      if (!token) {
        handleErrors('not found token')
        return
      }

      const endpoint = `${dataBaseRoute}/FAQS.json?auth=${token}`
      const response = await simpleGet('getDefinition', endpoint, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })

      faqs.value = response.data
      initialized.value = true
    } catch (error) {
      handleErrors(error)
    }
  }

  async function getFaqs() {
    if (!initialized.value) {
      await initializeFaqs()
    }
    return faqs
  }

  return {
    faqs,
    getFaqs,
    initialized
  }
})
