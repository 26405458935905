<script setup>
  import { ref, inject, watchEffect } from 'vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'

  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore

  const props = defineProps({
    showMarginTop: {
      type: Boolean,
      default: true
    }
  })

  const urlLinkedIn = ref({ title: '', body: '' })
  const urlFacebook = ref({ title: '', body: '' })
  const urlInstagram = ref({ title: '', body: '' })
  const urlYouTube = ref({ title: '', body: '' })
  watchEffect(async () => {
    urlLinkedIn.value = await getDefinitionById('urlLinkedIn')
    urlFacebook.value = await getDefinitionById('urlFacebook')
    urlInstagram.value = await getDefinitionById('urlInstagram')
    urlYouTube.value = await getDefinitionById('urlYouTube')
  })
  const emitter = inject('emitter')
  const customNavigation = inject('customNavigation')
</script>

<template>
  <div :class="{ 'mt-4.5': props.showMarginTop }" class="main-container bg-light border-top">
    <div class="container-fluid mx-0 px-3 pt-1">
      <footer class="row pt-4 pb-0 mb-0 text-14 text-primary">
        <div class="col-1 me-5 p-0">
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click.prevent="customNavigation.navigate({ newPage: customNavigation.routes.Home.name })"
                href="#"
                >
                Home
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click.prevent="
                  customNavigation.navigate({
                    newPage: customNavigation.routes.helpAndResources.name
                  })
                "
                href="#"
                >
                Help
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click="emitter.emit('toggleContactModal')" 
                >
                Contact
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click="
                  customNavigation.navigate({
                    newPage: customNavigation.routes.memberProfile.name
                  })
                "
                >
                Profile
              </a>
            </li>
          </ul>
        </div>

        <div class="col-2 me-3 p-0">
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click.prevent="
                  customNavigation.navigate({
                    newPage: customNavigation.routes.ListOfEmployers.name
                  })
                "
                href="#"
                >
                All Employer Dashboard
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click.prevent="
                  customNavigation.navigate({
                    newPage: customNavigation.routes.helpAndResources.name
                  })
                "
                href="#"
                >
                Training and Resources
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                class="hand-cursor nav-link p-0"
                @click.prevent="
                  customNavigation.navigate({
                    newPage: customNavigation.routes.frequentlyQuestions.name
                  })
                "
                href="#"
                >
                Frequently Asked Questions
              </a>
            </li>
          </ul>
        </div>

        <div class="col ps-5">
          <!-- <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a class="hand-cursor nav-link p-0" data-bs-toggle="modal"
                    data-bs-target="#pendingFunctionModal">Privacy Policy</a>
            </li>
            <li class="nav-item mb-2">
              <a class="hand-cursor nav-link p-0" data-bs-toggle="modal"
                    data-bs-target="#pendingFunctionModal">Terms & Conditions</a>
            </li>
            <li class="nav-item mb-2">
              <a class="hand-cursor nav-link p-0" data-bs-toggle="modal"
                    data-bs-target="#pendingFunctionModal">Legal</a>
            </li>
          </ul> -->
        </div>

        <div class="col-md-6 text-end pe-0">
          <ul class="d-flex float-end list-unstyled">
            <li class="ms-3">
              <a class="hand-cursor" target="_blank" :href="urlLinkedIn.body">
                <i class="fs-4 bi bi-linkedin social-icon"></i>
              </a>
            </li>
            <li class="ms-3">
              <a class="hand-cursor" target="_blank" :href="urlFacebook.body">
                <i class="fs-4 bi bi-facebook social-icon"></i>
              </a>
            </li>
            <li class="ms-3">
              <a class="hand-cursor" target="_blank" :href="urlInstagram.body">
                <i class="fs-4 bi bi-instagram social-icon"></i>
              </a>
            </li>
            <li class="ms-3">
              <a class="hand-cursor" target="_blank" :href="urlYouTube.body">
                <i class="fs-3 bi bi-youtube social-icon"></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <div class="row pb-2 pt-4">
        <div class="col-12 px-0">
          <i class="bi bi-c-circle"></i>
          <p class="ps-1 text-12 d-inline">
            {{ new Date().getFullYear() }} Benefits Compliance Solutions. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
