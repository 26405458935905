<script setup>
  import { ref, onMounted } from 'vue'
  import BcsModal from './BcsModal.vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'

  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore

  const props = defineProps({
    onCloseModal: {
      type: Function,
      default: () => null
    },
    logoBase64: {
      type: String,
      default: ''
    },
    handleDownload: {
      type: Function,
      default: () => null
    },
    logoSize: {
      type: String,
      default: 'small'
    }
  })

  const whiteLabelModalContent = ref({})
  const modalRef = ref(null)

  onMounted(async () => {
    whiteLabelModalContent.value = await getDefinitionById(
      'whiteLabelModalContentConfirmation',
      'White label modal content'
    )
  })

  function openModal() {
    modalRef.value.openModal()
  }
  function closeModal() {
    modalRef.value.closeModal()
  }

  defineExpose({ openModal, closeModal })
</script>

<template>
  <BcsModal
    @modalButtonClick="() => props.handleDownload({ printWithCustomLogo: true })"
    @closeButtonClicked="props.onCloseModal"
    ref="modalRef"
    modalSize="small"
    :modalType="'confirmation'"
    :modalButton="'Print With My Logo'"
    :modalTitle="'Would you like to include your uploaded logo on this document?'">
    <p class="mb-4">
      {{ whiteLabelModalContent.body }}
    </p>

    <template #body-header>
      <img
        :src="props.logoBase64"
        alt="Logo"
        class="mx-auto d-block mb-3"
        :class="props.logoSize === 'h_rectangle' ? 'logo-large' : 'logo-small'" />
    </template>

    <template #footer-body>
      <div class="d-flex flex-column align-items-center gap-2 mt-3">
        <button
          type="button"
          class="btn btn-outline-primary fw-medium"
          @click="() => props.handleDownload({ printWithCustomLogo: false })">
          Print Without My Logo
        </button>
        <button type="button" class="btn text-primary fw-bold" @click="props.onCloseModal">
          Cancel Print
        </button>
      </div>
    </template>
  </BcsModal>
</template>

<style scoped>
  .logo-small {
    max-width: 100px;
  }
  .logo-large {
    max-width: 150px;
  }
</style>
