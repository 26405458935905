import bcsData from '../../database/127_0_0_1_instance_c1187.json'
import { RequestService } from './RequestService'
import {
  agencyInsert,
  questionInsert,
  eventInsert,
  questionSectionInsert,
  agencyEmployeeInsert,
  complianceEventInsert,
  employerInsert,
  employerPlanYearInsert,
  answerInsert,
  reminderSentInsert,
  agencyByOriginalId,
  userByOriginalId,
  questionSectionsByOriginalId,
  questionByOriginalId,
  employerPlanYearByOriginalId,
  employerByOriginalId,
  userDeleteMany,
  agencyDeleteMany,
  questionSectionDeleteMany,
  agencyEmployeeDeleteMany,
  eventDeleteMany,
  complianceEventDeleteMany,
  answerDeleteMany,
  employerDeleteMany,
  employerPlanYearDeleteMany,
  reminderSentDeleteMany
} from '@bcs-pro/data-connector'

import axios from 'axios'

const { handleErrors /*, post*/ } = RequestService()

// const axiosConfig = {
//   headers: {
//     'Content-Type': 'application/json',
//     Accept: 'application/json',
//     'Access-Control-Allow-Origin': '*'
//   }
// }

export const DataLoadService = () => {
  // const defaultPass = '123456'

  const deleteAllUsers = async () => {
    try {
      await userDeleteMany()
      await agencyDeleteMany()
      await questionSectionDeleteMany()
      await agencyEmployeeDeleteMany()
      await eventDeleteMany()
      await complianceEventDeleteMany()
      await answerDeleteMany()
      await employerDeleteMany()
      await employerPlanYearDeleteMany()
      await reminderSentDeleteMany()
      console.log('deleted')
    } catch (error) {
      handleErrors('deleteAllUsers', error)
    }
  }

  function toCamelCase(snakeStr) {
    return snakeStr.replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
  }

  function convertKeysToCamelCase(obj) {
    if (Array.isArray(obj)) {
      return obj.map((v) => convertKeysToCamelCase(v))
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce((result, key) => {
        result[toCamelCase(key)] = convertKeysToCamelCase(obj[key])
        return result
      }, {})
    }
    return obj
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  const loadDetachedTable = async (
    tableName,
    insertMethod,
    hasOrigId,
    intValues = [],
    floatValues = [],
    boolValues = [],
    references = {} // {finalFieldName: [operation, varNameOnJson]}
  ) => {
    const items = bcsData.filter((val) => val.name === tableName)[0]
    //items.data.forEach(async (agency) => {
    for (let dataItem of items.data) {
      if (hasOrigId) {
        const origId = parseInt(dataItem.id)
        dataItem.originalId = origId
      }
      delete dataItem.id
      dataItem = convertKeysToCamelCase(dataItem)
      //finally we transform the values
      intValues.forEach((val) => {
        dataItem[val] = parseInt(dataItem[val] || '0')
      })

      floatValues.forEach((val) => {
        dataItem[val] = parseFloat(dataItem[val] || '0')
      })

      boolValues.forEach((val) => {
        dataItem[val] = dataItem[val] && dataItem[val] === '1'
      })
      //finally we check for references
      for (const parameter of Object.keys(references)) {
        try {
          const response = await references[parameter][0]({
            originalId: parseInt(dataItem[references[parameter][1]])
          })
          if (!Object.values(response.data)[0][0]?.id) {
            throw new Error(`No item ${references[parameter][0].name} found`)
          }
          dataItem[parameter] = { id: Object.values(response.data)[0][0].id }
          delete dataItem[references[parameter][1]]
        } catch (error) {
          handleErrors('loadDetachedTable', error)
        }
      }
      try {
        const insertedItem = await insertMethod(dataItem)
        console.log(insertMethod.name + 'result :', insertedItem)
        await delay(1000)
      } catch (error) {
        handleErrors('insertMethod ', insertMethod.name, error)
      }
    }
  }

  const loadData = async () => {
    //We start by reviwigin the users
    let all = false
    if (all) {
      const usersObj = bcsData.filter((val) => val.name === 'modx_users')[0]
      //usersObj.data.forEach(async (user) => {
      for (const user of usersObj.data) {
        //for each user get te user attributes
        const userAttributesObj = bcsData
          .filter((val) => val.name === 'modx_user_attributes')[0]
          .data.filter((val) => val.internalKey === user.id)[0]

        const userObj = {
          username: user.username,
          email: userAttributesObj.email,
          fullname: userAttributesObj.fullname,
          phone: userAttributesObj.phone,
          originalId: user.id
        }

        try {
          let data = JSON.stringify(userObj)
          let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: 'https://userbyemail-ey3yxqi7rq-uc.a.run.app?email=' + userAttributesObj.email,
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          }

          const newUser = await axios.request(config)

          console.log('stored user', newUser)
        } catch (error) {
          handleErrors('loadData', error)
        }
      }

      //then we load all independent tables
      await loadDetachedTable('modx_bcs_agencies', agencyInsert, true)
      await loadDetachedTable(
        'modx_bcs_question_sections',
        questionSectionInsert,
        true,
        ['isMain', 'dependsOn', 'type'],
        ['weight']
      )
      //and now with users and agencies we create the user_agencies
      const userAgenciesObj = bcsData.filter((val) => val.name === 'modx_bcs_agency_employee')[0]
      //userAgenciesObj.data.forEach(async (userAgency) => {
      try {
        for (const userAgency of userAgenciesObj.data) {
          //first we search for the user
          const user = await userByOriginalId({ originalId: parseInt(userAgency.user_id) })
          const agency = await agencyByOriginalId({ originalId: parseInt(userAgency.agency_id) })

          console.log('user', user)
          console.log('agency', agency)

          if (user.data.users.length === 0) {
            console.error('Missing user: ', userAgency.user_id)
            continue
          }

          if (agency.data.agencies.length === 0) {
            console.error('Missing agency: ', userAgency.agency_id)
            continue
          }

          await agencyEmployeeInsert({
            user: { id: user.data.users[0].id },
            agency: { id: agency.data.agencies[0].id },
            isManager: parseInt(userAgency.is_manager)
          }) //
        }
      } catch (error) {
        handleErrors('loadData', error)
      }

      //Now we load the questions
      await loadDetachedTable(
        'modx_bcs_questions',
        questionInsert,
        true,
        ['formPosition', 'type', 'liability'],
        ['score'],
        ['includeInScore'],
        { section: [questionSectionsByOriginalId, 'sectionId'] }
      )
      //Now we load the events
      await loadDetachedTable('modx_bcs_events', eventInsert, true)
      //Now we load the compliance events
      await loadDetachedTable('modx_bcs_compliance_event', complianceEventInsert, false, [
        'planStartMonth',
        'type'
      ])
      //Now we load the employers
      await loadDetachedTable(
        'modx_bcs_employers',
        employerInsert,
        true,
        [],
        ['percentageComplianceRisk', 'totalComplianceRisk'],
        [],
        { agency: [agencyByOriginalId, 'agencyId'] }
      )

      //Now we load the employers plan years
      await loadDetachedTable(
        'modx_bcs_employer_plan_years',
        employerPlanYearInsert,
        true,
        [],
        [],
        [],
        {
          employer: [employerByOriginalId, 'employerId'],
          agency: [agencyByOriginalId, 'agencyId']
        }
      )

      //Now we load the answers
      await loadDetachedTable('modx_bcs_answers', answerInsert, true, [], [], [], {
        question: [questionByOriginalId, 'questionId'],
        user: [userByOriginalId, 'userId'],
        employerPlanYear: [employerPlanYearByOriginalId, 'employerPlanYearsId']
      })

      //Now we load the reminders sent
      await loadDetachedTable('modx_bcs_reminders_sent', reminderSentInsert, false, [], [], [], {
        user: [userByOriginalId, 'userId'],
        employer: [employerByOriginalId, 'employerId']
      })
    }
  }

  return {
    loadData,
    deleteAllUsers
  }
}
