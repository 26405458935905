<script setup>
  import { storeToRefs } from 'pinia'
  import TextWithDefinitions from './../TextWithDefinitions.vue'
  import { useNotesStore } from '../../stores/NotesStore.js'
  import { getTodayFormatted } from '../../utils'
  const notesStore = useNotesStore()
  const { setDeleteNote } = notesStore
  const { selectedNote } = storeToRefs(notesStore)

  const props = defineProps({
    noteData: {
      type: Object,
      required: true
    },
    tab: {
      type: String,
      required: true
    }
  })
</script>

<template>
  <div v-if="props.noteData.category === 'Assessment' || props.noteData.category === 'Planning'">
    <div
      :class="{ selected: props.noteData.id == selectedNote?.id }"
      class="text-14 item-note border-style">
      <strong class="d-flex justify-content-between">
        {{ props.noteData.user }}
        <!-- {{ props.noteData.question }} -->
        <span v-if="props.tab === 'All'" class="pill">{{ props.noteData.category }}</span>
      </strong>
      <div class="last-edit">Last Edit:
        {{ getTodayFormatted() === props.noteData.note_updated_at ? 'Today' : (props.noteData.dateFormatted || 'Today') }}
      </div>
      <div class="assessment-item section-assessment">
        <strong>Section:</strong>
        <span>{{ props.noteData.subtitle }}</span>
      </div>
      <div class="assessment-item question-assessment">
        <strong>
          {{ props.noteData.category === 'Assessment' ? 'Question:' : 'Discussion Points:' }}
        </strong>
        <span>
          <TextWithDefinitions :key="props.noteData.question" :text="props.noteData.question" />
        </span>
      </div>
      <div class="assessment-item description">
        <strong>Note:</strong>
        <span>{{ props.noteData.notes }}</span>
        <!-- <div @click="openModal" class="description-delete text-danger">Delete</div> -->
      </div>
    </div>
  </div>

  <div v-else-if="props.noteData.category === 'General'">
    <div
      :class="{ selected: props.noteData.id == selectedNote?.id }"
      class="text-14 item-note border-style">
      <strong class="d-flex justify-content-between">
        {{ props.noteData?.user }}
        <span v-if="props.tab === 'All'" class="pill">{{ props.noteData.category }}</span>
      </strong>
      <div class="general-item last-edit">
        Last Edit:
        {{ getTodayFormatted() === props.noteData.date ? 'Today' : props.noteData.dateFormatted }}
      </div>
      <div class="general-item description">
        {{ props.noteData.note }}
        <div @click="setDeleteNote()" class="description-delete text-danger">Delete</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .item-note {
    padding-left: 60px;
    cursor: pointer;
    padding-right: 10px;
  }

  .last-edit {
    color: var(--bs-gray-600);
    margin-top: 5px;
  }

  .description {
    &-delete {
      cursor: pointer;
      margin-top: 10px;
      width: fit-content;
    }
  }

  .pill {
    background-color: #6c757d;
    color: #fff;
    padding: 4px 10px;
    border-radius: 9999px;
    height: 25px;
  }

  .assessment-item,
  .general-item {
    margin-top: 8px;

    span {
      font-weight: 300;
      color: #495057;
      padding-left: 8px;
    }
  }

  .selected {
    background-color: var(--bs-gray-100);
    border-left: 4px solid #a193cc;
  }
</style>
